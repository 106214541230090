import React, { useContext, useState } from 'react'
import { Button, Col, Pagination, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import moment from 'moment'
import { SportsIcons } from 'components/SportsIcons'
import PlayerMarketsListing from 'components/PlayerMarketsListing'
import { Event } from 'utils/sports.types'
import SuspendOverlay from 'components/SuspendOverlay'
import _ from 'lodash'
import styles from './playerCatalog.module.css'

export default observer(function PlayerCatalog() {
  const { t } = useTranslation()
  const { sports } = useContext(StoreContext)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 30

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleSelectedEvent = (match: Event) => {
    if (sports.selectedMatch?.intKey !== match.intKey) {
      match.allOddsList = _.sortBy(match.allOddsList, ['limit', 'outcomeId'])
      sports.setSelectedEvent(match)
    } else sports.unsetSelectedEvent()
  }

  const PrematchTableHeader = () => {
    const headerConfig = [
      {
        span: 3,
        key: 1,
        headerText: t('dateTime'),
      },
      {
        span: 2,
        key: 2,
        headerText: t('sport'),
      },
      {
        span: 2,
        key: 3,
        headerText: t('mCode'),
      },
      {
        span: 4,
        key: 4,
        headerText: t('players'),
      },
      {
        span: 6,
        key: 5,
        headerText: t('homeCompetitor'),
      },
      {
        span: 6,
        key: 6,
        headerText: t('awayCompetitor'),
      },
      {
        span: 1,
        key: 7,
        headerText: t('all'),
      },
    ]

    return (
      <Row gutter={1}>
        {headerConfig.map((c) => (
          <Col span={c.span} key={c.key}>
            <div className={styles.matchHeaderColumn}>
              <span>{c.headerText}</span>
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <div className={styles.playerCatalog}>
      <Row>
        <div className={styles.matchHeader}>
          <span>{t('matches')}</span>
        </div>
      </Row>
      <PrematchTableHeader />
      {sports.loaded ? (
        <div className={styles.playerOffer}>
          {sports.filteredPlayerEvents.map(
            (el, index) =>
              index >= (currentPage - 1) * pageSize &&
              index < currentPage * pageSize && (
                <div key={el.intKey} className={styles.matchRow}>
                  {el.isSuspended && <SuspendOverlay />}
                  <Row gutter={1}>
                    <Col span={3}>
                      <div className={styles.matchColumn}>
                        <span>
                          {moment.utc(el.start).local().format('DD/MM HH:mm')}
                        </span>
                      </div>
                    </Col>
                    <Col span={2}>
                      <div className={styles.matchColumn}>
                        <div className={styles.iconCircle}>
                          {SportsIcons.get(el.sportId)}
                        </div>
                      </div>
                    </Col>
                    <Col span={2}>
                      <div className={styles.matchColumn}>
                        <span>{el.eventCode}</span>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className={styles.matchColumn}>
                        <span>{el.playerName}</span>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className={styles.matchColumn}>
                        <span>{el.dualHome}</span>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className={styles.matchColumn}>
                        <span>{el.dualAway}</span>
                      </div>
                    </Col>
                    <Col span={1}>
                      <div className={styles.matchColumn}>
                        <Button
                          size="small"
                          style={{ fontSize: '10px', padding: '4px' }}
                          onClick={() => toggleSelectedEvent(el)}
                        >
                          {t('all')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {sports.selectedMatch &&
                    sports.selectedMatch.intKey === el.intKey && (
                      <PlayerMarketsListing event={sports.selectedMatch} />
                    )}
                </div>
              )
          )}
        </div>
      ) : (
        <div className={styles.noResults}>{t('noResults')}</div>
      )}
      <Pagination
        style={{ marginTop: 'auto' }}
        defaultCurrent={1}
        current={currentPage}
        pageSize={pageSize}
        onChange={handlePageChange}
        showSizeChanger={false}
        total={sports.filteredPlayerEvents.length}
      />
    </div>
  )
})
