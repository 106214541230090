import { Input } from 'antd'
import React, { forwardRef, useContext } from 'react'
import { SlipGroup } from 'store/SlipStore'
import StoreContext from 'store/StoreContext'
import { handleKeyEvent, isPlus, Keycodes } from 'utils/keyboardEvents'
import styles from './slipReview.module.css'

interface SystemInputProps {
  group: SlipGroup
  value: number | null
  index: number
  visible: boolean
  eventsCount: number
  onRemove: (position: number) => void
  onEnter: () => void
  focusNext: any
}

const SystemInput = (props: SystemInputProps, ref: any) => {
  const { slip } = useContext(StoreContext)
  const {
    group,
    value,
    index,
    visible,
    eventsCount,
    onRemove,
    onEnter,
    focusNext,
  } = props

  const onInputRemove = (e: any) => {
    if (!value && group.system.length > 1) {
      slip.removeSystem(group, index)
      onRemove(index)
      e.preventDefault()
    }
  }

  const handleKeyPress = (e: any) => {
    if (isPlus(e)) {
      e.preventDefault()
      if (index + 1 === group.system.length) {
        slip.addEmptySystem(group)
      } else {
        focusNext()
      }
    } else {
      handleKeyEvent(e, {
        [Keycodes.ENTER]: onEnter,
        [Keycodes.BACKSPACE]: onInputRemove,
      })
    }
  }

  const onChange = (e: any) => {
    const system = Number(e.target.value)
    const isNumber = Number.isNaN(system)
    const exists = group.system.includes(system)
    const isValid = system <= eventsCount

    if (!isNumber && !exists && isValid) {
      slip.updateSystem(group, system, index)
    }
  }

  if (!visible) return null

  return (
    <Input
      autoFocus={index !== 0}
      ref={ref}
      className={styles.systemInput}
      value={value || ''}
      size="small"
      onKeyDown={handleKeyPress}
      onChange={onChange}
    />
  )
}

export default forwardRef(SystemInput)
