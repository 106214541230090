import React, { useCallback, useContext, useRef } from 'react'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import { Button, Col, Row } from 'antd'
import { Event } from 'utils/sports.types'
import SuspendOverlay from 'components/SuspendOverlay'
import styles from './marketsListing.module.css'
import { sortOddsByLimitOrOutcomeId } from '../../utils/generalHelpers'

interface MarketsListingProps {
  event: Event
  live: boolean
}

export default observer(function MarketsListing({
  event,
  live,
}: MarketsListingProps) {
  const { sports, slip } = useContext(StoreContext)
  const getMarkets = (id: number) => {
    if (!live) {
      return sports.markets.find((sport) => sport.id === id)?.marketGroupsList
    }
    return sports.liveMarkets.find((sport) => sport.id === id)?.marketGroupsList
  }
  const createRefArray = useCallback(() => {
    const tmpArray: any[] = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 600; i++) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      tmpArray.push(useRef<HTMLDivElement>(null))
    }
    return tmpArray
  }, [])

  const refArray = createRefArray()

  const setHighlightedOdd = (intKey: number, oddIntKey: number) => {
    let higlightedClass = ''
    const event: { selectedOdds: any[] } = slip.findEvent(intKey)
    if (event) {
      if (event.selectedOdds.find((odd) => odd.int_key === oddIntKey))
        higlightedClass = styles.highlightedColumn
    }
    return higlightedClass
  }

  const checkIsSuspended = (marketId: number) => {
    if (live) {
      if (sports.selectedLiveMatch && sports.selectedLiveMatch.marketsMap) {
        const marketToSuspend = sports.selectedLiveMatch?.marketsMap.find(
          (market) => parseInt(market[0], 10) === marketId
        )
        return marketToSuspend ? marketToSuspend[1] : false
      }
      return false
    }
    if (sports.selectedMatch && sports.selectedMatch.marketsMap) {
      const marketToSuspend = sports.selectedMatch?.marketsMap.find(
        (market) => parseInt(market[0], 10) === marketId
      )
      return marketToSuspend ? marketToSuspend[1] : false
    }
    return false
  }

  const getOddClassName = (odd: any, isSuspended: boolean, spanRef: any) => {
    spanRef?.current?.classList?.remove(styles.oddDownColor)
    spanRef?.current?.classList?.remove(styles.oddUpColor)
    spanRef?.current?.classList?.add(styles.oddDefaultColor)
    if (isSuspended) {
      spanRef?.current?.classList?.add(styles.oddColorMatchSuspended)
      return styles.oddColorMatchSuspended
    }
    if (odd.isDown) {
      spanRef?.current?.classList?.add(styles.oddDownColor)
      setTimeout(function () {
        spanRef?.current?.classList?.remove(styles.oddDownColor)
        spanRef?.current?.classList?.add(styles.oddDefaultColor)
        odd.isDown = false
        odd.isUp = false
      }, 6000)
      return styles.oddDownColor
    }
    if (odd.isUp) {
      spanRef?.current?.classList?.add(styles.oddUpColor)
      setTimeout(function () {
        spanRef?.current?.classList?.remove(styles.oddUpColor)
        spanRef?.current?.classList?.add(styles.oddDefaultColor)
        odd.isDown = false
        odd.isUp = false
      }, 6000)
      return styles.oddUpColor
    }
    return styles.oddDefaultColor
  }

  const checkIfMarketListhasOutcome = (marketList: any, odds: any) => {
    let hasOdds = false
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < odds.length; i++) {
      if (marketList.outcomesList.has(odds[i].outcomeId)) {
        hasOdds = true
        break
      }
    }
    return hasOdds
  }

  const oddCellClasses = (el: any, ref: any) => {
    const higlightClass = setHighlightedOdd(event.intKey, el.intKey || 0)
    const statusClass = getOddClassName(el, el.isSuspended, ref)
    return `${styles.matchColumn} ${higlightClass} ${statusClass}`
  }

  const handleOddClick = (el: any, marketList: any) => {
    slip.toggleOdd(event, el, marketList.outcomesList.get(el.outcomeId))
  }

  const getOutcomePrintCode = (marketList: any, el: any) => {
    return marketList?.outcomesList.get(el.outcomeId)?.printCode
  }

  const renderOdd = (eventIntKey: number, intKey: number) => {
    const oddValue = (sports.eventOddListIntKeyMap(eventIntKey) as any)[
      `e-${intKey}`
    ]?.oddValue
    return oddValue ? (oddValue / 100).toFixed(2) : 'N/A'
  }

  return (
    <div style={live ? { height: '788px' } : { height: 'auto' }}>
      {live && (event.isSuspended || event.betStop) && <SuspendOverlay />}
      <Row gutter={4} style={{ padding: '8px 0', backgroundColor: '#000' }}>
        {getMarkets(event.sportId)?.map((el) => (
          <Col key={el.id} style={{ margin: '2px 0' }}>
            <Button
              size="small"
              style={{
                width: '190px',
                fontSize: '12px',
                color:
                  sports.selectedMarketGroup?.id === el.id ? 'green' : 'white',
                borderColor:
                  sports.selectedMarketGroup?.id === el.id
                    ? 'green'
                    : '#434343',
              }}
              onClick={() => sports.setSelectedMarketGroup(el)}
            >
              {el.name}
            </Button>
          </Col>
        ))}
      </Row>
      <div style={{ paddingTop: '5px', backgroundColor: '#000' }}>
        <Row gutter={8}>
          {sports.selectedMarketGroup &&
            sports.selectedMarketGroup.marketsList.map((marketList) => {
              if (checkIfMarketListhasOutcome(marketList, event.allOddsList)) {
                return (
                  <Col
                    span={12}
                    key={marketList.id}
                    style={{ marginBottom: '8px', position: 'relative' }}
                  >
                    {checkIsSuspended(marketList.id) && <SuspendOverlay />}
                    <Row>
                      <div className={styles.matchHeader}>
                        {marketList.name}
                      </div>
                    </Row>
                    <Row>
                      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                      {sortOddsByLimitOrOutcomeId(event.allOddsList)?.map(
                        (el, index) => {
                          return (
                            marketList.outcomesList.has(el.outcomeId) && (
                              <>
                                {el.limit !== 0 &&
                                  (el.limit2 === 0 ||
                                    el.limit2 === null ||
                                    Number.isNaN(el.limit2)) && (
                                    <Col span={3} key={el.intKey}>
                                      <div className={styles.limitColumn}>
                                        {el.limit}
                                        {el.limit2 &&
                                        el.limit2 !== 0 &&
                                        el.limit2 !== null &&
                                        !Number.isNaN(el.limit2)
                                          ? `,${el.limit2}`
                                          : ''}
                                      </div>
                                    </Col>
                                  )}
                                {el.limit !== 0 &&
                                  el.limit2 !== null &&
                                  !Number.isNaN(el.limit2) &&
                                  el.limit2 !== 0 && (
                                    <Col span={3} key={el.intKey}>
                                      <div className={styles.limit2Column}>
                                        {el.limit2}
                                        <br />
                                        {el.limit}
                                      </div>
                                    </Col>
                                  )}
                                <Col span={5} key={el.limit ? null : el.intKey}>
                                  <div className={styles.matchColumn}>
                                    {getOutcomePrintCode(
                                      marketList,
                                      el
                                    ).replace('TR gem', '')}
                                  </div>
                                </Col>
                                <Col span={el.limit !== 0 ? 4 : 3}>
                                  {(el.isSuspended ||
                                    !el.oddValue ||
                                    el.oddValue?.toString() === '100' ||
                                    el.oddValue?.toString() === '1') && (
                                    <SuspendOverlay />
                                  )}
                                  <div
                                    ref={refArray[index]}
                                    style={{ cursor: 'pointer' }}
                                    className={oddCellClasses(
                                      el,
                                      refArray[index]
                                    )}
                                    onClick={() =>
                                      handleOddClick(el, marketList)
                                    }
                                  >
                                    {el.oddValue?.toString() === '100' ||
                                    el.oddValue?.toString() === '1'
                                      ? ''
                                      : renderOdd(event.intKey, el.intKey)}
                                  </div>
                                </Col>
                              </>
                            )
                          )
                        }
                      )}
                    </Row>
                  </Col>
                )
              }
              return ''
            })}
        </Row>
      </div>
    </div>
  )
})
