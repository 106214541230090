import React, { useContext } from 'react'
import { Col, Row } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { useTranslation } from 'react-i18next'
import styles from './liveMatchDetails.module.css'

export default observer(function LiveMatch() {
  const { sports } = useContext(StoreContext)
  const { t } = useTranslation()

  const getHTResoult = (event: any, home: boolean) => {
    if (!event?.detailedResult) {
      return '-'
    }
    if (
      event.sportId === sports?.sportNames.get('Fudbal') ||
      event.sportId === sports?.sportNames.get('Futsal')
    ) {
      if (event.currentPhase?.SN === 'FH') {
        return '-'
      }
      if (home) {
        return event.detailedResult?.FH ? event.detailedResult?.FH?.G[0] : '0'
      }
      return event.detailedResult?.FH ? event.detailedResult?.FH?.G[1] : '0'
    }

    return '-'
  }
  const getCurrentResoult = (event: any, home: boolean) => {
    if (!event?.detailedResult) {
      return '-'
    }
    if (
      event.sportId === sports?.sportNames.get('Fudbal') ||
      event.sportId === sports?.sportNames.get('Futsal')
    ) {
      if (home) {
        return `${event.currentResult?.G[0]}`
      }
      return `${event.currentResult?.G[1]}`
    }

    return '-'
  }

  return (
    <>
      {sports.selectedLiveMatch && (
        <div>
          <Row>
            <Col span={24}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.competitionName}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.timeColumn}>
                {!sports.selectedLiveMatch?.currentPhase.isBreak
                  ? `${
                      sports.selectedLiveMatch?.currentPhase?.T?.PRT ||
                      sports.selectedLiveMatch?.currentPhase?.T?.M ||
                      sports.selectedLiveMatch?.currentPhase?.time ||
                      sports.selectedLiveMatch?.currentPhase?.SN ||
                      0
                    }' / ${sports.selectedLiveMatch?.currentPhase?.N}`
                  : t('break')}
              </div>
            </Col>
            <Col span={4}>
              <div className={styles.timeColumn}>Cards</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>CK</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>HT</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>FT</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>ET</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>PK</div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.home}
              </div>
            </Col>
            <Col span={4}>
              <div className={styles.detailsColumn}>
                <div className={styles.cardsHolder}>
                  <div className={styles.cardIconYellow} />
                  &nbsp;
                  <div>
                    {
                      sports.selectedLiveMatch?.eventStatistics?.cards
                        ?.cardsHome?.yellow
                    }
                  </div>
                  &nbsp;
                  <div className={styles.cardIconRed} />
                  &nbsp;
                  {
                    sports.selectedLiveMatch?.eventStatistics?.cards?.cardsHome
                      ?.red
                  }
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.eventStatistics?.CK
                  ? sports.selectedLiveMatch?.eventStatistics?.CK?.home
                  : '-'}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {getHTResoult(sports.selectedLiveMatch, true)}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {getCurrentResoult(sports.selectedLiveMatch, true)}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.detailedResult?.OT
                  ? sports.selectedLiveMatch?.detailedResult.OT[0]
                  : '-'}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.detailedResult?.PK
                  ? sports.selectedLiveMatch?.detailedResult.PK?.G[0]
                  : '-'}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.away}
              </div>
            </Col>
            <Col span={4}>
              <div className={styles.detailsColumn}>
                <div className={styles.cardsHolder}>
                  <div className={styles.cardIconYellow} />
                  &nbsp;
                  <div>
                    {
                      sports.selectedLiveMatch?.eventStatistics?.cards
                        ?.cardsAway?.yellow
                    }
                  </div>
                  &nbsp;
                  <div className={styles.cardIconRed} />
                  &nbsp;
                  {
                    sports.selectedLiveMatch?.eventStatistics?.cards?.cardsAway
                      ?.red
                  }
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.eventStatistics?.CK
                  ? sports.selectedLiveMatch?.eventStatistics?.CK?.away
                  : '-'}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {getHTResoult(sports.selectedLiveMatch, false)}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {getCurrentResoult(sports.selectedLiveMatch, false)}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.detailedResult?.OT
                  ? sports.selectedLiveMatch?.detailedResult?.OT[1]
                  : '-'}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.detailedResult?.PK
                  ? sports.selectedLiveMatch?.detailedResult?.PK?.G[1]
                  : '-'}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
})
