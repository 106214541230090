import React, { useContext, useEffect, useState } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import SuspendOverlay from 'components/SuspendOverlay'
import {
  getOddIsSuspended,
  getOutcomeBySportAndPrintCode,
} from 'utils/slip.service'
import styles from './oddCell.module.css'

export default observer(function OddCell({
  el,
  code,
}: {
  el: any
  code: string
}) {
  const { sports, slip } = useContext(StoreContext)
  const [isToggled, setIsToggled] = useState(false)
  const [outcome, setOutcome] = useState<any>({})
  const [odd, setOdd] = useState<any>({})

  const renderOdd = () => {
    const oddValue = (sports.eventOddList(el.intKey) as any)[`e-${outcome?.id}`]
      ?.oddValue
    if (oddValue.toString() === '1' || oddValue.toString() === '100') {
      return ' '
    }
    return oddValue ? (oddValue / 100).toFixed(2) : ' '
  }
  const isSuspended = () =>
    getOddIsSuspended(
      el,
      sports.outcomesBySportMap,
      code,
      sports.eventOddList,
      outcome.marketId
    )

  const setHighlightedOdd = (intKey: number, oddIntKey: number) => {
    let higlightedClass = ''
    if (isToggled) {
      higlightedClass = styles.highlightedColumn
      return higlightedClass
    }
    const event: { selectedOdds: any[] } = slip.findEvent(intKey)
    if (event) {
      if (event.selectedOdds.find((odd) => odd.int_key === oddIntKey))
        higlightedClass = styles.highlightedColumn
    }
    return higlightedClass
  }

  const getOddClassName = (odd: any, isSuspended: boolean) => {
    if (
      isSuspended ||
      odd?.oddValue?.toString() === '100' ||
      odd?.oddValue?.toString() === '1'
    )
      return styles.oddColorMatchSuspended
    if (odd?.isDown) return styles.oddDownColor
    if (odd?.isUp) return styles.oddUpColor
    return isToggled ? styles.highlightedColumn : styles.oddDefaultColor
  }

  const oddCellClasses = (event: any) => {
    const higlightClass = setHighlightedOdd(el?.intKey, event?.intKey || 0)
    const statusClass = getOddClassName(event, event?.isSuspended)
    return `${styles.matchColumn} ${higlightClass} ${statusClass}`
  }

  const onHighlightChange = (oddIntKey: any) =>
    autorun(() => {
      if (!oddIntKey) return
      if (slip.isHighlighted(el?.intKey, Number(oddIntKey))) {
        setIsToggled(true)
      } else {
        setIsToggled(false)
      }
    })

  const isOddSuspended = (odd: any) => {
    if (
      odd === undefined ||
      odd.toString() === '1' ||
      odd.toString() === '100'
    ) {
      return true
    }
    return false
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const tempOutcome = getOutcomeBySportAndPrintCode(
      sports.outcomesBySportMap,
      el.sportId,
      code
    )
    if (!tempOutcome) return
    const tempOdd = (sports.eventOddList(el.intKey) as any)[
      `e-${tempOutcome.id}`
    ]
    setOutcome(tempOutcome)
    setOdd(tempOdd)
    onHighlightChange(tempOdd && tempOdd?.intKey)
    // eslint-disable-next-line
  }, [])

  const onClick = () => {
    if (
      !odd ||
      !odd?.oddValue ||
      odd.oddValue?.toString() === '1' ||
      odd.oddValue?.toString() === '100'
    )
      return
    slip.toggleOdd(el, odd, outcome)
  }

  return (
    <>
      {isSuspended() && <SuspendOverlay />}
      <div
        className={oddCellClasses(
          (sports.eventOddList(el?.intKey) as any)[`e-${outcome?.id}`]
        )}
        onClick={() => onClick()}
      >
        {isOddSuspended(
          (sports.eventOddList(el.intKey) as any)[`e-${outcome?.id}`]?.oddValue
        ) ? (
          <SuspendOverlay />
        ) : (
          renderOdd()
        )}
      </div>
    </>
  )
})
