import {
  SingleTicket,
  SlipEvent,
  SlipPrint,
  SlipOdd,
} from '../store/models/SlipModels'

const getEvents = (events: SlipEvent[]) => {
  return events.reduce((acc: SlipEvent[], event: SlipEvent) => {
    if (event.event_type === 'A') {
      event.event_type = 'ANTEPOST'
    }
    // ovo je zbog viseznaka, na staroj platformi stampa sa ovakvom strukturom, svaki odd iz viseznaka
    // se salje kao zaseban event sa jednim oddom
    if (event.odds.length > 1) {
      const events: SlipEvent[] = []
      event.odds.forEach((odd) => {
        const newEvent = { ...event, odds: [odd] }
        events.push(newEvent)
      })
      return [...acc, ...events]
    }
    return [...acc, event]
  }, [])
}

const getMarketName = (event: SlipEvent, odd: SlipOdd) =>
  event.event_type === 'ANTEPOST'
    ? (event.event_name as string)
    : (odd.market_name as string)

const getPrintCode = (odd: SlipOdd) => {
  const limit = odd.limit_value ? `(${odd.limit_value})` : ''
  if (odd.outcome_print_code) {
    return odd.outcome_print_code + limit
  }
  if (odd.outcome_name) {
    return odd.outcome_name + limit
  }
  if (odd.name) {
    return odd.name + limit
  }

  return ''
}

const getEventName = (event: SlipEvent) =>
  event.event_type === 'ANTEPOST'
    ? (event as any).market_name
    : event.event_name

const formatSlipPrint = (slip: SingleTicket): SlipPrint => {
  return {
    amount: slip.amount,
    max_odds_value: slip.max_odds_value,
    max_winning_amount: slip.max_winning_amount,
    min_odds_value: slip.min_odds_value,
    min_winning_amount: slip.min_winning_amount,
    max_bonus_amount: slip.max_bonus_amount,
    min_bonus_amount: slip.min_bonus_amount,
    max_total_amount: slip.max_total_amount,
    bonus_percentage: slip.bonus_percentage,
    min_total_amount: slip.min_total_amount,
    number_of_matches: slip.number_of_matches,
    short_uuid: slip.short_uuid,
    created_at: slip.created_at,
    is_system: slip.is_system,
    num_of_combinations: slip.number_of_combinations,
    location: 'FWW',
    address: 'Bulevar Mihajla Pupina 115a',
    slip_groups: slip.slip_groups.map((group) => ({
      system: group.system,
      num_of_combinations: group.num_of_combinations,
      events: getEvents(group.events).map((event) => ({
        event_start_time: event.event_start_time,
        event_code: event.event_code,
        competition_name: event.competition_name,
        event_name: getEventName(event),
        sport_name: event.sport_name,

        event_type: event.event_stage.toUpperCase(),
        odds: event.odds.map((odd) => {
          return {
            market_name: getMarketName(event, odd),
            outcome_print_code: getPrintCode(odd),
            odd_value: odd.odd_value.toFixed(2),
          }
        }),
      })),
    })),
    status: slip.status,
  }
}

export default formatSlipPrint
