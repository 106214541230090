export const sr = {
  translation: {
    sportBetting: 'Sportsko Klađenje',
    username: 'Korisničko ime',
    password: 'Lozinka',
    login: 'Uloguj se',
    validationUsername: 'Molimo Vas unesite validno Korisničko ime',
    validationPassword: 'Molimo Vas unesite validnu Lozinku',
    logout: 'Izloguj se',
    matchCatalog: 'Lista utakmica',
    prematch: 'Pre utakmice',
    live: 'Uživo',
    outright: 'Outright',
    prematchCatalogFilters: 'Pretraga Liste',
    prematchCatalog: 'Lista',
    antepostCatalog: 'Outright Katalog',
    matchBetSelection: 'Izbor klađenja',
    payInReport: 'Izveštaj Uplate',
    searchLiveMatches: 'Pretraga Utakmica Uživo',
    liveMatchDetails: 'Detalji Utakmice Uživo',
    liveMatches: 'Utakmice uživo',
    liveMatchBets: 'Izbor klađenja uživo',
    matchName: 'Naziv meča',
    betSearch: 'Pretraga igre',
    matchCode: 'Šifra utakmice',
    lock: 'Zaključaj',
    betCode: 'Igra',
    lineGroup: 'Line Group',
    keepMatches: 'Zadrži utakmice',
    slipReview: 'Pregled tiketa',
    slipPayIn: 'Uplata tiketa',
    payInAmount: 'Uplata',
    payIn: 'Uplati',
    cancel: 'Otkaži',
    sport: 'Sport',
    country: 'Država',
    competition: 'Takmičenje',
    sort: 'Sort',
    filter: 'Filter',
    slipLog: 'Slip Log',
    getSlips: 'Dovuci tikete',
    eSlipId: 'ESlip Id',
    slipId: 'Slip Id',
    submit: 'Unesi',
    preview: 'Pregled',
    checkStatus: 'Proveri status',
    payOut: 'Isplata',
    cancelSlip: 'Otkaži tiket',
    loading: 'Učitavanje...',
    matches: 'Utakmice',
    dateTime: 'Dat. Vreme',
    time: 'Vreme',
    mCode: 'Šifra',
    homeCompetitor: 'Domaći Takmičar',
    awayCompetitor: 'Gostujući takmičar',
    all: 'SVE',
    betParameter: 'Par',
    groups: 'Grupa',
    odd: 'Kvota',
    minOdd: 'Min kvota',
    maxOdd: 'Max kvota',
    minWin: 'Min dobitak',
    maxWin: 'Max dobitak',
    minPayIn: 'Min uplata',
    matchNum: 'Broj mečeva',
    combNum: 'Broj kombinacija',
    maxPayOut: 'Max isplata',
    noResults: 'Nema Rezultata',
    competitor: 'Takmičar:',
    noSelection: 'Nema selektovane utakmice',
    minimumAmount: 'Minimalna uplata je {{amount}}.',
    maxEvents: 'Maksimalan broj događaja je {{eventsNumber}}.',
    maxCombination: 'Maksimalan broj kombinacija je {{combinations}}.',
    minAmountPerCombination: 'Minimalna uplata po kombinaciji je {{amount}}.',
    maxEventOdds: 'Maksimalan broj igara za isti događaj je {{max}}.',
    maxSlipOdd: 'Maksimalna kvota je {{max}}.',
    marketMaxAmount: 'Nije ispunjen uslov maksimalne uplate za markete.',
    oddMaxAmount: 'Nije ispunjen uslov maksimalne uplate za tip',
    eventMaxAmount: 'Nije ispunjen uslov maksimalne uplate za mečeve.',
    suspendedMarkets: 'Klađenje na jedan ili više marketa je onemogućeno.',
    suspendedOdd: 'Klađenje na jedan ili više tipova je onemogućeno.',
    eventEnded: 'Neki od eventova je završen.',
    suspendedEvent: 'Klađenje na jedan ili više događaja je onemogućeno.',
    noBetDual:
      'Nije moguće kladiti se na playera koji je vezan za dualni event koji je u tiketu.',
    selectedEventSuspended: 'Izabrani događaj je suspendovan.',
    selectedBetSuspended: 'Izabrani bet je suspendovan.',
    eventNotFound: 'Ne postoji događaj sa tom šifrom',
    maxPlayerEvents: 'Maksimalan broj IGRAČI događaja je {{max}}.',
    liveEventPrematchMarket:
      'Nije moguće igrati prematch igre na utakmici koja je počela.',
    placeBetGenericError: 'Greška pri uplati tiketa. Pokušajte ponovo.',
    slipNotFound: 'Tiket nije pronađen ili je neaktivan!',
    rollBackSuccess: 'Tiket je uspešno storniran.',
    rollBackErrorSlipStarted:
      'Nije moguće stornirati tiket. Utakmice su već počele ili su se završile.',
    somethingWentWrong: 'Došlo je do greške. Pokušajte ponovo.',
    slipNotResolved: 'Tiket nije odobren za isplatu.',
    slipPaid: 'Tiket je isplaćen.',
    slipNotPassed: 'Tiket nije odobren za isplatu.',
    slipPayotSuccess: 'Tiket je uspešno označen kao isplaćen.',
    liveSuspended: 'Klađenje uživo je suspendovano',
    break: 'Pauza',
    favourites: 'Favourites',
    prematchSuspended: 'Klađenje je suspendovano',
    sbClientTab: 'SB Client',
    dateTimeFromTo: 'Date Time From To',
    pdf: 'PDF',
    oneH: '1 h',
    twoH: '3 h',
    today: 'Danas',
    catalog: 'Katalog',
    allCapital: 'Sve',
    magazin: 'Magazin',
    sports: 'Sports',
    search: 'Pretraga...',
    homeCompetitorPdf: 'Domaći Tak.',
    awayCompetitorPdf: 'Gostujući Tak.',
    competitionPdf: 'Takmičenje',
    allSports: 'Svi sportovi',
    bettingAmountAboveLive: 'Uplata je veća od dozvoljene uplate {{max}}. ',
    bettingOddAbove: 'Ukupna kvota je veća od dozvoljene ukupne kvote. ',
    searchDeposit: 'Pretraži',
    online: 'Online',
    userDetails: 'Korisnički Podaci',
    deposit: 'Depozit',
    withdraw: 'Povući',
    userName: 'Korisničko ime',
    dateOfBirth: 'Datum rodjenja',
    name: 'Ime',
    surname: 'Prezime',
    email: 'Email',
    payInAmountValue: 'Cifra za uplatu',
    withdrawAmountValue: 'Cifra za isplatu',
    withdrawCode: 'Kod za podizanje',
    jmbgLast6Digit: 'JMBG zadnjih 6 cifara',
    jmbg: 'JMBG',
    jmbgConfirm: 'JMBG potvrda',
    reservationAmount: 'Rezervisani iznos',
    mandatoryField: 'Polje je obavezno.',
    yes: 'DA',
    no: 'NE',
    depositSuccessMsg: 'Deposit je uspešno uplaćen',
    withdrawSuccessMsg: 'Sredstva su uspešno isplaćena',
    depositWarningMsg: 'Došlo je do greške.',
    userNotFoundMsg: 'Korisnik nije pronadjen!',
    positiveNumber: 'Minimalna vrednost je 1!',
    reservationNotFound: 'Rezervacija nije pronadjena!',
    errorWrongBettingPlace: 'Isplata je zakazana u drugoj poslovnici!',
    depositModalMsg:
      'Da li ste sigurno da zelite da uplatite ovaj iznos na {{username}} nalog?',
    withdrawModalMsg:
      'Da li ste sigurno da zelite da isplatite ovaj iznos sa {{username}} naloga?',
    availableWithdrawMsg:
      'Cifra za isplatu biće podešena na najveću dostupnu cifru: {{available}} !',
    players: 'Igrači',
    won: 'Dobitan',
    lost: 'Gubitan',
    pending: 'Čeka se',
    cashback: 'Cashback',
    accepted: 'Prihvaćen',
    paidOut: 'Isplaćen',
    pleaseRefresh: 'Molim vas osvežite stranu!',
    refresh: 'Osveži',
    onlyAntepost: 'Nije moguće mešati kratkoročne i dugoročne dogadjaje.',
    singleAntepost: 'Morate odigrati jedan dugoročan dogadjaj.',
    multiBetMaxReached: 'Maksimalan broj betova po jednom događaju!',
    apiError: 'Došlo je do greške pri api pozivu!',
    slipsManualTitle: 'Slipovi na manualnom odobravanju',
    slipsAllSubmitted: 'Log svih poslatih tiketa',
    greeko: 'Greeko',
    cashFlow: 'CashFlow',
    viewPreviousGames: 'Pregled kola',
    payment: 'Uplata',
    previousMatch: 'Predhodna kola',
    currentMatch: 'Trenutno kola',
    colNumber: 'Broj kola',
    insertNumber: 'Unesi broj',
    insertedNumbers: 'Uneti brojevi',
    system: 'Sistem',
    next: 'Narednih',
    amount: 'Iznos',
    total: 'Ukupno',
    reset: 'Reset',
    ticketCheck: 'Provera tiketa',
    previousTickets: 'Predhodni tiketi',
    ticketID: 'ID tiketa',
    checkTicket: 'Proveri tiket',
    repeat: 'Ponovi',
    cancelTicket: 'Storniraj',
    winTicketStatus: 'DOBITAN',
    note: 'Napomena',
    sendForApproval: 'Posalji na odobravanje',
    transacionLog: 'Dnevni Log transakcija',
    print: 'Print',
    transactionTime: 'TransactionTime',
    transactionType: 'TransactionType',
    transactionLogHeader: 'Log transakcija',
    canceled: 'Storniran',
    no_privileges: 'Nemate privilegije za ovu operaciju!',
    no_combine_dual: 'Nije moguće kombinovati Antepost sa Dualnim eventovima!',
    valid_numbers_greeko:
      'Validni brojevi za Greeko su izmedju 1 i 80 i moraju biti jedinstveni u tiketu!',
    next_rounds: 'Broj narednih rundi mora biti izmedju 1 i 10.',
    valid_length_greeko: 'Maximum numbers must be between 1 and 15',
    pay_value_greeko: 'Uplata mora biti izmedju 20 RSD i 10 000 RSD.',
    greeko_ticket_success: 'Uspešno ste uplatitli tiket!',
    greeko_ticket_delete: 'Uspešno ste stortnirali tiket!',
    pdfTitle: 'Raspored meceva po terminima. ',
    userPendingPayment: 'Najava isplate čeka na odobravanje!',
    userNotApprovedPayment: 'Isplata nije odobrena!"',
    cancelSlipModal: 'Da li ste sigurni da zalite da poništite slip?',
    payoutSlipModal: 'Da li ste sigurni da zalite da isplatite slip?',
    sportBettingSlipPayInCount: 'Slip broj uplata',
    sportBettingSlipPayInAmount: 'Slip iznos uplata',
    sportBettingSlipPayOutCount: 'Slip broj isplata',
    sportBettingSlipPayOutAmount: 'Slip iznos isplata',
    greekoSlipPayInCount: 'Greeko broj uplata',
    greekoSlipPayInAmount: 'Greeko iznos uplata',
    greekoSlipPayOutCount: 'Greeko broj isplata',
    greekoSlipPayOutAmount: 'Greeko iznos isplata',
    depositSlipPayInCount: 'Deposit broj uplata',
    depositSlipPayInAmount: 'Deposit iznos uplata',
    depositSlipPayOutCount: 'Deposit broj isplata',
    depositSlipPayOutAmount: 'Deposit iznos isplata',
  },
}
