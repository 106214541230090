import React, { useContext } from 'react'
import { SectionContainer } from 'components/SectionContainer'
import PrematchCatalogFilters from 'components/PrematchCatalogFilters'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import NoSelection from 'components/NoSelection'
import AntepostCatalog from 'components/AntepostCatalog'

export default observer(function Outright() {
  const { feed } = useContext(StoreContext)

  return (
    <div>
      {feed.isPrematchOff && <NoSelection translation="prematchSuspended" />}
      {!feed.isPrematchOff && (
        <>
          <SectionContainer translation="prematchCatalogFilters">
            <PrematchCatalogFilters eventType="outright" />
          </SectionContainer>
          <SectionContainer translation="antepostCatalog">
            <AntepostCatalog />
          </SectionContainer>
        </>
      )}
    </div>
  )
})
