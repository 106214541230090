import { LoginRequestType } from 'components/LoginForm'
import { action, makeObservable, observable, runInAction } from 'mobx'
import i18n from 'i18next'
import to from 'await-to-js'
import { message } from 'antd'
import RootStore from './RootStore'
import BaseStore from './BaseStore'
import { NetworkModels } from './models/NetworkModels'

interface UserType {
  id: number
  created_at: string
  modified_at: string
  username: string
  first_name: string
  last_name: string
  is_active: boolean
  user_type: string
  external_id: string
}

interface UserDeposit {
  id: number
  username: string
  first_name: string
  last_name: string
  email: string
  date_of_birth: string
}

interface UserWithdraw {
  id: number
  withdrawCode: string
  jmbgLast6Digit: string
  dateOfBirth: string
  username: string
  first_name: string
  last_name: string
  email: string
  ssn: string
  reservationAmount: number
  withdrawAmount: number
  betting_place_id: number
  withdrawal_reservation: number
}

interface LoginResponseType {
  token: string
}

interface GetUserResponseType {
  user: UserType
  betting_place_id: number
  betting_machine_id: number
}

export default class UserStore extends BaseStore {
  loginCall: NetworkModels<LoginResponseType>
  getUserCall: NetworkModels<GetUserResponseType>
  getUserCallUsernameAndBDateCall: NetworkModels<any>
  getUserByWithdrawCodeCall: NetworkModels<any>
  setDepositCall: NetworkModels<any>
  user: UserType | null = null
  selectedUser: UserDeposit | null = null
  selectedUserWithdraw: UserWithdraw | null = null
  isLoggedIn = false
  availableSum = null
  // TODO change this when we can retrieve it from be
  // this is on prod env
  // bettingPlaceId: string = process.env.NODE_ENV === 'development' ? '18' : '999'
  bettingPlaceId: any
  bettingMachineId: any
  timeDifference: any = 0.5
  isUserLoading: boolean

  constructor(rootStore: RootStore) {
    super(rootStore)
    this.isUserLoading = true
    this.loginCall = new NetworkModels(rootStore, {
      path: 'user/login/landbase',
      secured: false,
    })
    this.getUserCall = new NetworkModels(rootStore, {
      path: 'user/landbase',
      secured: true,
    })
    this.getUserCallUsernameAndBDateCall = new NetworkModels(rootStore, {
      path: 'user/all',
      secured: true,
    })
    this.getUserByWithdrawCodeCall = new NetworkModels(rootStore, {
      path: 'payments/lb/check-withdrawal-reservation',
      secured: true,
    })
    this.setDepositCall = new NetworkModels(rootStore, {
      path: 'payments/lb/deposit',
      secured: true,
    })

    this.decodeToken()
    makeObservable(this, {
      user: observable,
      selectedUser: observable,
      availableSum: observable,
      selectedUserWithdraw: observable,
      isLoggedIn: observable,
      isUserLoading: observable,
      login: action,
      decodeToken: action,
      logout: action,
      getUser: action,
      getUserByUsernameAndBDate: action,
      getUserByWithdrawCode: action,
      setDeposit: action,
      setWithdraw: action,
    })
  }

  decodeToken = () => {
    const token = localStorage.getItem('token')
    if (token) {
      this.isLoggedIn = true
    }
    return Promise.resolve()
  }

  logout = () => {
    localStorage.removeItem('token')
    this.isLoggedIn = false
    this.isUserLoading = false
    this.rootStore.sports.resetOffer()
  }

  login = async (data: LoginRequestType) => {
    const [err, res] = await to(this.loginCall.call('post', data))
    if (err) throw err
    res!.mapResult((result) => {
      localStorage.setItem('token', result.data.token as string)
      this.decodeToken()
      this.rootStore.sports.resetOffer()
      // TODO mocked this till we get bp id
      // this.bettingPlaceId = result.data.betting_place_id
      this.rootStore.router.router.push('/sport-betting/live')
      return true
    })
  }

  getUser = async () => {
    // @ts-ignore
    try {
      const [err, res] = await to(this.getUserCall.call('get'))
      if (!err) {
        runInAction(() => {
          res!.mapResult((result) => {
            this.user = result.data.user as UserType
            this.bettingPlaceId = result?.data?.betting_place_id
            this.bettingMachineId = result?.data?.betting_machine_id
            this.isUserLoading = false
            return true
          })
        })
      } else {
        this.isUserLoading = false
        this.logout()
        console.log('Enter error loader', err)
      }
    } catch (e) {
      console.log('Enter catch error block', e)
      this.isUserLoading = false
      this.logout()
      console.log('Enter error loader', e)
    }
  }

  getUserByUsernameAndBDate = async (
    username: string,
    date_of_birth: string
  ) => {
    const [err, res] = await to(
      this.getUserCallUsernameAndBDateCall.call(
        'get',
        null,
        `?username=${username}&date_of_birth=${date_of_birth}`
      )
    )
    if (!err) {
      runInAction(() => {
        res!.mapResult((result) => {
          if (result.data[0]) {
            this.selectedUser = result.data[0] as UserDeposit
          } else {
            this.selectedUser = null
            message.error(i18n.t('userNotFoundMsg'))
          }
          return true
        })
      })
    } else {
      // console.log(err)
    }
  }

  getUserByWithdrawCode = async (code: string, ssn: string) => {
    const [err, res] = await to(
      this.getUserByWithdrawCodeCall.call(
        'get',
        null,
        `?code=${code}&ssn=${ssn}`
      )
    )
    if (!err) {
      runInAction(() => {
        res!.mapResult((result) => {
          if (result.data?.user) {
            this.selectedUserWithdraw = result.data.user as UserWithdraw
            this.selectedUserWithdraw.withdrawCode = code
            this.selectedUserWithdraw.jmbgLast6Digit = ssn
            this.selectedUserWithdraw.reservationAmount =
              result.data?.withdrawal_reservation?.amount
            this.selectedUserWithdraw.withdrawAmount =
              result.data?.withdrawal_reservation?.amount
            this.selectedUserWithdraw.betting_place_id =
              result.data?.withdrawal_reservation?.betting_place_id
            this.selectedUserWithdraw.withdrawal_reservation =
              result.data?.withdrawal_reservation?.id
          } else {
            this.selectedUserWithdraw = null
            message.error(i18n.t('userNotFoundMsg'))
          }
          return true
        })
      })
    } else if (err.message === 'ERR_WRONG_BETTING_PLACE') {
      message.error(i18n.t('errorWrongBettingPlace'))
    } else if (err.message === 'ERR_PENDING_VALIDATION') {
      message.error(i18n.t('userPendingPayment'))
    } else if (err.message === 'ERR_WITHDRAWAL_NOT_PERMITTED') {
      message.error(i18n.t('userNotApprovedPayment'))
    } else {
      message.error(i18n.t('userNotFoundMsg'))
    }
  }

  setDeposit = async (data: any) => {
    const [err, res] = await to(this.setDepositCall.call('post', data))
    if (!err) {
      res!.mapResult(() => {
        message.success(i18n.t('depositSuccessMsg'))
        return true
      })
    }
    return res
    message.error(i18n.t('depositWarningMsg'))
  }

  setWithdraw = async (data: any, setFieldValue: any) => {
    const setWithdrawCall = new NetworkModels(this.rootStore, {
      path: `payments/lb/lb/withdraw/${this.selectedUserWithdraw?.withdrawal_reservation}`,
      secured: true,
    })
    const [err, res] = await to(setWithdrawCall.call('post', data))
    if (!err) {
      res!.mapResult((result) => {
        // @ts-ignore
        const availableSumResult = result?.data?.available
        if (availableSumResult) {
          if (this.selectedUserWithdraw) {
            setFieldValue('withdrawAmount', availableSumResult)
          }
          message.error(
            i18n.t('availableWithdrawMsg', {
              available: availableSumResult,
            })
          )
        } else {
          message.success(i18n.t('withdrawSuccessMsg'))
        }
        return true
      })
    } else if (err.message === 'ERR_NOT_FOUND') {
      message.error(i18n.t('reservationNotFound'))
    } else {
      message.error(i18n.t('depositWarningMsg'))
    }
    return res
  }
}
