import React from 'react'

import placeBetErrorHandlers, {
  noBetPlayerDual,
  ValidationRule,
} from 'utils/place-bet-errors'
import styles from './slipPayIn.module.css'

const handleOddMaxAmount = (odds: any[], slip: any) => {
  return (
    <div>
      {odds.map(({ id, rule_value }) => {
        const odd = slip.findOddInEvents(id)
        if (!odd) return null
        return (
          <div className={styles.errorDisplay}>
            {/* @ts-ignore */}
            <div style={{ marginRight: '6px' }}>{odd.printCode}:</div>
            <div>{rule_value}</div>
          </div>
        )
      })}
    </div>
  )
}

const handleMarketMaxAmount = (markets: any[], sports: any) => {
  return (
    <div>
      {markets.map(({ id, rule_value }) => {
        const market = sports.findMarketById(id)
        if (!market) return null
        return (
          <div className={styles.errorDisplay}>
            {/* @ts-ignore */}
            <div style={{ marginRight: '6px' }}>{market.name}:</div>
            <div>{rule_value}</div>
          </div>
        )
      })}
    </div>
  )
}

const handleOddsSuspended = (odds: string[], slip: any) => {
  return (
    <div>
      {odds.map((id: any) => {
        let odd = slip.findOddInEvents(id?.D)
        let event = slip.findEventByOdd(id?.D)
        if (!odd) {
          odd = slip.findOddInEvents(id?.A)
          event = slip.findEventByOdd(id?.A)
          if (!odd) {
            odd = slip.findOddInEvents(id?.P)
            event = slip.findEventByOdd(id?.P)
            if (!odd) {
              return null
            }
          }
        }
        let eventName = ''
        if (event) {
          eventName = event?.name ? `- ${event?.name}` : ''
        }
        // @ts-ignore
        return (
          <div className={styles.errorDisplay}>
            {/* @ts-ignore */}
            <div style={{ marginRight: '6px' }}>
              {`${odd.printCode || odd.name} ${eventName}`} -
            </div>
            <div>suspended</div>
          </div>
        )
      })}
    </div>
  )
}

const handleEventsMaxAmount = (events: any[], sports: any) => {
  return (
    <div>
      {events.map(({ id, rule_value }) => {
        const event = sports.findEventById(id)
        if (!event) return null
        return (
          <div className={styles.errorDisplay}>
            <div style={{ marginRight: '6px' }}>{event.name}:</div>
            <div>{rule_value}</div>
          </div>
        )
      })}
    </div>
  )
}

const handleMarketsSuspended = (markets: any[], sports: any, slip: any) => {
  return (
    <div>
      {markets.map((id) => {
        let event = slip.findEventByDualIntKey(id?.event_int_key)
        if (!event) {
          event = slip.findEvent(parseInt(id.event_int_key, 10))
        }
        let market = sports.findMarketById(id?.D)
        if (!market) {
          market = sports.findPlayerMarketById(id?.P, event?.sportId)
          if (!market) {
            return null
          }
        }
        return (
          <div className={styles.errorDisplay}>
            <div style={{ marginRight: '6px' }}>{market?.name} -</div>
            {event && <div style={{ marginRight: '6px' }}>{event?.name} -</div>}
            <div>suspended</div>
          </div>
        )
      })}
    </div>
  )
}

const handleEventsSuspended = (events: any[], slip: any) => {
  return (
    <div>
      {events.map((id) => {
        let event = slip.findEvent(parseInt(id.D, 10))
        if (!event) {
          event = slip.findEvent(parseInt(id.A, 10))
          if (!event) {
            event = slip.findEvent(parseInt(id.P, 10))
            if (!event) {
              return null
            }
          }
        }
        return (
          <div className={styles.errorDisplay}>
            <div style={{ marginRight: '2px' }}>
              {event.eventCode || event.code}{' '}
            </div>
            <div style={{ marginRight: '6px' }}>{event.name} -</div>
            <div>suspended</div>
          </div>
        )
      })}
    </div>
  )
}

const handleEventsEnded = (events: any[], slip: any) => {
  return (
    <div>
      {events.map((id) => {
        let event = slip.findEvent(parseInt(id.D, 10))
        if (!event) {
          event = slip.findEvent(parseInt(id.P, 10))
          if (!event) {
            return null
          }
        }
        return (
          <div className={styles.errorDisplay}>
            <div style={{ marginRight: '2px' }}>
              {event?.code || event?.dualIntKey},
            </div>
            <div style={{ marginRight: '6px' }}>{event?.name} -</div>
            <div>ended</div>
          </div>
        )
      })}
    </div>
  )
}

const handleEventInLive = (events: any[], slip: any) => {
  return (
    <div>
      {events.map((id) => {
        const event = slip.findEvent(parseInt(id.D, 10))
        if (!event) return null
        return (
          <div className={styles.errorDisplay}>
            <div style={{ marginRight: '2px' }}>{event?.code},</div>
            <div style={{ marginRight: '6px' }}>{event?.name} -</div>
            <div> is now in live</div>
          </div>
        )
      })}
    </div>
  )
}

const handleMinAmount = (events: any[]) => {
  return (
    <div>
      {events.map(({ rule_value }) => {
        return (
          <div className={styles.errorDisplay}>
            <div style={{ marginRight: '6px' }}>Minimalni iznos je:</div>
            <div>{rule_value}</div>
          </div>
        )
      })}
    </div>
  )
}

const handleAntepost = (events: any[]) => {
  return (
    <div>
      {events.map(({ rule_value }) => {
        return (
          <div className={styles.errorDisplay}>
            <div>{rule_value}</div>
          </div>
        )
      })}
    </div>
  )
}

export const ErrorWrapper = ({
  errorType,
  children,
}: {
  errorType: string
  children: any
}) => {
  const handler = placeBetErrorHandlers[errorType]
  return (
    <div style={{ marginBottom: '16px' }}>
      <div style={{ fontWeight: 'bold' }}>{handler()}</div>
      {children}
    </div>
  )
}

const rendererPerRule = (data: any[], slip: any, sports: any) => ({
  [ValidationRule.ODD_MAX_AMOUNT]: () => handleOddMaxAmount(data, slip),
  [ValidationRule.MARKET_MAX_AMOUNT]: () => handleMarketMaxAmount(data, sports),
  [ValidationRule.SUSPENDED_ODDS]: () => handleOddsSuspended(data, slip),
  [ValidationRule.EVENT_ENDED]: () => handleEventsEnded(data, slip),
  [ValidationRule.LIVE_EVENT_PRE]: () => handleEventInLive(data, slip),
  [ValidationRule.NO_BET_PLAYER_DUAL]: noBetPlayerDual,
  [ValidationRule.SUSPENDED_MARKETS]: () =>
    handleMarketsSuspended(data, sports, slip),
  [ValidationRule.SUSPENDED_EVENTS]: () => handleEventsSuspended(data, slip),
  [ValidationRule.EVENT_MAX_AMOUNT]: () => handleEventsMaxAmount(data, sports),
  [ValidationRule.MIN_AMOUNT]: () => handleMinAmount(data),
  [ValidationRule.MIN_AMOUNT]: () => handleMinAmount(data),
  [ValidationRule.SINGLE_ANTEPOST]: () => handleAntepost(data),
  [ValidationRule.ONLY_ANTEPOST]: () => handleAntepost(data),
})

export default rendererPerRule
