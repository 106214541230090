import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'
import { useStore } from 'store/StoreContext'
import { formatCurrency, formatCurrencyForReport } from 'utils/formatCurrency'

const PayInReport = () => {
  const { slip } = useStore()

  return (
    <Row gutter={0}>
      <Col span={8} style={{ color: '#9c9c9c' }}>
        SBSlipCount
      </Col>
      <Col span={2}>{slip.numberOfSlipsSubmitted}</Col>
      <Col span={8} style={{ color: '#9c9c9c' }}>
        SBSlipAmount
      </Col>
      <Col span={6}>{formatCurrencyForReport(slip.totalOfSlipsSubmitted)}</Col>

      <Col span={8} style={{ color: '#9c9c9c' }}>
        GreekoSlipCount
      </Col>
      <Col span={2}>{slip.numberOfSlipsSubmittedGreeko}</Col>
      <Col span={8} style={{ color: '#9c9c9c' }}>
        GreekoSlipAmount
      </Col>
      <Col span={6}>
        {formatCurrencyForReport(slip.totalOfSlipsSubmittedGreeko)}
      </Col>

      <Col span={8} />
      <Col span={2} />
      <Col span={8} style={{ color: '#9c9c9c' }}>
        DepPayInaAmount
      </Col>
      <Col span={6}>{formatCurrencyForReport(slip.depPayInaAmount)}</Col>

      <Col span={8} style={{ color: '#9c9c9c' }}>
        Broj tiketa
      </Col>
      <Col span={2}>
        {slip.numberOfSlipsSubmitted + slip.numberOfSlipsSubmittedGreeko}
      </Col>
      <Col span={8} style={{ color: '#9c9c9c' }}>
        Total
      </Col>
      <Col span={6}>
        {formatCurrency(
          slip.totalOfSlipsSubmitted +
            slip.totalOfSlipsSubmittedGreeko +
            slip.depPayInaAmount
        )}
      </Col>
    </Row>
  )
}

export default observer(PayInReport)
