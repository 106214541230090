import i18next from 'i18next'

export enum ValidationRule {
  MIN_AMOUNT = 'minimum_betting_amount',
  MAX_EVENTS = 'max_number_of_different_events_non_system_slip',
  MAX_EVENTS_SYSTEM = 'max_number_of_different_events_system_slip',
  MAX_COMBINATIONS = 'max_number_of_combinations_system_slip',
  MIN_COMBINATION_AMOUNT = 'min_betting_amount_per_combination_system_slip',
  MAX_EVENT_ODDS = 'number_of_same_dual_events_on_one_slip',
  MARKET_MAX_AMOUNT = 'market_max_amount',
  SUSPENDED_MARKETS = 'market_suspended',
  ODD_MAX_AMOUNT = 'odd_max_amount',
  ONLY_ANTEPOST = 'only_antepost',
  SINGLE_ANTEPOST = 'antepost_single',
  EVENT_MAX_AMOUNT = 'event_max_amount',
  SUSPENDED_ODDS = 'odd_suspended',
  EVENT_ENDED = 'event_ended',
  LIVE_EVENT_PRE = 'live_event_prematch_market',
  SUSPENDED_EVENTS = 'event_suspended',
  NO_BET_PLAYER_DUAL = 'can_not_bet_player_and_dual',
  MAX_PLAYER_EVENTS = 'max_event_player_types',
  LIVE_EVENT_PREMATCH_MARKET = 'live_event_prematch_market',
  MAX_SLIP_ODD = 'max_slip_odd',
}

export type ValidationRuleType =
  | ValidationRule.MIN_AMOUNT
  | ValidationRule.MARKET_MAX_AMOUNT
  | ValidationRule.SUSPENDED_EVENTS
  | ValidationRule.NO_BET_PLAYER_DUAL
  | ValidationRule.SUSPENDED_MARKETS
  | ValidationRule.SUSPENDED_ODDS
  | ValidationRule.EVENT_ENDED
  | ValidationRule.LIVE_EVENT_PRE
  | ValidationRule.EVENT_MAX_AMOUNT
  | ValidationRule.ODD_MAX_AMOUNT
  | ValidationRule.ONLY_ANTEPOST
  | ValidationRule.SINGLE_ANTEPOST

export const minimumAmount = () => {
  return i18next.t('minimumAmount')
}

export const onlyAntepost = () => {
  return i18next.t('onlyAntepost')
}

export const singleAntepost = () => {
  return i18next.t('singleAntepost')
}

export const maxEvents = (eventsNumber: number) => {
  return i18next.t('maxEvents', { eventsNumber })
}

export const maxCombination = (combinations: number) => {
  return i18next.t('maxCombination', { combinations })
}

export const minAmountPerCombination = (amount: number) => {
  return i18next.t('minAmountPerCombination', { amount })
}

export const maxEventOdds = (max: number) => {
  return i18next.t('maxEventOdds', { max })
}

export const suspendedMarkets = () => {
  return i18next.t('suspendedMarkets')
}

export const marketMaxAmount = () => {
  return i18next.t('marketMaxAmount')
}

export const oddMaxAmount = () => {
  return i18next.t('oddMaxAmount')
}

export const eventMaxAmount = () => {
  return i18next.t('eventMaxAmount')
}

export const suspendedOdds = () => {
  return i18next.t('suspendedOdd')
}
export const eventEnded = () => {
  return i18next.t('suspendedOdd')
}

export const suspendedEvents = () => {
  return i18next.t('suspendedEvent')
}

export const noBetPlayerDual = () => {
  return i18next.t('noBetDual')
}

export const maxPlayerEvents = (max: number) => {
  return i18next.t('maxPlayerEvents', { max })
}

export const liveEventPrematchMarket = () => {
  return i18next.t('liveEventPrematchMarket')
}

export const maxSlipOdd = (max: number) => {
  return i18next.t('maxSlipOdd', { max })
}

const errorHandlers: any = {
  [ValidationRule.MIN_AMOUNT]: minimumAmount,
  [ValidationRule.LIVE_EVENT_PREMATCH_MARKET]: liveEventPrematchMarket,
  [ValidationRule.MAX_EVENTS]: maxEvents,
  [ValidationRule.MAX_PLAYER_EVENTS]: maxPlayerEvents,
  [ValidationRule.SUSPENDED_EVENTS]: suspendedEvents,
  [ValidationRule.NO_BET_PLAYER_DUAL]: noBetPlayerDual,
  [ValidationRule.SUSPENDED_ODDS]: suspendedOdds,
  [ValidationRule.EVENT_ENDED]: eventEnded,
  [ValidationRule.SUSPENDED_MARKETS]: suspendedMarkets,
  [ValidationRule.EVENT_MAX_AMOUNT]: eventMaxAmount,
  [ValidationRule.ODD_MAX_AMOUNT]: oddMaxAmount,
  [ValidationRule.MARKET_MAX_AMOUNT]: marketMaxAmount,
  [ValidationRule.MAX_COMBINATIONS]: maxCombination,
  [ValidationRule.MIN_COMBINATION_AMOUNT]: minAmountPerCombination,
  [ValidationRule.MAX_PLAYER_EVENTS]: maxPlayerEvents,
  [ValidationRule.MAX_SLIP_ODD]: maxSlipOdd,
  [ValidationRule.ONLY_ANTEPOST]: onlyAntepost,
  [ValidationRule.SINGLE_ANTEPOST]: singleAntepost,
}

export default errorHandlers
