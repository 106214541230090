/* eslint-disable */
import {Button, Col, Row, Tooltip} from 'antd'
import EllipsisText from 'components/EllipsisText'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, {useContext, useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { SlipGroup, SlipOdd } from 'store/SlipStore'
import StoreContext from 'store/StoreContext'
import GroupSystem from './GroupSystem'
import styles from './slipReview.module.css'
import { isDayAfterTomorrow, isToday, isTomorrow } from '../../utils/datetime'
import {ShortcutEvents} from "../../store/ShortcutsStore";

const HeaderCol = ({ span, children }: any) => (
  <Col span={span}>
    <div className={styles.matchHeaderColumn}>{children}</div>
  </Col>
)

const RowCell = ({ span, children }: any) => (
  <Col span={span}>
    <div className={styles.matchColumn}>{children}</div>
  </Col>
)

const isOddOne = (oddValue: any) => {
    if(oddValue?.toString() === '1' || oddValue?.toString() === '100') {
        return true
    } else {
        return false
    }
}


const GroupHeader = ({ group, events, system, name }: any) => {
  const { t } = useTranslation()

  return (
    <Row>
      <div className={styles.groupHeader}>
        <GroupSystem events={events} system={system} group={group} name={name}/>
        <span>
          {t('groups')} {name}
        </span>
      </div>
    </Row>
  )
}

const TableHeader = () => {
  const { t } = useTranslation()

  return (
    <Row gutter={1}>
      <HeaderCol span={3}>{t('mCode')}</HeaderCol>
      <HeaderCol span={4}>{t('dateTime')}</HeaderCol>
      <HeaderCol span={7}>{t('matchName')}</HeaderCol>
      <HeaderCol span={3}>{t('betCode')}</HeaderCol>
      <HeaderCol span={2}>{t('betParameter')}</HeaderCol>
      <HeaderCol span={2}>{t('odd')}</HeaderCol>
      <HeaderCol span={1}>Del</HeaderCol>
      <HeaderCol span={2}>{t('group')}</HeaderCol>
    </Row>
  )
}

const GroupsCell = ({ group, event }: { group: SlipGroup; event: any }) => {
  const { slip } = useContext(StoreContext)
  const accessibleGroups = [
    ...slip.groupsBefore(group),
    ...slip.groupsAfter(group),
  ]

  return (
    <div className={styles.groupsWrapper}>
      {accessibleGroups.map((g) => (
        <button
          key={g}
          type="button"
          className={styles.group}
          onClick={() => slip.moveEventToGroup(event, g)}
        >
          {g}
        </button>
      ))}
    </div>
  )
}

const SlipReview = () => {
  const { slip, shortcut } = useContext(StoreContext)

    const refArray: any[] = []
    for (let i = 0; i < 600; i++) {
        refArray.push(useRef<HTMLSpanElement>(null))
    }

  const getClassByMatchStart = (type: string, start: string) => {
    if (type === 'L') return styles.matchLiveColor
    if (isTomorrow(new Date(start))) return styles.matchTomorrowColor
    if (isToday(new Date(start))) return styles.matchTodayColor
    if (isDayAfterTomorrow(new Date(start)))
      return styles.matchDayAfterTomorrowColor
    return styles.matchDefaultColor
  }

  const getOddClassName = (odd: any, isSuspended: boolean, spanRef: any) => {
      spanRef?.current?.classList?.remove(styles.oddDownColor);
      spanRef?.current?.classList?.remove(styles.oddUpColor);
      spanRef?.current?.classList?.add(styles.oddDefaultColor);
    if (isSuspended) {
        spanRef?.current?.classList?.add(styles.oddColorMatchSuspended);
        return styles.oddColorMatchSuspended
    }
    if (odd.isDown) {
        spanRef?.current?.classList?.add(styles.oddDownColor);
        setTimeout(function() {
            spanRef?.current?.classList?.remove(styles.oddDownColor);
            spanRef?.current?.classList?.add(styles.oddDefaultColor);
            odd.isDown = false
            odd.isUp = false
        }, 6000);
        return styles.oddDownColor
    }
    if (odd.isUp) {
        spanRef?.current?.classList?.add(styles.oddUpColor);
        setTimeout(function() {
            spanRef?.current?.classList?.remove(styles.oddUpColor);
            spanRef?.current?.classList?.add(styles.oddDefaultColor);
            odd.isDown = false
            odd.isUp = false
        }, 6000);
        return styles.oddUpColor
    }
    return styles.oddDefaultColor
  }

  const getParamClassName = (isSuspended: boolean) => {
    if (isSuspended) return styles.oddColorMatchSuspended
  }

    // @ts-ignore
    return (
    <div className={styles.slipReview}>
      {slip.groups.map((group, index) => (
        <div key={index}>
          <div>
            <GroupHeader
              group={group}
              events={group.events}
              system={group.system}
              name={index + 1}
            />
            <TableHeader />
            {group?.events?.map((el: any, eventIndex) =>
              el?.event_type !== 'A' ? (
                el?.selectedOdds?.map((odd: SlipOdd, oddIndex: number) => (
                  <div key={odd.intKey} className={styles.matchRow}>
                    <Row gutter={1}>
                      <RowCell span={3}>
                        <span
                          className={getClassByMatchStart(
                            el.event_type,
                            el.start
                          )}
                        >
                          {oddIndex === 0 && el?.event_type === 'P' && (el.dualLandbaseCode ? el.dualLandbaseCode+'/'+el.eventCode : el.eventCode)}
                          {oddIndex === 0 && el?.event_type !== 'P' && (el.eventCode || el.landbaseCode || el.code)}
                        </span>
                      </RowCell>
                      <RowCell span={4}>
                        {oddIndex === 0 && (
                          <>
                            <span
                              className={getClassByMatchStart(
                                el.event_type,
                                el.start
                              )}
                            >
                              {moment.utc(el.start).local().format('HH:mm:ss')}
                            </span>
                            <span
                              className={getClassByMatchStart(
                                el.event_type,
                                el.start
                              )}
                            >
                              {moment
                                .utc(el.start)
                                .local()
                                .format('DD/MM/YYYY')}
                            </span>
                          </>
                        )}
                      </RowCell>
                      <RowCell span={7}>
                        {oddIndex === 0 && el?.event_type !== 'P' && (
                          <>
                              <Tooltip placement="top" title={el.competitionName ? el.competitionName : ''}>
                                <span style={{textOverflow: 'ellipsis', textDecoration: 'none',lineHeight: '12px', fontWeight:'normal', overflowX: "hidden", overflowY: "hidden"}} >
                                    {el?.competitionName}
                                </span>
                              </Tooltip>
                            <EllipsisText text={el?.home || el?.dualHome} />
                            <EllipsisText text={el?.away || el?.dualAway} />
                          </>
                        )}
                        {oddIndex === 0 && el?.event_type === 'P' && (
                          <>
                            <EllipsisText text={el?.playerName || el?.name} />
                            <EllipsisText text={el?.dualHome} />
                            <EllipsisText text={el?.dualAway} />
                          </>
                        )}
                        {(el?.note || el?.settlementNote) && (
                          <Tooltip placement="top" title={el.event_type === 'L' ? el.settlementNote : el.note}>
                            <span style={{ position: 'absolute', bottom: 0, right: 5, cursor: 'pointer', fontSize: '20px'}} >*</span>
                          </Tooltip>
                        )}
                      </RowCell>
                      <RowCell span={3}>{odd.printCode}</RowCell>
                      <RowCell span={2}>
                         <span className={getParamClassName(el.isSuspended)}>
                            {odd.limit2 || ''}
                         </span>
                        <span className={getParamClassName(el.isSuspended)}>
                            {odd.limit || '/'}
                        </span>
                      </RowCell>
                      <RowCell span={2}>
                        <span
                          ref={refArray[eventIndex * 10 + oddIndex]}
                          className={getOddClassName(
                            odd,
                            el.isSuspended || el.betStop ||
                              isOddOne(odd?.oddValue) ||
                              el?.selectedOdds?.find(
                                (o: any) => o.code === el.code
                              )?.isSuspended,
                              refArray[eventIndex * 10 + oddIndex]
                          )}
                        >
                        {isOddOne(odd?.oddValue) ? ' ' : (odd?.oddValue / 100).toFixed(2)}
                        </span>
                      </RowCell>
                      <RowCell span={1}>
                        <Button
                          disabled={slip.dataInfoLoading}
                          size="small"
                          style={{ fontSize: '10px', padding: '4px' }}
                          onClick={() => {
                            slip.removeOdd(group, el, odd.intKey || odd.int_key)
                            slip.removeSingleHighlightedOdd(
                              el.intKey,
                              odd.intKey
                            )
                              if(el?.selectedOdds.length === 0) {
                                  slip.removeHighlightedOdd(el.intKey)
                              }
                          }}
                        >
                          X
                        </Button>
                      </RowCell>
                      <RowCell span={2}>
                        <GroupsCell group={group} event={el} />
                      </RowCell>
                    </Row>
                  </div>
                ))
              ) : (
                <div key={el.intKey} className={styles.matchRow}>
                  <Row gutter={1}>
                    <RowCell span={3}>
                      <span className={styles.matchTodayColor}>
                        {index === 0 && el.antepostCode}
                      </span>
                    </RowCell>
                    <RowCell span={4}>
                      {index === 0 && (
                        <>
                          <span className={styles.matchTodayColor}>
                            {moment.utc(el.end).local().format('HH:mm:ss')}
                          </span>
                          <span className={styles.matchTodayColor}>
                            {moment.utc(el.end).local().format('DD/MM/YYYY')}
                          </span>
                        </>
                      )}
                    </RowCell>
                    <RowCell span={8}>
                      {index === 0 && (
                        <>
                          <EllipsisText text={el.market?.competitionName} />
                          <EllipsisText
                            text={
                              (el.market?.antepostName || el?.name)
                            }
                          />
                          <EllipsisText
                            text={el?.antepostBetsList?.find(
                                (o: any) => o.intKey === el.oIntKey
                            )?.name}
                          />
                        </>
                      )}
                    {el?.note && (
                        <Tooltip placement="top" title={el.note}>
                            <span style={{ position: 'absolute', bottom: 0, right: 5, cursor: 'pointer', fontSize: '20px'}} >*</span>
                        </Tooltip>
                    )}
                    </RowCell>
                    <RowCell span={1}>
                       {el.code}
                    </RowCell>
                    <RowCell span={3}>
                      <span
                        className={getParamClassName(el.isSuspended || el.betStop)}
                      ></span>
                    </RowCell>
                    <RowCell span={2}>
                      <span
                      ref={refArray[index]}
                      className={getOddClassName(
                          el,
                          el.isSuspended || el.betStop ||
                          isOddOne(el?.oddValue) ||
                          el?.antepostBetsList?.find(
                              (o: any) => o.code === el.code
                          )?.isSuspended,
                          refArray[index]
                      )}
                      >
                        {isOddOne(el?.oddValue) ? ' ' : (el?.oddValue / 100).toFixed(2)}
                      </span>
                    </RowCell>
                    <RowCell span={1}>
                      <Button
                        disabled={slip.dataInfoLoading}
                        size="small"
                        style={{ fontSize: '10px', padding: '4px' }}
                        onClick={() => {
                          slip.removeEvent(group, el.intKey)
                          slip.setSelectedOdd(null)
                        }}
                      >
                        X
                      </Button>
                    </RowCell>
                    <RowCell span={2}>
                      <GroupsCell group={group} event={el} />
                    </RowCell>
                  </Row>
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default observer(SlipReview)
