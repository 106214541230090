import React, { useContext } from 'react'
import { Formik } from 'formik'
import { SubmitButton, Input, Form, FormItem } from 'formik-antd'
import { Row } from 'antd'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import StoreContext from 'store/StoreContext'
import style from './loginForm.module.css'

export interface LoginRequestType {
  username: string
  password: string
  apiError?: string
}

export const LoginForm = () => {
  const { t } = useTranslation()

  const initialValues: LoginRequestType = {
    username: '',
    password: '',
  }

  const { user } = useContext(StoreContext)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        return user
          .login(values)
          .catch((error) =>
            helpers.setFieldError(
              'apiError',
              error?.message ? error?.message : t('apiError')
            )
          )
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required(t('validationUsername')),
        password: Yup.string().required(t('validationPassword')),
      })}
    >
      {({ errors }) => (
        <Form autoComplete="off">
          <div style={{ minHeight: '300px' }}>
            <FormItem name="username" required>
              <Input name="username" placeholder={t('username')} />
            </FormItem>
            <FormItem name="password" required>
              <Input
                name="password"
                placeholder={t('password')}
                className={style.passwordInput}
              />
            </FormItem>
            <Row>
              <SubmitButton block>{t('login')}</SubmitButton>
            </Row>
            <Row>
              <div style={{ color: '#a61d24', marginTop: 16 }}>
                {errors.apiError}
              </div>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  )
}
