import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languageResources } from './locales/index'

i18n.use(initReactI18next).init({
  resources: languageResources,
  lng: 'sr',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
