export const en = {
  translation: {
    sportBetting: 'Sport Betting',
    username: 'Username',
    password: 'Password',
    login: 'Login',
    validationUsername: 'Please enter valid username',
    validationPassword: 'Please enter valid password',
    logout: 'Logout',
    matchCatalog: 'Match Catalog',
    prematch: 'Prematch',
    live: 'Live',
    outright: 'Outright',
    prematchCatalogFilters: 'Prematch Catalog Filters',
    prematchCatalog: 'Prematch Catalog',
    antepostCatalog: 'Outright Catalog',
    matchBetSelection: 'Match Bet Selection',
    payInReport: 'Pay In Report',
    searchLiveMatches: 'Search Live Matches',
    liveMatchDetails: 'Live Match Details',
    liveMatches: 'Live Matches',
    liveMatchBets: 'Live Match Bets',
    matchName: 'Match Name',
    betSearch: 'Bet Search',
    matchCode: 'Match Code',
    lock: 'Lock',
    betCode: 'Bet Code',
    lineGroup: 'Line Group',
    keepMatches: 'Keep Matches',
    slipReview: 'Slip Review',
    slipPayIn: 'Slip Pay In',
    payInAmount: 'Pay In Amount',
    payIn: 'Pay In',
    cancel: 'Cancel',
    sport: 'Sport',
    country: 'Country',
    competition: 'Competition',
    sort: 'Sort',
    filter: 'Filter',
    slipLog: 'Slip Log',
    getSlips: 'Get Slips',
    eSlipId: 'ESlip Id',
    slipId: 'Slip Id',
    submit: 'Submit',
    preview: 'Preview',
    checkStatus: 'Check Status',
    payOut: 'Pay Out',
    cancelSlip: 'Cancel Slip',
    loading: 'Loading...',
    matches: 'Matches',
    dateTime: 'Date Time',
    time: 'Time',
    mCode: 'MCode',
    homeCompetitor: 'Home Competitor',
    awayCompetitor: 'Away Competitor',
    all: 'ALL',
    betParameter: 'Par',
    groups: 'Group',
    odd: 'Odd',
    minOdd: 'Min coef.',
    maxOdd: 'Max coef.',
    minWin: 'Min win.',
    maxWin: 'Max win.',
    minPayIn: 'Min pay in',
    matchNum: 'Match count',
    combNum: 'Combinations number',
    maxPayOut: 'Max pay out',
    noResults: 'No Results',
    competitor: 'Competitor:',
    noSelection: 'No match selected',
    minimumAmount: 'Minimum betting amount is {{amount}}.',
    maxEvents: 'Maximum number of events is {{eventsNumber}}.',
    maxCombination: 'Maximum number of combinations is {{combinations}}.',
    minAmountPerCombination: 'Minimum amount per combination is {{amount}}.',
    maxEventOdds: 'Maximum number of odds on same event is {{max}}.',
    maxSlipOdd: 'Maximum odd is {{max}}.',
    marketMaxAmount: 'Max amount condition has not been fullfiled for markets.',
    oddMaxAmount: 'Max amount condition has not been fullfiled for odds.',
    eventMaxAmount: 'Max amount condition has not been fullfiled for events.',
    suspendedOdd: 'Odds suspended',
    eventEnded: 'Event ended',
    suspendedMarkets: 'Markets suspended.',
    suspendedEvent: 'Events suspended',
    selectedEventSuspended: 'Selected event is suspended.',
    noBetDual: 'Can not bet on player and dual event.',
    selectedBetSuspended: 'Selected bet is suspended.',
    eventNotFound: 'No event with that code.',
    maxPlayerEvents: 'Maximum number of player events is {{max}}.',
    liveEventPrematchMarket: "You can't play prematch markets on live events",
    placeBetGenericError: 'Error while trying to place bet. Please, try again.',
    slipNotFound: 'Slip not found or it is inactive!',
    rollBackSuccess: 'Slip is canceled successfuly.',
    rollBackErrorSlipStarted: 'Slip cannot be canceled.',
    somethingWentWrong: 'Something went wrong. Please try again.',
    slipNotResolved: 'Slip cannot be payed out because it is not resolved.',
    slipPaid: 'Slip is already payed out.',
    slipNotPassed: 'Slip not passed.',
    slipPayotSuccess: 'Slip payed out successfuly.',
    liveSuspended: 'Live is suspended',
    break: 'Break',
    favourites: 'Favourites',
    prematchSuspended: 'Prematch is suspended',
    sbClientTab: 'SB Client',
    dateTimeFromTo: 'Date Time From To',
    pdf: 'PDF',
    oneH: '1 h',
    twoH: '3 h',
    today: 'Today',
    catalog: 'Catalog',
    allCapital: 'All',
    magazin: 'Magazin',
    sports: 'Sports',
    search: 'Search here...',
    homeCompetitorPdf: 'Home Comp.',
    awayCompetitorPdf: 'Away Comp.',
    competitionPdf: 'Competition',
    allSports: 'All sports',
    bettingAmountAboveLive: 'Betting amount above live {{max}}.',
    bettingOddAbove: 'Betting odd above permitted value.',
    searchDeposit: 'Search',
    online: 'Online',
    userDetails: 'User Details',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    userName: 'User Name',
    dateOfBirth: 'Date of birth',
    name: 'Name',
    surname: 'Surname',
    email: 'Email',
    payInAmountValue: 'Pay in amount',
    withdrawAmountValue: 'Withdraw amount',
    withdrawCode: 'Withdraw code',
    jmbgLast6Digit: 'JMBG last 6 digits',
    jmbg: 'JMBG',
    jmbgConfirm: 'JMBG confirmation',
    reservationAmount: 'Reservation amount',
    mandatoryField: 'Field is required.',
    yes: 'YES',
    no: 'NO',
    depositSuccessMsg: 'Deposit is added.',
    withdrawSuccessMsg: 'Withdraw is successful.',
    depositWarningMsg: 'Something went wrong.',
    userNotFoundMsg: 'User not found!',
    positiveNumber: 'Minimal value is 1!',
    reservationNotFound: 'Reservation not found!',
    errorWrongBettingPlace: 'Wrong withdraw place!',
    depositModalMsg:
      'Are you sure that you want to deposit this amount to the  {{username}} account?',
    withdrawModalMsg:
      'Are you sure that you want to withdraw this amount to the  {{username}} account?',
    availableWithdrawMsg:
      'Withdraw amount will be set to maximum available amount: {{available}} !',
    players: 'Players',
    won: 'Won',
    lost: 'Lost',
    pending: 'Pending',
    cashback: 'Cashback',
    accepted: 'Accepted',
    paidOut: 'Paid out',
    pleaseRefresh: 'Please refresh this page!',
    refresh: 'Refresh',
    onlyAntepost: 'It is not possible to mix long and short type of matches.',
    singleAntepost: 'You need to play at least one long event',
    multiBetMaxReached: 'Maximum amount of bets per single event reached!',
    apiError: 'APi error occurred!',
    slipsManualTitle: 'Slips waiting for manual approval',
    slipsAllSubmitted: 'All submitted slips Log',
    greeko: 'Greeko',
    cashFlow: 'CashFlow',
    viewPreviousGames: 'Previous games',
    payment: 'Payment',
    previousMatch: 'Previous Match',
    currentMatch: 'Current Match',
    colNumber: 'Col number',
    insertNumber: 'Insert number',
    insertedNumbers: 'Inserted number',
    system: 'System',
    next: 'Next',
    amount: 'Amount',
    total: 'Total',
    reset: 'Reset',
    ticketCheck: 'Ticket check',
    previousTickets: 'Previous tickets',
    ticketID: 'Ticket ID',
    checkTicket: 'Check ticket',
    repeat: 'Repeat',
    cancelTicket: 'Cancel',
    winTicketStatus: 'Won',
    note: 'Note',
    sendForApproval: 'Send for approval',
    transacionLog: 'CurrentDayTransactionLog',
    print: 'Print',
    transactionTime: 'TransactionTime',
    transactionType: 'TransactionType',
    transactionLogHeader: 'TransactionLog',
    canceled: 'Canceled',
    no_privileges: 'You do not have privileges for this operation!',
    no_combine_dual: 'It is not posssible to combine dual and Antepost events',
    valid_numbers_greeko:
      'Valid numbers in Greeko are from 1 to 80 and must be unique!',
    next_rounds: 'Valid numbers for round repetition is from 1 to 10.',
    valid_length_greeko:
      'Maksimalni broj unetih brojeva mora biti izmedju 1 i 15',
    pay_value_greeko: 'Payment must be between 20 RSD and 10 000 RSD',
    greeko_ticket_success: 'You have successfully submited ticket!',
    greeko_ticket_delete: 'You have successfully canceled ticket!',
    pdfTitle: 'Schedule of matches by dates.',
    userPendingPayment: 'Pending payment approval!',
    userNotApprovedPayment: 'Isplata nije odobrena!"',
    cancelSlipModal: 'Are you sure that you want to cancel the slip?',
    payoutSlipModal: 'Are you sure that you want to payout the slip?',
    sportBettingSlipPayInCount: 'Slip Pay In Count',
    sportBettingSlipPayInAmount: 'Slip Pay In Amount',
    sportBettingSlipPayOutCount: 'Slip Pay Out Count',
    sportBettingSlipPayOutAmount: 'Slip Pay Out Amount',
    greekoSlipPayInCount: 'Greeko Pay In Count',
    greekoSlipPayInAmount: 'Greeko Pay In Amount',
    greekoSlipPayOutCount: 'Greeko Pay Out Count',
    greekoSlipPayOutAmount: 'Greeko Pay Out Amount',
    depositSlipPayInCount: 'Deposit Pay In Count',
    depositSlipPayInAmount: 'Deposit Pay In Amount',
    depositSlipPayOutCount: 'Deposit Pay Out Amount',
    depositSlipPayOutAmount: 'Deposit Pay In Count',
  },
}
