import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store/StoreContext'
import { Input, Col, Row, Button, message, Modal } from 'antd'
import { observer } from 'mobx-react'
import { handleKeyEvent, Keycodes, isStar } from 'utils/keyboardEvents'
import { ShortcutEvents } from 'store/ShortcutsStore'
import placeBetErrorHandlers, {
  ValidationRuleType,
} from 'utils/place-bet-errors'
import i18next from 'i18next'
import rendererPerRule, { ErrorWrapper } from './errorRenderers'

import styles from './slipPayIn.module.css'

function SlipPayIn() {
  const { t } = useTranslation()
  const { slip, slipApi, sports, shortcut, user } = useStore()
  const amountInput = useRef(null)
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [errorData, setErrorData] = useState<any>(null)

  const handleArrayErrors = (errorType: string, data: any[]) => {
    const renderer = rendererPerRule(data, slip, sports)[
      errorType as ValidationRuleType
    ]
    if (renderer) {
      return <ErrorWrapper errorType={errorType}>{renderer()}</ErrorWrapper>
    }

    return t('placeBetGenericError')
  }

  const renderErrors = (errors: any) => {
    if (!errors) return null
    return Object.keys(errors).map((error: string) => {
      const handler = placeBetErrorHandlers[error]
      const errorData = errors[error]

      if (handler) {
        if (Array.isArray(errorData)) {
          return <div>{handleArrayErrors(error, errorData)}</div>
        }
        const errorMessage = handler(errorData.rule_value)
        return errorMessage
      }
      return t('placeBetGenericError')
    })
  }

  const closeErrorModal = () => {
    setErrorModal(false)
  }

  const submit = async () => {
    if (user.timeDifference > 1) {
      message.error(
        'Razlika vremena na računaru i serveru mora biti manja od 1 minuta!'
      )
      return
    }

    if (
      slip.slipData.slip_groups.length === 1 &&
      slip.eventsCountInput !== slip.slipData.slip_groups[0].events.length &&
      slip.eventsCountInput !== 0
    ) {
      message.error('Nemoguć sistem!')
      return
    }

    try {
      slip.slipData.slip_groups = slip.slipData.slip_groups.map(
        (group: any) => ({
          ...group,
          events: group.events.map((event: any) => ({
            ...event,
            odds: event.odds.map((odd: any) => ({
              ...odd,
              odd_value: odd.odd_value,
            })),
          })),
        })
      )
      const successResponse = await slipApi.placeBet(slip.slipData)

      if (successResponse) {
        if (!slip.slipData.for_approving) {
          if (successResponse.amount !== undefined) {
            slip.updateReportData(slip.slipData.amount)
          }
        } else if (successResponse?.amount) {
          slip.updateReportData(successResponse?.amount)
        }
        if (!slip?.keepMatches) {
          slip.reset()
        }
      }
    } catch (e) {
      if (e.error === 'ERR_VALIDATION_FAILED') {
        setErrorData(e.data)
        setErrorModal(true)
      } else if (e.error === 'ERR_LIVE_MANUAL_APPROVING') {
        setErrorData(e.data)
        setErrorModal(true)
      } else if (e.errors?.length) {
        let errorMsg = ''
        e.errors.forEach((errorObject: any) => {
          if (
            errorObject.error === 'BETTING_AMOUNT_ABOVE_LIVE' ||
            errorObject.error === 'BETTING_AMOUNT_ABOVE_PREMATCH'
          ) {
            const max = errorObject.rule_value
            errorMsg += i18next.t('bettingAmountAboveLive', { max })
          }
          if (errorObject.error === 'BETTING_ODD_ABOVE') {
            errorMsg += i18next.t('bettingAmountAboveLive')
          }
        })
        message.error({
          duration: 2,
          content: `${errorMsg}`,
          className: styles.errorMsg,
        })
      } else {
        message.error(t('placeBetGenericError'))
      }
    } finally {
      shortcut.setEvent(ShortcutEvents.FOCUS_MATCH_CODE)
    }
  }

  const toggleKeepMatches = () => {
    shortcut.setEvent(ShortcutEvents.TOGGLE_KEEP_MATCHES)
  }

  const handleKeyDown = (e: any) => {
    if (isStar(e)) {
      e.preventDefault()
      slip.toggleManualApproval()
    } else {
      handleKeyEvent(
        e,
        {
          [Keycodes.ENTER]: () => {
            if (!slipApi.placeBetInProgress && !slip.placeBetDisabled) {
              submit()
            }
          },
          [Keycodes.END]: toggleKeepMatches,
          [Keycodes.F11]: toggleKeepMatches,
        },
        true
      )
    }
  }

  const resetSlip = () => {
    slip.reset()
    slip.errors = []
    shortcut.setEvent(ShortcutEvents.FOCUS_MATCH_CODE)
  }

  useEffect(() => {
    slipApi.connectTicketApprovalFeed()
  }, [slipApi])

  useEffect(() => {
    if (shortcut.event === ShortcutEvents.FOCUS_PAYIN_AMOUNT) {
      // @ts-ignore
      amountInput?.current?.focus()
      slip.amount = null
      shortcut.clear()
    }
  }, [shortcut.event, shortcut, slip])

  return (
    <div>
      <Row gutter={8} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Col span={12}>
          <label htmlFor="amount">{t('payInAmount')}</label>

          <Input
            ref={amountInput}
            value={slip.amount || ''}
            id="amount"
            name="payInAmount"
            autoComplete="off"
            onKeyDown={handleKeyDown}
            onChange={(e) => slip.setAmount(e.target.value)}
            maxLength={8}
          />
        </Col>
        <Col span={12}>
          <Button
            disabled={slipApi.placeBetInProgress || slip.placeBetDisabled}
            type="primary"
            onClick={submit}
          >
            {t('payIn')}
          </Button>

          <Button danger onClick={resetSlip} style={{ marginLeft: 12 }}>
            {t('cancel')}
          </Button>
        </Col>
      </Row>

      <Row
        gutter={8}
        style={{ marginLeft: 4, marginTop: 4, minHeight: 24, fontSize: 12 }}
      >
        {Object.keys(slip.errors).map((errorKey: string) => (
          <span key={errorKey}>{` -${slip.errors[errorKey]}`} &nbsp; </span>
        ))}
      </Row>
      <Modal
        onCancel={closeErrorModal}
        visible={errorModal}
        footer={false}
        closable
        width={600}
        title={
          <div className={styles.erroModalHeader}>Slip validation message</div>
        }
      >
        {renderErrors(errorData)}
        <div className={styles.errorModalFooter}>
          <Button size="small" danger onClick={closeErrorModal}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default observer(SlipPayIn)
