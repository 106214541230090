import React, { useContext } from 'react'
import { Col, Row } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { useTranslation } from 'react-i18next'
import styles from '../LiveMatchDetails/liveMatchDetails.module.css'
import { checkIfExistsOrIsZero } from '../../utils/generalHelpers'

export default observer(function LiveMatch() {
  const { sports } = useContext(StoreContext)
  const { t } = useTranslation()

  return (
    <>
      {sports.selectedLiveMatch && (
        <div>
          <Row>
            <Col span={24}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.competitionName}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.timeColumn}>
                {!sports.selectedLiveMatch?.currentPhase.isBreak
                  ? `${
                      sports.selectedLiveMatch?.currentPhase?.T?.PRT ||
                      sports.selectedLiveMatch?.currentPhase?.T?.M ||
                      sports.selectedLiveMatch?.currentPhase?.time ||
                      sports.selectedLiveMatch?.currentPhase?.SN ||
                      0
                    }' / ${sports.selectedLiveMatch?.currentPhase?.N}`
                  : t('break')}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>1/4</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>2/4</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>3/4</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>4/4</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>FT</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>OT</div>
            </Col>
            <Col span={2}>
              <div className={styles.timeColumn}>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.home}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q1?.P[0]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q2?.P[0]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q3?.P[0]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q4?.P[0]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch.currentResult?.P[0]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.OT
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div className={styles.detailsColumn}>
                {sports.selectedLiveMatch?.away}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q1?.P[1]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q2?.P[1]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q3?.P[1]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.detailedResult?.Q4?.P[1]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch.currentResult?.P[1]
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>
                {checkIfExistsOrIsZero(
                  sports.selectedLiveMatch?.currentResult?.OT
                )}
              </div>
            </Col>
            <Col span={2}>
              <div className={styles.detailsColumn}>&nbsp;</div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
})
