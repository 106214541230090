import React, { useContext } from 'react'
import { Collapse } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import LiveMatch from 'components/LiveMatch'
import { SportsIcons } from 'components/SportsIcons'
import { useTranslation } from 'react-i18next'
import { ReactComponent as StarIcon } from 'assets/star.svg'
import styles from './liveMatches.module.css'

export default observer(function LiveMatches() {
  const { sports } = useContext(StoreContext)
  const { t } = useTranslation()

  const { Panel } = Collapse
  const sportsIDs = Array.from(Array(30).keys())
  const liveEventsArray = Array.from(sports.liveEventsMap.values())
  // Hardcoded because id's of the sports are not good on BE, we agreed on this
  const sortedSports = [
    { id: 1, name: 'Fudbal' },
    { id: 2, name: 'Kosarka' },
    { id: 5, name: 'Tenis' },
    { id: 4, name: 'Rukomet' },
    { id: 3, name: 'Odbojka' },
    { id: 7, name: 'Hokej' },
    { id: 11, name: 'Futsal' },
    { id: 8, name: 'Americki fudbal' },
    { id: 12, name: 'Stoni tenis' },
  ]

  const getLiveSports = () => {
    return sortedSports.filter((sport) =>
      liveEventsArray.find((filter) => filter.sportId === sport.id)
    )
  }

  return (
    <div className={styles.liveMatches}>
      <Collapse
        defaultActiveKey={sportsIDs}
        expandIconPosition="right"
        style={{ lineHeight: '16px' }}
      >
        {liveEventsArray.filter((event) => event.isFavourite === true).length >
          0 && (
          <Panel
            header={
              <div className={styles.header}>
                <div className={styles.headerIcon}>
                  <StarIcon className={styles.starIconYellow} />
                </div>{' '}
                <div>{t('favourites')}</div>
              </div>
            }
            key="Favourites"
          >
            <div className={styles.matchesWrapper}>
              <LiveMatch
                events={liveEventsArray.filter(
                  (event) => event.isFavourite === true
                )}
              />
            </div>
          </Panel>
        )}
        {getLiveSports().map((sport) => (
          <Panel
            header={
              <div className={styles.header}>
                <div className={styles.headerIcon}>
                  {SportsIcons.get(sport.id)}
                </div>{' '}
                <div>{sport.name}</div>
              </div>
            }
            key={sport.id}
          >
            <div className={styles.matchesWrapper}>
              <LiveMatch
                events={liveEventsArray.filter(
                  (event) => event.sportId === sport.id
                )}
              />
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
})
