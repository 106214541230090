export const formatCurrency = (amount: number) => {
  if (amount) {
    return amount.toLocaleString('rs-RS', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return 0
}

export const formatCurrencyForReport = (amount: number) => {
  if (amount) {
    return amount.toLocaleString('rs-RS', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return 0
}

export const formatToTwoDigits = (amount: any) => {
  return parseFloat(amount).toFixed(2)
}
