import { createBrowserHistory } from 'history'
import RouterStore from './RouterStore'
import UserStore from './UserStore'
import SportsStore from './SportsStore'
import FeedStore from './FeedStore'
import SlipApiStore from './SlipApiStore'
import SlipStore from './SlipStore'
import ShortcutsStore from './ShortcutsStore'

export const history = createBrowserHistory()
export default class RootStore {
  router
  user
  sports
  slip
  feed
  slipApi
  shortcut

  constructor() {
    this.router = new RouterStore(this, history)
    this.user = new UserStore(this)
    this.sports = new SportsStore(this)
    this.feed = new FeedStore(this)
    this.slipApi = new SlipApiStore(this)
    this.slip = new SlipStore(this)
    this.shortcut = new ShortcutsStore(this)
  }
}
