import React from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './prematchCatalog.module.css'

const PrematchTableHeader = () => {
  const { t } = useTranslation()
  const headerConfig = [
    {
      span: 2,
      key: 1,
      headerText: t('dateTime'),
    },
    {
      span: 1,
      key: 2,
      headerText: t('sport'),
    },
    {
      span: 5,
      key: 3,
      headerText: t('competition'),
    },
    {
      span: 2,
      key: 4,
      headerText: t('mCode'),
    },
    {
      span: 5,
      key: 5,
      headerText: t('homeCompetitor'),
    },
    {
      span: 5,
      key: 6,
      headerText: t('awayCompetitor'),
    },
    {
      span: 1,
      key: 7,
      headerText: 'KI 1',
    },
    {
      span: 1,
      key: 8,
      headerText: 'KI X',
    },
    {
      span: 1,
      key: 9,
      headerText: 'KI 2',
    },
    {
      span: 1,
      key: 10,
      headerText: t('all'),
    },
  ]

  return (
    <Row gutter={1}>
      {headerConfig.map((c) => (
        <Col span={c.span} key={c.key}>
          <div className={styles.matchHeaderColumn}>
            <span>{c.headerText}</span>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default PrematchTableHeader
