import React, { useContext, useEffect } from 'react'
import { Col, Row } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { ReactComponent as StarIcon } from 'assets/star.svg'
import SuspendOverlay from 'components/SuspendOverlay'
import { Event } from 'utils/sports.types'
import { useTranslation } from 'react-i18next'
import { action } from 'mobx'
import styles from './liveMatchTemplate.module.css'

interface SoccerTemplateProps {
  event: Event
}

export default observer(function SoccerTemplate({
  event,
}: SoccerTemplateProps) {
  const { sports } = useContext(StoreContext)
  const { t } = useTranslation()

  const updateAwayResultChange = () => {
    setTimeout(() => {
      action(() => {
        event = { ...event, awayResultChanged: false }
      })()
    }, 5000)
  }

  const updateHomeResultChange = () => {
    setTimeout(() => {
      action(() => {
        event = { ...event, homeResultChanged: false }
      })()
    }, 5000)
  }

  const printResoult = (event: any, homeAway: number) => {
    if (event.currentPhase?.SN === 'FH') {
      return '-'
    }
    if (event.detailedResult?.FH) {
      return event.detailedResult?.FH?.G[homeAway]
    }
    return '-'
  }

  const getResoult = (event: any, home: boolean) => {
    if (!event.currentResult) {
      return '-'
    }
    // fudbal(1) / rukomet(4) / futsal(11)
    if (
      event.sportId === sports?.sportNames.get('Fudbal') ||
      event.sportId === sports?.sportNames.get('Rukomet') ||
      event.sportId === sports?.sportNames.get('Futsal')
    ) {
      if (home) {
        return `${event.currentResult?.G[0]}(${printResoult(event, 0)})`
      }
      return `${event.currentResult?.G[1]}(${printResoult(event, 1)})`
    }

    // kosarka
    if (event.sportId === sports?.sportNames.get('Kosarka')) {
      if (home) {
        return `${
          event.detailedResult?.Q4?.P[0] ||
          event.detailedResult?.Q3?.P[0] ||
          event.detailedResult?.Q2?.P[0] ||
          event.detailedResult?.Q1?.P[0]
        }|${event.currentResult?.P[0]}(${
          event.detailedResult?.Q3
            ? event.detailedResult?.Q1?.P[0] + event.detailedResult?.Q2?.P[0]
            : '0'
        })`
      }
      return `${
        event.detailedResult?.Q4?.P[1] ||
        event.detailedResult?.Q3?.P[1] ||
        event.detailedResult?.Q2?.P[1] ||
        event.detailedResult?.Q1?.P[1]
      }|${event.currentResult?.P[1]}(${
        event.detailedResult?.Q3
          ? event.detailedResult?.Q1?.P[1] + event.detailedResult?.Q2?.P[1]
          : '0'
      })`
    }

    // odbojka(3) / stoni-tenis(12)
    if (
      event.sportId === sports?.sportNames?.get('Odbojka') ||
      event.sportId === sports?.sportNames.get('Stoni tenis')
    ) {
      if (home) {
        return `${
          event.detailedResult?.SET5?.P[0] ||
          event.detailedResult?.SET4?.P[0] ||
          event.detailedResult?.SET3?.P[0] ||
          event.detailedResult?.SET2?.P[0] ||
          event.detailedResult?.SET1?.P[0]
        }|${event.currentResult?.S[0]}`
      }
      return `${
        event.detailedResult?.SET5?.P[1] ||
        event.detailedResult?.SET4?.P[1] ||
        event.detailedResult?.SET3?.P[1] ||
        event.detailedResult?.SET2?.P[1] ||
        event.detailedResult?.SET1?.P[1]
      }|${event.currentResult?.S[1]}`
    }

    // Tenis
    if (event.sportId === sports?.sportNames.get('Tenis')) {
      if (home) {
        return `${
          event.detailedResult?.SET5?.G[0] ||
          event.detailedResult?.SET4?.G[0] ||
          event.detailedResult?.SET3?.G[0] ||
          event.detailedResult?.SET2?.G[0] ||
          event.detailedResult?.SET1?.G[0]
        }|${event.currentResult?.S[0]}`
      }
      return `${
        event.detailedResult?.SET5?.G[1] ||
        event.detailedResult?.SET4?.G[1] ||
        event.detailedResult?.SET3?.G[1] ||
        event.detailedResult?.SET2?.G[1] ||
        event.detailedResult?.SET1?.G[1]
      }|${event.currentResult?.S[1]}`
    }

    // hockej(7)
    if (event.sportId === sports?.sportNames?.get('Hokej')) {
      if (home) {
        return `${
          event?.detailedResult?.P3?.G[0] ||
          event.detailedResult?.P2?.G[0] ||
          event.detailedResult?.P1?.G[0]
        }|${event.currentResult?.G[0]}`
      }
      return `${
        event.detailedResult?.P3?.G[1] ||
        event.detailedResult?.P2?.G[1] ||
        event.detailedResult?.P1?.G[1]
      }|${event.currentResult?.G[1]}`
    }

    return '-'
  }

  useEffect(() => {
    if (event.awayResultChanged) {
      updateAwayResultChange()
    }
    if (event.homeResultChanged) {
      updateHomeResultChange()
    }
  }, [event.awayResultChanged, event.homeResultChanged]) // eslint-disable-line

  return (
    <div className={styles.liveEvent} key={event.id}>
      {(event.isSuspended || event.betStop) && <SuspendOverlay />}
      <Row>
        <div
          className={styles.liveEventSelected}
          style={
            sports.selectedLiveMatch?.intKey === event.intKey
              ? { backgroundColor: '#00a551' }
              : { backgroundColor: '#313131' }
          }
        />
        <div style={{ width: 'calc(100% - 4px)' }}>
          <Row gutter={1}>
            {!event.isFavourite && (
              <Col span={3}>
                <div
                  style={{ cursor: 'pointer' }}
                  className={styles.liveColumnIcon}
                  onClick={() => sports.addToFavourites(event)}
                >
                  <StarIcon className={styles.starIcon} />
                </div>
              </Col>
            )}
            {event.isFavourite && (
              <Col span={3}>
                <div
                  style={{ cursor: 'pointer' }}
                  className={styles.liveColumnIcon}
                  onClick={() => sports.removeFromFavourites(event)}
                >
                  <StarIcon className={styles.starIconYellow} />
                </div>
              </Col>
            )}
            <Col span={6}>
              <div className={styles.liveColumn}>{event.landbaseCode}</div>
            </Col>
            <Col span={8}>
              <div className={styles.liveColumn}>
                {!event.currentPhase?.isBreak
                  ? `${
                      event.currentPhase?.time ||
                      event.currentPhase?.T?.PRT ||
                      event.currentPhase?.T?.M ||
                      event.currentPhase?.SN ||
                      0
                    }' / ${event.currentPhase?.N}`
                  : t('break')}
              </div>
            </Col>
            <Col span={7}>
              <div className={styles.liveColumn}>
                {event.competitionShortName !== ''
                  ? event.competitionShortName
                  : '-'}
              </div>
            </Col>
          </Row>
          <Row
            gutter={2}
            style={{ cursor: 'pointer' }}
            onClick={() => sports.setSelectedLiveEvent(event)}
          >
            <Col span={24}>
              <div className={styles.liveColumnResult}>
                <span>{event?.home}</span>
                <span
                  style={event.homeResultChanged ? { color: '#9079D2' } : {}}
                >
                  {getResoult(event, true)}
                </span>
              </div>
            </Col>
            <Col span={24}>
              <div className={styles.liveColumnResult}>
                <span>{event?.away}</span>
                <span
                  style={event.awayResultChanged ? { color: '#9079D2' } : {}}
                >
                  {getResoult(event, false)}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
})
