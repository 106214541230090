import { getSocketUrl } from './urlBuilder'
import { wsHeartBeat } from './wsHeartBeat'

export enum FeedMessageType {
  ODDS = 'odds',
  LIVE_ODDS = 'live_odds',
  SUSPEND_PREMATCH = 'event_suspended_prematch',
  UNSUSPEND_PREMATCH = 'event_unsuspended_prematch',
  SUSPEND_LIVE = 'event_suspended_live',
  UNSUSPEND_LIVE = 'event_unsuspended_live',
  EVENT_HIDDEN = 'event_hidden',
  PREMATCH_REMOVED = 'prematch_removed',
  EVENT_START_DATE_UPDATED = 'event_start_date_updated',

  PLAYER_TIME_UPDATED = 'player_events_start_updated',
  PLAYER_PUBLISHED = 'event_player_published',
  SUSPEND_PLAYER = 'event_player_suspended_prematch',
  UNSUSPEND_PLAYER = 'event_player_unsuspended_prematch',
  EVENT_PLAYER_HIDDEN = 'event_player_hidden',
  PLAYER_ODD_SUSPENDED = 'odd_player_suspended',
  PLAYER_ODD_UNSUSPENDED = 'odd_player_unsuspended',
  PLAYER_ODD_UPDATE = 'player_odds_sc',
  PLAYER_MARKET_SUSPENDED = 'market_event_player_suspended',
  PLAYER_MARKET_UNSUSPENDED = 'market_event_player_unsuspended',
  PLAYER_REMOVED = 'event_player_removed',

  NOTE_UPDATE = 'note',

  ANTEPOST_MARKET_SUSPENDED = 'antepost_market_suspended',
  ANTEPOST_MARKET_UNSUSPENDED = 'antepost_market_unsuspended',

  ANTEPOST_ODD_SUSPENDED = 'antepost_bet_suspended',
  ANTEPOST_ODD_UNSUSPENDED = 'antepost_bet_unsuspended',
  ANTEPOST_ODD_HIDDEN = 'antepost_bet_hidden',
  ANTEPOST_ODD_PUBLISHED = 'antepost_bet_published',
  ANTEPOST_ODD_UPDATE = 'antepost_bet_update',

  ANTEPOST_PUBLISHED = 'antepost_published',
  ANTEPOST_UPDATE = 'antepost_update',
  ANTEPOST_REMOVED = 'antepost_removed',
  ANTEPOST_BET_DELETE = 'antepost_bet_delete',
  ANTEPOST_SUSPENDED = 'antepost_suspended',
  ANTEPOST_UNSUSPENDED = 'antepost_unsuspended',
  ANTEPOST_EVENT_HIDDEN = 'antepost_hidden',

  EVENT_PUBLISHED = 'event_published',
  SERVER_TIME = 'server_time',
  MARKET_ODDS_CHANGE = 'all_odds_platform',
  ODDS_PLATFORM = 'odds_platform',
  LIVE_OFF = 'live_off',
  LIVE_ON = 'live_on',
  BET_STOP_LIVE = 'event_bet_stop_live',
  BET_START_LIVE = 'event_bet_start_live',
  PREMATCH_OFF = 'prematch_off',
  PREMATCH_ON = 'prematch_on',
  RESULT = 'result',
  EVENT_START = 'event_start',
  EVENT_END = 'event_end',
  PROD_ODD = 'prod_odd',
  MARKET_EVENT_SUSPENDED = 'market_event_suspended',
  MARKET_EVENT_UNSUSPENDED = 'market_event_unsuspended',
  ODD_SUSPENDED = 'odd_suspended',
  ODD_UNSUSPENDED = 'odd_unsuspended',
}

class FeedApi {
  private socket: WebSocket
  private feedStore: any
  private handlers: any

  constructor(handlers: any, store: any) {
    const url = getSocketUrl('ws/odds/sc/')
    this.socket = new WebSocket(url)
    this.handlers = handlers
    this.feedStore = store
  }

  refreshConnection = () => {
    const url = getSocketUrl('ws/odds/sc/')
    this.socket = new WebSocket(url)
    this.connect()
  }

  heartBeat = () => {
    wsHeartBeat(this.socket, this.refreshConnection, this.feedStore)
  }

  connect = () => {
    this.heartBeat()
    this.socket.addEventListener('message', ({ data }: any) => {
      try {
        const { type, message } = JSON.parse(data)
        if (this.handlers[type]) {
          this.handlers[type](message)
        }
      } catch (_) {
        // do non
      }
    })
    this.socket.addEventListener('onClose', () => console.log('disconnected'))
  }
}

export default FeedApi
