import React from 'react'
import { Button, Col, Modal, Row, Statistic, Tooltip } from 'antd'

import { SlipEvent, SlipGroup, SlipOdd } from 'store/models/SlipModels'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  getAcceptedStatusClass,
  getCanceledStatusClass,
  getEventStatusColor,
  getLostStatusClass,
  getPaidOutStatusClass,
  getWonStatusClass,
  getCashBackClass,
  isPayOutDisabled,
} from 'utils/slip.service'
import styles from './reviewSlipModal.module.css'

type ModalType = 'REVIEW' | 'STATUS'

interface ReviewSlipModalProps {
  visible: boolean
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
  loading: boolean
  data: any | null
  modalType: ModalType
}

const roundOnTwoDecimals = (amount: number | string | null) => {
  return amount && !Number.isNaN(+amount) ? (+amount).toFixed(2) : amount
}

const formatMaxPayOut = (payOut: any) => {
  if (payOut) {
    return roundOnTwoDecimals(
      payOut.toLocaleString('rs-RS', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    )
  }

  return 0
}

const RenderAmount = ({
  old,
  amount,
}: {
  old: number | null
  amount: number
}) => {
  if (old) {
    return (
      <div className={styles.changedOdd}>
        <div className={styles.oldOdd}>{roundOnTwoDecimals(old)}</div>
        <div style={{ margin: '0 4px', color: 'white' }}>&rarr;</div>
        <div className={styles.currentOdd}>{roundOnTwoDecimals(amount)}</div>
      </div>
    )
  }
  return <div>{amount}</div>
}

const formatOdd = (oddValue: any) =>
  oddValue ? parseFloat(oddValue).toFixed(2) : '/'

const RenderOdd = ({ odd, modalType }: { odd: SlipOdd; modalType: string }) => {
  if (odd.old_odd_value && modalType !== 'STATUS') {
    return (
      <div className={styles.changedOdd}>
        <div className={styles.oldOdd}>{formatOdd(odd.old_odd_value)}</div>
        <div style={{ margin: '0 4px', color: 'white' }}>&rarr;</div>
        <div className={styles.currentOdd}>{formatOdd(odd.odd_value)}</div>
      </div>
    )
  }
  return <div>{formatOdd(odd.odd_value)}</div>
}

const RenderLimit = ({ odd }: { odd: SlipOdd }) => {
  if (odd.old_limit2_value) {
    return (
      <span>
        <div className={styles.changedOdd}>
          <div className={styles.oldOdd}>{odd.old_limit2_value}</div>
          <div style={{ margin: '0 1px', color: 'white' }}>&rarr;</div>
          <div className={styles.currentOdd}>{odd.limit2_value}</div>
        </div>
        <div className={styles.changedOdd}>
          <div className={styles.oldOdd}>{odd.old_limit_value}</div>
          <div style={{ margin: '0 1px', color: 'white' }}>&rarr;</div>
          <div className={styles.currentOdd}>{odd.limit_value}</div>
        </div>
      </span>
    )
  }
  if (odd.old_limit_value) {
    return (
      <div className={styles.changedOdd}>
        <div className={styles.oldOdd}>{odd.old_limit_value}</div>
        <div style={{ margin: '0 1px', color: 'white' }}>&rarr;</div>
        <div className={styles.currentOdd}>{odd.limit_value}</div>
      </div>
    )
  }
  return (
    <span>
      <div>{odd.limit2_value || ''}</div>
      <div>{odd.limit_value || '/'}</div>
    </span>
  )
}

const statusColumnStyles = (eventStatus: string | null, modalType: ModalType) =>
  styles[getEventStatusColor(eventStatus, modalType)]

const RenderEvent = ({
  event,
  modalType,
}: {
  event: SlipEvent
  modalType: ModalType
}) => {
  console.log('event.odds[0].automated_status', event.odds)
  return (
    <Row className={styles.matchRow}>
      <Col span={3}>
        <div className={styles.matchColumn}>{event.event_code}</div>
      </Col>
      <Col span={3}>
        <div className={styles.matchColumn}>
          {moment(event.event_start_time).format('DD/MM HH:mm')}
        </div>
      </Col>
      <Col span={8}>
        <div className={styles.matchColumn}>
          {event.player_name || event.event_name}
        </div>
      </Col>
      <Col
        span={10}
        className={statusColumnStyles(
          event.automated_status, // event.odds[0].automated_status,
          modalType
        )}
      >
        {event.odds.map((odd) => (
          <Row>
            <Col span={odd?.old_limit_value ? 2 : 5}>
              <div className={styles.matchColumn}>
                <div>
                  {odd.outcome_print_code}
                  {event?.event_note && (
                    <Tooltip placement="top" title={event?.event_note}>
                      <span
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          right: 10,
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                      >
                        *
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            </Col>
            <Col span={odd?.old_limit_value ? 8 : 4}>
              <div className={styles.matchColumn}>
                <RenderLimit odd={odd} />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.matchColumn}>
                <RenderOdd odd={odd} modalType={modalType} />
              </div>
            </Col>
            {modalType !== 'REVIEW' && (
              <Col span={6}>
                <Row>
                  <Col span={odd?.old_limit_value ? 12 : 16}>
                    <div className={styles.matchColumn}>n/n</div>
                  </Col>
                  <Col span={odd?.old_limit_value ? 12 : 8}>
                    <div className={styles.matchColumn}>n/n</div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        ))}
      </Col>
    </Row>
  )
}

const RenderGroup = ({
  group,
  groupNum,
  modalType,
}: {
  group: SlipGroup
  groupNum: number
  modalType: ModalType
}) => {
  return (
    <div className={styles.slipGroup}>
      <Row>
        <Col span={20}>
          <div className={styles.slipGroupHeader}>{group.system}</div>
        </Col>
        <Col span={4}>
          <div className={styles.slipGroupHeader}>Group: {groupNum}</div>
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <div className={styles.matchHeaderColumn}>Mcode</div>
        </Col>
        <Col span={3}>
          <div className={styles.matchHeaderColumn}>MatchTime</div>
        </Col>
        <Col span={8}>
          <div className={styles.matchHeaderColumn}>Match</div>
        </Col>
        <Col span={2}>
          <div className={styles.matchHeaderColumn}>Bet</div>
        </Col>
        <Col span={2}>
          <div className={styles.matchHeaderColumn}>Par</div>
        </Col>
        <Col span={modalType !== 'REVIEW' ? 3 : 6}>
          <div className={styles.matchHeaderColumn}>Coef.</div>
        </Col>
        {modalType !== 'REVIEW' && (
          <Col span={3}>
            <Row>
              <Col span={16}>
                <div className={styles.matchHeaderColumn}>I per. </div>
              </Col>
              <Col span={8}>
                <div className={styles.matchHeaderColumn}>FT</div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {group.events.map((event) => (
        <RenderEvent event={event} modalType={modalType} />
      ))}
    </div>
  )
}

const ReveiwSlipActions = ({
  onConfirm,
  onCancel,
  loading,
  modalType,
  status,
}: any) => {
  // const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        loading={loading}
        style={{ marginRight: '4px' }}
        onClick={onConfirm}
        disabled={
          modalType === 'STATUS' &&
          isPayOutDisabled(status) &&
          status !== '__CASHBACK__'
        }
      >
        {modalType === 'REVIEW' ? 'Accept changes' : 'Pay Out'}
      </Button>
      <Button loading={loading} onClick={onCancel} type="ghost" danger>
        {modalType === 'REVIEW' ? 'Decline changes' : 'Cancel'}
      </Button>
    </div>
  )
}

const STATUS_BARS_STYLE_MAP = {
  red: styles.statusBarRed,
  green: styles.statusBarGreen,
  yellow: styles.statusBarYellow,
}

const StatusRow = ({
  label,
  statusClass,
}: {
  label: string
  statusClass: keyof typeof STATUS_BARS_STYLE_MAP
}) => (
  <div className={styles.statusRow}>
    <div>{label}</div>
    <div className={STATUS_BARS_STYLE_MAP[statusClass]} />
  </div>
)

const ReviewSlipModal = ({
  visible,
  onConfirm,
  onCancel,
  onClose,
  loading,
  data,
  modalType,
}: ReviewSlipModalProps) => {
  const { t } = useTranslation()

  const statusRows = [
    { label: t('accepted'), statusClass: getAcceptedStatusClass(data?.status) },
    { label: t('canceled'), statusClass: getCanceledStatusClass(data?.status) },
    { label: t('won'), statusClass: getWonStatusClass(data?.status) },
    { label: t('lost'), statusClass: getLostStatusClass(data?.status) },
    { label: t('cashback'), statusClass: getCashBackClass(data?.status) },
    { label: t('paidOut'), statusClass: getPaidOutStatusClass(data?.status) },
  ]

  return (
    <Modal
      closable
      visible={visible}
      onCancel={onClose}
      title={`Review Slip ID:${data?.short_uuid}`}
      footer={false}
      width={800}
    >
      <div className={styles.modalBody}>
        {visible && (
          <div className={styles.modalBody}>
            {data?.slip_groups.map((group: any, i: number) => (
              <RenderGroup
                group={group}
                groupNum={i + 1}
                modalType={modalType}
              />
            ))}
          </div>
        )}
      </div>
      {data && (
        <div className={styles.slipInfo}>
          <div className={styles.slipInfoLeft}>
            <div>
              <Statistic
                valueStyle={{ fontSize: '14px' }}
                title={t('minOdd')}
                value={roundOnTwoDecimals(data.min_odds_value) || '0.00'}
              />
              <Statistic
                valueStyle={{ fontSize: '14px' }}
                title={t('maxOdd')}
                value={roundOnTwoDecimals(data.max_odds_value) || '0.00'}
              />
            </div>

            <div style={{ marginLeft: 24 }}>
              <Statistic
                valueStyle={{
                  fontSize: '14px',
                }}
                title={t('minWin')}
                value={roundOnTwoDecimals(data.min_winning_amount) || '0.00'}
              />
              <Statistic
                valueStyle={{ fontSize: '14px' }}
                title={t('maxWin')}
                value={roundOnTwoDecimals(data.max_winning_amount) || 0}
              />
            </div>
          </div>

          <div className={styles.slipInfoRight}>
            <div className={styles.slipInfoRightTop}>
              <Statistic
                valueStyle={{
                  fontSize: '14px',
                }}
                title={t('Uplata')}
                formatter={(amount: any) => (
                  <RenderAmount old={data.old_amount} amount={amount} />
                )}
                value={data.amount}
              />
              <Statistic
                valueStyle={{ fontSize: '14px' }}
                title={t('matchNum')}
                value={data.number_of_matches}
              />
              <Statistic
                valueStyle={{ fontSize: '14px' }}
                title={t('combNum')}
                value={data.number_of_combinations || 0}
              />
            </div>

            <div className={styles.slipInfoRightBottom}>
              <span>
                {modalType === 'REVIEW' ? t('maxPayOut') : t('payOut')}:
              </span>
              <span>
                {formatMaxPayOut(
                  data.status === '__CASHBACK__'
                    ? data?.cash_back
                    : data.actual_winning_amount || data.max_winning_amount
                ) || '0.00'}
              </span>
            </div>
          </div>
          {modalType === 'STATUS' && (
            <div className={styles.ticketStatusBars}>
              <p>Status:</p>
              {statusRows.map((row) => (
                <StatusRow
                  label={row.label}
                  statusClass={
                    row.statusClass as keyof typeof STATUS_BARS_STYLE_MAP
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
      <ReveiwSlipActions
        onCancel={onCancel}
        loading={loading}
        onConfirm={onConfirm}
        modalType={modalType}
        status={data?.status}
      />
    </Modal>
  )
}

export default ReviewSlipModal
