import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import {
  DatePicker,
  Form,
  FormItem,
  Input,
  InputNumber,
  SubmitButton,
} from 'formik-antd'
import { Button, Col, Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer, Observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import * as Yup from 'yup'
import moment from 'moment'
import { SectionContainer } from '../SectionContainer'
import styles from '../SlipPayIn/slipPayIn.module.css'

export default observer(function OnlineDeposit() {
  const { t } = useTranslation()
  const { user, slip, slipApi } = useContext(StoreContext)

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t('mandatoryField')),
    dateOfBirth: Yup.string()
      .required(t('mandatoryField'))
      .typeError(t('mandatoryField')),
  })

  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [initialState, setInitialState] = React.useState({
    username: '',
    dateOfBirth: '',
    usernameFound: '',
    name: '',
    surname: '',
    email: '',
    dateOfBirthFound: '',
    payInAmount: 0,
  })

  useEffect(() => {
    if (!user.selectedUser) {
      setInitialState({
        username: '',
        dateOfBirth: '',
        usernameFound: '',
        name: '',
        surname: '',
        email: '',
        dateOfBirthFound: '',
        payInAmount: 0,
      })
      return
    }
    setInitialState({
      username: user.selectedUser?.username || '',
      dateOfBirth: user.selectedUser?.date_of_birth || '',
      usernameFound: user.selectedUser?.username || '',
      name: user.selectedUser?.first_name || '',
      surname: user.selectedUser?.last_name || '',
      email: user.selectedUser?.email || '',
      dateOfBirthFound:
        moment(user.selectedUser?.date_of_birth).format('YYYY-MM-DD') || '',
      payInAmount: 1,
    })
  }, [user.selectedUser])

  const applayFilters = (values: any) => {
    return user.getUserByUsernameAndBDate(values.username, values.dateOfBirth)
  }

  const closeErrorModal = () => {
    setErrorModal(false)
  }

  const payInDeposit = () => {
    setErrorModal(true)
  }

  const payInClear = () => {
    setInitialState({
      username: '',
      dateOfBirth: '',
      usernameFound: '',
      name: '',
      surname: '',
      email: '',
      dateOfBirthFound: '',
      payInAmount: 0,
    })
  }

  const sendPayDeposit = (values: any) => {
    user
      .setDeposit({
        platform_user_id: user.selectedUser?.id,
        system_user_id: user.user?.id,
        amount: values.payInAmount,
      })
      .then((data: any) => {
        if (data?.status === 200) {
          slip.depPayInaAmount += values.payInAmount
          payInClear()
          const postData = {
            UserName: data?.data?.username,
            FirstName: data?.data?.first_name,
            LastName: data?.data?.last_name,
            Email: data?.data?.email,
            Amount: data?.data?.amount,
            Location: data?.data?.location,
            WithdrawCode: '',
            Tip: 'Uplata',
          }
          slipApi.printSlipDepositWithdrawal(postData)
        }
      })
    setErrorModal(false)
  }

  return (
    <div>
      <SectionContainer translation="userDetails">
        <Row gutter={16}>
          <Col span={24}>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={initialState}
              onSubmit={(values) => {
                return applayFilters(values)
              }}
            >
              {({ setFieldValue, values, handleChange }) => (
                <Observer>
                  {() => (
                    <Form>
                      <Row gutter={16}>
                        <Col span={3}>
                          <FormItem
                            name="username"
                            label={t('userName')}
                            labelCol={{ span: 24 }}
                            style={{ marginBottom: '20px' }}
                          >
                            <Input
                              name="username"
                              style={{ width: '162px' }}
                              onChange={handleChange}
                            />
                          </FormItem>
                        </Col>
                        <Col span={3}>
                          <FormItem
                            name="dateOfBirth"
                            label={t('dateOfBirth')}
                            labelCol={{ span: 24 }}
                            style={{ marginBottom: '20px' }}
                          >
                            <DatePicker
                              name="dateOfBirth"
                              style={{ width: '162px' }}
                              onChange={(date, dateString) => {
                                if (date) {
                                  setFieldValue('dateOfBirth', dateString)
                                }
                              }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={2}>
                          <SubmitButton
                            style={{ marginTop: '40px', width: '100%' }}
                          >
                            {t('searchDeposit')}
                          </SubmitButton>
                        </Col>
                        <Col span={15} />
                      </Row>
                      <Col span={6} style={{ paddingRight: '12px' }}>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="usernameFound"
                              labelAlign="left"
                              label={t('userName')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="usernameFound"
                                id="usernameFound"
                                style={{ width: '162px' }}
                                value={initialState.usernameFound}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="name"
                              labelAlign="left"
                              label={t('name')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="name"
                                id="name"
                                style={{ width: '162px' }}
                                value={initialState.name}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="surname"
                              labelAlign="left"
                              label={t('surname')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="surname"
                                id="surname"
                                style={{ width: '162px' }}
                                value={initialState.surname}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="email"
                              labelAlign="left"
                              label={t('email')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="email"
                                id="email"
                                style={{ width: '162px' }}
                                value={initialState.email}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="dateOfBirthFound"
                              labelAlign="left"
                              label={t('dateOfBirth')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="dateOfBirthFound"
                                style={{ width: '162px' }}
                                value={initialState.dateOfBirthFound}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="payInAmount"
                              labelAlign="left"
                              label={t('payInAmountValue')}
                              style={{
                                marginBottom: '20px',
                                marginTop: '30px',
                              }}
                            >
                              <InputNumber
                                name="payInAmount"
                                type="number"
                                id="payInAmount"
                                min={1}
                                style={{ width: '162px' }}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <Button
                              type="primary"
                              disabled={
                                initialState.payInAmount === 0 &&
                                initialState.surname === ''
                              }
                              onClick={payInDeposit}
                              style={{ width: '75px' }}
                            >
                              {t('payIn')}
                            </Button>
                            <Button
                              danger
                              onClick={() => {
                                payInClear()
                              }}
                              style={{ marginLeft: 12, width: '75px' }}
                            >
                              {t('cancel')}
                            </Button>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Modal
                          onCancel={closeErrorModal}
                          visible={errorModal}
                          footer={false}
                          closable
                          width={600}
                          title={
                            <div className={styles.errorModalHeader}>
                              {t('depositModalMsg', {
                                username: initialState.usernameFound,
                              })}
                            </div>
                          }
                        >
                          <div className={styles.errorModalFooter}>
                            <Button
                              size="small"
                              type="primary"
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                sendPayDeposit(values)
                              }}
                            >
                              {t('yes')}
                            </Button>
                            <Button
                              size="small"
                              danger
                              onClick={closeErrorModal}
                            >
                              {t('no')}
                            </Button>
                          </div>
                        </Modal>
                      </Col>
                    </Form>
                  )}
                </Observer>
              )}
            </Formik>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  )
})
