export enum Keycodes {
  ENTER = 13,
  SPACE = 32,
  PAGE_UP = 33,
  DEL = 46,
  PAGE_DOWN = 34,
  END = 35,
  BACKSPACE = 8,
  MINUS = 187,
  DIGIT_EIGHT = 56,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F11 = 122,
  SLASH = 111,
  PLUS = 107,
  STAR = 106,
}

export const isPlus = (e: any) => {
  return (e.shiftKey && e.which === Keycodes.MINUS) || e.which === Keycodes.PLUS
}

export const isStar = (e: any) => {
  return (
    (e.shiftKey && e.which === Keycodes.DIGIT_EIGHT) ||
    e.which === Keycodes.STAR
  )
}

export const handleKeyEvent = (
  e: any,
  handlers: any,
  preventDefault?: boolean
) => {
  Object.keys(handlers).forEach((key: any) => {
    if (Number(e.which) === Number(key)) {
      if (preventDefault) {
        e.preventDefault()
      }

      handlers[key](e)
    }
  })
}

export const registerGlobalEvent = (handlers: any) => {
  window.addEventListener('keydown', (e: any) => {
    handleKeyEvent(e, handlers, true)
  })
}

export const removeGlobalEvent = () => {
  // @ts-ignore
  window.removeEventListener('keydown', null)
}
