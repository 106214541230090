import React, { useContext, useRef } from 'react'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'
import { Event } from 'utils/sports.types'
import SuspendOverlay from 'components/SuspendOverlay'
import styles from './marketsListing.module.css'

interface PlayerMarketsListingProps {
  event: Event
}

export default observer(function PlayerMarketsListing({
  event,
}: PlayerMarketsListingProps) {
  const { sports, slip } = useContext(StoreContext)

  const getMarkets = (id: number) => {
    return sports.playerMarkets.find((sport) => sport.id === id)?.marketsList
  }

  const refArray: any[] = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 600; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    refArray.push(useRef<HTMLSpanElement>(null))
  }

  const setHighlightedOdd = (intKey: number, oddIntKey: number) => {
    let higlightedClass = ''
    const event: { selectedOdds: any[] } = slip.findEvent(intKey)
    if (event) {
      if (event.selectedOdds.find((odd) => odd.int_key === oddIntKey))
        higlightedClass = styles.highlightedColumn
    }
    return higlightedClass
  }

  const checkIsSuspended = (event: Event, marketId: number) => {
    if (event && event.marketsMap) {
      const marketToSuspend = event?.marketsMap.find(
        (market) => parseInt(market[0], 10) === marketId
      )
      return marketToSuspend ? marketToSuspend[1] : false
    }
    return false
  }

  const getOddClassName = (
    odd: any,
    isSuspended: boolean,
    inputReactPlayerVar: any
  ) => {
    inputReactPlayerVar?.current?.classList?.remove(styles.oddDownColor)
    inputReactPlayerVar?.current?.classList?.remove(styles.oddUpColor)
    inputReactPlayerVar?.current?.classList?.add(styles.oddDefaultColor)
    if (isSuspended) {
      inputReactPlayerVar?.current?.classList?.add(
        styles.oddColorMatchSuspended
      )
      return styles.oddColorMatchSuspended
    }
    if (odd.isDown) {
      inputReactPlayerVar?.current?.classList?.add(styles.oddDownColor)
      setTimeout(function () {
        inputReactPlayerVar?.current?.classList?.remove(styles.oddDownColor)
        inputReactPlayerVar?.current?.classList?.remove(styles.oddDownColor)
        inputReactPlayerVar?.current?.classList?.add(styles.oddDefaultColor)
        odd.isDown = false
        odd.isUp = false
      }, 6000)
      return styles.oddDownColor
    }
    if (odd.isUp) {
      inputReactPlayerVar?.current?.classList?.add(styles.oddUpColor)
      setTimeout(function () {
        inputReactPlayerVar?.current?.classList?.remove(styles.oddUpColor)
        inputReactPlayerVar?.current?.classList?.add(styles.oddDefaultColor)
        odd.isDown = false
        odd.isUp = false
      }, 6000)
      return styles.oddUpColor
    }
    return styles.oddDefaultColor
  }

  return (
    <div style={{ height: 'auto' }}>
      <div style={{ paddingTop: '5px', backgroundColor: '#000' }}>
        <Row gutter={8} style={{ backgroundColor: '#000' }}>
          {getMarkets(event?.sportId)?.map((marketList: any) => {
            return (
              <Col
                span={12}
                key={marketList?.id}
                style={{ marginBottom: '8px', position: 'relative' }}
              >
                {checkIsSuspended(event, marketList?.id) && <SuspendOverlay />}
                <Row>
                  <div className={styles.matchHeader}>{marketList.name}</div>
                </Row>
                <Row>
                  {event?.allOddsList?.map((el, index) => {
                    return (
                      marketList?.outcomesList?.has(el?.outcomeId) && (
                        <>
                          {el.limit !== 0 &&
                            (index === 0 ||
                              event?.allOddsList?.[index].limit !==
                                event?.allOddsList?.[index - 1].limit) && (
                              <Col span={6} key={el?.limit}>
                                <div className={styles.limitColumn}>
                                  {el.limit}
                                </div>
                              </Col>
                            )}
                          <Col span={5} key={JSON.stringify(el)}>
                            <div className={styles.matchColumn}>
                              {
                                marketList?.outcomesList.get(el?.outcomeId)
                                  ?.printCode
                              }
                            </div>
                          </Col>
                          <Col span={el?.limit !== 0 ? 4 : 3} key={el?.intKey}>
                            {(el?.isSuspended ||
                              el?.oddValue?.toString() === '100' ||
                              el?.oddValue?.toString() === '1') && (
                              <SuspendOverlay />
                            )}
                            <div
                              ref={refArray[index]}
                              style={{ cursor: 'pointer' }}
                              className={`${
                                styles.matchColumn
                              } ${setHighlightedOdd(
                                event.intKey,
                                el.intKey || 0
                              )} ${getOddClassName(
                                el,
                                el?.isSuspended,
                                refArray[index]
                              )}`}
                              onClick={() =>
                                slip?.toggleOdd(
                                  event,
                                  el,
                                  marketList?.outcomesList?.get(el?.outcomeId)
                                )
                              }
                            >
                              {el?.oddValue?.toString() === '100' ||
                              el?.oddValue?.toString() === '1'
                                ? ''
                                : (el?.oddValue / 100).toFixed(2)}
                            </div>
                          </Col>
                        </>
                      )
                    )
                  })}
                </Row>
              </Col>
            )
          })}
        </Row>
      </div>
    </div>
  )
})
