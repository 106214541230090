import { action, makeObservable, observable } from 'mobx'
import BaseStore from './BaseStore'
import RootStore from './RootStore'

export enum ShortcutEvents {
  FOCUS_SYSTEM,
  FOCUS_PAYIN_AMOUNT,
  FOCUS_MATCH_CODE,
  TOGGLE_KEEP_MATCHES,
  FOCUS_LAST_GROUP,
  FOCUS_PAYIN_LEAVE_GROUPS,
  CHANGED_COUNT,
}

export default class ShortcutsStore extends BaseStore {
  event: any = null

  constructor(rootStore: RootStore) {
    super(rootStore)

    makeObservable(this, {
      event: observable,
      setEvent: action,
      clear: action,
    })
  }

  setEvent = (event: any) => {
    this.event = event
  }

  clear = () => {
    this.event = null
  }
}
