import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, Input } from 'antd'
import StoreContext from 'store/StoreContext'
import { useTranslation } from 'react-i18next'

const ManualApprovalInfo = () => {
  const { slip } = useContext(StoreContext)
  const { t } = useTranslation()

  return (
    <div>
      <div>
        {/* eslint-disable-next-line */}
        <label htmlFor="manualApprovalCheck">{t('sendForApproval')}:</label>
        <Checkbox
          style={{ marginLeft: 8 }}
          id="manualApprovalCheck"
          checked={slip.sendToManualApproval}
          disabled={slip.disableManualApproval}
          onChange={() => slip.toggleManualApproval()}
        />
      </div>
      <div style={{ marginTop: 18 }}>
        {/* eslint-disable-next-line */}
      <label htmlFor="manualApprovalNote">{t('username')}:</label>
        <Input
          value={slip.manualUsername}
          onChange={(e: any) => slip.setManualUsername(e.target.value)}
          name="add-username"
          height="regular"
          className="text-center"
        />
      </div>
      <div style={{ marginTop: 18 }}>
        {/* eslint-disable-next-line */}
        <label htmlFor="manualApprovalNote">{t('note')}:</label>
        <Input.TextArea
          rows={3}
          value={slip.manualApprovalReason}
          id="manualApprovalNote"
          onChange={(e: any) => slip.setManualApprovalReason(e.target.value)}
        />
      </div>
    </div>
  )
}

export default observer(ManualApprovalInfo)
