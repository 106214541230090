import React from 'react'
import { Router } from 'react-router'
import Routes from './routes'
import MainRoutes from './routes/MainRoutes'
import { history } from './store/RootStore'
import './i18n'
import 'antd/dist/antd.dark.less'
import 'overrides.less'

function App() {
  return (
    <Router history={history}>
      <Routes routes={MainRoutes} />
    </Router>
  )
}

export default App
