import React, { useContext, useState } from 'react'
import { Button, Col, Pagination, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import { SportsIcons } from 'components/SportsIcons'
import SuspendOverlay from 'components/SuspendOverlay'
import AntepostMarketsListing from 'components/AntepostMarketsListing'
import _ from 'lodash'
import styles from './antepostCatalog.module.css'

export default observer(function AntepostCatalog() {
  const { t } = useTranslation()
  const { sports } = useContext(StoreContext)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 30

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleSelectedEvent = (match: any) => {
    if (sports.selectedAntepost?.intKey !== match.intKey) {
      match.allOddsList = _.sortBy(match.allOddsList, 'outcomeId')
      sports.setAntepostEvent(match)
    } else {
      sports.unsetAntepostSelectedEvent()
    }
  }

  const PrematchTableHeader = () => {
    const headerConfig = [
      {
        span: 3,
        key: 1,
        headerText: t('sport'),
      },
      {
        span: 6,
        key: 2,
        headerText: t('name'),
      },
      {
        span: 7,
        key: 3,
        headerText: t('country'),
      },
      {
        span: 7,
        key: 4,
        headerText: t('competition'),
      },
      {
        span: 1,
        key: 5,
        headerText: t('all'),
      },
    ]

    return (
      <Row gutter={1}>
        {headerConfig.map((c) => (
          <Col span={c.span} key={c.key}>
            <div className={styles.matchHeaderColumn}>
              <span>{c.headerText}</span>
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <div className={styles.antepostCatalog}>
      <Row>
        <div className={styles.matchHeader}>
          <span>{t('matches')}</span>
        </div>
      </Row>
      <PrematchTableHeader />
      {sports.loaded ? (
        <div className={styles.antepostOffer}>
          {sports.filteredAntepostEvents.map(
            (el: any, index: any) =>
              index >= (currentPage - 1) * pageSize &&
              index < currentPage * pageSize && (
                <div key={el.intKey} className={styles.matchRow}>
                  {(el.isSuspended || el.market.isSuspended) && (
                    <SuspendOverlay />
                  )}
                  <Row gutter={1}>
                    <Col span={3}>
                      <div className={styles.matchColumn}>
                        <div className={styles.iconCircle}>
                          {SportsIcons.get(el.market.sportId)}
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className={styles.matchColumn}>
                        <span>{el.name}</span>
                      </div>
                    </Col>
                    <Col span={7}>
                      <div className={styles.matchColumn}>
                        <span>{el.market.locationName}</span>
                      </div>
                    </Col>
                    <Col span={7}>
                      <div className={styles.matchColumn}>
                        <span>{el.market.competitionName}</span>
                      </div>
                    </Col>
                    <Col span={1}>
                      <div className={styles.matchColumn}>
                        <Button
                          size="small"
                          style={{ fontSize: '10px', padding: '4px' }}
                          onClick={() => toggleSelectedEvent(el)}
                        >
                          {t('all')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {sports.selectedAntepost && // setAntepostEvent
                    sports.selectedAntepost.intKey === el.intKey && (
                      <AntepostMarketsListing
                        key={el.intKey}
                        event={sports?.selectedAntepost}
                      />
                    )}
                </div>
              )
          )}
        </div>
      ) : (
        <div className={styles.noResults}>{t('noResults')}</div>
      )}
      <Pagination
        style={{ marginTop: 'auto' }}
        defaultCurrent={1}
        current={currentPage}
        pageSize={pageSize}
        onChange={handlePageChange}
        showSizeChanger={false}
        total={sports.filteredAntepostEvents.length}
      />
    </div>
  )
})
