import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, DatePicker, Input, message, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import styles from './greekoGame.module.css'
import { SectionContainer } from '../SectionContainer'
import { useStore } from '../../store/StoreContext'

export default observer(function GreekoGame() {
  const { t } = useTranslation()
  const { slipApi, user, slip } = useStore()
  const [ticket, setTicket] = useState<any[]>([])
  const [selectedPrevious, setSelectedPrevious] = useState<any>([])
  const [roundData, setRoundData] = useState<any>(null)
  const [counter, setCounter] = useState<any>(null)
  const [numOfPlays, setNumOfPlays] = useState(null)

  const payInputValue = useRef<Input>(null)
  const addInputValue = useRef<Input>(null)
  const addNumPlaysInputValue = useRef<Input>(null)

  const [selectedPlayRound, setSelectedPlayRound] = useState()
  const [selectedSystem, setSelectedSystem] = useState('')
  const [payValue, setPayValue] = useState(null)
  const [calculatedValue, setCalculatedValue] = useState<any>('0 RSD')

  const [currentDate, setCurrentDate] = useState<any>(moment())
  const [dateRounds, setDateRounds] = useState<any>(null)

  const getNextRoundData = () => {
    slipApi.getNextRoundData().then((data: any) => {
      if (data) {
        setRoundData(data)
        setCounter(0)
        setCounter(data.seconds)
      } else {
        message.error(t('apiError'))
      }
    })
  }

  const getRoundsForDate = (date: string) => {
    slipApi.getRoundsForDate(date).then((data: any) => {
      if (data) {
        setDateRounds(data)
      } else {
        message.error(t('apiError'))
      }
    })
  }

  const postTicket = () => {
    const postData = {
      amount: payValue,
      external_round_id: selectedPlayRound,
      system_length: selectedSystem?.split('/')[0],
      repeat: numOfPlays,
      numbers: ticket,
    }
    slipApi.postTicket(postData).then((data: any) => {
      if (data) {
        setTicket([])
        slipApi.printSlipGreeko(data)
        slipApi.getTicketHistory()
        slip.totalOfSlipsSubmittedGreeko += data[0].amount * data.length
        slip.numberOfSlipsSubmittedGreeko += data.length
      }
    })
  }

  useEffect(() => {
    const intervalId = setInterval(getNextRoundData, 10000)
    return () => {
      clearInterval(intervalId)
    }
  },[]) // eslint-disable-line

  useEffect(() => {
    if (slipApi.repeatTicket) {
      setTicket(slipApi.repeatTicket.numbers)
      addNumPlaysInputValue?.current?.setValue(
        slipApi.repeatTicket.number_of_combinations
      )
      payInputValue?.current?.setValue(
        // eslint-disable-next-line radix
        slipApi.repeatTicket.amount_per_combination
      )
      setSelectedSystem(
        `${slipApi.repeatTicket.combination_length}/${slipApi.repeatTicket.numbers.length}`
      )
      setNumOfPlays(slipApi.repeatTicket.number_of_combinations)
      setPayValue(slipApi.repeatTicket.amount_per_combination)
      // setSelectedSystem(slipApi.repeatTicket.amount_per_combination)
    }
  }, [slipApi.repeatTicket]) // eslint-disable-line

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer as any)
  }, [counter])

  useEffect(() => {
    // @ts-ignore
    if (numOfPlays >= 1 && payValue >= 1) {
      // @ts-ignore
      setCalculatedValue(`${numOfPlays * payValue} RSD`)
    } else {
      setCalculatedValue('0 RSD')
    }
  }, [numOfPlays, payValue])

  const addNumberToTicket = (e: any) => {
    if (e.key === 'Enter') {
      if (
        e.target.value >= 1 &&
        e.target.value <= 80 &&
        !(ticket.indexOf(e.target.value) > -1)
      ) {
        if (ticket.length >= 15) {
          message.error(t('valid_length_greeko'))
        } else {
          setTicket([...ticket, e.target.value])
          addInputValue?.current?.setValue('')
        }
      } else {
        message.error(t('valid_numbers_greeko'))
      }
    } else {
      const allowedChars = new RegExp('[-/*+0-9]+', 'g')
      if (e.key !== 'Backspace') {
        if (!allowedChars.test(e.key)) {
          e.preventDefault()
        }
      }
    }
  }

  const changeNumberOfPlays = (e: any) => {
    if (e.target.value >= 1 && e.target.value <= 10) {
      setNumOfPlays(e.target.value)
    } else {
      message.error(t('next_rounds'))
    }
  }

  const changeDate = (date: any) => {
    setCurrentDate(date)
    if (date !== null) {
      getRoundsForDate(date.format('YYYY-MM-DD'))
    }
  }

  const changePreviousRound = (roundId: any) => {
    const winningCombination = dateRounds.find(
      (obj: any) => obj.roundId === roundId
    )?.winningCombination
    setSelectedPrevious(winningCombination)
  }

  const changeValue = (e: any) => {
    if (e.target.value >= 20 && e.target.value <= 30000) {
      setPayValue(e.target.value)
    }
    if (e.target.value < 20) {
      // @ts-ignore
      setPayValue(0)
    }
  }

  const changeSelectedBetRound = (roundId: any) => {
    setSelectedPlayRound(roundId)
  }

  const changeSelectedSystem = (system: any) => {
    setSelectedSystem(system)
  }

  useEffect(() => {
    user.getUser()
    if (!user.isUserLoading) {
      getNextRoundData()
      getRoundsForDate(currentDate.format('YYYY-MM-DD'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isUserLoading])

  const optionsArray = [
    { name: "1'", value: ['1', '11', '21', '31', '41', '51', '61', '71'] },
    { name: "2'", value: ['2', '12', '22', '32', '42', '52', '62', '72'] },
    { name: "3'", value: ['3', '13', '23', '33', '43', '53', '63', '73'] },
    { name: "4'", value: ['4', '14', '24', '34', '44', '54', '64', '74'] },
    { name: "5'", value: ['5', '15', '25', '35', '45', '55', '65', '75'] },
    { name: "6'", value: ['6', '16', '26', '36', '46', '56', '66', '76'] },
    { name: "7'", value: ['7', '17', '27', '37', '47', '57', '67', '77'] },
    { name: "8'", value: ['8', '18', '28', '38', '48', '58', '68', '78'] },
    { name: "9'", value: ['9', '19', '29', '39', '49', '59', '69', '79'] },
    { name: "10'", value: ['10', '20', '30', '40', '50', '60', '70', '80'] },
    {
      name: "1-10'",
      value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    },
    {
      name: "11-20'",
      value: ['11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
    },
    {
      name: "21-30'",
      value: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
    },
    {
      name: "31-40'",
      value: ['31', '32', '33', '34', '35', '36', '37', '38', '39', '40'],
    },
    {
      name: "41-50'",
      value: ['41', '42', '43', '44', '45', '46', '47', '48', '49', '50'],
    },
    {
      name: "51-60'",
      value: ['51', '52', '53', '54', '55', '56', '57', '58', '59', '60'],
    },
    {
      name: "61-70'",
      value: ['61', '62', '63', '64', '65', '66', '67', '68', '69', '70'],
    },
    {
      name: "71-80'",
      value: ['71', '72', '73', '74', '75', '76', '77', '78', '79', '80'],
    },
  ]
  const { Option } = Select
  // @ts-ignore
  return (
    <div>
      <SectionContainer translation="viewPreviousGames">
        <Row>
          <Col span={5} className={styles.inputTitle}>
            {t('previousMatch')}
          </Col>
          <Col span={16} />
          <Col span={3} className={styles.inputTitle}>
            {t('currentMatch')}
          </Col>
        </Row>
        <Row>
          <Col span={5} style={{ marginTop: '5px' }}>
            <DatePicker
              style={{ width: '100%' }}
              value={currentDate}
              onChange={changeDate}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={16}>
            <Row style={{ marginTop: '10px' }}>
              <Col span={2} />
              {selectedPrevious?.map((obj: any, i: number) => {
                if (i < 10) {
                  return (
                    <Col span={2} className={styles.colStyle}>
                      {obj}
                    </Col>
                  )
                }
                return null
              })}
              <Col span={2} />
            </Row>
          </Col>
          <Col span={3} style={{ marginTop: '10px' }}>
            {roundData?.external_round_id}
          </Col>
        </Row>
        <Row>
          <Col span={5} style={{ marginTop: '10px' }}>
            <Select style={{ width: '100%' }} onChange={changePreviousRound}>
              {dateRounds?.map((obj: any) => (
                <Option
                  value={obj?.roundId}
                >{`${obj?.roundId} ${obj?.time}`}</Option>
              ))}
            </Select>
          </Col>
          <Col span={16} style={{ marginTop: '15px' }}>
            <Row>
              <Col span={2} />
              {selectedPrevious?.map((obj: any, i: number) => {
                if (i >= 10) {
                  return (
                    <Col span={2} className={styles.colStyle}>
                      {obj}
                    </Col>
                  )
                }
                return null
              })}
              <Col span={2} />
            </Row>
          </Col>
          <Col
            span={3}
            className={styles.inputTitle}
            style={{ marginTop: '10px' }}
          >
            {t('time')}
          </Col>
        </Row>
        <Row style={{ marginTop: '-5px' }}>
          <Col span={5} />
          <Col span={16} />
          <Col span={3}>
            {counter > 0
              ? moment
                  .utc(counter * 1000)
                  .local()
                  .format('mm:ss')
              : '0'}
          </Col>
        </Row>
      </SectionContainer>
      <SectionContainer translation="payment">
        <div style={{ height: '808px', overflow: 'hidden' }}>
          <Row>
            <Col span={4} className={styles.inputTitle}>
              {t('colNumber')}
            </Col>
            <Col
              span={3}
              className={styles.inputTitle}
              style={{ marginLeft: '10px' }}
            >
              {t('insertNumber')}
            </Col>
            <Col span={17} />
          </Row>
          <Row>
            <Col span={4}>
              <Select
                value={selectedPlayRound}
                style={{ width: '100%' }}
                onChange={changeSelectedBetRound}
              >
                {roundData?.rounds?.map((obj: any) => (
                  <Option value={obj?.external_round_id}>
                    {`${obj?.external_round_id} ${moment
                      .utc(obj?.draw_time)
                      .local()
                      .format('HH:mm')}`}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={3} style={{ marginLeft: '10px' }}>
              <Input
                name="number"
                type="number"
                ref={addInputValue}
                onKeyDown={addNumberToTicket}
              />
            </Col>
            <Col span={17} />
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={3} className={styles.inputTitle}>
              {t('insertedNumbers')}
            </Col>
            <Col span={21}>
              <Row>
                <Col span={1} />
                {ticket.map((value) => (
                  <Col className={styles.colStyle} span={1}>
                    {value}
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={17} />
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col span={4} className={styles.inputTitle}>
              {t('system')}
            </Col>
            <Col
              span={3}
              className={styles.inputTitle}
              style={{ marginLeft: '10px' }}
            >
              {t('next')}
            </Col>
            <Col span={17} />
          </Row>
          <Row>
            <Col span={4}>
              <Select
                value={selectedSystem}
                style={{ width: '100%' }}
                onChange={changeSelectedSystem}
              >
                {ticket.map((obj: any, index: number) => (
                  <Option value={`${index + 1}/${ticket.length}`}>{`${
                    index + 1
                  }/${ticket.length}`}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3} style={{ marginLeft: '10px' }}>
              <Input
                ref={addNumPlaysInputValue}
                name="number"
                type="number"
                onChange={changeNumberOfPlays}
              />
            </Col>
            <Col span={17} />
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col span={4} className={styles.inputTitle}>
              {t('amount')}
            </Col>
            <Col
              span={3}
              className={styles.inputTitle}
              style={{ marginLeft: '10px' }}
            >
              {t('total')}
            </Col>
            <Col span={17} />
          </Row>
          <Row align="middle">
            <Col span={4}>
              <Input
                ref={payInputValue}
                name="number"
                type="number"
                onChange={changeValue}
              />
            </Col>
            <Col span={3} style={{ marginLeft: '10px' }}>
              {calculatedValue}
            </Col>
            <Col span={17} />
          </Row>
          <Row style={{ marginTop: '50px' }} gutter={16} justify="center">
            <Col>
              <Button
                type="primary"
                disabled={
                  !selectedPlayRound ||
                  !selectedSystem ||
                  !numOfPlays ||
                  !payValue
                }
                onClick={() => {
                  postTicket()
                }}
                style={{ width: '75px' }}
              >
                {t('payIn')}
              </Button>
              <Button
                danger
                style={{ marginLeft: 30, width: '75px' }}
                onClick={() => {
                  setTicket([])
                  setPayValue(null)
                  setNumOfPlays(null)
                  setSelectedSystem('')
                  setCalculatedValue('0 RSD')
                  setSelectedPlayRound(undefined)

                  addNumPlaysInputValue?.current?.setValue('')
                  payInputValue?.current?.setValue('')
                  addInputValue?.current?.setValue('')
                }}
              >
                {t('reset')}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '90px' }}>
            <Col span={24}>
              <Row justify="space-between">
                <Col span={1} />
                {optionsArray.map((obj: any, i: number) => {
                  if (i <= 9) {
                    return (
                      <Col
                        span={2}
                        className={styles.combinations}
                        onClick={() => {
                          setTicket(obj.value)
                        }}
                      >
                        {obj.name}
                      </Col>
                    )
                  }
                  return null
                })}
                <Col span={1} />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col span={24}>
              <Row justify="space-between">
                <Col span={2} />
                {optionsArray.map((obj: any, i: number) => {
                  if (i <= 17 && i >= 10) {
                    return (
                      <Col
                        span={2}
                        className={styles.combinations}
                        onClick={() => {
                          setTicket(obj.value)
                        }}
                      >
                        {obj.name}
                      </Col>
                    )
                  }
                  return null
                })}
                <Col span={2} />
              </Row>
            </Col>
          </Row>
          <Row style={{ height: '200px' }} />
        </div>
      </SectionContainer>
    </div>
  )
})
