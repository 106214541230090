export enum ReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export const wsHeartBeat = function (
  ws: any,
  refreshConnection: any,
  feedStore: any
) {
  const pingMessage = '__PING__'
  const pingInterval = 1000
  const pingTimeout = 2000
  let messageAccepted = true

  const isClosed = () => {
    return (
      ws.readyState === ReadyState.CLOSING ||
      ws.readyState === ReadyState.CLOSED
    )
  }

  const pingTimer = setInterval(() => {
    if (isClosed()) {
      console.log('CLOSED WS')
      ws.send(pingMessage)
      reconect() //eslint-disable-line
      clearInterval(pingTimer)
      return
    }
    try {
      ws.send(pingMessage)
    } catch (_) {
      // do nothing
    }
  }, pingInterval)

  // TODO: check if required
  setInterval(() => {
    if (ws.readyState !== ReadyState.OPEN) return
    if (!messageAccepted) {
      console.log('RECONNECT WS')
    } else {
      messageAccepted = false
    }
  }, pingTimeout)

  const reconect = () => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    feedStore.updateStatus(true)
    console.log('Closed')
    // setTimeout(refreshConnection, 3000)
  }

  ws.addEventListener('open', () => {
    messageAccepted = true
  })

  ws.addEventListener('message', () => {
    messageAccepted = true
  })
}
