import { History } from 'history'
import BaseStore from './BaseStore'
import RootStore from './RootStore'

export default class RouterStore extends BaseStore {
  router: History

  constructor(rootStore: RootStore, history: History) {
    super(rootStore)
    this.router = history
  }
}
