export enum TicketStatus {
  MANUAL_CHANGED = '__MANUAL_APPROVAL_CHANGED__',
  APPROVING = '__APPROVING__',
  NOT_RESOLVED = '__NOT_RESOLVED__',
  DENIED = '__DENIED__',
  CANCELED = '__CANCELED__',
  CASHBACK = '__CASHBACK__',
  DOBITAN = '__PASSED__',
  GUBITAN = '__FAILED__',
  PAID = '__PAYED_OUT__',
  UNCONFIRMED = 'unconfirmed',
}

export enum SlipSocketType {
  APPROVAL = 'slip_for_approval',
  CHANGED = 'slip_changed',
  APPROVED = 'slip_approved',
  DENIED = 'slip_denied',
  ROLLBACK = 'slip_rollback',
}

export enum StatusBySocketType {
  slip_for_approval = TicketStatus.APPROVING,
  slip_changed = TicketStatus.MANUAL_CHANGED,
  slip_approved = TicketStatus.NOT_RESOLVED,
  slip_denied = TicketStatus.DENIED,
}

export type SlipSocketTypes =
  | SlipSocketType.APPROVAL
  | SlipSocketType.APPROVED
  | SlipSocketType.CHANGED
  | SlipSocketType.DENIED

export type TicketStatuses =
  | TicketStatus.MANUAL_CHANGED
  | TicketStatus.APPROVING
  | TicketStatus.NOT_RESOLVED
  | TicketStatus.CASHBACK
  | TicketStatus.DOBITAN
  | TicketStatus.GUBITAN
  | TicketStatus.PAID
  | TicketStatus.DENIED
  | TicketStatus.UNCONFIRMED
  | TicketStatus.CANCELED

export interface TicketData {
  id: number
  date: string
  time: string
  amount: number
  status: TicketStatuses
  account: string
  win: number
}

export interface TicketEvent {
  competitors: string
  odd_value: number
  outcome: string
  start_time: string
  code: number
  result: string
}

export interface TicketsStatus {
  win: TicketData[]
  loss: TicketData[]
  active: TicketData[]
  cashback: TicketData[]
}

export interface TicketsResponse {
  amount: number
  created_at: string
  id: string
}

export interface SlipOdd {
  automated_status: any
  manual_status: string | null
  market_name: string | null
  odd_id: number
  odd_value: number
  name?: string
  outcome_name: string | null
  outcome_print_code: string | null
  old_odd_value: number | null
  limit_value: number | null
  limit2_value: number | null
  old_limit_value: number | null
  old_limit2_value: number | null
}

export interface SlipEvent {
  automated_status: any
  competition_id: any
  event_code: any
  event_id: number
  event_name: string | null
  event_note: string | null
  event_type: string | null
  event_stage: string
  location_id: any
  manual_status: string | null
  sport_id: any
  odds: SlipOdd[]
  event_start_time: string
  sport_name: string
  player_name?: string
  competition_name: string
  competition_short_name: string | null
}

export interface SlipGroup {
  automated_status: any
  manual_status: any
  num_of_combinations: number | null
  system: string
  events: SlipEvent[]
}

export interface SingleTicket {
  id: string
  actual_odds_value: number | null
  actual_winning_amount: number | null
  amount: number
  approval_reason: string | null
  approved_by: any
  automated_status: any
  betting_place_id: string | null
  manual_status: string | null
  max_odds_value: number | null
  cash_back?: number
  max_winning_amount: number | null
  min_odds_value: number | null
  min_winning_amount: number | null
  num_of_combinations: number | null
  max_bonus_amount: number | null
  bonus_percentage?: number | null
  min_bonus_amount: number | null
  max_total_amount?: number | null
  min_total_amount?: number | null
  number_of_combinations: number | null
  number_of_matches: number
  short_uuid: any
  transaction_id: string
  user: number
  created_at: string
  is_system: boolean
  slip_groups: SlipGroup[]
  status: TicketStatuses
  old_amount: null | number
}

export interface SlipPayloadOdd {
  int_key: number
  odd_value: number
}

export interface SlipPayloadEvent {
  event_id: string
  odds: SlipPayloadOdd[]
}

export interface SlipPayloadGroup {
  system: string
  events: {
    event_id: string
    odds: SlipPayloadOdd[]
  }
}

export interface PlaceBetPayload {
  user: number | null
  amount: number
  approval_reason: string | null
  slip_groups: SlipPayloadGroup[]
}

export enum ApprovalStatuses {
  APPROVED = 'slip_approved',
  DENIED = 'slip_denied',
  CHANGED = 'slip_changed',
}

export interface SlipPrint {
  amount: number
  max_odds_value: number | null
  max_winning_amount: number | null
  min_odds_value: number | null
  min_winning_amount: number | null
  max_bonus_amount: number | null
  min_bonus_amount: number | null
  max_total_amount?: number | null
  bonus_percentage?: number | null
  min_total_amount?: number | null
  number_of_matches: number
  short_uuid: any
  created_at: string
  is_system: boolean
  location: string
  num_of_combinations: number | null
  address: string
  slip_groups: Array<{
    system: string
    num_of_combinations: number | null
    events: Array<{
      event_start_time: string
      event_code: string
      event_name: string | null
      sport_name: string
      event_type: string | null
      odds: Array<{
        odd_value: string
        market_name: string | null
        outcome_print_code: string | null
      }>
    }>
  }>
  status: TicketStatuses
}
