import React, { useContext, useEffect } from 'react'
import MainHeader from 'components/MainHeader'
import StoreContext from 'store/StoreContext'
import Routes from 'routes'
import ClientRoutes from 'routes/ClientRoutes'
import { Button, Modal, Spin } from 'antd'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import styles from '../../components/SlipPayIn/slipPayIn.module.css'

export default observer(function Home() {
  const { user, sports, feed } = useContext(StoreContext)
  const { t } = useTranslation()

  const closeErrorModal = () => {
    window.location.reload()
  }

  useEffect(() => {
    user.getUser()
    if (!sports.loaded) {
      sports.loadData()
    }
    feed.start()
    feed.checkListingAvailability()
  }, []) // eslint-disable-line

  return (
    <div style={{ maxHeight: '100vh' }}>
      <Spin tip={t('loading')} spinning={!sports.loaded} size="large">
        <MainHeader />
        <Routes routes={ClientRoutes} />
      </Spin>
      <Modal
        onCancel={closeErrorModal}
        visible={feed.isDisconnected}
        footer={false}
        closable
        width={600}
        title={
          <div className={styles.erroModalHeader}>
            {i18n.t('pleaseRefresh')}
          </div>
        }
      >
        <div className={styles.errorModalFooter}>
          <Button size="small" danger onClick={closeErrorModal}>
            {i18n.t('refresh')}
          </Button>
        </div>
      </Modal>
    </div>
  )
})
