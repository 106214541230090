import React from 'react'
import Home from 'pages/Home'
import Login from 'pages/Login'

export interface RouteType {
  path: string
  exact: boolean
  component: React.ReactNode
  auth: string[] | (() => void) | boolean
  translationKey?: string
  showInMenu?: boolean
}
const MainRoutes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: <Login />,
    auth: false,
  },

  {
    path: '/login',
    exact: true,
    component: <Login />,
    auth: false,
  },

  {
    path: '/sport-betting',
    exact: false,
    component: <Home />,
    auth: true,
  },
  {
    path: '/sb-client',
    exact: false,
    component: <Home />,
    auth: true,
  },
  {
    path: '/greeko',
    exact: false,
    component: <Home />,
    auth: true,
  },
  {
    path: '/cash-flow',
    exact: false,
    component: <Home />,
    auth: true,
  },
  {
    path: '/online',
    exact: false,
    component: <Home />,
    auth: true,
  },
]
export default MainRoutes
