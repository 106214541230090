/* eslint-disable no-multi-assign */
/* eslint-disable prefer-promise-reject-errors */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { AutoComplete, Col, Input, message, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import StoreContext from 'store/StoreContext'
import { handleKeyEvent, Keycodes } from 'utils/keyboardEvents'
import { Event } from 'utils/sports.types'
import { ShortcutEvents } from 'store/ShortcutsStore'
import { MAX_LINE_GROUPS_COUNT } from 'store/SlipStore'
import to from 'await-to-js'
import sortBy from 'lodash/sortBy'
import _ from 'lodash'
import i18n from '../../i18n'
import styles from '../SlipPayIn/slipPayIn.module.css'

interface EventOption {
  label: string
  value: number
}

interface OutcomeOption {
  label: string
  value: number
}

export interface MatchType {
  matchName: EventOption | null
  betSearch: OutcomeOption | null
  matchCode: string
  lock: boolean
  betCode: string
  lineGroup: number
  keepMatches: boolean
}

const initialValues: MatchType = {
  matchName: null,
  betSearch: null,
  matchCode: '',
  lock: false,
  betCode: '',
  lineGroup: 1,
  keepMatches: false,
}

function MatchSelection() {
  const { t } = useTranslation()
  const { sports, slip, shortcut } = useContext(StoreContext)
  // @ts-ignore
  const formik = useFormik({ initialValues })

  // State
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [searchedEvents, setSearchedEvents] = useState<any[]>([])
  const [searchedString, setSearchedString] = useState('')
  const [searchedOutcomes, setSearchedOutcomes] = useState<any[]>([])
  const [searchedBets, setSearchedBets] = useState<any[]>([])
  const [betCodeNotFound, setBetCodeNotFound] = useState('')
  // const [previousBetCode, setPreviousBetCode] = useState(null)
  const [previousTypeCode, setPreviousTypeCode] = useState(null)

  // Input refs
  const betSearchRef = useRef(null)
  const betCodeRef = useRef(null)
  const lineGroupRef = useRef(null)
  const matchCodeRef = useRef(null)

  const focusField = (ref: any) => {
    if (ref && ref.current) {
      ref.current.focus()
    }
  }

  // eslint-disable-next-line
  const toggleKeepMatches = () => {
    const keep = formik.values.keepMatches
    formik.setFieldValue('keepMatches', !keep)
    slip.setKeepMatches(!keep)
  }

  useEffect(() => {
    if (shortcut.event === ShortcutEvents.FOCUS_MATCH_CODE) {
      focusField(matchCodeRef)
      shortcut.clear()
    } else if (shortcut.event === ShortcutEvents.TOGGLE_KEEP_MATCHES) {
      toggleKeepMatches()
      shortcut.clear()
    }
  }, [shortcut, shortcut.event, toggleKeepMatches])

  const resetForm = (options?: { hardReset: boolean }) => {
    const { values, setFieldValue } = formik

    setFieldValue('betSearch', null)
    setFieldValue('betCode', '')
    setFieldValue('lineGroup', 1)
    setSearchedBets([])
    setSearchedOutcomes([])

    if (!values.lock || options?.hardReset) {
      setFieldValue('matchName', null)
      setFieldValue('matchCode', '')
      focusField(matchCodeRef)
      setSelectedEvent(null)
    }
  }

  const handleEventNameSearch = (name: string) => {
    const events = sports
      .filterEventsByName(name)
      .filter((e) => !e.isSuspended && !e.betStop)
    setSearchedEvents(events)
    setSearchedString(name)
  }

  const onEventChange = (event: Event) => {
    event.allOddsList.forEach((o: any) => {
      const outcome =
        (sports.outcomesCodeMap as any)[`s-${event.sportId}`][
          `id-${o.outcomeId}`
        ] ||
        (sports.playerOutcomesCodeMap as any)[`s-${event.sportId}`][
          `id-${o.outcomeId}`
        ]
      if (!outcome) return
      o.typeCode = outcome?.typeCode
      o.name = outcome?.name
    })
    setSelectedEvent(event)
  }

  const handleEventNameSelect = (_id: number, event: EventOption) => {
    const selected = searchedEvents.find((e) => e.intKey === event.value)
    formik.setFieldValue('matchName', event)
    formik.setFieldValue('matchCode', selected?.landbaseCode)

    onEventChange(selected)
    // wait for betSearch to become enabled
    setTimeout(() => focusField(betSearchRef))
  }

  const handleBetSearch = (outcome: string) => {
    if (!selectedEvent) return

    const outcomes =
      selectedEvent.event_type === 'P'
        ? sports.filterPlayerOutcomes(outcome, selectedEvent?.allOddsList)
        : sports.filterOutcomes(outcome, selectedEvent?.allOddsList)
    setSearchedOutcomes(outcomes)
  }

  const handleBetSelect = async (odd: any) => {
    if (!odd) {
      return
    }
    if (selectedEvent?.event_type === 'D' && slip.checkIfAntepostIsInSlip()) {
      message.error({
        duration: 2,
        content: i18n.t('no_combine_dual'),
        className: styles.errorMsg,
      })
      return
    }
    slip.checkIfAntepostIsInSlip()
    if (
      odd.isSuspended ||
      odd.oddValue.toString() === '1' ||
      odd.oddValue.toString() === '1.00' ||
      odd.oddValue.toString() === '100'
    ) {
      message.error(t('selectedBetSuspended'))
      return
    }
    if (slip.hasEvent(selectedEvent)) {
      if (formik.values.lock) {
        const [err] = await to(slip.extendEvent(selectedEvent, odd))
        if (err) {
          message.error(t(err.message))
        }
      } else {
        slip.updateEvent(selectedEvent, odd)
      }
    } else {
      const group = Number(formik.values.lineGroup) - 1
      slip.addEvent(selectedEvent, odd, group)
    }
  }

  const handleBetNameSelect = (_id: string, outcome: OutcomeOption) => {
    const selected = searchedOutcomes.find((o) => o.id === outcome.value)
    const odd = selectedEvent?.allOddsList.find(
      (o: any) => o.outcomeId === outcome.value
    )
    if (
      odd.isSuspended ||
      odd.oddValue.toString() === '1' ||
      odd.oddValue.toString() === '1.00' ||
      odd.oddValue.toString() === '100'
    ) {
      message.error(t('selectedBetSuspended'))
      return
    }
    formik.setFieldValue('betSearch', outcome)
    formik.setFieldValue('betCode', selected.typeCode)

    if (selectedEvent && odd && outcome) {
      handleBetSelect({ ...odd, printCode: selected.printCode })
      resetForm()
      const filteredSports = sports
        .filterEventsByName('')
        .filter((e) => !e.isSuspended && !e.betStop)
      setSearchedEvents(filteredSports)
    }
  }

  // since search by matchCode finds antepost bets, we immediatly add it to slip
  const addAntepostBetToSlip = (eventCode: string, antepostBet: any) => {
    const antepostCode = Number(eventCode.split('/')[0])
    const antepostEvent = sports.filterAntepostEventsByCode(antepostCode)
    if (antepostBet?.isSuspended || antepostEvent?.isSuspended) return false
    slip.setSelectedOdd(antepostBet.intKey)
    slip.toggleAntepostEvent({
      oIntKey: antepostBet.intKey,
      ...antepostBet,
      ...antepostEvent,
    })
    resetForm()
    return true
  }

  /**
   * @name populateMatchName
   * @description search events by their code, antepost events are searched : antepost_code/antepost_bet_code
   * while player events are searched: dual_event_code/player_event_code
   *
   */
  const populateMatchName = () => {
    const { setFieldValue, values } = formik
    let event: any
    let antepostBet: any
    if (`${values?.matchCode}`.includes('/')) {
      event = sports.playerEventsTypingCodesMap[values.matchCode]

      if (!event) {
        antepostBet = sports.antepostBetsTypingCodesMap[values.matchCode]
        if (antepostBet) {
          return addAntepostBetToSlip(values.matchCode, antepostBet)
            ? Promise.resolve()
            : Promise.reject('selectedBetSuspended')
        }
      }
    } else {
      event = sports.filterDualEventsByCode(Number(values.matchCode))
    }
    if (!event) return Promise.reject('eventNotFound')
    if (event.isSuspended || event.betStop) {
      return Promise.reject('selectedEventSuspended')
    }
    setFieldValue('matchName', {
      label:
        event.event_type === 'P'
          ? `${event.playerName} | ${event.name}`
          : event.name,
      value: event.intKey,
    })
    focusField(betSearchRef)
    onEventChange(event)
    return Promise.resolve()
  }

  const onMatchCodeSelect = async () => {
    const [err] = await to(populateMatchName())
    if (err) {
      message.error(t(err as any))
      return
    }
    focusField(betCodeRef)
  }

  const matchCodeKeyEvents = () => {
    return (e: any) => {
      handleKeyEvent(
        e,
        {
          [Keycodes.SLASH]: () => {
            slip.resetCounts()
            slip.resetReport()
          },
          [Keycodes.ENTER]: onMatchCodeSelect,
          [Keycodes.END]: toggleKeepMatches,
          [Keycodes.F11]: toggleKeepMatches,
          [Keycodes.SPACE]: () => {
            shortcut.setEvent(ShortcutEvents.FOCUS_SYSTEM)
          },
          [Keycodes.PAGE_DOWN]: () => {
            shortcut.setEvent(ShortcutEvents.FOCUS_PAYIN_AMOUNT)
          },
          [Keycodes.DEL]: () => {
            slip.clearAllSlips()
            slip.clearOdds()
          },
          [Keycodes.PAGE_UP]: () => {
            // onMatchCodeSelect()
            formik.setFieldValue('lock', !formik.values.lock)
            slip.setLockEvent(true)
          },
        },
        true
      )
    }
  }

  const betCodeKeyEvents = () => {
    const { setFieldValue } = formik

    return (e: any) => {
      handleKeyEvent(
        e,
        {
          [Keycodes.END]: toggleKeepMatches,
          [Keycodes.PAGE_UP]: () => {
            setFieldValue('lock', false)
            slip.setLockEvent(false)
            shortcut.setEvent(ShortcutEvents.FOCUS_MATCH_CODE)
            resetForm({ hardReset: true })
          },
          [Keycodes.DEL]: () => {
            slip.clearAllSlips()
            slip.clearOdds()
          },
          [Keycodes.SPACE]: () => {
            shortcut.setEvent(ShortcutEvents.FOCUS_SYSTEM)
          },
          [Keycodes.F1]: () => formik.setFieldValue('lineGroup', 1),
          [Keycodes.F2]: () => formik.setFieldValue('lineGroup', 2),
          [Keycodes.F3]: () => formik.setFieldValue('lineGroup', 3),
          [Keycodes.F11]: toggleKeepMatches,
        },
        true
      )
    }
  }

  const onLineGroupChange = (e: any) => {
    if (Number(e.target.value) <= MAX_LINE_GROUPS_COUNT) {
      formik.handleChange(e)
    }
  }

  const getOutcomeOptions = () => {
    return searchedOutcomes.map((outcome) => ({
      value: outcome.id,
      label: `${outcome.printCode} (${outcome.typeCode})`,
    }))
  }

  const getOddsOptions = () => {
    return searchedBets.map((bet: any) => ({
      value: bet.intKey,
      label: `${bet.printCode} ${bet.limit ? `- ${bet.limit}` : ''}`,
    }))
  }

  const selectExactBetCode = (e: any) => {
    const code = e.target.value
    if (code === '') setBetCodeNotFound('')
    const outcome =
      selectedEvent?.event_type === 'P'
        ? sports.filterPlayerOutcomesByCode(code)
        : sports.filterOutcomesByCode(code)

    const odds = _.filter(selectedEvent?.allOddsList, (o: any) => {
      const isFound =
        outcome &&
        (outcome as any)?.map((ot: any) => ot?.id).includes(o.outcomeId)
      return isFound
    })

    if (!outcome) {
      setBetCodeNotFound('')
      setSearchedBets([])
      return
    }

    if (!odds || !odds.length) {
      setBetCodeNotFound(`Na eventu ne postoji kvota za igru ${code}`)
      setSearchedBets([])

      return
    }
    const searchedBets = odds.map((o: any) => {
      const oddOutcome = outcome.find((outcome) => outcome.id === o.outcomeId)

      return { ...o, printCode: oddOutcome?.printCode }
    })
    const sortedSearcedBets = sortBy(searchedBets, ['outcomeId'])

    setSearchedBets(sortedSearcedBets)
    if (e.target.value === '') {
      return
    }
    let odd = sortedSearcedBets.find(
      (o: any) =>
        o.typeCode === e.target.value || o.typeCode === previousTypeCode
    )

    if (!odd) {
      odd = selectedEvent.allOddsList.find(
        (o: any) => o.typeCode === previousTypeCode
      )
    }

    if (!odd) {
      return
    }

    /* if (!odd) {
      const oddForSelectedEvent = selectedEvent.allOddsList.find(
        (o: any) => o.typeCode === previousTypeCode
      )
      if (oddForSelectedEvent) {
        // oddForSelectedEvent.printCode = previousBetCode
        handleBetSelect(oddForSelectedEvent)
        resetForm()
      }
    } */

    if (odd) {
      // setPreviousBetCode(odd?.printCode)
      setPreviousTypeCode(e.target.value)
      handleBetSelect(odd)
      resetForm()
    }
  }

  const onBetCodeChange = (e: any) => {
    if (e.key === 'Enter') {
      selectExactBetCode(e)
    } else {
      const allowedChars = new RegExp('[-/*+0-9]+', 'g')

      if (!allowedChars.test(e.key)) {
        e.preventDefault()
      }
    }
  }

  const onBetCodeFocus = (e: any) => {
    e.target.select()
  }

  const formatTwoDigits = (n: any) => {
    return n < 10 ? `0${n}` : n
  }

  const getTimeForEvent = (event: any) => {
    const startDate = new Date(event.start)
    return `${startDate.getHours()}:${formatTwoDigits(startDate.getMinutes())}`
  }

  const getEventsOptions = () => {
    return searchedEvents.map((event) => ({
      label: event.playerName
        ? `${`${getTimeForEvent(event)} ${
            event.playerName
          } `} | ${`${event.name}`}`
        : `${getTimeForEvent(event)} ${event.name}`,
      value: event.intKey,
    }))
  }

  useEffect(() => {
    const filteredSports = sports
      .filterEventsByName(searchedString)
      .filter((e) => !e.isSuspended && !e.betStop)
    setSearchedEvents(filteredSports)
    // eslint-disable-next-line
  }, [sports.allEvents])
  return (
    <Row gutter={[0, 16]}>
      <Row gutter={8} style={{ width: '100%' }}>
        <Col span={14}>
          <label htmlFor="matchName">{t('matchName')}</label>
          <Row>
            <AutoComplete
              style={{ width: '100%' }}
              id="matchName"
              name="matchName"
              defaultActiveFirstOption
              value={
                formik.values.matchName ? formik.values.matchName.label : ''
              }
              dropdownMatchSelectWidth={false}
              options={getEventsOptions()}
              onSearch={handleEventNameSearch}
              // @ts-ignore
              onSelect={handleEventNameSelect}
              disabled={formik.values.lock && selectedEvent}
            >
              <Input
                name="matchName"
                onChange={formik.handleChange}
                autoComplete="off"
              />
            </AutoComplete>
          </Row>
        </Col>

        <Col span={10} style={{ width: '100%' }}>
          <label htmlFor="betSearch">{t('betSearch')}</label>

          <AutoComplete
            style={{ width: '100%' }}
            id="betSearch"
            defaultActiveFirstOption
            value={
              formik.values.betSearch ? formik.values.betSearch?.label : ''
            }
            open={formik.values.betSearch ? true : false}
            options={getOutcomeOptions()}
            onSearch={handleBetSearch}
            disabled={!selectedEvent}
            onSelect={handleBetNameSelect as any}
          >
            <Input
              name="betSearch"
              autoComplete="off"
              onChange={formik.handleChange}
              ref={betSearchRef}
            />
          </AutoComplete>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={5}>
          <label htmlFor="matchCode">{t('matchCode')}</label>
          <Input
            ref={matchCodeRef}
            id="matchCode"
            name="matchCode"
            autoComplete="off"
            disabled={formik.values.lock && selectedEvent}
            onKeyDown={matchCodeKeyEvents()}
            value={formik.values.matchCode}
            onChange={(e: React.ChangeEvent<any>) => {
              formik.setFieldValue(
                'matchCode',
                e.target.value.replace(/[^0-9/]/g, '')
              )
              formik.setFieldValue('betCode', previousTypeCode)
            }}
          />
        </Col>

        <Col span={3}>
          <label htmlFor="lock">{t('lock')}</label>

          <Checkbox
            id="lock"
            checked={formik.values.lock}
            onChange={(e: CheckboxChangeEvent) => {
              slip.setLockEvent(e.target.checked)
              formik.setFieldValue('lock', e.target.checked)
            }}
          />
        </Col>

        <Col span={5}>
          <label htmlFor="betCode">{t('betCode')}</label>

          <AutoComplete
            style={{ width: '100%' }}
            id="betCode"
            dropdownStyle={{ visibility: 'hidden' }}
            defaultActiveFirstOption
            value={formik.values.betCode}
            options={getOddsOptions()}
            // @ts-ignore
            onFocus={onBetCodeFocus}
            // @ts-ignore
            notFoundContent={betCodeNotFound}
            disabled={!selectedEvent}
          >
            <Input
              id="betCode"
              onKeyDown={betCodeKeyEvents()}
              onKeyPress={onBetCodeChange}
              onPressEnter={onBetCodeChange}
              name="betCode"
              autoComplete="off"
              onChange={formik.handleChange}
              ref={betCodeRef}
            />
          </AutoComplete>
        </Col>

        <Col span={4}>
          <label htmlFor="lineGroup">{t('lineGroup')}</label>

          <Input
            id="lineGroup"
            name="lineGroup"
            ref={lineGroupRef}
            type="number"
            max={3}
            min={1}
            value={formik.values.lineGroup}
            onChange={onLineGroupChange}
          />
        </Col>

        <Col span={6}>
          <label htmlFor="keepMatches">{t('keepMatches')}</label>

          <Row>
            <Checkbox
              id="keepMatches"
              checked={formik.values.keepMatches}
              onChange={toggleKeepMatches}
            />
          </Row>
        </Col>
      </Row>
    </Row>
  )
}

export default observer(MatchSelection)
