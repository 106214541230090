import { action, makeObservable, observable, runInAction } from 'mobx'
import to from 'await-to-js'
import moment from 'moment'
import _ from 'lodash'
import BaseStore from './BaseStore'
import RootStore from './RootStore'

import FeedApi, { FeedMessageType } from '../utils/feedApi'
import FeedApiBroadcast from '../utils/feedApiBroadcast'

import { Event } from '../utils/sports.types'
import { NetworkModels } from './models/NetworkModels'
// fakecommit
const STORE_CONFIG = {
  deep: false,
}

interface CheckListingAvailabilityType {
  live_is_off: boolean
  prematch_is_off: boolean
}

export default class FeedStore extends BaseStore {
  api: FeedApi
  apiBroadcast: FeedApiBroadcast

  /** Store all odds received from websocket */
  feedOdds = new Map()

  /** Stores only displayed odds */
  checkListingAvailabilityCall: NetworkModels<CheckListingAvailabilityType>
  isLiveOff = false
  isDisconnected: boolean = false
  isPrematchOff = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    const messageHandlers = {
      [FeedMessageType.ODDS]: this.handleOddsUpdate,
      [FeedMessageType.LIVE_ODDS]: this.handleLiveOddsUpdate,
      [FeedMessageType.SUSPEND_PREMATCH]: this.handleSuspendPrematch,
      [FeedMessageType.ANTEPOST_SUSPENDED]: this.handleSuspendAntepost,
      [FeedMessageType.SUSPEND_PLAYER]: this.handleSuspendPlayer,
      [FeedMessageType.UNSUSPEND_PREMATCH]: this.handleUnsuspendPrematch,
      [FeedMessageType.ANTEPOST_UNSUSPENDED]: this.handleUnsuspendAntepost,
      [FeedMessageType.UNSUSPEND_PLAYER]: this.handleUnsuspendPlayer,
      [FeedMessageType.SUSPEND_LIVE]: this.handleSuspendLive,
      [FeedMessageType.UNSUSPEND_LIVE]: this.handleUnsuspendLive,
      [FeedMessageType.EVENT_HIDDEN]: this.handleHide,
      [FeedMessageType.EVENT_PLAYER_HIDDEN]: this.handleEventPlayerHidden,
      [FeedMessageType.ANTEPOST_EVENT_HIDDEN]: this.handleAntepostEventHidden,
      [FeedMessageType.PREMATCH_REMOVED]: this.handlePrematchRemoved,
      [FeedMessageType.ANTEPOST_REMOVED]: this.handleAntepostRemoved,
      [FeedMessageType.ANTEPOST_BET_DELETE]: this.handleAntepostBetDelete,
      [FeedMessageType.EVENT_PUBLISHED]: this.handleEventPublished,
      [FeedMessageType.EVENT_START_DATE_UPDATED]: this.handleEventPublished,
      [FeedMessageType.MARKET_ODDS_CHANGE]: this.handleMarketOddsPublished,
      [FeedMessageType.ODDS_PLATFORM]: this.handleMarketOddsPublished,
      [FeedMessageType.ANTEPOST_PUBLISHED]: this.handleAntepostPublishAndUpdate,
      [FeedMessageType.ANTEPOST_UPDATE]: this.handleAntepostPublishAndUpdate,
      [FeedMessageType.LIVE_OFF]: this.handleLiveOff,
      [FeedMessageType.LIVE_ON]: this.handleLiveOn,
      [FeedMessageType.BET_START_LIVE]: this.handleBetUnsuspendLive,
      [FeedMessageType.BET_STOP_LIVE]: this.handleBetSuspendLive,
      [FeedMessageType.PREMATCH_OFF]: this.handlePrematchOff,
      [FeedMessageType.PREMATCH_ON]: this.handlePrematchOn,
      [FeedMessageType.RESULT]: this.handleResult,
      [FeedMessageType.EVENT_END]: this.handleEventEnd,
      [FeedMessageType.PROD_ODD]: this.handleProdOddUpdate,
      [FeedMessageType.MARKET_EVENT_SUSPENDED]: this.handleMarketSuspend,
      [FeedMessageType.MARKET_EVENT_UNSUSPENDED]: this.handleMarketUnsuspend,
      [FeedMessageType.PLAYER_MARKET_SUSPENDED]: this.handlePlayerMarketSuspend,
      [FeedMessageType.PLAYER_PUBLISHED]: this.handlePlayersPublished,
      [FeedMessageType.PLAYER_REMOVED]: this.handlePlayerRemoved,
      [FeedMessageType.NOTE_UPDATE]: this.handleNoteUpdate,
      [FeedMessageType.PLAYER_TIME_UPDATED]: this.handleTimeUpdate,
      [FeedMessageType.SERVER_TIME]: this.handleServerTime,
      [FeedMessageType.PLAYER_MARKET_UNSUSPENDED]:
        this.handlePlayerMarketUnsuspend,
      [FeedMessageType.ANTEPOST_MARKET_SUSPENDED]:
        this.handleAntepostMarketSuspend,
      [FeedMessageType.ANTEPOST_MARKET_UNSUSPENDED]:
        this.handleAntepostMarketUnSuspend,
      [FeedMessageType.ODD_SUSPENDED]: this.handleOddSuspend,
      [FeedMessageType.ODD_UNSUSPENDED]: this.handleOddUnsuspend,
      [FeedMessageType.PLAYER_ODD_SUSPENDED]: this.handlePlayerOddSuspend,
      [FeedMessageType.PLAYER_ODD_UNSUSPENDED]: this.handlePlayerOddUnsuspend,
      [FeedMessageType.PLAYER_ODD_UPDATE]: this.handlePlayerOddUpdate,
      [FeedMessageType.ANTEPOST_ODD_SUSPENDED]: this.handleAntepostOddSuspend,
      [FeedMessageType.ANTEPOST_ODD_HIDDEN]: this.handleAntepostOddHiden,
      [FeedMessageType.ANTEPOST_ODD_PUBLISHED]: this.handleAntepostOddPublish,
      [FeedMessageType.ANTEPOST_ODD_UPDATE]: this.handleAntepostOddUpdate,
      [FeedMessageType.ANTEPOST_ODD_UNSUSPENDED]:
        this.handleAntepostOddUnsuspend,
    }

    this.checkListingAvailabilityCall = new NetworkModels(rootStore, {
      path: 'odds/platform/check-platform-off/',
      secured: true,
    })

    const storeProperties = {
      feedOdds: observable,
      isLiveOff: observable,
      isDisconnected: observable,
      isPrematchOff: observable,
      updateEvents: action,
      updateStatus: action,
      handleEventHidden: action,
      handleAntepostEventHidden: action,
      checkListingAvailability: action,
    }
    this.api = new FeedApi(messageHandlers, this)
    this.apiBroadcast = new FeedApiBroadcast(messageHandlers, this)
    makeObservable(this, storeProperties, STORE_CONFIG)
  }

  start = () => {
    this.api.connect()
    this.apiBroadcast.connect()
  }

  getFromFeed = (eventIk: number, outcomeId: number): number | null => {
    const event = this.feedOdds.get(eventIk)
    if (!event) return null
    return event.get(outcomeId) || null
  }

  updatePrematch = (event: number, odds: any) => {
    runInAction(() => {
      this.updateEvents(event, this.rootStore.sports.upcomingEventsMap, odds)
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }

  updateStatus = (status: boolean) => {
    this.isDisconnected = status
  }

  updateLive = (event: number, odds: any) => {
    runInAction(() => {
      this.updateEvents(event, this.rootStore.sports.liveEventsMap, odds)
    })
  }

  updateEvents = (intKey: number, events: any, odds: any) => {
    events.get(intKey).allOddsList = odds.map(
      (odd: {
        i_k: number
        o_v: string
        o_i: string
        i_s: boolean
        lim: string
        lim2: string
      }) => {
        return {
          id: '',
          intKey: odd.i_k,
          oddValue: parseFloat(odd.o_v),
          outcomeId: odd.o_i,
          isSuspended: odd.i_s,
          limit: parseFloat(odd.lim),
          limit2: parseFloat(odd.lim2),
          ...this.handleOddsColor(events.get(intKey).allOddsList, odd),
        }
      }
    )
  }

  handleOddsColor = (
    oddsList: any,
    odd: { i_k: number; o_v: string; o_i: string }
  ) => {
    const color: { isUp: boolean | undefined; isDown: boolean | undefined } = {
      isUp: false,
      isDown: false,
    }
    const changedOdd = oddsList.find((o: any) => o.intKey === odd.i_k)
    if (changedOdd) {
      const isEqual = changedOdd.oddValue === parseFloat(odd.o_v)
      color.isDown =
        changedOdd.oddValue > parseFloat(odd.o_v) ||
        (isEqual && changedOdd.isDown)
      color.isUp =
        changedOdd.oddValue < parseFloat(odd.o_v) ||
        (isEqual && changedOdd.isUp)
    }

    return color
  }

  handleOddsColorPlayer = (
    changedOdd: any,
    odd: { i_k: number; v: string; o_i: string }
  ) => {
    const color: { isUp: boolean | undefined; isDown: boolean | undefined } = {
      isUp: false,
      isDown: false,
    }
    if (changedOdd) {
      const isEqual = changedOdd.oddValue === odd.v
      color.isDown =
        changedOdd.oddValue > odd.v || (isEqual && changedOdd.isDown)
      color.isUp = changedOdd.oddValue < odd.v || (isEqual && changedOdd.isUp)
    }

    return color
  }

  handleOddsUpdate = (data: any) => {
    const { e_i_k: eIk, odds } = data
    this.updatePrematch(eIk, odds)
    this.rootStore.slip.updateOdds(eIk, odds)
  }

  handlePlayerOddUpdate = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, odds } = data
      if (this.rootStore.sports.playerEventsMap.get(eIk)) {
        odds.forEach((odd: any) => {
          const oddIndex = this.rootStore.sports.playerEventsMap
            .get(eIk)
            ?.allOddsList.findIndex((o: any) => o.intKey === odd.i_k)

          if (
            this.rootStore.sports.playerEventsMap.has(eIk) &&
            oddIndex !== undefined &&
            oddIndex >= 0
          ) {
            // @ts-ignore
            const event = this.rootStore.sports.playerEventsMap.get(eIk)
            // @ts-ignore
            event.allOddsList[oddIndex] = {
              intKey: odd.i_k,
              oddValue: odd.v,
              outcomeId: odd.o_i,
              isSuspended: odd.i_s,
              limit: parseFloat(odd.lim), // @ts-ignore
              ...this.handleOddsColorPlayer(event.allOddsList[oddIndex], odd),
            }
          } else {
            this.rootStore.sports.playerEventsMap.get(eIk)?.allOddsList.push({
              intKey: odd.i_k,
              oddValue: odd.v,
              outcomeId: odd.o_i,
              isSuspended: odd.i_s,
              limit: parseFloat(odd.lim),
            })
          }
        })
        this.rootStore.slip.updateOdds(eIk, odds)
        this.rootStore.sports.setPlayerFilters(
          this.rootStore.sports.filtersPremach
        )
      }
    })
  }

  handleLiveOddsUpdate = (data: any) => {
    const { e_i_k: eIk, odds } = data
    this.updateLive(eIk, odds)
    this.rootStore.slip.updateOdds(eIk, odds)
  }

  findEventByIntKey = (intKey: number, events: Event[]) => {
    return events.find((event) => event.intKey === intKey)
  }

  removeEventFromStore = (eventsStoreArray: any, eventIntKey: number) => {
    if (!eventsStoreArray.find((e: any) => e.intKey === eventIntKey)) return
    eventsStoreArray.splice(
      eventsStoreArray.findIndex((e: any) => e.intKey === eventIntKey),
      1
    )
  }

  handleSuspendChange = (
    eventsMap: any,
    intKey: number,
    nextSuspendState: boolean
  ) => {
    const event = eventsMap.get(intKey)
    if (!event) return
    event.isSuspended = nextSuspendState
  }

  handleBetStopChange = (
    eventsMap: any,
    eventIntKey: number,
    nextStopState: boolean
  ) => {
    const event = eventsMap.get(eventIntKey)
    if (!event) return
    event.betStop = nextStopState
  }

  handleBetStopChangeMap = (
    eventsMap: any,
    intKey: number,
    nextStopState: boolean
  ) => {
    const event = eventsMap.get(intKey)
    if (!event) return
    event.betStop = nextStopState
  }

  handleSuspendPrematch = (data: any) => {
    runInAction(() => {
      data?.e_i_k.forEach((event: number) => {
        this.handleSuspendChange(
          this.rootStore.sports.upcomingEventsMap,
          event,
          true
        )
      })
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      this.rootStore.slip.handleSuspendChangeEvent(data, true)
    })
  }

  handleSuspendPlayer = (data: any) => {
    runInAction(() => {
      data?.forEach((event: any) => {
        this.handleSuspendChange(
          this.rootStore.sports.playerEventsMap,
          event?.e_i_k,
          true
        )
      })
      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
      this.rootStore.slip.handleSuspendChangeEvent(data, true)
    })
  }

  handleUnsuspendPrematch = (data: any) => {
    runInAction(() => {
      data?.e_i_k.forEach((event: number) => {
        this.handleSuspendChange(
          this.rootStore.sports.upcomingEventsMap,
          event,
          false
        )
      })
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      this.rootStore.slip.handleSuspendChangeEvent(data, false)
    })
  }

  handleUnsuspendPlayer = (data: any) => {
    runInAction(() => {
      data?.forEach((event: any) => {
        this.handleSuspendChange(
          this.rootStore.sports.playerEventsMap,
          event?.e_i_k,
          false
        )
      })
      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
      this.rootStore.slip.handleSuspendChangeEvent(data, false)
    })
  }

  handleSuspendAntepost = (data: any) => {
    runInAction(() => {
      data.forEach((event: any) => {
        this.handleSuspendChange(
          this.rootStore.sports.antepostEventsAndMarketsMap,
          event.a_i_k,
          true
        )
      })
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
      this.rootStore.slip.handleAntepostSuspendChange(data, true)
    })
  }

  handleUnsuspendAntepost = (data: any) => {
    runInAction(() => {
      data.forEach((event: any) => {
        this.handleSuspendChange(
          this.rootStore.sports.antepostEventsAndMarketsMap,
          event.a_i_k,
          false
        )
      })
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
      this.rootStore.slip.handleAntepostSuspendChange(data, false)
    })
  }

  handleSuspendLive = (data: any) => {
    runInAction(() => {
      data.e_i_k.forEach((e_i_k: any) => {
        this.handleSuspendChange(
          this.rootStore.sports.liveEventsMap,
          e_i_k,
          true
        )
      })
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      this.rootStore.slip.handleSuspendChangeEvent(data, true)
    })
  }

  handleUnsuspendLive = (data: any) => {
    runInAction(() => {
      data.e_i_k.forEach((e_i_k: any) => {
        this.handleSuspendChange(
          this.rootStore.sports.liveEventsMap,
          e_i_k,
          false
        )
      })
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      this.rootStore.slip.handleSuspendChangeEvent(data, false)
    })
  }

  handleBetSuspendLive = (data: any) => {
    runInAction(() => {
      data?.forEach((e_i_k: any) => {
        this.handleBetStopChange(
          this.rootStore.sports.liveEventsMap,
          e_i_k,
          true
        )
        this.handleBetStopChangeMap(
          this.rootStore.sports.upcomingEventsMap,
          e_i_k,
          true
        )
      })
      this.rootStore.slip.handleStopChange(data, true)
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }

  handleBetUnsuspendLive = (data: any) => {
    runInAction(() => {
      data?.forEach((e_i_k: any) => {
        this.handleBetStopChange(
          this.rootStore.sports.liveEventsMap,
          e_i_k,
          false
        )
        this.handleBetStopChangeMap(
          this.rootStore.sports.upcomingEventsMap,
          e_i_k,
          false
        )
      })
      this.rootStore.slip.handleStopChange(data, false)
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }

  handleHide = (data: any) => {
    console.log('handleHide', data)
    runInAction(() => {
      // this.rootStore.slip.removeEventFromSlip(data.e_i_k)
      this.handleEventHidden(
        data.e_i_k,
        this.rootStore.sports.upcomingEventsMap
      )
      this.handleEventHidden(data.e_i_k, this.rootStore.sports.liveEventsMap)
    })
  }

  handleAntepostEventHidden = (data: any) => {
    runInAction(() => {
      this.rootStore.sports.antepostEventsAndMarketsMap.delete(data.a_i_k)
      /* const group = this.rootStore.slip.findGroupWithEvent(data.a_i_k)
      if (group) {
        this.rootStore.slip.removeEvent(group, data.a_i_k)
      } */
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    })
  }

  handleEventHidden = (eventIk: number, events: any) => {
    const eventToHide = events.get(eventIk)
    if (eventToHide) {
      events.delete(eventIk)
      if (
        eventToHide.event_type === 'L' &&
        this.rootStore.sports?.selectedLiveMatch?.intKey === eventIk
      ) {
        this.rootStore.sports.selectedLiveMatch = null
      }
    }
    this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
  }

  handleEventPlayerHidden = ({ e_i_k }: { e_i_k: number }) => {
    this.rootStore.sports.playerEventsMap.delete(e_i_k)
    // this.rootStore.slip.removePlayerEventFromSlip(e_i_k)
    this.rootStore.sports.setPlayerFilters(this.rootStore.sports.filtersPlayers)
  }

  handlePrematchRemoved = (events: number[]) => {
    runInAction(() => {
      events.forEach((eventIk) => {
        this.rootStore.sports.upcomingEventsMap.delete(eventIk)
      })
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }

  handleAntepostRemoved = ({ a_i_k }: { a_i_k: number }) => {
    runInAction(() => {
      this.rootStore.sports.antepostEventsAndMarketsMap.delete(a_i_k)
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    })
  }

  handleAntepostBetDelete = (data: any) => {
    runInAction(() => {
      const { a_i_k: eIk, a_b_i_k: intKey } = data
      const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(eIk)
      if (event) {
        event.antepostBetsList.splice(
          event.antepostBetsList.findIndex((odd: any) => odd.intKey === intKey),
          1
        )
        this.rootStore.sports.selectedAntepost = event
        this.rootStore.slip.removeEventFromSlip(event.intKey)
      }
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    })
  }

  handlePlayerRemoved = (events: number[]) => {
    runInAction(() => {
      events.forEach((eventIk) =>
        this.rootStore.sports.playerEventsMap.delete(eventIk)
      )
      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
    })
  }

  handleNoteUpdate = (data: any) => {
    if (data.e_t === 'D') {
      if (this.rootStore.sports.upcomingEventsMap.get(data.e_i_k)) {
        // @ts-ignore
        this.rootStore.sports.upcomingEventsMap.get(data.e_i_k).note = data.n
      }
    }

    if (data.e_t === 'L') {
      if (this.rootStore.sports.upcomingEventsMap.get(data.e_i_k)) {
        // @ts-ignore
        this.rootStore.sports.upcomingEventsMap.get(data.e_i_k).settlementNote =
          data.n
        // TODO Raspraviti ovaj deo na kraju, update nota nece raditi
        /*
        if (this.rootStore.sports?.selectedLiveMatch?.intKey === data.e_i_k) {
          // @ts-ignore
          this.rootStore.sports?.selectedLiveMatch?.settlementNote = data.n
        } */
      }
    }

    if (
      data.e_t === 'P' &&
      this.rootStore.sports.playerEventsMap.has(data.e_i_k)
    ) {
      const event = this.rootStore.sports.playerEventsMap.get(data.e_i_k)
      // @ts-ignore
      event.note = data.n
    }

    if (data.e_t === 'A') {
      const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(
        data.e_i_k
      )
      event.note = data.n
    }
  }

  handleTimeUpdate = (data: any) => {
    data.forEach((playerData: any) => {
      if (this.rootStore.sports.playerEventsMap.has(playerData.i_k)) {
        const event = this.rootStore.sports.playerEventsMap.get(playerData.i_k)
        // @ts-ignore
        event.start = playerData.sta
      }
      const slipEvent = this.rootStore.slip.findEvent(playerData.i_k)
      if (slipEvent) {
        slipEvent.start = playerData.sta
      }
    })
    this.rootStore.sports.setPlayerFilters(this.rootStore.sports.filtersPlayers)
  }

  handleServerTime = (data: any) => {
    const difference = moment
      .duration(moment().diff(moment(data.time)))
      .asMinutes()
    this.rootStore.user.timeDifference = difference
  }

  handleAntepostPublishAndUpdate = (event: any) => {
    if (event.a.i_k) {
      const antepostEvent = {
        end: event.a.e,
        event_type: 'A',
        intKey: event.a.i_k,
        isSuspended: event.a.i_s,
        marketId: event.a.m_i,
        name: event.a.na,
        code: event.c,
        landbaseCode: event.c,
        antepostCode: event.c,
        sportId: event.s,
        note: event.a.n,
        start: event.a.s,
        market: {
          competitionId: event.c,
          competitionName: event.c_n,
          id: event.id,
          isSuspended: event.i_s,
          locationId: event.l_i,
          locationName: event.l_n,
          name: event.na,
          sportId: event.s,
        },
        antepostBetsList: event.a.bets.map((odd: any) => ({
          code: odd.c,
          intKey: odd.i_k,
          isSuspended: odd.i_s,
          manualStatus: odd.manual_status,
          name: odd.na,
          oddValue: parseFloat(odd.o_v),
          param: odd.p,
        })),
      }
      this.rootStore.sports.antepostEventsAndMarketsMap.set(
        event.a.i_k,
        antepostEvent
      )

      const group = this.rootStore.slip.findGroupWithEvent(antepostEvent.intKey)
      const slipEvent = this.rootStore.slip.findEvent(antepostEvent.intKey)
      if (group && slipEvent) {
        slipEvent.start = antepostEvent.start
        slipEvent.end = antepostEvent.end
        this.rootStore.slip.removeEvent(group, slipEvent.intKey)
        this.rootStore.slip.addEventAntepost(slipEvent, 0)
      }
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    }
  }

  handlePlayersPublished = (event: any) => {
    runInAction(() => {
      this.rootStore.sports.playerEventsMap.set(event.i_k, {
        event_type: 'P',
        competitionId: event.c_i,
        competitorId: event.cr_i,
        dualHome: event.d_h,
        dualAway: event.d_a,
        dualIntKey: event.d_i_k,
        dualLandbaseCode: event.d_c,
        eventCode: event.e_c,
        feedOddsList: [],
        intKey: event.i_k,
        isSuspended: event.i_s,
        magazine: event.mgz,
        locationId: event.l_i,
        marketsMap: Object.entries(event.m),
        name: event.n,
        note: event.nt,
        playerName: event.p_n,
        sportId: event.s_i,
        start: event.sta,
        allOddsList: event.o.map(
          (odd: {
            i_k: number
            v: string
            o_i: number
            i_s: boolean
            lim: string
          }) => ({
            intKey: odd.i_k,
            oddValue: parseFloat(odd.v),
            outcomeId: odd.o_i,
            isSuspended: odd.i_s,
            limit: parseFloat(odd.lim),
          })
        ),
        competitionName: '',
      })
    })
    this.rootStore.sports.setPlayerFilters(this.rootStore.sports.filtersPlayers)
  }

  handleMarketOddsPublished = (event: any) => {
    let elementPrematch: any = null
    let elementPrematchPlayers: any = null
    let elementLive: any = null

    if (event.stage.toLowerCase() === 'prematch') {
      elementPrematch = this.rootStore.sports.upcomingEventsMap.get(event.e_i_k)
    }

    if (event.stage.toLowerCase() === 'prematch') {
      elementPrematchPlayers = this.rootStore.sports.playerEventsMap.get(
        event.e_i_k
      )
    }

    if (event.stage === 'live') {
      elementLive = this.rootStore.sports.liveEventsMap.get(event.e_i_k)
    }

    if (elementLive && event.stage === 'live') {
      runInAction(() => {
        this.rootStore.slip.updateOdds(event.e_i_k, event.odds)
        elementLive = {
          ...elementLive,
          allOddsList: event.odds.map((odd: any) => ({
            intKey: odd.i_k,
            oddValue: parseFloat(odd.o_v),
            outcomeId: odd.o_i,
            isSuspended: odd.i_s,
            limit:
              odd.lim === 0 || odd.lim === 0.0 || odd.lim === '0'
                ? 0
                : parseFloat(odd.lim),
            limit2:
              odd.lim === 0 || odd.lim === 0.0 || odd.lim === '0'
                ? 0
                : parseFloat(odd.lim2),
          })),
        }
        elementLive.allOddsList = _.sortBy(elementLive.allOddsList, [
          'outcomeId',
        ])
        this.rootStore.sports.liveEventsMap.set(event.e_i_k, elementLive)

        if (
          elementLive.event_type === 'L' &&
          this.rootStore.sports?.selectedMatch?.intKey === elementLive.intKey
        ) {
          const marketGroup = this.rootStore.sports.selectedMarketGroup
          this.rootStore.sports.setSelectedEvent(null)
          this.rootStore.sports.setSelectedEvent(elementLive)
          this.rootStore.sports.setSelectedMarketGroup(marketGroup)
        }

        this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      })
    }

    if (elementPrematchPlayers) {
      runInAction(() => {
        this.rootStore.slip.updateOdds(event.e_i_k, event.odds)
        elementPrematchPlayers = {
          ...elementPrematchPlayers,
          allOddsList: event.odds.map((odd: any) => ({
            intKey: odd.i_k,
            oddValue: parseFloat(odd.o_v),
            outcomeId: odd.o_i,
            isSuspended: odd.i_s,
            limit:
              odd.lim === 0 || odd.lim === 0.0 || odd.lim === '0'
                ? 0
                : parseFloat(odd.lim),
            limit2:
              odd.lim === 0 || odd.lim === 0.0 || odd.lim === '0'
                ? 0
                : parseFloat(odd.lim2),
          })),
        }
        elementPrematchPlayers.allOddsList = _.sortBy(
          elementPrematchPlayers.allOddsList,
          ['outcomeId']
        )
        this.rootStore.sports.playerEventsMap.set(
          event.e_i_k,
          elementPrematchPlayers
        )

        if (
          elementPrematchPlayers.event_type === 'P' &&
          this.rootStore.sports?.selectedMatch?.intKey ===
            elementPrematchPlayers.intKey
        ) {
          const marketGroup = this.rootStore.sports.selectedMarketGroup
          this.rootStore.sports.setSelectedEvent(null)
          this.rootStore.sports.setSelectedMarketGroup(marketGroup)
          this.rootStore.sports.setSelectedEvent(elementPrematchPlayers)
        }

        this.rootStore.sports.setFilters(this.rootStore.sports.filtersPlayers)
      })
    } else if (elementPrematch && event.stage.toLowerCase() === 'prematch') {
      runInAction(() => {
        this.rootStore.slip.updateOdds(event.e_i_k, event.odds)
        elementPrematch = {
          ...elementPrematch,
          allOddsList: event.odds.map((odd: any) => ({
            intKey: odd.i_k,
            oddValue: parseFloat(odd.o_v),
            outcomeId: odd.o_i,
            isSuspended: odd.i_s,
            limit:
              odd.lim === 0 || odd.lim === 0.0 || odd.lim === '0'
                ? 0
                : parseFloat(odd.lim),
            limit2:
              odd.lim === 0 || odd.lim === 0.0 || odd.lim === '0'
                ? 0
                : parseFloat(odd.lim2),
          })),
        }
        elementPrematch.allOddsList = _.sortBy(elementPrematch.allOddsList, [
          'outcomeId',
        ])

        this.rootStore.sports.upcomingEventsMap.set(
          event.e_i_k,
          elementPrematch
        )

        if (
          elementPrematch.event_type === 'D' &&
          this.rootStore.sports?.selectedMatch?.intKey ===
            elementPrematch.intKey
        ) {
          const marketGroup = this.rootStore.sports.selectedMarketGroup
          this.rootStore.sports.setSelectedEvent(null)
          this.rootStore.sports.setSelectedEvent(elementPrematch)
          this.rootStore.sports.setSelectedMarketGroup(marketGroup)
        }

        this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      })
    }
  }

  handleEventPublished = (event: any) => {
    if (event.stg === 'live') {
      runInAction(() => {
        this.rootStore.sports.liveEventsMap.set(event.i_k, {
          event_type: 'L',
          competitionId: event.c_i,
          id: event.i,
          intKey: event.i_k,
          isDisabled: false,
          isSingleApproval: false,
          isSuspicious: false,
          landbaseCode: event.e_c,
          magazine: event.mgz,
          currentResult: event.c_r,
          competitionShortName: event.c_s_n,
          locationId: event.l_i,
          home: event.h,
          away: event.a,
          currentPhase: {
            time: event.c_p?.T?.PRT || event.c_p?.T?.M,
            isBreak: event.c_p?.B,
            SN: event.c_p?.SN,
            N: event.c_p?.N,
          },
          detailedResult: {
            FT: event.d_r.FT
              ? {
                  away: event.d_r.FT.period_away_score,
                  home: event.d_r.FT.period_home_score,
                  periodName: event.d_r.FT.period_name,
                }
              : undefined,
            HT: event.d_r.HT
              ? {
                  periodAwayScore: event.d_r.HT.period_away_score,
                  periodHomeScore: event.d_r.HT.period_home_score,
                  periodName: event.d_r.HT.period_name,
                }
              : undefined,
            OT: event.d_r.OT
              ? {
                  away: event.d_r.OT.period_away_score,
                  home: event.d_r.OT.period_home_score,
                  periodName: event.d_r.OT.period_name,
                }
              : undefined,
            PK: event.d_r.PK
              ? {
                  away: event.d_r.PK.away,
                  home: event.d_r.PK.home,
                  periodName: '',
                }
              : undefined,
            P1: event.d_r.P1 ? event.d_r.P1 : undefined,
            P2: event.d_r.P2 ? event.d_r.P2 : undefined,
            P3: event.d_r.P3 ? event.d_r.P3 : undefined,
            Q1: event.d_r.Q1 ? event.d_r.Q1 : undefined,
            Q2: event.d_r.Q2 ? event.d_r.Q2 : undefined,
            Q3: event.d_r.Q3 ? event.d_r.Q3 : undefined,
            Q4: event.d_r.Q4 ? event.d_r.Q4 : undefined,
            SET1: event.d_r.SET1 ? event.d_r.SET1 : undefined,
            SET2: event.d_r.SET2 ? event.d_r.SET2 : undefined,
            SET3: event.d_r.SET3 ? event.d_r.SET3 : undefined,
            SET4: event.d_r.SET4 ? event.d_r.SET4 : undefined,
            SET5: event.d_r.SET5 ? event.d_r.SET5 : undefined,
          },
          name: event.n,
          note: event.nt,
          marketsMap: event.m,
          allOddsList: event.o.map(
            (odd: {
              i_k: number
              o_v: string
              o_i: number
              i_s: boolean
              lim: string
              lim2: string
            }) => ({
              intKey: odd.i_k,
              oddValue: parseFloat(odd.o_v),
              outcomeId: odd.o_i,
              isSuspended: odd.i_s,
              limit: parseFloat(odd.lim),
              limit2: parseFloat(odd.lim2),
            })
          ),
          outrightType: '',
          sportId: event.s_i,
          start: event.sta,
          competitionName: event.c_n,
          isSuspended: event.i_s,
        })
        this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      })
    }
    if (event.stg.toLowerCase() === 'prematch') {
      runInAction(() => {
        this.rootStore.sports.upcomingEventsMap.set(event.i_k, {
          event_type: 'D',
          competitionId: event.c_i,
          id: event.i,
          intKey: event.i_k,
          isDisabled: false,
          isSingleApproval: false,
          isSuspicious: false,
          landbaseCode: event.e_c,
          magazine: event.mgz,
          locationId: event.l_i,
          home: event.h,
          away: event.a,
          name: event.n,
          note: event.nt,
          marketsMap: Object.entries(event.m),
          allOddsList: event.o.map(
            (odd: {
              i_k: number
              o_v: string
              o_i: number
              i_s: boolean
              lim: string
              lim2: string
            }) => ({
              intKey: odd.i_k,
              oddValue: parseFloat(odd.o_v),
              outcomeId: odd.o_i,
              isSuspended: odd.i_s,
              limit: parseFloat(odd.lim),
              limit2: parseFloat(odd.lim2),
            })
          ),
          outrightType: '',
          sportId: event.s_i,
          start: event.sta,
          competitionName: event.c_n,
          competitionShortName: event.c_s_n,
          isSuspended: event.i_s,
        })
        this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      })
    }
    const slipEvent = this.rootStore.slip.findEvent(event.i_k)
    if (slipEvent) {
      slipEvent.start = event.sta
    }
    this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
  }

  handleLiveOff = () => {
    runInAction(() => {
      this.isLiveOff = true
    })
  }
  handleLiveOn = () => {
    runInAction(() => {
      this.isLiveOff = false
    })
  }

  handlePrematchOff = () => {
    runInAction(() => {
      this.isPrematchOff = true
    })
  }
  handlePrematchOn = () => {
    runInAction(() => {
      this.isPrematchOff = false
    })
  }

  checkListingAvailability = async () => {
    const [err, res] = await to(this.checkListingAvailabilityCall.call('get'))
    if (!err) {
      runInAction(() => {
        res!.mapResult((result) => {
          this.isLiveOff = result.data.live_is_off
          this.isPrematchOff = result.data.prematch_is_off
          return true
        })
      })
    } else {
      // console.log(err)
    }
  }

  handleResult = (data: any) => {
    runInAction(() => {
      this.rootStore.sports.liveEventsMap.forEach((event: any) => {
        if (data.e_i_k === event.intKey) {
          event.currentPhase = data.c_p
          if (event.currentResult && event.currentResult[0] !== data.r.c_r[0]) {
            event.homeResultChanged = true
          }
          if (event.currentResult && event.currentResult[1] !== data.r.c_r[1]) {
            event.awayResultChanged = true
          }
          event.currentResult = data.r.c_r
          event.detailedResult = {
            FT: data.r.d_r.FT
              ? {
                  away: data.r.d_r.FT.away,
                  home: data.r.d_r.FT.home,
                  periodName: data.r.d_r.FT.period_name,
                }
              : undefined,
            SH: data.r.d_r.SH ? data.r.d_r.SH : undefined,
            FH: data.r.d_r.FH ? data.r.d_r.FH : undefined,
            HT: data.r.d_r.HT
              ? {
                  periodAwayScore: data.r.d_r.HT.period_away_score,
                  periodHomeScore: data.r.d_r.HT.period_home_score,
                  periodName: data.r.d_r.HT.period_name,
                }
              : undefined,
            OT: data.r.d_r.OT
              ? {
                  away: data.r.d_r.OT.away,
                  home: data.r.d_r.OT.home,
                  periodName: data.r.d_r.OT.period_name,
                }
              : undefined,
            PK: data.r.d_r.PK
              ? {
                  away: data.r.d_r.PK.away,
                  home: data.r.d_r.PK.home,
                  periodName: '',
                }
              : undefined,
            P1: data.r.d_r.P1 ? data.r.d_r.P1 : undefined,
            P2: data.r.d_r.P2 ? data.r.d_r.P2 : undefined,
            P3: data.r.d_r.P3 ? data.r.d_r.P3 : undefined,
            Q1: data.r.d_r.Q1 ? data.r.d_r.Q1 : undefined,
            Q2: data.r.d_r.Q2 ? data.r.d_r.Q2 : undefined,
            Q3: data.r.d_r.Q3 ? data.r.d_r.Q3 : undefined,
            Q4: data.r.d_r.Q4 ? data.r.d_r.Q4 : undefined,
            SET1: data.r.d_r.SET1 ? data.r.d_r.SET1 : undefined,
            SET2: data.r.d_r.SET2 ? data.r.d_r.SET2 : undefined,
            SET3: data.r.d_r.SET3 ? data.r.d_r.SET3 : undefined,
            SET4: data.r.d_r.SET4 ? data.r.d_r.SET4 : undefined,
            SET5: data.r.d_r.SET5 ? data.r.d_r.SET5 : undefined,
          }

          event.eventStatistics = {
            CK: data.s.CK
              ? {
                  away: data.s.CK.away,
                  home: data.s.CK.home,
                  periodName: '',
                }
              : undefined,
            cards: data.s.cards
              ? {
                  cardsAway: {
                    yellow: data.s.cards.cards_away.yellow,
                    red: data.s.cards.cards_away.red,
                  },
                  cardsHome: {
                    yellow: data.s.cards.cards_home.yellow,
                    red: data.s.cards.cards_home.red,
                  },
                }
              : undefined,
          }
        }
      })
    })
  }

  handleEventEnd = (data: any) => {
    runInAction(() => {
      if (this.rootStore.sports.liveEventsMap.get(data.e_i_k)) {
        this.rootStore.sports.liveEventsMap.delete(data.e_i_k)
        this.rootStore.sports.selectedLiveMatch = null
      }
    })
    this.rootStore.slip.removeEventFromSlip(data.e_i_k)
  }

  handleProdOddUpdate = (data: any) => {
    runInAction(() => {
      const { e_i_k: intKey, odds } = data
      this.rootStore.slip.updateOdds(intKey, odds)
      const eventToUpdate = this.rootStore.sports.upcomingEventsMap.get(intKey)
      if (eventToUpdate) {
        // @ts-ignore
        this.rootStore.sports.upcomingEventsMap.get(intKey).allOddsList =
          eventToUpdate.allOddsList.map((odd) => {
            const oddToChange = odds.find((el: any) => {
              return el.i_k === odd.intKey
            })
            if (oddToChange) {
              const color: {
                isUp: boolean | undefined
                isDown: boolean | undefined
              } = {
                isUp: false,
                isDown: false,
              }
              const isEqual = odd.oddValue === oddToChange.v
              color.isDown =
                odd.oddValue > oddToChange.v || (isEqual && oddToChange.isDown)
              color.isUp =
                odd.oddValue < oddToChange.v || (isEqual && oddToChange.isUp)
              odd.isDown = color.isDown
              odd.isUp = color.isUp
              odd.oddValue = parseFloat(oddToChange.v)
            }
            return odd
          })
      }
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }

  handleAntepostOddSuspend = (message: any) => {
    message.forEach((data: any) => {
      runInAction(() => {
        const { a_i_k: eIk, i_k: intKey } = data
        const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(eIk)
        if (event) {
          const oddToUnsuspend = event.antepostBetsList.find(
            (odd: any) => odd.intKey === intKey
          )
          if (oddToUnsuspend) {
            oddToUnsuspend.isSuspended = true
          }
        }
        this.rootStore.sports.setOutrightFilters(
          this.rootStore.sports.filtersOutright
        )
      })
    })
  }

  handlePlayerOddSuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, o_i_k: intKey } = data
      const event = this.rootStore.sports.playerEventsMap.get(eIk)
      if (event) {
        const oddToUnsuspend = event.allOddsList.find(
          (odd: any) => odd.intKey === intKey
        )
        if (oddToUnsuspend) {
          oddToUnsuspend.isSuspended = true
          const slipEvent = this.rootStore.slip.findEvent(eIk)
          if (slipEvent) {
            const slipOdd = this.rootStore.slip.findOdd(slipEvent, intKey)
            if (slipOdd) {
              slipOdd.isSuspended = true
            }
          }
        }
      }
      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
    })
  }

  handlePlayerOddUnsuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, o_i_k: intKey } = data
      const event = this.rootStore.sports.playerEventsMap.get(eIk)
      if (event) {
        const oddToUnsuspend = event.allOddsList.find(
          (odd: any) => odd.intKey === intKey
        )
        if (oddToUnsuspend) {
          oddToUnsuspend.isSuspended = false
          const slipEvent = this.rootStore.slip.findEvent(eIk)
          if (slipEvent) {
            const slipOdd = this.rootStore.slip.findOdd(slipEvent, intKey)
            if (slipOdd) {
              slipOdd.isSuspended = false
            }
          }
        }
      }

      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
    })
  }

  handleAntepostOddPublish = (data: any) => {
    const { a_i_k: eIk } = data
    const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(eIk)
    // antepostPublish message contains odd inside it, so it publishes that odd, but then
    // antepostBetPublished message arrives, so we have to check if we have that odd already, then override it
    // otherwise it duplicates itself
    const index = event.antepostBetsList.findIndex(
      (odd: any) => odd.intKey === data.i_k
    )
    if (index >= 0) {
      this.handleAntepostOddUpdate(data)
      return
    }
    runInAction(() => {
      if (event) {
        event.event_type = 'A'
        event.antepostCode = event.landbaseCode
        event.antepostBetsList.push({
          code: data.c,
          intKey: data.i_k,
          isSuspended: data.i_s,
          manualStatus: data.m_s,
          name: data.na,
          oddValue: data?.o_v,
          param: data.p,
        })
      }
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    })
  }

  handleAntepostOddUpdate = (data: any) => {
    runInAction(() => {
      const { a_i_k: eIk, i_k: intKey } = data
      const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(eIk)

      if (event) {
        const index = event.antepostBetsList.findIndex(
          (odd: any) => odd.intKey === intKey
        )

        const color: {
          isUp: boolean | undefined
          isDown: boolean | undefined
        } = {
          isUp: false,
          isDown: false,
        }
        if (event.antepostBetsList[index]) {
          const isEqual =
            event.antepostBetsList[index].oddValue === parseFloat(data.o_v)
          color.isDown =
            event.antepostBetsList[index].oddValue > parseFloat(data.o_v) ||
            (isEqual && event.antepostBetsList[index].isDown)
          color.isUp =
            event.antepostBetsList[index].oddValue < parseFloat(data.o_v) ||
            (isEqual && event.antepostBetsList[index].isUp)
        }

        const slipEvent = this.rootStore.slip.findEvent(eIk)
        if (slipEvent) {
          const group = this.rootStore.slip.findGroupWithEvent(slipEvent.intKey)
          if (group) {
            slipEvent.code = Number(data.c)
            slipEvent.intKey = eIk
            slipEvent.oIntKey = data.i_k
            slipEvent.isSuspended = data.i_s
            slipEvent.manualStatus = data.m_s
            slipEvent.oddValue = data?.o_v
            slipEvent.isDown = color.isDown
            slipEvent.isUp = color.isUp
            slipEvent.param = data.p
            this.rootStore.slip.removeEvent(group, slipEvent.intKey)
            this.rootStore.slip.addEventAntepost(slipEvent, 0)
          }
        }

        event.antepostBetsList[index] = {
          ...event.antepostBetsList[index],
          code: Number(data.c),
          intKey: data.i_k,
          isSuspended: data.i_s,
          manualStatus: data.m_s,
          name: data.na,
          oddValue: data?.o_v,
          param: data.p,
          ...color,
        }
      }
      data.intKey = eIk
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    })
  }

  handleAntepostOddHiden = (data: any) => {
    runInAction(() => {
      const { a_i_k: eIk, a_b_i_k: intKey } = data
      const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(eIk)
      if (event) {
        event.antepostBetsList.splice(
          event.antepostBetsList.findIndex((odd: any) => odd.intKey === intKey),
          1
        )
        this.rootStore.sports.selectedAntepost = event
        // this.rootStore.slip.removeEventFromSlip(event.intKey)
      }
      this.rootStore.sports.setOutrightFilters(
        this.rootStore.sports.filtersOutright
      )
    })
  }

  handleAntepostOddUnsuspend = (message: any) => {
    message.forEach((data: any) => {
      runInAction(() => {
        const { a_i_k: eIk, i_k: intKey } = data
        const event = this.rootStore.sports.antepostEventsAndMarketsMap.get(eIk)
        if (event) {
          const oddToUnsuspend = event.antepostBetsList.find(
            (odd: any) => odd.intKey === intKey
          )
          if (oddToUnsuspend) {
            oddToUnsuspend.isSuspended = false
          }
        }
        this.rootStore.sports.setOutrightFilters(
          this.rootStore.sports.filtersOutright
        )
      })
    })
  }

  handleOddSuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, o_i_k: intKey } = data
      let event = this.rootStore.sports.upcomingEventsMap.get(eIk)

      if (!event) {
        event = this.rootStore.sports.liveEventsMap.get(eIk)
      }

      if (event) {
        const oddToUnsuspend = event.allOddsList.find(
          (odd) => odd.intKey === intKey
        )
        if (oddToUnsuspend) {
          oddToUnsuspend.isSuspended = true
        }
      }
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      this.rootStore.slip.handleSuspendChangeOdd(eIk, intKey, true)
    })
  }

  handleAntepostMarketSuspend = (data: any) => {
    runInAction(() => {
      const { market_id: marketId } = data
      this.rootStore.sports.antepostEventsAndMarketsMap.forEach(
        (antepost: any) => {
          if (antepost.market.id === marketId) {
            antepost.market.isSuspended = true
            const event = this.rootStore.slip.findEvent(antepost.intKey)
            if (event) {
              event.isSuspended = true
            }
          }
        }
      )
    })
  }

  handleAntepostMarketUnSuspend = (data: any) => {
    runInAction(() => {
      const { market_id: marketId } = data
      this.rootStore.sports.antepostEventsAndMarketsMap.forEach(
        (antepost: any) => {
          if (antepost.market.id === marketId) {
            antepost.market.isSuspended = false
            const event = this.rootStore.slip.findEvent(antepost.intKey)
            if (event) {
              event.isSuspended = false
            }
          }
        }
      )
    })
  }

  handleOddUnsuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, o_i_k: intKey } = data
      let event = this.rootStore.sports.upcomingEventsMap.get(eIk)

      if (!event) {
        event = this.rootStore.sports.liveEventsMap.get(eIk)
      }

      if (event) {
        const oddToUnsuspend = event.allOddsList.find(
          (odd) => odd.intKey === intKey
        )
        if (oddToUnsuspend) {
          oddToUnsuspend.isSuspended = false
        }
      }
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
      this.rootStore.slip.handleSuspendChangeOdd(eIk, intKey, false)
    })
  }

  checkIsMarketSuspended = (event: Event, marketId: number) => {
    if (event && event.marketsMap) {
      const marketToSuspend = event?.marketsMap.find(
        (market) => parseInt(market[0], 10) === marketId
      )
      return marketToSuspend ? marketToSuspend[1] : false
    }
    return false
  }

  handlePlayerMarketSuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, m_i: marketId } = data
      const event = this.rootStore.sports.playerEventsMap.get(eIk)
      if (event) {
        event.marketsMap = event.marketsMap?.map((market) => {
          if (parseInt(market[0], 10) === marketId) {
            return [market[0], true]
          }
          return market
        })
        const slipEvent = this.rootStore.slip.findEvent(eIk)
        if (slipEvent) {
          slipEvent.marketsMap = event.marketsMap
          slipEvent.selectedOdds.map((odd: any) => {
            const outcome = this.rootStore.sports.playOutcomesList.find(
              (o) => o.id === odd.outcomeId
            )
            if (outcome) {
              odd.isSuspended = this.checkIsMarketSuspended(
                slipEvent,
                outcome.marketId
              )
            }
            return odd
          })
        }
      }

      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
    })
  }

  handlePlayerMarketUnsuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, m_i: marketId } = data
      const event = this.rootStore.sports.playerEventsMap.get(eIk)

      if (event) {
        event.marketsMap = event.marketsMap?.map((market) => {
          if (parseInt(market[0], 10) === marketId) {
            return [market[0], false]
          }
          return market
        })
        const slipEvent = this.rootStore.slip.findEvent(eIk)
        if (slipEvent) {
          slipEvent.marketsMap = event.marketsMap
          slipEvent.selectedOdds.map((odd: any) => {
            const outcome = this.rootStore.sports.playOutcomesList.find(
              (o) => o.id === odd.outcomeId
            )
            if (outcome) {
              odd.isSuspended = this.checkIsMarketSuspended(
                slipEvent,
                outcome.marketId
              )
            }
            return odd
          })
        }
      }

      this.rootStore.sports.setPlayerFilters(
        this.rootStore.sports.filtersPlayers
      )
    })
  }

  handleMarketSuspend = (data: any) => {
    runInAction(() => {
      // eslint-disable-next-line prefer-const
      let { e_i_k: eIk, m_i: marketId } = data
      eIk = parseInt(eIk, 10)
      let event = this.rootStore.sports.upcomingEventsMap.get(eIk)

      if (!event) {
        event = this.rootStore.sports.liveEventsMap.get(eIk)
      }
      if (event) {
        event.marketsMap = event.marketsMap?.map((market) => {
          if (parseInt(market[0], 10) === marketId) {
            return [market[0], true]
          }
          return market
        })
        if (
          this.rootStore.sports.selectedMatch &&
          this.rootStore.sports.selectedMatch.intKey === eIk
        ) {
          this.rootStore.sports.selectedMatch = event
        }
        if (
          this.rootStore.sports.selectedLiveMatch &&
          this.rootStore.sports.selectedLiveMatch.intKey === eIk
        ) {
          this.rootStore.sports.selectedLiveMatch = event
        }
      }
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }

  handleMarketUnsuspend = (data: any) => {
    runInAction(() => {
      const { e_i_k: eIk, m_i: marketId } = data
      let event = this.rootStore.sports.upcomingEventsMap.get(parseInt(eIk, 10))

      if (!event) {
        event = this.rootStore.sports.liveEventsMap.get(eIk)
      }
      if (event) {
        event.marketsMap = event.marketsMap?.map((market) => {
          if (parseInt(market[0], 10) === marketId) {
            return [market[0], false]
          }
          return market
        })
        if (
          this.rootStore.sports.selectedMatch &&
          this.rootStore.sports.selectedMatch.intKey === eIk
        ) {
          this.rootStore.sports.selectedMatch = event
        }
        if (
          this.rootStore.sports.selectedLiveMatch &&
          this.rootStore.sports.selectedLiveMatch.intKey === eIk
        ) {
          this.rootStore.sports.selectedLiveMatch = event
        }
      }
      this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
    })
  }
}
