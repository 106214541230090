import React, { useContext } from 'react'
import { Statistic } from 'antd'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/formatCurrency'
import styles from './slipInfo.module.css'

function SlipInfo() {
  const { t } = useTranslation()
  const { slip } = useContext(StoreContext)

  return (
    <div className={styles.slipInfo}>
      <div className={styles.slipInfoLeft}>
        <div>
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('minOdd')}
            value={
              slip.paymentInfo.min_odds_value
                ? slip.paymentInfo.min_odds_value?.toFixed(2)
                : 0
            }
          />
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('maxOdd')}
            value={
              slip.paymentInfo.max_odds_value
                ? slip.paymentInfo.max_odds_value?.toFixed(2)
                : 0
            }
          />
        </div>

        <div style={{ marginLeft: 18 }}>
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('minWin')}
            value={
              slip.paymentInfo.min_winning_amount
                ? slip.paymentInfo.min_winning_amount?.toFixed(2)
                : 0
            }
          />
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('maxWin')}
            value={
              slip.paymentInfo.max_winning_amount
                ? slip.paymentInfo.max_winning_amount?.toFixed(2)
                : 0
            }
          />
        </div>
      </div>

      <div className={styles.slipInfoRight}>
        <div className={styles.slipInfoRightTop}>
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('minPayIn')}
            value={slip.paymentInfo.min_pay_in || 0}
          />
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('matchNum')}
            value={slip.matchNum}
          />
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            title={t('combNum')}
            value={slip.paymentInfo.number_of_combinations || 0}
          />
        </div>

        <div className={styles.slipInfoRightBottom}>
          <span>{t('maxPayOut')}:</span>
          <span>{formatCurrency(slip.paymentInfo.max_pay_out)}</span>
        </div>
      </div>
    </div>
  )
}

export default observer(SlipInfo)
