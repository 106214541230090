/* eslint-disable no-console */
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import SlipApi from 'utils/slipApi'
import {
  SingleTicket,
  SlipSocketType,
  StatusBySocketType,
  TicketStatus,
} from 'store/models/SlipModels'
import moment from 'moment'
import mapSlipFactory from 'utils/mapSlipFactory'
import formatSlipPrint from 'utils/formatSlipPrint'
import { message } from 'antd'
import BaseStore from './BaseStore'
import RootStore from './RootStore'

export enum RollbackErrors {
  SLIP_STARTED = 'slip_started',
  SLIP_NOT_FOUND = 'slip_not_found',
  INSUFFICIENT_PRIVILEGES = 'ERR_INSUFFICIENT_PRIVILEGES',
}

export enum PayoutErrors {
  SLIP_NOT_RESOLVED = 'ERR_NOT_RESOLVED',
  SLIP_PAID = 'ERR_PAID',
  SLIP_NOT_PASSED = 'ERR_NOT_PASSED',
  SLIP_NOT_FOUND = 'slip_not_found',
}

export default class SlipStore extends BaseStore {
  $slipApprovals: Map<string, SingleTicket>
  $slipSubmitted: Map<string, SingleTicket>
  approvalTickets: Array<any>
  api: SlipApi
  slipInterval: any
  selectedSlipID: any = null
  validateInProgress: boolean
  placeBetInProgress: boolean = false
  greekoTicketHistory: any
  repeatTicket: any

  constructor(rootStore: RootStore) {
    super(rootStore)
    this.$slipApprovals = new Map()
    this.$slipSubmitted = new Map()
    this.approvalTickets = []
    this.api = new SlipApi()
    this.validateInProgress = false
    this.greekoTicketHistory = null
    makeObservable(this, {
      placeBet: action,
      getTicket: action,
      distributeTicket: action,
      onApprovalStatusChange: action,
      checkSlipStailness: action,
      updateSlip: action,
      removeApprovalSlip: action,
      clearSlipCheckInterval: action,
      onChangesAction: action,
      addApprovalSlip: action,
      removeSubmittedSlip: action,
      addSubmittedSlip: action,
      setApprovalSlips: action,
      setSubmittedSlips: action,
      placeBetInProgress: observable,
      selectedSlipID: observable,
      $slipApprovals: observable,
      $slipSubmitted: observable,
      greekoTicketHistory: observable,
      repeatTicket: observable,
      approvalSlips: computed,
      submittedSlips: computed,
    })
  }

  setApprovalSlips = (approvalSlips: SingleTicket[]) => {
    this.$slipApprovals = new Map()
    approvalSlips.forEach((slip: SingleTicket) => this.addApprovalSlip(slip))
  }

  setSubmittedSlips = (submittedSlips: SingleTicket[]) => {
    this.$slipSubmitted = new Map()
    submittedSlips.forEach((slip: SingleTicket) => this.addSubmittedSlip(slip))
  }

  getFastTicket = async (code: string) => {
    try {
      const { data } = await this.api.getFastSlip(code)
      return data
    } catch (e) {
      console.log(e)
    }

    return null
  }

  getNextRoundData = async () => {
    try {
      const { data } = await this.api.getNextRound()
      return data
    } catch (e) {
      console.log('error', e)
    }
    return null
  }

  getTicketHistory = async () => {
    try {
      const { data } = await this.api.getTicketHistory()
      this.greekoTicketHistory = data
    } catch (e) {
      console.log('error', e)
    }
    return null
  }

  getRoundsForDate = async (date: string) => {
    try {
      const { data } = await this.api.getRoundsForDate(date)
      return data
    } catch (e) {
      console.log('error', e)
    }
    return null
  }

  getTicketGreeko = async (ticketId: string) => {
    try {
      const { data } = await this.api.getTicketGreeko(ticketId)
      return data
    } catch (e) {
      console.log('error', e)
      if (e?.data?.detail?.message) {
        message.error(e?.data?.detail?.message)
      }
    }
    return null
  }

  payTicketGreeko = async (ticketId: string) => {
    try {
      const { data } = await this.api.payTicketGreeko(ticketId)
      return data
    } catch (e) {
      console.log('error', e)
      if (e?.data?.detail?.message) {
        message.error(e?.data?.detail?.message)
      }
    }
    return null
  }

  postTicket = async (postData: any) => {
    try {
      const { data } = await this.api.postTicket(postData)
      return data
    } catch (e) {
      console.log('error', e)
      if (e?.data?.detail?.message) {
        message.error(e?.data?.detail?.message)
      }
    }
    return null
  }

  deleteTicket = async (postData: any) => {
    try {
      const { data } = await this.api.deleteTicket(postData)
      return data
    } catch (e) {
      console.log('error', e)
      if (e?.data?.detail?.errors.message) {
        message.error(e?.data?.detail?.errors.message)
      }
    }
    return null
  }

  getTransactionLogData = async (type: string) => {
    try {
      const { data } = await this.api.getTransactionsLog(type)
      return data
    } catch (e) {
      console.log('error', e)
    }
    return null
  }

  getTicketByShortId = async (shortId: string) => {
    try {
      const { data } = await this.api.getSlipByShortId(shortId)
      return data
    } catch (e) {
      console.log(e)
    }

    return null
  }

  getTicket = async (slipId: string) => {
    try {
      const response = await this.api.getTicket(slipId)
      this.distributeTicket(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  // used only for ticket statistics
  getTicketData = async (slipId: string) => {
    try {
      const response = await this.api.getTicket(slipId)
      return response
    } catch (e) {
      console.log(e)
    }
    return null
  }

  private checkIfSystemIsActuallyFix = (groups: any) => {
    const systemTypes = groups.map((g: any) =>
      g.system.split('/').map((r: any) => r.trim())
    )
    return systemTypes.some((system: any) => system[0] !== system[1])
  }

  private mergeSystemsGroupsIntoFix = (slipGroups: any) => {
    const newFixSlipGroup = [
      {
        events: slipGroups.reduce((events: any, group: any) => {
          return [...events, ...group.events]
        }, []),
        system: '',
      },
    ]
    newFixSlipGroup[0].system = `${newFixSlipGroup[0].events.length} / ${newFixSlipGroup[0].events.length}`
    return newFixSlipGroup
  }

  placeBet = async (payload: any) => {
    this.placeBetInProgress = true
    try {
      if (!this.checkIfSystemIsActuallyFix(payload.slip_groups)) {
        payload.slip_groups = this.mergeSystemsGroupsIntoFix(
          payload.slip_groups
        )
      }
    } catch (e) {
      return Promise.reject(e)
    }

    try {
      const response: any = await this.api.placeBet(payload)
      if (response?.data?.status === TicketStatus.NOT_RESOLVED) {
        this.addSubmittedSlip(response.data)
        this.rootStore.slip.updateReport(response.data)
        this.printSlip(response.data)
      }
      return response.data
    } catch (e) {
      return Promise.reject(e.data)
    } finally {
      this.placeBetInProgress = false
    }
  }

  rollBack = async (slipId: string) => {
    try {
      await this.api.rollBack(slipId)
      const slip = this.findSubmittedSlipByField('short_uuid', slipId)

      if (slip) {
        // @ts-ignore
        this.updateSubmittedSlip(slip.id, { status: TicketStatus.CANCELED })
      }

      return true
    } catch (e) {
      let errorType
      console.log(e)
      if (e.status === 404) {
        errorType = RollbackErrors.SLIP_NOT_FOUND
      } else if (e.data?.service === 'ERR_EXPIRED') {
        errorType = RollbackErrors.SLIP_STARTED
      } else if (e.data?.error === 'ERR_INSUFFICIENT_PRIVILEGES') {
        errorType = RollbackErrors.INSUFFICIENT_PRIVILEGES
      }
      return Promise.reject(errorType)
    }
  }

  payOut = async (slipId: string) => {
    try {
      await this.api.payOut(slipId)
      const slip = this.findSubmittedSlipByField('short_uuid', slipId)

      if (slip) {
        // @ts-ignore
        this.updateSubmittedSlip(slip.id, { status: TicketStatus.PAID })
      }

      return true
    } catch (e) {
      let errorType

      if (e.status === 404) {
        errorType = PayoutErrors.SLIP_NOT_FOUND
      }
      if (e.status === 400) {
        errorType = PayoutErrors.SLIP_NOT_PASSED
      } else if (e.data?.service) {
        errorType = e.data.service
      }

      return Promise.reject(errorType)
    }
  }

  onChangesAction = async (
    id: string,
    method: 'acceptChanges' | 'declineChanges'
  ) => {
    this.validateInProgress = true
    try {
      const response = await this.api[method](id)
      if (method === 'acceptChanges') {
        this.rootStore.slip.updateReportData(response.data.amount)
        this.distributeTicket(response.data)
      }
    } catch (e) {
      console.log('Failed to accept changes')
    } finally {
      runInAction(() => {
        this.validateInProgress = false
      })
    }
  }

  distributeTicket = (slip: SingleTicket) => {
    if (
      slip.status === TicketStatus.APPROVING ||
      slip.status === TicketStatus.MANUAL_CHANGED
    ) {
      this.addApprovalSlip(slip)
    } else {
      this.removeApprovalSlip(slip.id)
      this.addSubmittedSlip(slip)
      if (slip.status === TicketStatus.NOT_RESOLVED) {
        console.log('slip', slip)
        this.printSlip(slip)
        this.rootStore.slip.updateReport(slip)
      }
    }
  }

  addApprovalSlip = (slip: SingleTicket) => {
    this.$slipApprovals.set(slip.id, slip)
  }

  removeApprovalSlip = (slipId: string) => {
    this.$slipApprovals.delete(slipId)
  }

  addSubmittedSlip = (slip: SingleTicket) => {
    this.$slipSubmitted.set(slip.id, slip)
  }

  removeSubmittedSlip = (slipId: string) => {
    this.$slipSubmitted.delete(slipId)
  }

  updateSubmittedSlip = (slipId: string, fields: any) => {
    const slip = this.$slipSubmitted.get(slipId)
    if (!slip) return

    this.$slipSubmitted.set(slipId, { ...slip, ...fields })
  }

  findSubmittedSlipByField = (key: string, value: any) => {
    let slip

    this.$slipSubmitted.forEach((s: any) => {
      if (s[key] && s[key] === value) {
        slip = s
      }
    })

    return slip
  }

  onSlipRollback = (data: any) => {
    const existingSlip = this.$slipSubmitted.get(data.message)
    if (existingSlip) {
      existingSlip.status = TicketStatus.CANCELED
    }
  }

  connectTicketApprovalFeed = () => {
    // TODO: maybe seperate this onApprovalStatusChange
    const handlers = {
      [SlipSocketType.ROLLBACK]: this.onSlipRollback,
      [SlipSocketType.APPROVAL]: this.onApprovalStatusChange,
      [SlipSocketType.CHANGED]: this.onApprovalStatusChange,
      [SlipSocketType.APPROVED]: this.onApprovalStatusChange,
      [SlipSocketType.DENIED]: this.onApprovalStatusChange,
    }
    this.api.connectTicketFeed(handlers)
  }

  onApprovalStatusChange = (ticket: any) => {
    this.updateSlip(ticket)
    if (
      ticket.type === SlipSocketType.DENIED ||
      ticket.type === SlipSocketType.APPROVED
    ) {
      if (ticket.type === SlipSocketType.APPROVED) {
        this.onChangesAction(ticket.message.id, 'acceptChanges')
      }
      this.scheduleForDelete(ticket.message.id)
    }
  }

  updateSlip = (slip: any) => {
    const existingSlip = this.$slipApprovals.get(slip.message.id)
    if (existingSlip && slip.type !== SlipSocketType.CHANGED) {
      this.$slipApprovals.set(slip.message.id, {
        ...existingSlip,
        status: StatusBySocketType[slip.type] as TicketStatus,
      } as SingleTicket)
    } else if (slip.type === SlipSocketType.APPROVAL) {
      this.addApprovalSlip(mapSlipFactory(slip.message))
    } else {
      this.getTicket(slip.message.id)
    }
  }

  scheduleForDelete = (slipId: string) => {
    setTimeout(() => {
      const slip = this.$slipApprovals.get(slipId) as SingleTicket
      if (slip) this.addSubmittedSlip(slip)
      this.removeApprovalSlip(slipId)
    }, 5000)
  }

  checkSlipStailness = () => {
    console.log('check slip stailness')
    this.submittedSlips.forEach((slip: SingleTicket) => {
      const ticketDiff = Math.abs(
        moment(slip.created_at).diff(moment.now(), 'minutes')
      )
      if (ticketDiff >= 30) {
        this.removeSubmittedSlip(slip.id)
      }
    })
  }

  initSlipCreatedCheck = () => {
    if (!this.slipInterval) {
      this.slipInterval = setInterval(() => this.checkSlipStailness(), 5000)
    }
  }

  clearSlipCheckInterval = () => {
    clearInterval(this.slipInterval)
    this.slipInterval = null
  }

  previewSlip = async (slip: SingleTicket) => {
    try {
      const response = await this.api.getTicket(slip.id)
      this.selectedSlipID = ''
      this.rootStore.slip.clearOdds()
      this.selectedSlipID = response.data?.short_uuid
      this.rootStore.slip.loadSlip(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  printSlip = async (slip: SingleTicket) => {
    try {
      await this.api.printSlip(formatSlipPrint(slip))
    } catch (e) {
      console.log(e.data)
    }
  }

  printSlipGreeko = async (slip: any) => {
    try {
      await this.api.printGreekoSlip(slip)
    } catch (e) {
      console.log(e.data)
    }
  }

  printSlipDepositWithdrawal = async (slip: any) => {
    try {
      await this.api.printUserDepositOrWithdrawal(slip)
    } catch (e) {
      console.log(e.data)
    }
  }

  getSlipsPendingApproval = async () => {
    try {
      const { data } = await this.api.getSlipsPendingApproval()
      this.setApprovalSlips(data.results)
      return Promise.resolve()
    } catch (e) {
      console.error(e)
      return Promise.reject()
    }
  }

  getSlipsInLast30Minutes = async () => {
    const thirtyMinutes = 30 * 60 * 1000
    const bodyParams = {
      created_at_from: moment().subtract(thirtyMinutes),
      created_at_to: moment(),
      betting_place_id: this.rootStore.user.bettingPlaceId,
      betting_machine_id: this.rootStore.user.bettingMachineId,
      sc_user_id: this.rootStore.user?.user?.id,
    }
    try {
      const { data } = await this.api.getSlipsInLast30Minutes(bodyParams)
      this.setSubmittedSlips(data.results)
      return Promise.resolve()
    } catch (e) {
      console.error(e)
      return Promise.reject()
    }
  }

  get approvalSlips() {
    return Array.from(this.$slipApprovals, ([, value]) => ({ ...value }))
  }

  get submittedSlips() {
    return Array.from(this.$slipSubmitted, ([, value]) => ({ ...value }))
  }
}
