import Empty from 'google-protobuf/google/protobuf/empty_pb'

import { OddsServiceClient } from '../proto/odds/odds_grpc_web_pb'
import { OddsStreamServiceClient } from '../proto/odds_stream/odds_stream_grpc_web_pb'
import ProtobufApi from './protobufApi'
import { getApiUrl, getApiUrlGo } from './urlBuilder'
import {
  Competition,
  Location,
  Market,
  MarketGroup,
  Outcome,
} from './sports.types'
import { http } from './request'

export type SportsAndMarketsSCResponse = {
  id: number
  name: string
  marketGroupsList: MarketGroup[]
}

export type PlayerSportsAndMarketsSCResponse = {
  id: number
  name: string
  marketsList: Market[]
}

export type OutcomesSCResponse = {
  outcomesList: Outcome[]
}

export type LocationsSCResponse = {
  locationsList: Location[]
}

export type CompetitionsSCResponse = {
  competitionsList: Competition[]
}

class SportsApi {
  private client: OddsServiceClient
  private clientGo: OddsStreamServiceClient
  private api: ProtobufApi
  private apiGo: ProtobufApi

  constructor() {
    this.client = new OddsServiceClient(getApiUrl(), {}, {})
    this.clientGo = new OddsStreamServiceClient(getApiUrlGo(), {}, {})
    this.api = new ProtobufApi(this.client)
    this.apiGo = new ProtobufApi(this.clientGo)
  }

  getPrematchEvents(onData: any, onEnd: any, options = {}) {
    const request = new Empty.Empty()
    this.apiGo.stream('streamGetEventsPrematchSC', onData, onEnd, [
      request,
      options,
    ])
  }

  getLiveEvents(onData: any, onEnd: any, options = {}) {
    const request = new Empty.Empty()
    this.apiGo.stream('streamGetEventsLiveSC', onData, onEnd, [
      request,
      options,
    ])
  }

  getPlayerEvents(onData: any, onEnd: any, options = {}) {
    const request = new Empty.Empty()
    this.apiGo.stream('streamGetPlayerEventsSC', onData, onEnd, [
      request,
      options,
    ])
  }

  getAntepost(onData: any, onEnd: any, options = {}) {
    const request = new Empty.Empty()
    this.apiGo.stream('streamGetAntepost', onData, onEnd, [request, options])
  }

  getOutcomes(options = {}): Promise<OutcomesSCResponse> {
    const request = new Empty.Empty()
    return this.api.request<OutcomesSCResponse>('getOutcomesSC', [
      request,
      options,
    ])
  }

  getPlayerOutcomes(options = {}): Promise<OutcomesSCResponse> {
    const request = new Empty.Empty()
    return this.api.request<OutcomesSCResponse>('getPlayerOutcomesSC', [
      request,
      options,
    ])
  }

  getLocations(options = {}): Promise<LocationsSCResponse> {
    const request = new Empty.Empty()
    return this.api.request<LocationsSCResponse>('getLocationsSC', [
      request,
      options,
    ])
  }

  getFilterConfig() {
    return http.get(`/odds/sports_competitions/`)
  }

  getAntepostMarkets(options = {}): Promise<any> {
    const request = new Empty.Empty()
    return this.api.request<any>('getAntepostMarketsSC', [request, options])
  }

  getCompetitions(options = {}): Promise<CompetitionsSCResponse> {
    const request = new Empty.Empty()
    return this.api.request<CompetitionsSCResponse>('getCompetitionsSC', [
      request,
      options,
    ])
  }

  getSportsAndMarkets(options = {}): Promise<{
    sportsList: SportsAndMarketsSCResponse[]
  }> {
    const request = new Empty.Empty()
    return this.api.request<{
      sportsList: SportsAndMarketsSCResponse[]
    }>('getSportsMarketSC', [request, options])
  }

  getLiveSportsAndMarkets(options = {}): Promise<{
    sportsList: SportsAndMarketsSCResponse[]
  }> {
    const request = new Empty.Empty()
    return this.api.request<{
      sportsList: SportsAndMarketsSCResponse[]
    }>('getLiveSportsMarketSC', [request, options])
  }

  getPlayerSportsAndMarkets(options = {}): Promise<{
    sportsList: PlayerSportsAndMarketsSCResponse[]
  }> {
    const request = new Empty.Empty()
    return this.api.request<{
      sportsList: PlayerSportsAndMarketsSCResponse[]
    }>('getPlayerSportsMarketSC', [request, options])
  }
}

export default SportsApi
