import React, { useContext } from 'react'
import { Button, Col, Pagination, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import moment from 'moment'
import MarketsListing from 'components/MarketsListing'
import { SportsIcons } from 'components/SportsIcons'
import { Event } from 'utils/sports.types'
import SuspendOverlay from 'components/SuspendOverlay'
import OddCell from 'components/OddCell'
import _ from 'lodash'
import PrematchTableHeader from './PrematchTableHeader'
import styles from './prematchCatalog.module.css'

export default observer(function PrematchCatalog() {
  const { t } = useTranslation()
  const { sports } = useContext(StoreContext)

  const toggleSelectedEvent = (match: Event) => {
    if (sports.selectedMatch?.intKey !== match.intKey) {
      match.allOddsList = _.sortBy(match.allOddsList, ['limit', 'outcomeId'])
      sports.setSelectedEvent(match)
    } else sports.unsetSelectedEvent()
  }

  return (
    <div className={styles.prematchCatalog}>
      <Row>
        <div className={styles.matchHeader}>
          <span>{t('matches')}</span>
        </div>
      </Row>
      <PrematchTableHeader />
      {sports.loaded ? (
        <div className={styles.prematchOffer}>
          {sports.eventsPaginationPrematchCatalog.map((el) => (
            <div key={el.intKey} className={styles.matchRow}>
              {(el.isSuspended || el.betStop) && <SuspendOverlay />}
              <Row gutter={1}>
                <Col span={2}>
                  <div className={styles.matchColumn}>
                    <span>
                      {moment.utc(el.start).local().format('DD/MM HH:mm')}
                    </span>
                  </div>
                </Col>
                <Col span={1}>
                  <div className={styles.matchColumn}>
                    <div className={styles.iconCircle}>
                      {SportsIcons.get(el.sportId)}
                    </div>
                  </div>
                </Col>
                <Col span={5}>
                  <div className={styles.matchColumn}>
                    <span>{el.competitionName}</span>
                  </div>
                </Col>
                <Col span={2}>
                  <div className={styles.matchColumn}>
                    <span>{el.landbaseCode}</span>
                  </div>
                </Col>
                <Col span={5}>
                  <div className={styles.matchColumn}>
                    <span>{el?.home}</span>
                  </div>
                </Col>
                <Col span={5}>
                  <div className={styles.matchColumn}>
                    <span>{el?.away}</span>
                  </div>
                </Col>
                <Col span={1}>
                  {el.allOddsList?.length ? (
                    <OddCell el={el} code="KI 1" />
                  ) : null}
                </Col>
                <Col span={1}>
                  {el.allOddsList?.length ? (
                    <OddCell el={el} code="KI X" />
                  ) : null}
                </Col>
                <Col span={1}>
                  {el.allOddsList?.length ? (
                    <OddCell el={el} code="KI 2" />
                  ) : null}
                </Col>
                <Col span={1}>
                  <div className={styles.matchColumn}>
                    <Button
                      size="small"
                      style={{ fontSize: '10px', padding: '4px' }}
                      onClick={() => toggleSelectedEvent(el)}
                    >
                      {t('all')}
                    </Button>
                  </div>
                </Col>
              </Row>
              {sports.selectedMatch &&
                sports.selectedMatch.intKey === el.intKey && (
                  <MarketsListing event={sports.selectedMatch} live={false} />
                )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noResults}>{t('noResults')}</div>
      )}
      <Pagination
        style={{ marginTop: 'auto' }}
        defaultCurrent={1}
        current={sports.currentPage}
        pageSize={sports.pageSize}
        onChange={sports.handlePageChange}
        showSizeChanger={false}
        total={sports.filteredUpcomingEvents.length}
      />
    </div>
  )
})
