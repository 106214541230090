import React, { createContext } from 'react'
import RootStore from './RootStore'

const rootStore = new RootStore()
const StoreContext = createContext(rootStore)

export const useStore = () => {
  const store = React.useContext(StoreContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

export default StoreContext
