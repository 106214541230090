import React, { useEffect, useState } from 'react'
import { Input, Button, Col, Row, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store/StoreContext'
import { ShortcutEvents } from 'store/ShortcutsStore'
import { PayoutErrors, RollbackErrors } from 'store/SlipApiStore'
import ReviewSlipModal from 'components/ReviewSlipModal'
import { observer } from 'mobx-react'
import styles from '../SlipPayIn/slipPayIn.module.css'

export interface GetSlipType {
  eSlipId: string
  slipId: string
}
export default observer(function GetSlips() {
  const { t } = useTranslation()
  const { slipApi, slip, shortcut } = useStore()

  // State
  const [eSlipId, setESlipId] = useState('')
  const [slipId, setSlipId] = useState('')
  const [modalSlipData, setModalSlipData] = useState<any>(null)

  useEffect(() => {
    if (slipApi?.selectedSlipID) {
      setSlipId(slipApi.selectedSlipID)
    }
  }, [slipApi.selectedSlipID])

  const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false)

  const confirmCancelSlipModal = () => {
    setConfirmCancelModal(false)
  }

  const [confirmPayoutModal, setConfirmPayoutModal] = useState<boolean>(false)

  const payoutCancelSlipModal = () => {
    setConfirmPayoutModal(false)
  }

  const getFastSlip = () => {
    slipApi.getFastTicket(eSlipId).then((data: any) => {
      if (data) {
        slip.loadSlip(data)
        shortcut.setEvent(ShortcutEvents.FOCUS_PAYIN_AMOUNT)
        setESlipId('')
      } else {
        message.error(t('slipNotFound'))
      }
    })
  }

  const onESlipInputChange = (e: any) => {
    setESlipId(e.target.value)
  }

  const getSlipById = () => {
    slipApi.getTicketByShortId(slipId).then((data: any) => {
      if (data) {
        slip.loadSlip(data)
        slip.dataInfoLoading = false
        shortcut.setEvent(ShortcutEvents.FOCUS_PAYIN_AMOUNT)
        setSlipId('')
      } else {
        message.error(t('slipNotFound'))
      }
    })
  }

  const getSlipStatusById = () => {
    slipApi.getTicketByShortId(slipId).then((data: any) => {
      if (data) {
        setModalSlipData(data)
      } else {
        message.error(t('slipNotFound'))
      }
    })
  }

  const cancelSlip = async () => {
    setConfirmCancelModal(false)
    if (slipId) {
      try {
        await slipApi.rollBack(slipId)
        message.success(t('rollBackSuccess'))
        setSlipId('')
      } catch (e) {
        if (e === RollbackErrors.SLIP_NOT_FOUND) {
          message.error(t('slipNotFound'))
        } else if (e === RollbackErrors.SLIP_STARTED) {
          message.error(t('rollBackErrorSlipStarted'))
        } else if (e === RollbackErrors.INSUFFICIENT_PRIVILEGES) {
          message.error(t('no_privileges'))
        } else {
          // message.error(t('somethingWentWrong'))
          message.error(t('slipNotFound'))
        }
      }
    }
  }

  const displayPayoutError = (e: any) => {
    switch (e) {
      case PayoutErrors.SLIP_NOT_RESOLVED:
        message.error(t('slipNotResolved'))
        break
      case PayoutErrors.SLIP_PAID:
        message.error(t('slipPaid'))
        break
      case PayoutErrors.SLIP_NOT_PASSED:
        message.error(t('slipNotPassed'))
        break
      case PayoutErrors.SLIP_NOT_FOUND:
        message.error(t('slipNotFound'))
        break
      default:
        message.error(t('slipNotFound'))
      // message.error(t('somethingWentWrong'))
    }
  }

  const payOut = async () => {
    setConfirmPayoutModal(false)
    if (slipId) {
      try {
        console.log('slipId', slipId)
        await slipApi.payOut(slipId)
        message.success(t('slipPayotSuccess'))
        setSlipId('')
        return Promise.resolve()
      } catch (e) {
        displayPayoutError(e)
        return Promise.reject()
      }
    }
    return Promise.reject()
  }

  const onSlipInputChange = (e: any) => {
    setSlipId(e.target.value)
  }

  const closeModal = () => {
    setModalSlipData(null)
  }

  const accept = async () => {
    try {
      await payOut()
      closeModal()
    } catch (e) {
      displayPayoutError(e)
    }
  }

  return (
    <Row>
      <ReviewSlipModal
        data={modalSlipData}
        onCancel={closeModal}
        onConfirm={accept}
        onClose={closeModal}
        visible={!!modalSlipData}
        loading={false}
        modalType="STATUS"
      />
      <Row
        gutter={8}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <Col span={9}>
          <label htmlFor="eSlipId">{t('eSlipId')}</label>

          <Input
            name="eSlipId"
            id="eSlipId"
            onChange={onESlipInputChange}
            value={eSlipId}
            autoComplete="off"
            onPressEnter={getFastSlip}
          />
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={getFastSlip}>
            {t('submit')}
          </Button>
        </Col>
        <Col span={9}>
          <label htmlFor="slipId">{t('slipId')}</label>
          <Input
            id="slipId"
            name="slipId"
            value={slipId}
            autoComplete="off"
            onChange={onSlipInputChange}
          />
        </Col>
      </Row>
      <Row gutter={8} style={{ marginTop: 15 }}>
        <Col>
          <Button onClick={getSlipById} disabled={!slipId}>
            {t('preview')}
          </Button>
        </Col>
        <Col>
          <Button onClick={getSlipStatusById} disabled={!slipId}>
            {t('checkStatus')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setConfirmPayoutModal(true)
            }}
            disabled={!slipId}
          >
            {t('payOut')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setConfirmCancelModal(true)
            }}
            disabled={!slipId}
          >
            {t('cancelSlip')}
          </Button>
        </Col>
      </Row>
      <Modal
        onCancel={confirmCancelSlipModal}
        visible={confirmCancelModal}
        footer={false}
        closable
        width={600}
        title={
          <div className={styles.errorModalHeader}>{t('cancelSlipModal')}</div>
        }
      >
        <div className={styles.errorModalFooter}>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: 10 }}
            onClick={cancelSlip}
          >
            {t('yes')}
          </Button>
          <Button size="small" danger onClick={confirmCancelSlipModal}>
            {t('no')}
          </Button>
        </div>
      </Modal>
      <Modal
        onCancel={payoutCancelSlipModal}
        visible={confirmPayoutModal}
        footer={false}
        closable
        width={600}
        title={
          <div className={styles.errorModalHeader}>{t('payoutSlipModal')}</div>
        }
      >
        <div className={styles.errorModalFooter}>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: 10 }}
            onClick={payOut}
          >
            {t('yes')}
          </Button>
          <Button size="small" danger onClick={payoutCancelSlipModal}>
            {t('no')}
          </Button>
        </div>
      </Modal>
    </Row>
  )
})
