import React from 'react'
import { observer } from 'mobx-react'
import { Event } from 'utils/sports.types'
import SoccerTemplate from 'components/Live/LiveTemplates/SoccerTemplate'

interface LiveMatchProps {
  events: Event[]
}

export default observer(function LiveMatch({ events }: LiveMatchProps) {
  return (
    <div>
      {events.map((event) => (
        <SoccerTemplate event={event} key={event.intKey} />
      ))}
    </div>
  )
})
