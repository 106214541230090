import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './noSelection.module.css'

interface NoSelectionProps {
  translation: string
}

export default function NoSelection({ translation }: NoSelectionProps) {
  const { t } = useTranslation()

  return <div className={styles.noSelection}>{t(translation)}</div>
}
