import SportBettingHome from 'pages/SportBettingHome'
import React from 'react'
import OnlineHome from 'pages/OnlineHome'
import SbClientHome from 'pages/SbClientHome'
import GreekoHome from 'pages/Greeko'
import CashFlow from 'pages/CashFlow'
import { RouteType } from './MainRoutes'

const ClientRoutes: RouteType[] = [
  {
    path: '/sport-betting',
    exact: false,
    component: <SportBettingHome />,
    auth: true,
    translationKey: 'sportBetting',
    showInMenu: true,
  },
  {
    path: '/greeko',
    exact: false,
    component: <GreekoHome />,
    auth: true,
    translationKey: 'greeko',
    showInMenu: true,
  },
  {
    path: '/sb-client',
    exact: false,
    component: <SbClientHome />,
    auth: true,
    translationKey: 'sbClientTab',
    showInMenu: true,
  },
  {
    path: '/cash-flow',
    exact: false,
    component: <CashFlow />,
    auth: true,
    translationKey: 'cashFlow',
    showInMenu: true,
  },
  {
    path: '/online',
    exact: false,
    component: <OnlineHome />,
    auth: true,
    translationKey: 'online',
    showInMenu: true,
  },
]
export default ClientRoutes
