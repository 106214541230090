import { Input } from 'antd'
import { observer } from 'mobx-react'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { ShortcutEvents } from 'store/ShortcutsStore'
import { SlipGroup } from 'store/SlipStore'
import StoreContext from 'store/StoreContext'
import { handleKeyEvent, Keycodes } from 'utils/keyboardEvents'
import styles from './slipReview.module.css'
import SystemInput from './SystemInput'

interface GroupSystemProps {
  group: SlipGroup
  events: any[]
  system: Array<number | null>
  name: any
}

const GroupSystem = ({ group, events, system, name }: GroupSystemProps) => {
  const { shortcut, slip } = useContext(StoreContext)
  const [eventsCount, setEventsCount] = useState(events.length)
  slip.setEventsCountInput(eventsCount)

  // eslint-disable-next-line
  const systemInputs = [useRef(null), useRef(null), useRef(null)]
  const eventsCountInput = useRef(null)

  const focus = (input: any) => {
    // @ts-ignore
    input?.current?.select()
  }

  useEffect(() => {
    if (shortcut.event === ShortcutEvents.FOCUS_SYSTEM) {
      focus(systemInputs[0])
      shortcut.clear()
    }

    if (shortcut.event === ShortcutEvents.FOCUS_LAST_GROUP) {
      if (slip.groups.length === name) {
        focus(systemInputs[0])
        shortcut.clear()
      }
    }
    if (shortcut.event === ShortcutEvents.CHANGED_COUNT) {
      if (slip.groups.length === name) {
        focus(eventsCountInput)
        shortcut.clear()
      }
    }
  }, [shortcut.event, shortcut, systemInputs, name, system, slip.groups.length])

  useEffect(() => {
    if (events.length !== eventsCount) {
      setEventsCount(events.length)
    }
  }, [events.length]) // eslint-disable-line

  const onEventsCountChange = (e: any) => {
    const value = Number(e.target.value)
    const isNumber = !Number.isNaN(value)
    // @ts-ignore
    // const highestSystem = Math.max(...system.filter((s) => s !== null))
    const isValid = value <= group.events.length
    if (e.target.value === '' || (isNumber && isValid)) {
      setEventsCount(e.target.value)
      shortcut.setEvent(ShortcutEvents.CHANGED_COUNT)
    }
  }

  const onEventsKeyDown = (e: any) => {
    handleKeyEvent(e, {
      [Keycodes.ENTER]: () => {
        const value = Number(e.target.value)
        // @ts-ignore
        const isValid = value <= group.events.length && value >= system[0]
        // @ts-ignore
        if (!isValid) return
        if (!eventsCount) return
        if (eventsCount < events.length) {
          slip.moveLastEvents(group, events.length - eventsCount)
          shortcut.setEvent(ShortcutEvents.FOCUS_LAST_GROUP)
        } else if (eventsCount === events.length) {
          slip.setEventsCountInput(value)
          shortcut.setEvent(ShortcutEvents.FOCUS_PAYIN_AMOUNT)
        } else {
          slip.setEventsCountInput(value)
          shortcut.setEvent(ShortcutEvents.FOCUS_PAYIN_AMOUNT)
        }
      },
    })
  }
  const onLeave = (e: any) => {
    const value = Number(e.target.value)
    slip.setEventsCountInput(value)
  }

  const focusSystemInput = () => {
    focus(eventsCountInput)
  }

  const onRemove = (index: number) => {
    focus(systemInputs[index - 1])
  }

  const renderInputSeparator = (inputPosition: number) => {
    const separatorStyles = {
      marginLeft: 4,
      display: system.length >= inputPosition + 1 ? 'inline' : 'none',
    }

    if (inputPosition === 0) {
      return null
    }

    return <span style={separatorStyles}>+</span>
  }

  // console.log('systemInputs', toJS(system))
  // console.log('systemInputs', toJS(group))
  return (
    <div className={styles.systemInputs}>
      {systemInputs.map((input: any, i: number) => (
        // eslint-disable-next-line
        <Fragment key={i}>
          {renderInputSeparator(i)}
          <SystemInput
            ref={input}
            group={group}
            value={system.length > i ? system[i] : null}
            onRemove={onRemove}
            visible={system.length >= i + 1}
            eventsCount={eventsCount}
            onEnter={focusSystemInput}
            focusNext={() => {
              focus(systemInputs[i + 1])
            }}
            index={i}
          />
        </Fragment>
      ))}
      <span style={{ marginLeft: 4, marginRight: 4 }}>/</span>
      <Input
        ref={eventsCountInput}
        className={styles.systemInput}
        value={eventsCount}
        size="small"
        onChange={onEventsCountChange}
        onKeyDown={onEventsKeyDown}
        onBlur={onLeave}
      />
    </div>
  )
}

export default observer(GroupSystem)
