import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import { AutoComplete, Col, Row, Select as AntdSelect } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer, Observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { SortDropdown } from 'store/SportsStore'

export interface FilterType {
  filters: {
    sportId: number
    locationId: number
    competitionId: number
  }
}

interface PrematchCatalogFiltersProps {
  eventType: string
}

const { Option } = Select

export default observer(function PrematchCatalogFilters({
  eventType,
}: PrematchCatalogFiltersProps) {
  const { t } = useTranslation()
  const { sports } = useContext(StoreContext)

  const getInitFilterValue = (eventType: string) => {
    if (eventType === 'players') {
      return sports.filtersPlayers
    }
    if (eventType === 'outright') {
      return sports.filtersOutright
    }
    return sports.filtersPremach
  }
  const getLocationOptions = (sportId: number) => {
    const locationsList = sports.filterConfig?.find(
      (o: any) => o.id === sportId
    )?.locations
    return locationsList?.map((el: any) => ({
      ...el,
      label: el.name,
      value: el.name,
    }))
  }

  const onBetCodeFocus = (e: any) => {
    e.target.select()
  }

  const getSportsOptions = () => {
    return sports.filterConfig?.map((el: any) => ({
      ...el,
      label: el.name,
      value: el.name,
    }))
  }

  const getSportId = (sportName: string) => {
    return (
      sports.filterConfig?.find(
        (o: any) => o.name === sportName
        // @ts-ignore
      )?.id || 0
    )
  }

  const getSportName = (id: number) => {
    return (
      sports.filterConfig?.find(
        (o: any) => o.id === id
        // @ts-ignore
      )?.name || 'Fudbal'
    )
  }

  const [locations, setLocations] = useState(
    getLocationOptions(getInitFilterValue(eventType).filters.sportId)
  )

  const [sportValue, setSportValue] = useState(
    getSportName(getInitFilterValue(eventType).filters.sportId)
  )

  const getCompetitionsOptions = () => {
    return sports.competitionsList?.map((el: any) => ({
      id: el.id,
      label: el.name,
      value: el.name,
    }))
  }

  const [competitions, setCompetitions] = useState(getCompetitionsOptions())

  useEffect(() => {
    setCompetitions(getCompetitionsOptions())
    // eslint-disable-next-line
  }, [sports.competitionsList])

  const [locationValue, setLocationValue] = useState(
    locations?.find(
      (o: any) => o.id === getInitFilterValue(eventType).filters.locationId
      // @ts-ignore
    )?.name || 'Sve'
  )

  const [competitionValue, setCompetitionValue] = useState(
    competitions?.find(
      (o: any) => o.id === getInitFilterValue(eventType).filters.competitionId
      // @ts-ignore
    )?.name || 'Sve'
  )

  useEffect(() => {
    const locationsList = getLocationOptions(
      getInitFilterValue(eventType).filters.sportId
    )
    if (locationsList) {
      locationsList.unshift({
        id: 0,
        name: 'Sve',
        competitions: null,
        label: 'Sve',
        value: 'Sve',
      })
    }

    setLocations(locationsList)
    setLocationValue(
      locationsList?.find(
        (o: any) => o.id === getInitFilterValue(eventType).filters.locationId
        // @ts-ignore
      )?.name
    )
    // eslint-disable-next-line
  }, [getInitFilterValue(eventType).filters.sportId, sports.filterConfig])

  useEffect(() => {
    /* const locations = getLocationOptions(
      getInitFilterValue(eventType).filters.sportId
    ) */
    setCompetitionValue(
      sports.competitionsList?.find(
        (o: any) => o.id === getInitFilterValue(eventType).filters.competitionId
        // @ts-ignore
      )?.name || 'Sve'
    )
    // eslint-disable-next-line
  }, [locationValue, sports.filterConfig])

  // @ts-ignore
  return (
    <Formik
      initialValues={getInitFilterValue(eventType)}
      enableReinitialize
      onSubmit={(values, options) => {
        if (eventType === 'players') {
          sports.setPlayerFilters(values)
        } else if (eventType === 'outright') {
          sports.setOutrightFilters(values)
        } else {
          sports.setFilters(values)
        }
        options.setSubmitting(false)
      }}
    >
      {({ setFieldValue }) => (
        <Observer>
          {() => (
            <Form>
              <Row gutter={16}>
                <Col span={5}>
                  <FormItem
                    name="filters.sportId"
                    label={t('sport')}
                    labelCol={{ span: 24 }}
                    style={{ marginBottom: '20px' }}
                  >
                    <AutoComplete
                      options={getSportsOptions()}
                      onFocus={onBetCodeFocus}
                      onSelect={(value, option) =>
                        setFieldValue('filters.sportId', option.id)
                      }
                      onChange={(data: any) => {
                        if (data !== '') {
                          setFieldValue('filters.sportId', 0)
                        }
                        getInitFilterValue(eventType).filters.sportId =
                          getSportId(data)
                        setSportValue(data)
                      }}
                      value={sportValue}
                      filterOption={(inputValue, option) => {
                        return (
                          (option?.label as string)
                            ?.toLowerCase()
                            .indexOf(inputValue?.toLowerCase()) !== -1
                        )
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={5}>
                  <FormItem
                    name="filters.locationId"
                    label={t('country')}
                    labelCol={{ span: 24 }}
                    style={{ marginBottom: '20px' }}
                  >
                    <AutoComplete
                      options={locations}
                      onFocus={onBetCodeFocus}
                      onSelect={(value, option) =>
                        setFieldValue('filters.locationId', option.id)
                      }
                      onChange={(data: any) => {
                        if (data === '') {
                          setFieldValue('filters.locationId', 0)
                        }
                        setLocationValue(data)
                        getInitFilterValue(eventType).filters.competitionId = 0
                      }}
                      value={locationValue}
                      filterOption={(inputValue, option) => {
                        return (
                          (option?.label as string)
                            ?.toLowerCase()
                            .indexOf(inputValue?.toLowerCase()) !== -1
                        )
                      }}
                    />
                  </FormItem>
                </Col>
                {eventType !== 'outright' && (
                  <Col span={5}>
                    <FormItem
                      name="filters.competitionId"
                      label={t('competition')}
                      labelCol={{ span: 24 }}
                      style={{ marginBottom: '20px' }}
                    >
                      <AutoComplete
                        options={competitions}
                        onFocus={onBetCodeFocus}
                        onSelect={(value, option) =>
                          setFieldValue('filters.competitionId', option.id)
                        }
                        onChange={(data: any) => {
                          if (data === '') {
                            setFieldValue('filters.competitionId', 0)
                          }
                          setCompetitionValue(data)
                        }}
                        value={competitionValue}
                        filterOption={(inputValue, option) => {
                          return (option?.label as string)
                            .toLowerCase()
                            .includes(inputValue)
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                <Col span={2}>
                  <SubmitButton style={{ marginTop: '40px' }}>
                    {t('filter')}
                  </SubmitButton>
                </Col>
                <Col span={3} />
                {eventType !== 'outright' && (
                  <Col span={4}>
                    <FormItem
                      name="sort"
                      label={t('sort')}
                      labelCol={{ span: 24 }}
                      style={{ marginBottom: '20px' }}
                    >
                      <AntdSelect
                        onChange={
                          eventType === 'players'
                            ? sports.setSortPlayers
                            : sports.setSort
                        }
                        value={sports.sort}
                      >
                        {SortDropdown.map((el, index) => (
                          <Option key={el.name} value={index}>
                            {el.name}
                          </Option>
                        ))}
                      </AntdSelect>
                    </FormItem>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Observer>
      )}
    </Formik>
  )
})
