import React from 'react'
import OnlineDeposit from 'components/OnlineDeposit'
import OnlineWithdraw from 'components/OnlineWithdraw'
import { RouteType } from './MainRoutes'

const OnlineRoutes: RouteType[] = [
  {
    path: '/online',
    exact: true,
    component: <OnlineDeposit />,
    auth: true,
    translationKey: 'deposit',
    showInMenu: false,
  },
  {
    path: '/online/withdraw',
    exact: true,
    component: <OnlineWithdraw />,
    auth: true,
    translationKey: 'withdraw',
    showInMenu: true,
  },
]
export default OnlineRoutes
