import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './sectionContainer.module.css'

export interface ContainerProps {
  translation: string
}

export const SectionContainer: React.FC<ContainerProps> = ({
  translation,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.title}>{t(translation)}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
