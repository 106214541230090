import { AxiosResponse, Method } from 'axios'
import { makeObservable, observable } from 'mobx'
import moment from 'moment'
import { http } from 'utils/request'
import BaseStore from '../BaseStore'
import RootStore from '../RootStore'

export enum AsyncStates {
  NONE = 'none',
  DONE = 'done',
  PENDING = 'pending',
  ERROR = 'error',
  DATA_READY = 'data_ready',
  FORBIDDEN = 'forbidden',
}
export interface NetworkConfigType {
  path: string
  secured: boolean
}
export interface CacheIntervalType {
  unit: 'minutes' | 'seconds'
  value: number
}
export type HTTPMethods = 'get' | 'delete' | 'post'
export class NetworkModels<V> extends BaseStore {
  callStatus: AsyncStates = AsyncStates.NONE
  path: string
  secured: boolean
  cachedResult: AxiosResponse<V> | null = null
  cacheInterval: CacheIntervalType | null = null
  lastCall: moment.Moment | null = null
  constructor(rootStore: RootStore, { path, secured }: NetworkConfigType) {
    super(rootStore)
    this.path = path
    this.secured = secured
    makeObservable(this, {
      callStatus: observable,
    })
  }

  call = async <T>(method: Method, data?: T, getParams?: string) => {
    return await this.customCall(
      method,
      `${this.path || ''}${getParams ? getParams : ''}`,
      data
    )
  }
  customCall = async <T>(method: Method, path: string, data: T) => {
    this.callStatus = AsyncStates.PENDING
    let result: AxiosResponse<V>

    if (this.cached && this.cachedResult) {
      result = this.cachedResult
    } else {
      try {
        result = await http.request({
          url: path,
          method,
          data: {
            ...data,
            /* axios: {
              unsecured: !this.secured,
            }, */
          },
        })
      } catch (error) {
        if (error.status === 401) {
          this.callStatus = AsyncStates.ERROR
          this.rootStore.user.logout()
          // return {
          //   mapResult: () => {
          //     this.callStatus = AsyncStates.ERROR
          //   },
          // }
        }
        if (error.status === 400) {
          this.callStatus = AsyncStates.ERROR
          // return {
          //   mapResult: () => {
          //     this.callStatus = AsyncStates.ERROR
          //   },
          // }
        }
        // return {
        //   mapResult: () => {
        //     this.callStatus = AsyncStates.ERROR
        //   },
        // }
        throw new Error(error.data.detail)
      }
    }

    // if (
    //   result.data.status === 403 &&
    //   result.data.message === 'USER_HAS_NO_ACCESS_RIGHTS'
    // ) {
    //   return {
    //     mapResult: () => {
    //       this.callStatus = AsyncStates.FORBIDDEN
    //     },
    //   }
    // }

    this.callStatus = AsyncStates.DONE
    if (this.cached) {
      this.lastCall = moment()
      this.cachedResult = result
    }
    return {
      ...result,
      mapResult: (input: (arg: AxiosResponse<V>) => boolean) => {
        const inpRes = input(result)
        if (inpRes) {
          this.callStatus = AsyncStates.DATA_READY
        } else {
          this.callStatus = AsyncStates.ERROR
        }
      },
    }
  }
  get callInProgress() {
    return this.status === AsyncStates.PENDING
  }
  get callError() {
    return this.status === AsyncStates.ERROR
  }
  get callDone() {
    return this.status === AsyncStates.DONE
  }
  get dataReady() {
    return this.status === AsyncStates.DATA_READY
  }
  get status() {
    return this.callStatus || AsyncStates.NONE
  }

  get forbidden() {
    return this.callStatus === AsyncStates.FORBIDDEN
  }
  get cached() {
    if (this.cacheInterval && this.lastCall) {
      const current = moment()
      const diff = current.diff(this.lastCall, this.cacheInterval.unit)
      if (diff <= this.cacheInterval.value) {
        return true
      }
    }
    return false
  }
}
