import isObject from 'lodash/isObject'
import _ from 'lodash'
import { SportsIcons } from '../components/SportsIcons'

export function checkIfExistsOrIsZero(resValue: any) {
  if (resValue || resValue?.toString() === '0') {
    return resValue.toString()
  }
  return '-'
}

export function getCurrentServis(event: any, home: number) {
  if (home.toString() === '0') {
    if (event.currentPhase.S === 'H') return SportsIcons.get(event.sportId)
  } else if (event.currentPhase.S === 'A') return SportsIcons.get(event.sportId)
  return ' '
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function factorial(num: number) {
  if (num === 0 || num === 1) return 1
  // eslint-disable-next-line no-plusplus
  for (let i = num - 1; i >= 1; i--) {
    num *= i
  }
  return num
}

function parseType(slipGroup: any) {
  let total
  let elements
  if (slipGroup.indexOf('+') >= 0) {
    elements = slipGroup
      .split('/')[0]
      .trim()
      .split('+')
      .map((x: string) => {
        return parseInt(x, 10)
      })
    // eslint-disable-next-line prefer-destructuring
    total = parseInt(
      slipGroup.split('/')[slipGroup.split('/').length - 1].trim(),
      10
    )
  } else {
    elements = [parseInt(slipGroup.split('/')[0].trim(), 10)]
    total = parseInt(slipGroup.split('/')[1].trim(), 10)
  }
  return { elements, total }
}

function mathOver(k: number, n: number) {
  return factorial(n) / factorial(n - k) / factorial(k)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function combinationsNumber(slipGroups: any) {
  let totalSum = 1
  slipGroups.forEach((slipGroup: any) => {
    let groupTotal = 0
    const { elements, total } = parseType(slipGroup.system)
    elements.forEach((element: any) => {
      if (element === total) {
        groupTotal += 1
      } else {
        groupTotal += mathOver(element, total)
      }
    })
    totalSum *= groupTotal
  })
  return totalSum
}

export function sortOddsByLimitOrOutcomeId(odds: any[]) {
  /* console.log('odds', toJS(odds))
  if (odds.length && !odds[0].limit)
    return odds.slice().sort((a, b) => a.outcome_id - b.outcome_id)

  const mapLimits = odds.reduce((groupOdds, odd) => {
    const { limit } = odd
    groupOdds[limit] = groupOdds[limit] ?? []
    groupOdds[limit].push(odd)
    return groupOdds
  }, {})
  const limits = []
  for (const key in mapLimits) {
    if (Object.keys(mapLimits).length && Object.keys(mapLimits).length === 1) {
      limits.push(
        ...mapLimits[key].sort((a: any, b: any) => a.outcome_id - b.outcome_id)
      )
    } else {
      limits.push(...mapLimits[key])
    }
  } */
  return _.orderBy(odds, 'limit', 'asc')
}

export const mapCurrentResult = (data: any) => {
  const currentResultData = data
  const mappedCurrentResult = currentResultData.reduce(
    (acc: any, result: any) => {
      const resultValue = result[1].listValue.valuesList.map(
        (value: any) => value.numberValue
      )
      return { ...acc, [result[0]]: resultValue }
    },
    {}
  )
  return mappedCurrentResult
}

export const mapStructPeriodAndCurrentResult = (data: any) => {
  let periodData = {}
  let timeData = {}
  data.forEach((periodArray: any) => {
    for (const key in periodArray[1]) {
      if (periodArray[0] === 'B') {
        periodData = {
          ...periodData,
          [periodArray[0]]: periodArray[1].boolValue,
        }
      }
      if (periodArray[0] === 'N') {
        periodData = {
          ...periodData,
          [periodArray[0]]: periodArray[1].stringValue,
        }
      }

      if (isObject(periodArray[1][key])) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        periodArray[1][key].fieldsMap.forEach((time: any) => {
          for (const key in time[1]) {
            if (time[1][key]) {
              const resultValue = time[1][key]?.valuesList?.map(
                (value: any) => {
                  return value.numberValue
                }
              )
              timeData = {
                ...timeData,
                [time[0]]: resultValue,
              }
            }
          }
          if (time[0] === 'N' || time[0] === 'M' || time[0] === 'S') {
            timeData = {
              ...timeData,
              [time[0]]: time[1].stringValue,
            }
          }
        })
        periodData = {
          ...periodData,
          [periodArray[0]]: timeData,
        }
        return
      }
      if (periodArray[1][key]) {
        periodData = {
          ...periodData,
          [periodArray[0]]: periodArray[1][key],
        }
      }
    }
    // console.log(period.toObject());
  })

  return periodData
}
