import { action, autorun, computed, makeObservable, observable } from 'mobx'
import { message } from 'antd'
import BaseStore from 'store/BaseStore'
import { Event, Odd, Outcome } from 'utils/sports.types'
import SlipApi from 'utils/slipApi'
import debounce from 'utils/debounce'
import { bettingRules } from 'utils/bettingRules'
import { maxSlipOdd } from 'utils/place-bet-errors'
import omit from 'lodash/omit'
import i18n from 'i18n'
import RootStore from './RootStore'
import { SingleTicket } from './models/SlipModels'
import styles from '../components/SlipPayIn/slipPayIn.module.css'
import { combinationsNumber } from '../utils/generalHelpers'

export interface SlipOdd extends Odd {
  printCode: string
  int_key: number
  odd_value: number
}

export interface SlipEvent extends Event {
  selectedOdds: SlipOdd[]
}

export interface SlipGroup {
  system: Array<number | null>
  events: any[]
}

export const MAX_LINE_GROUPS_COUNT = 3

export default class SlipStore extends BaseStore {
  api: SlipApi
  groups: SlipGroup[] = []
  eventsCountInput: any = 0
  amount: number | null = null

  numberOfSlipsSubmitted: number = 0
  totalOfSlipsSubmitted: number = 0

  numberOfSlipsSubmittedGreeko: number = 0
  totalOfSlipsSubmittedGreeko: number = 0
  depPayInaAmount: number = 0

  keepMatches: boolean = false
  lockEvent: boolean = false
  sendToManualApproval: boolean = false
  manualApprovalReason: string = ''
  manualUsername: string = ''
  paymentInfo: any = {}
  validationRules: any = {}
  bonusConfig: any = {}
  errors: any = {}
  slipReport: any = this.loadReportData()
  maxOddReached: boolean = false
  disableManualApproval: boolean = false
  dataInfoLoading: boolean = false
  selectedOdd: any = null
  highligtedOdds: { [key: string]: any } = {}

  constructor(rootStore: RootStore) {
    super(rootStore)

    this.api = new SlipApi()
    this.getValidationRules()
    this.getSlipBonusConfigData()

    makeObservable(this, {
      groups: observable,
      eventsCountInput: observable,
      dataInfoLoading: observable,
      selectedOdd: observable,
      amount: observable,
      numberOfSlipsSubmitted: observable,
      totalOfSlipsSubmitted: observable,
      numberOfSlipsSubmittedGreeko: observable,
      totalOfSlipsSubmittedGreeko: observable,
      depPayInaAmount: observable,
      errors: observable,
      maxOddReached: observable,
      keepMatches: observable,
      lockEvent: observable,
      sendToManualApproval: observable,
      manualApprovalReason: observable,
      manualUsername: observable,
      paymentInfo: observable,
      slipReport: observable,
      disableManualApproval: observable,
      slipsCount: computed,
      totalPayInAmount: computed,
      loadSlip: action,
      setPaymentInfo: action,
      setKeepMatches: action,
      setLockEvent: action,
      reset: action,
      resetCounts: action,
      updateReportData: action,
      updateReport: action,
      resetReport: action,
      setAmount: action,
      validateAmount: action,
      validateCombinations: action,
      validateEventsLength: action,
      validateEventOdds: action,
      addEvent: action,
      clearAllSlips: action,
      updateEvent: action,
      overrideEvent: action,
      updateSystem: action,
      removeSystem: action,
      addEmptySystem: action,
      removeOdd: action,
      setSelectedOdd: action,
      removeEvent: action,
      createEmptyGroup: action,
      moveEventToGroup: action,
      moveLastEvents: action,
      toggleManualApproval: action,
      setManualApprovalReason: action,
      setManualUsername: action,
      addEventOdd: action,
      slipData: computed,
      hasErrors: computed,
      matchNum: computed,
      placeBetDisabled: computed,
      validateOdd: action,
      toggleOdd: action,
      findLiveEvent: action,
      highligtedOdds: observable,
      addHighlightedOdd: action,
      removeHighlightedOdd: action,
      clearOdds: action,
    })

    autorun(() => {
      if (this.groups.length) {
        const isSystem = this.checkIfTicketIsSystemTicket(this.slipData)
        if (isSystem) {
          if (
            combinationsNumber(this.slipData.slip_groups) <
            this.validationRules.max_number_of_combinations_system_slip
          ) {
            this.getSlipPaymentInfo(this.slipData)
          } else {
            message.error({
              duration: 3,
              content: i18n.t('maxCombination', {
                combinations:
                  this.validationRules.max_number_of_combinations_system_slip,
              }),
              className: styles.errorMsg,
            })
          }
        } else if (this.slipData.slip_groups[0]?.events?.length > 0) {
          const data = this.updateNonSystemPaymentInfo(this.slipData)
          this.setPaymentInfo(data)
        }
      } else {
        this.paymentInfo = {}
      }
    })
  }

  setEventsCountInput(ref: any) {
    this.eventsCountInput = ref
  }

  checkIfTicketIsSystemTicket(slip: any) {
    if (slip.slip_groups.length > 1) {
      return true
    }
    if (
      slip.slip_groups[0]?.events?.length === 0 ||
      slip.slip_groups[0]?.system === ''
    ) {
      return false
    }
    const system = slip.slip_groups[0]?.system.split('/')
    if (system[0]?.trim() !== system[1]?.trim()) {
      return true
    }

    const systemGroup = slip.slip_groups[0]
    for (let i = 0; i < systemGroup?.events?.length; i += 1) {
      if (systemGroup.events[i].odds.length > 1) {
        return true
      }
    }
    return false
  }

  updateNonSystemPaymentInfo = (slip: any) => {
    const systemGroup = slip.slip_groups[0]
    const numberOfEvents = systemGroup?.events?.length
    let aboveMinOddValueNumber = 0
    let maxOddValue = systemGroup.events[0].odds[0].odd_value
    let minOddValue = systemGroup.events[0].odds[0].odd_value
    let totalOddValue = 1
    for (let i = 0; i < numberOfEvents; i += 1) {
      totalOddValue *= systemGroup.events[i].odds[0].odd_value
      if (systemGroup.events[i].odds[0].odd_value > maxOddValue) {
        maxOddValue = systemGroup.events[i].odds[0].odd_value
      }
      if (systemGroup.events[i].odds[0].odd_value < minOddValue) {
        minOddValue = systemGroup.events[i].odds[0].odd_value
      }
      if (
        systemGroup.events[i].odds[0].odd_value >=
        this.bonusConfig.min_odd_value
      ) {
        aboveMinOddValueNumber += 1
      }
    }
    let bonusPercentage = 0
    if (this.bonusConfig.num_of_games[aboveMinOddValueNumber]) {
      bonusPercentage = this.bonusConfig.num_of_games[aboveMinOddValueNumber]
    }

    let maxWinningAmount = slip.amount * totalOddValue
    const maxBonusAmount = (bonusPercentage / 100) * maxWinningAmount
    if (maxWinningAmount > this.validationRules.maximum_winning_amount) {
      maxWinningAmount = this.validationRules.maximum_winning_amount
    }
    if (totalOddValue > this.validationRules.max_odd_value) {
      totalOddValue = this.validationRules.max_odd_value
    }

    return {
      bonus_percent: bonusPercentage,
      max_bonus_amount: maxBonusAmount,
      max_odds_value: totalOddValue,
      max_winning_amount: maxWinningAmount,
      min_bonus_amount: maxBonusAmount,
      min_odds_value: totalOddValue,
      min_winning_amount: maxWinningAmount,
      number_of_combinations: 1,
    }
  }

  async getSlipBonusConfigData() {
    try {
      const { data } = await this.api.getSlipBonusConfig()
      this.bonusConfig = data
    } catch (err: any) {
      console.error('Error while trying to fetch bonusConfig')
    }
  }

  get isHighlighted() {
    return (eventIntKey: number, intKey: number) =>
      this.highligtedOdds[`e-${eventIntKey}`]
        ? this.highligtedOdds[`e-${eventIntKey}`].includes(intKey)
        : false
  }

  addHighlightedOdd = (eventIntKey: number, oddIntKey: any) => {
    this.highligtedOdds[`e-${eventIntKey}`] = this.highligtedOdds[
      `e-${eventIntKey}`
    ]
      ? [...this.highligtedOdds[`e-${eventIntKey}`], oddIntKey]
      : [oddIntKey]
  }

  removeHighlightedOdd = (eventIntKey: number) => {
    if (this.highligtedOdds[`e-${eventIntKey}`]) {
      const tempObj = omit(this.highligtedOdds, [`e-${eventIntKey}`])
      this.highligtedOdds = tempObj
    }
  }

  removeSingleHighlightedOdd = (eventIntKey: number, oddIntKey: any) => {
    if (this.highligtedOdds[`e-${eventIntKey}`]) {
      this.highligtedOdds[`e-${eventIntKey}`] = this.highligtedOdds[
        `e-${eventIntKey}`
      ].filter((oddIK: number) => oddIntKey !== oddIK)
    }
  }

  clearOdds = () => {
    this.highligtedOdds = {}
  }

  loadReportData() {
    const report = localStorage.getItem('slipReport')
    const payInReportData = localStorage.getItem('payInReportData')

    if (payInReportData) {
      const payInReportDataObject = JSON.parse(payInReportData)
      this.numberOfSlipsSubmitted = payInReportDataObject.numberOfSlipsSubmitted
      this.totalOfSlipsSubmitted = payInReportDataObject.totalOfSlipsSubmitted
    }

    if (!report) return new Map()

    const reportObject = JSON.parse(report)
    return new Map(Object.entries(reportObject))
  }

  async getValidationRules() {
    const { data } = await this.api.getValidationRules()
    const rules: any = {}

    for (const key in data) {
      rules[key] = data[key].rule_value
    }

    this.validationRules = rules
  }

  getSystemString(system: Array<any>, events: SlipEvent[]) {
    if (!system.length) return ''

    const combinations = system.reduce(
      (all: string, combination: number, i: number) => {
        if (i === 0) return `${combination} `
        return `${all} + ${combination} `
      },
      ''
    )

    return `${combinations} / ${events.length}`
  }

  returnOddsBasedOnEventType(event: any) {
    let odds: any = []
    if (event.event_type === 'D' || event.event_type === 'L') {
      odds = event.selectedOdds.map((odd: any) => ({
        event_type: 'D',
        int_key: odd.intKey || odd.int_key,
        odd_value:
          (odd.oddValue / 100).toFixed(2) || (odd.odd_value / 100).toFixed(2),
        limit: odd.limit,
      }))
      return odds
    }
    if (event.event_type === 'P') {
      odds = event.selectedOdds.map((odd: any) => ({
        event_type: 'P',
        int_key: odd.intKey || odd.int_key,
        odd_value:
          (odd.oddValue / 100).toFixed(2) || (odd.odd_value / 100).toFixed(2),
        limit: odd.limit,
      }))
      return odds
    }

    // antepost odds creation - only one
    /* const odd =
      event.antepostBetsList?.find((o: any) => o.code === event.code) ||
      event.selectedOdds?.[0] */
    // console.log('odd RELOAD', odd)
    odds = [
      {
        event_type: 'A',
        int_key: event.oIntKey, // odd?.intKey || odd?.int_key,
        odd_value: event.oddValue / 100,
        limit: 0,
      },
    ]
    return odds
  }

  get slipData() {
    return {
      for_approving: this.sendToManualApproval,
      approval_reason: this.manualApprovalReason,
      amount: this.amount ? this.amount : 0,
      slip_groups: this.groups.map((group) => ({
        system: this.getSystemString(
          group.system.filter((s) => !!s),
          group.events
        ),
        events: group.events.map((event) => {
          return {
            // when fetching data for slip preview, int_key is used
            event_int_key: event.intKey || event.int_key,
            odds: this.returnOddsBasedOnEventType(event),
          }
        }),
      })),
    }
  }

  get hasErrors() {
    return Object.keys(this.errors).length > 0
  }

  get matchNum() {
    return this.groups.reduce((events, g) => {
      return events + g.events.length
    }, 0)
  }

  get placeBetDisabled() {
    return this.hasErrors || !this.amount || !this.groups.length
  }

  get isSystem() {
    return (
      this.paymentInfo.number_of_combinations &&
      this.paymentInfo.number_of_combinations > 1
    )
  }

  get slipsCount() {
    return this.slipReport.size
  }

  get totalPayInAmount() {
    let total = 0

    this.slipReport.forEach((amount: number) => {
      total += amount
    })

    return total
  }

  loadSlip(slip: SingleTicket) {
    this.setAmount(String(slip.amount))
    this.dataInfoLoading = false
    this.groups = slip.slip_groups.map((group: any) => ({
      events: group.events.map((event: any) => {
        const loadedEvent = {
          id: event.event_id,
          name: event.event_name,
          start: event.event_start_time,
          dualHome: event.dual_home,
          dualAway: event.dual_away,
          antepostCode: event.event_code,
          competitionName: event.competition_name,
          event_type:
            event.event_stage.toLowerCase() === 'live' ? 'L' : event.event_type,
          int_key: event.int_key || event.intKey,
          dualIntKey: parseInt(event.dual_int_key, 10),
          sportId: event.sport_id,
          // antepost odd value
          oddValue: event.odds[0].odd_value * 100,
          oIntKey: event.odds[0]?.int_key,
          market: {
            competitionName: event.competition_name,
            name: event.market_name,
            antepostName: event.event_name,
          },
          selectedOdds: event.odds.map((odd: any) => {
            this.addHighlightedOdd(event.int_key, odd.int_key)
            if (event.event_type === 'A') {
              this.setSelectedOdd(odd.int_key)
            }
            return {
              int_key: odd.int_key || odd.intKey,
              printCode: odd.outcome_print_code,
              id: odd.odd_id,
              code: odd.code,
              oldOddValue: event.odds[0].old_odd_value
                ? event.odds[0].old_odd_value * 100
                : null,
              oddValue: odd.old_odd_value
                ? +odd.old_odd_value * 100 || odd.old_odd_value * 100
                : +odd.odd_value * 100 || odd.oddValue * 100,
              outcome_id: odd.outcome_idd || odd.outcomeId,
              limit: +odd.limit_value,
              limit2: +odd.limit2_value,
            }
          }),
          intKey: event.int_key || event.intKey,
          eventCode: event.event_code,
          antepostBetsList: event.odds.map((odd: any) => ({
            int_key: odd.int_key || odd.intKey,
            printCode: odd.outcome_print_code,
            market_name: odd.market_name,
            name: odd.name,
            id: odd.odd_id,
            oddValue: +odd.odd_value * 100 || odd.oddValue * 100,
            outcome_id: odd.outcome_idd || odd.outcomeId,
            limit: +odd.limit_value,
          })),
          note: event.event_note,
          code: event.event_type === 'A' ? '' : event.event_code,
        }
        if (event.event_type === 'A') {
          loadedEvent.code = loadedEvent.selectedOdds[0].code
        }
        return loadedEvent
      }),
      system: group.system.split('/')[0].split('+').map(Number),
    }))
    // this.rootStore.sports.setFilters(this.rootStore.sports.filtersPremach)
  }

  reset() {
    if (!this.keepMatches) {
      this.groups = []
    }
    this.clearOdds()
    this.selectedOdd = null
    this.paymentInfo = {}
    this.amount = null
    this.sendToManualApproval = false
    this.manualApprovalReason = ''
    this.manualUsername = ''
  }

  resetCounts() {
    this.numberOfSlipsSubmitted = 0
    this.numberOfSlipsSubmittedGreeko = 0
    this.totalOfSlipsSubmitted = 0
    this.totalOfSlipsSubmittedGreeko = 0
    this.depPayInaAmount = 0
    const payInReportData = localStorage.getItem('payInReportData')
    if (payInReportData) {
      localStorage.removeItem('payInReportData')
    }
  }

  updateReportData(ticketValue: number) {
    this.numberOfSlipsSubmitted += 1
    this.totalOfSlipsSubmitted += ticketValue
    const reportObject = {
      numberOfSlipsSubmitted: this.numberOfSlipsSubmitted,
      totalOfSlipsSubmitted: this.totalOfSlipsSubmitted,
    }
    localStorage.setItem('payInReportData', JSON.stringify(reportObject))
  }

  updateReport(slip: any) {
    this.slipReport.set(slip.id, slip.amount)
    const report = JSON.stringify(Object.fromEntries(this.slipReport))
    localStorage.setItem('slipReport', report)
  }

  // TODO: Refactor this
  toggleOdd = (event: Event, el: any, outcome: Outcome | undefined) => {
    if (this.checkIfAntepostIsInSlip()) {
      message.error({
        duration: 2,
        content: i18n.t('no_combine_dual'),
        className: styles.errorMsg,
      })
      return
    }
    // check if can be send to manual approval
    this.findLiveEvent()
    const elOdd = event.allOddsList.find((odd: any) => odd.intKey === el.intKey)
    if (!outcome) return
    const slipEvent = this.findEvent(event.intKey)
    const odd = { int_key: elOdd?.intKey }
    if (slipEvent && this.hasOdd(slipEvent, odd as SlipOdd)) {
      const group = this.findGroupWithEvent(slipEvent.intKey)
      if (group) {
        if (slipEvent.selectedOdds.length > 1) {
          this.removeSingleHighlightedOdd(event.intKey, elOdd?.intKey)
        } else {
          this.removeHighlightedOdd(event.intKey)
        }
        this.removeOdd(group, slipEvent, elOdd?.intKey)
      }
    } else if (this.lockEvent) {
      if (
        this.validationRules.number_of_same_dual_events_on_one_slip ===
        slipEvent?.selectedOdds.length
      ) {
        message.error(i18n.t('multiBetMaxReached'))
        return // or deselect odd if selected
      }
      this.addAnotherEventOdd(event, {
        ...elOdd,
        odd_value: elOdd?.oddValue,
        int_key: elOdd?.intKey,
        printCode: outcome.printCode,
      })
      this.addHighlightedOdd(event.intKey, elOdd?.intKey)
    } else {
      this.addEventOdd(event, {
        ...elOdd,
        odd_value: elOdd?.oddValue,
        int_key: elOdd?.intKey,
        printCode: outcome.printCode,
      })
      if (this.lockEvent) {
        this.removeSingleHighlightedOdd(event.intKey, elOdd?.intKey)
      } else {
        this.removeHighlightedOdd(event.intKey)
      }
      this.addHighlightedOdd(event.intKey, elOdd?.intKey)
    }
  }

  checkIfItIsSameOdd = (event: any) => {
    const slipEvent = this.findEvent(event.intKey)

    if (slipEvent?.code === event.code) {
      const group = this.findGroupWithEvent(slipEvent.intKey)
      if (group) {
        return true
      }
    }
    return false
  }

  toggleAntepostEvent = (event: any) => {
    this.findLiveEvent()
    if (this.checkIfPremachIsInSlip()) {
      message.error({
        duration: 2,
        content: i18n.t('no_combine_dual'),
        className: styles.errorMsg,
      })
      return
    }
    const slipEvent = this.findEvent(event.intKey)
    if (slipEvent?.code === event.code) {
      const group = this.findGroupWithEvent(slipEvent.intKey)
      if (group) {
        this.setSelectedOdd(null)
        this.removeEvent(group, slipEvent.intKey)
      }
      return
    }
    if (slipEvent?.intKey) {
      const group = this.findGroupWithEvent(slipEvent.intKey)
      if (group) {
        this.removeEvent(group, slipEvent.intKey)
        this.setSelectedOdd(null)
        this.addEventAntepost(event, 0)
      }
    } else {
      this.addEventAntepost(event, 0)
    }
  }

  checkIfPremachIsInSlip = () => {
    this.findLiveEvent()
    let premachInSlip = false
    this.groups.forEach((group) => {
      group.events.forEach((event: any) => {
        if (
          event.event_type === 'D' ||
          event.event_type === 'P' ||
          event.event_type === 'L'
        ) {
          premachInSlip = true
        }
      })
    })
    return premachInSlip
  }

  checkIfAntepostIsInSlip = () => {
    this.findLiveEvent()
    let premachInSlip = false
    this.groups.forEach((group) => {
      group.events.forEach((event: any) => {
        if (event.event_type === 'A') {
          premachInSlip = true
        }
      })
    })
    return premachInSlip
  }

  removePlayerEventFromSlip = (e_i_k: any) => {
    const slipEvent = this.findEvent(e_i_k)
    if (slipEvent) {
      const group = this.findGroupWithEvent(slipEvent.intKey)
      if (group) {
        this.removeEvent(group, slipEvent.intKey)
        this.setSelectedOdd(null)
      }
    }
  }

  removeEventFromSlip = (e_i_k: any) => {
    const slipEvent = this.findEvent(e_i_k)
    if (slipEvent) {
      const group = this.findGroupWithEvent(slipEvent.intKey)
      if (group) {
        this.removeEvent(group, slipEvent.intKey)
        this.removeHighlightedOdd(e_i_k)
        this.setSelectedOdd(null)
      }
    }
  }

  handleSuspendChangeEvent = (eventArray: any, isSuspended: boolean) => {
    eventArray?.e_i_k.forEach((eIntKey: any) => {
      const event = this.findEvent(eIntKey)
      if (event) {
        event.isSuspended = isSuspended
      }
    })
  }

  handleSuspendChangeOdd = (eIk: any, intKey: any, isSuspended: boolean) => {
    const event = this.findEvent(eIk)
    if (event) {
      const changeOdd = this.findOdd(event, intKey)
      if (changeOdd) {
        changeOdd.isSuspended = isSuspended
      }
    }
  }

  updateOdds = (intKey: number, odds: any[]) => {
    const event = this.findEvent(intKey)

    if (event) {
      event.selectedOdds = event.selectedOdds.map((odd: any) => {
        const updated = odds.find((o: any) => o.i_k === odd.intKey)
        if (!updated) return odd

        const updatedOdd = JSON.parse(updated.v || updated.o_v)
        const isEqual = updatedOdd === odd.oddValue
        const isDown = updatedOdd < odd.oddValue || (isEqual && odd.isDown)
        const isUp = updatedOdd > odd.oddValue || (isEqual && odd.isUp)

        return {
          ...odd,
          odd_value: updatedOdd,
          oddValue: updatedOdd,
          isDown,
          isUp,
        }
      })

      this.getSlipPaymentInfo(this.slipData)
    }
  }

  handleStopChange = (data: any, isStop: boolean) => {
    data.forEach((el: { e_i_k: number }) => {
      const event = this.findEvent(el.e_i_k)

      if (event) {
        event.betStop = isStop
      }
    })
  }

  handleAntepostSuspendChange = (data: any, isSuspended: boolean) => {
    data.forEach((el: { a_i_k: number }) => {
      const event = this.findEvent(el.a_i_k)
      if (event) {
        event.isSuspended = isSuspended
      }
    })
  }

  resetReport() {
    this.slipReport = new Map()
    localStorage.removeItem('slipReport')
  }

  toggleManualApproval() {
    this.sendToManualApproval = !this.sendToManualApproval
  }

  setManualApprovalReason(reason: string) {
    this.manualApprovalReason = reason
  }
  setManualUsername(username: string) {
    this.manualUsername = username
  }

  validateAmount() {
    const minAmount = this.validationRules.minimum_betting_amount
    if (!minAmount) return
    const minPayIn = this.paymentInfo.min_pay_in || minAmount
    if (this.amount && this.amount < minPayIn) {
      this.errors.minBettingAmount = `Minimalna uplata je ${minPayIn}`
    } else {
      delete this.errors.minBettingAmount
    }
  }

  validateCombinations() {
    const maxComb = this.validationRules.max_number_of_combinations_system_slip
    const numOfCombinations = this.paymentInfo.number_of_combinations
    if (!maxComb) return

    if (numOfCombinations && numOfCombinations > maxComb) {
      this.errors.maxCombinations = `Maksimalan broj kombinacija je ${maxComb}`
    } else {
      delete this.errors.maxCombinations
    }
  }

  validateEventsLength() {
    const { validationRules } = this
    const maxSystemEvents =
      validationRules.max_number_of_different_events_non_system_slip
    const maxFixEvents =
      validationRules.max_number_of_different_events_system_slip
    const maxEvents = this.isSystem ? maxSystemEvents : maxFixEvents
    if (!maxEvents) return

    if (this.matchNum > maxEvents) {
      this.errors.maxEvents = `Maksimalan broj dogadjaja je ${maxEvents}`
    } else {
      delete this.errors.maxEvents
    }
  }

  validateEventOdds() {
    const maxOdds = this.validationRules.number_of_same_dual_events_on_one_slip
    if (!maxOdds) return
    let isValid = true

    this.groups.forEach((group) => {
      const hasMultipleOdds = group.events.some((event) => {
        return event?.selectedOdds?.length > maxOdds
      })

      if (hasMultipleOdds) {
        isValid = false
      }
    })

    if (!isValid) {
      this.errors.maxOdds = `Maksimalan broj viseznaka je ${maxOdds}`
    } else {
      delete this.errors.maxOdds
    }
  }

  setAmount(amount: string) {
    this.amount = Number(amount)
    this.validateAmount()
  }

  setKeepMatches(keep: boolean) {
    this.keepMatches = keep
  }

  setLockEvent(lock: boolean) {
    this.lockEvent = lock
  }

  findOddByOutcomeId(odds: any[], id: number) {
    return odds.find((odd) => odd.outcome_id === id)
  }

  getGroup(groupPosition: number): SlipGroup | null {
    const group = !!this.groups.length && this.groups[groupPosition]

    if (group) {
      return group
    }

    const isFirst = !this.groups.length && groupPosition === 0
    const previousGroupExists = this.groups[groupPosition - 1]
    const canCreate = isFirst || previousGroupExists

    if (canCreate) {
      this.createEmptyGroup()
      return this.groups[groupPosition]
    }

    return null
  }

  createTicketOdd(odd: any, outcome: Outcome): SlipOdd {
    return { ...odd, printCode: outcome.printCode }
  }

  addEventAntepost(event: any, groupPosition: number = 0) {
    const group = this.getGroup(groupPosition)
    if (!group) return

    group.events.push(JSON.parse(JSON.stringify(event)))

    group.system = [group.events.length]
  }

  clearAllSlips() {
    this.groups = []
  }

  addEvent(event: Event, odd: SlipOdd, groupPosition: number = 0) {
    if (!odd) {
      message.info(
        // @ts-ignore
        `Na eventu ${event.name} ne postoji kvota za igru ${odd.printCode}`
      )

      return
    }

    const group = this.getGroup(groupPosition)
    if (!group) return

    group.events.push({
      ...event,
      selectedOdds: [odd],
    })

    this.addHighlightedOdd(event.intKey, odd.intKey)
    group.system = [group.events.length]
  }

  addEventOdd(event: Event, odd: any) {
    const group = this.getGroup(0)
    if (!group) return
    const e = this.findEvent(event.intKey)
    if (e) {
      e.selectedOdds[0] = odd
    } else {
      group.events.push({
        ...event,
        selectedOdds: [odd],
      })
    }

    group.system = [group.events.length]
    this.findLiveEvent()
  }

  addAnotherEventOdd(event: Event, odd: any) {
    const group = this.getGroup(0)
    if (!group) return
    const e = this.findEvent(event.intKey)
    if (e) {
      if (
        e.selectedOdds.length ===
        this.validationRules.number_of_same_dual_events_on_one_slip
      ) {
        return
      }
      e.selectedOdds.push(odd)
    } else {
      group.events.push({
        ...event,
        selectedOdds: [odd],
      })
    }
    group.system = [group.events.length]
  }

  hasOdd(event: SlipEvent, odd: SlipOdd) {
    return event.selectedOdds.some(
      (o: SlipOdd) => (o.int_key || o.intKey) === (odd.int_key || odd.intKey)
    )
  }

  findOdd(event: SlipEvent, oddIntKey: any) {
    return event.selectedOdds.find((o: any) => o.int_key === oddIntKey)
  }

  validateOdd(oddReached: boolean) {
    if (oddReached) {
      this.errors.maxSlipOdd = maxSlipOdd(bettingRules.MAX_SLIP_ODD)
    } else {
      delete this.errors.maxSlipOdd
    }
  }

  updateEvent(event: Event, odd: SlipOdd) {
    const e = this.findEvent(event.intKey)

    if (e && odd && this.hasEvent(e)) {
      this.removeHighlightedOdd(e.intKey)
      e.selectedOdds[0] = odd
      this.addHighlightedOdd(event.intKey, odd.intKey)
      return
    }
    if (e && odd && !this.hasOdd(e, odd)) {
      e.selectedOdds.push(odd)
      this.addHighlightedOdd(event.intKey, odd.intKey)
    }
  }

  extendEvent(event: Event, odd: SlipOdd) {
    const e = this.findEvent(event.intKey)
    if (!e) return Promise.reject(new Error('noEvent'))

    if (this.hasOdd(e, odd)) {
      return Promise.reject(new Error('Odd je dodat u tiket ili ne postoji.'))
    }

    if (this.lockEvent) {
      if (
        this.validationRules.number_of_same_dual_events_on_one_slip ===
        e?.selectedOdds.length
      ) {
        return Promise.reject(new Error(i18n.t('multiBetMaxReached')))
      }
    }

    e.selectedOdds.push(odd)
    this.addHighlightedOdd(event.intKey, odd.intKey)
    return Promise.resolve()
  }

  // TODO : check if is intended for 'dvoznak'
  overrideEvent(event: Event, odd: SlipOdd) {
    const e = this.findEvent(event.intKey)

    if (e && odd && !this.hasOdd(e, odd)) {
      this.removeHighlightedOdd(e.intKey)
      e.selectedOdds[0] = odd
      this.addHighlightedOdd(e.intKey, odd.intKey)
    }
  }

  setSelectedOdd(inKey: any) {
    this.selectedOdd = inKey
  }

  updateGroupSystem = (group: any) => {
    if (group.system[1] > group.events.length) {
      group.system[1] = group.events.length
    }
    if (group.system.length === 3) {
      if (group.system[1] === group.system[2]) {
        group.system = [group.system[0], group.system[1]]
      }

      if (group.system.length === 3 && group.system[0] === group.system[2]) {
        group.system = [group.system[0], group.system[1]]
      }

      if (group.system[0] === group.system[1]) {
        group.system = [group.system[0]]
      }
    }

    if (group.system.length === 2 && group.system[0] === group.system[1]) {
      group.system = [group.system[0]]
    }
    return group
  }

  removeOdd(group: SlipGroup, event: SlipEvent, intKey: any) {
    event.selectedOdds = event.selectedOdds.filter(
      (o: SlipOdd) => (o.intKey || o.int_key) !== intKey
    )
    group.events = group.events?.filter((e) => e.selectedOdds?.length > 0)
    group.system = group.system?.map((s) =>
      s && s > group.events?.length ? s - 1 : s
    )

    group = this.updateGroupSystem(group)
    this.groups.map((group) => {
      return this.updateGroupSystem(group)
    })
    this.groups = this.groups.filter((g) => g.events.length)
  }

  removeEvent = (group: SlipGroup, intKey: number) => {
    group.events = group.events.filter((e) => e.intKey !== intKey)
    group.system = group.system.map((s) =>
      s && s > group.events.length ? s - 1 : s
    )

    group = this.updateGroupSystem(group)
    this.groups.map((group) => {
      return this.updateGroupSystem(group)
    })
    this.groups = this.groups.filter((g) => g.events.length)
  }

  hasEvent(event: Event) {
    return !!this.findEvent(event.intKey)
  }

  findEvent(intKey: number) {
    const group = this.findGroupWithEvent(intKey)
    if (group) {
      return group.events.find((e) => e.intKey === intKey)
    }

    return null
  }

  findEventByDualIntKey(dualIntKey: number) {
    const group = this.groups.find((g) =>
      g.events.some((e) => e.dualIntKey === dualIntKey)
    )
    if (group) {
      return group.events.find((e) => e.dualIntKey === dualIntKey)
    }
    return null
  }

  findGroupWithEvent(intKey: number) {
    return this.groups.find((g) => g.events.some((e) => e.intKey === intKey))
  }

  addEmptySystem(group: SlipGroup) {
    if (group.system.length < 3) {
      group.system = [...group.system, null]
    }
  }

  updateSystem(group: SlipGroup, system: number, index: number) {
    group.system = group.system.map((s, i) => {
      if (i === index) {
        return !system ? null : system
      }

      return s
    })
  }

  removeSystem(group: SlipGroup, index: number) {
    group.system = group.system.filter((_s, i: number) => i !== index)
  }

  moveEventToGroup(event: SlipEvent, groupPosition: number) {
    const source = this.findGroupWithEvent(event.intKey)
    const targetGroupIndex = groupPosition - 1
    const target = this.getTargetGroup(targetGroupIndex)

    if (!source) return

    target.events.push(event)
    this.removeEvent(source, event.intKey)
    this.groups.forEach((group) => {
      group.system = [group.events.length]
    })
  }

  moveLastEvents(group: SlipGroup, numberOfEvents: number) {
    const targetGroupIndex = this.groups.indexOf(group) + 1

    if (targetGroupIndex < MAX_LINE_GROUPS_COUNT) {
      const target = this.getTargetGroup(targetGroupIndex)
      const events = group.events.splice(group.events.length - numberOfEvents)

      target.events.push(...events)
      target.system = [target.events.length]
    }
  }

  getTargetGroup(position: number) {
    if (!this.groups[position]) {
      this.createEmptyGroup()
    }

    return this.groups[position]
  }

  createEmptyGroup() {
    this.groups.push({ system: [], events: [] })
  }

  groupsBefore(group: SlipGroup) {
    const index = this.groups.indexOf(group)
    return this.groups
      .filter((_g, i) => i < index)
      .map((g) => this.groups.indexOf(g) + 1)
  }

  groupsAfter(group: SlipGroup) {
    const index = this.groups.indexOf(group)

    const groupsAfter = this.groups
      .filter((_g, i) => i > index)
      .map((g) => this.groups.indexOf(g) + 1)

    if (group.events.length > 1 && this.groups.length < MAX_LINE_GROUPS_COUNT) {
      groupsAfter.push(this.groups.length + 1)
    }

    return groupsAfter
  }

  getSlipPaymentInfo = debounce(async (slip: any) => {
    this.dataInfoLoading = true
    try {
      const { data } = await this.api.getSlipPaymentInfo(slip)
      if (this.groups.length !== 0) {
        this.setPaymentInfo(data)
        this.dataInfoLoading = false
      }
      if (this.maxOddReached) {
        this.maxOddReached = false
      }
    } catch (err: any) {
      this.dataInfoLoading = false
      if (
        err?.data?.error === 'ERR_MIN_ODD_OVER_LIMIT' ||
        err?.data?.error === 'ERR_MAX_ODD_OVER_LIMIT'
      ) {
        this.maxOddReached = true
        console.error('Error while trying to fetch slip payment info')
      }
    }
    this.validateOdd(this.maxOddReached)
  }, 500)

  setPaymentInfo(data: any) {
    if (data.max_winning_amount_per_combination) {
      this.paymentInfo = {
        ...data,
        min_pay_in: this.getMinPayIn(data.number_of_combinations),
        max_pay_out: this.getMaxPayOut(
          data.max_winning_amount,
          data.max_bonus_amount
        ),
        max_winning_amount: data.max_winning_amount_per_combination,
      }
    } else {
      this.paymentInfo = {
        ...data,
        min_pay_in: this.getMinPayIn(data.number_of_combinations),
        max_pay_out: this.getMaxPayOut(
          data.max_winning_amount,
          data.max_bonus_amount
        ),
      }
    }

    this.validateCombinations()
    this.validateEventsLength()
    this.validateEventOdds()
  }

  getMinPayIn(combinations: number) {
    const { validationRules } = this
    const minBetPerCombination =
      validationRules.min_betting_amount_per_combination_system_slip
    const minBettingAmount = this.validationRules.minimum_betting_amount
    const amountPerCombination = combinations * minBetPerCombination

    if (amountPerCombination >= minBettingAmount) {
      return amountPerCombination
    }

    return minBettingAmount
  }

  getMaxPayOut(winAmount: number, bonusAmount: number) {
    const { validationRules } = this
    const maxPayOut = validationRules.maximum_winning_amount
    const payOut = winAmount + bonusAmount

    if (!maxPayOut || payOut < maxPayOut) {
      return payOut
    }

    return maxPayOut
  }

  findOddInEvents = (intKey: number) => {
    let odd

    this.groups.forEach((group: any) => {
      const event = group.events?.find((e: any) => {
        if (e.event_type === 'A') {
          return e.antepostBetsList.find((o: any) => o.intKey === intKey)
        }
        return e.selectedOdds.some((o: any) => o.intKey === intKey)
      })
      if (event) {
        if (event.event_type === 'A') {
          odd = event.antepostBetsList.find((o: any) => o.intKey === intKey)
        } else {
          odd = event.selectedOdds.find((o: any) => o.intKey === intKey)
        }
      }
    })

    return odd || null
  }

  findEventByOdd = (intKey: number) => {
    let slipEvent
    this.groups.forEach((group: any) => {
      const event = group.events?.find((e: any) => {
        if (e.event_type === 'A') {
          return e.antepostBetsList.find((o: any) => o.intKey === intKey)
        }
        return e.selectedOdds.some((o: any) => o.intKey === intKey)
      })
      if (event) {
        slipEvent = event
      } else {
        slipEvent = null
      }
    })

    return slipEvent
  }

  findLiveEvent = () => {
    this.groups.forEach((group: any) => {
      const event = group.events?.find((e: Event) => e.event_type === 'L')

      if (event) {
        this.disableManualApproval = true
        this.sendToManualApproval = false
      } else {
        this.disableManualApproval = false
      }
    })
  }
}
