import React, { useEffect, useState } from 'react'
import { Button, Col, Input, message, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import styles from './greekoGameCheckTickets.module.css'
import { SectionContainer } from '../SectionContainer'
import { useStore } from '../../store/StoreContext'

export default observer(function GreekoGameCheckTickets() {
  const { t } = useTranslation()
  const [selectedTicketId, setSelectedTicketId] = useState<any>(null)
  const [loadedTicket, setLoadedTicket] = useState<any>(null)
  const [status, setStatus] = useState<string>('')
  const { slipApi } = useStore()

  const checkTicket = (e: any) => {
    setSelectedTicketId(e.target.value)
  }

  const getTicketGreeko = () => {
    slipApi.getTicketGreeko(selectedTicketId).then((data: any) => {
      if (data) {
        setLoadedTicket(data)
      }
    })
  }

  const repeatTicketGreeko = () => {
    slipApi.getTicketGreeko(selectedTicketId).then((data: any) => {
      if (data) {
        slipApi.repeatTicket = data
      }
    })
  }

  const deleteTicket = () => {
    const postData = {
      ticket_id: selectedTicketId,
    }
    slipApi.deleteTicket(postData).then((data: any) => {
      if (data) {
        setLoadedTicket(null)
        slipApi.getTicketHistory()
        message.success(t('greeko_ticket_delete'))
      }
    })
  }

  const payTicketGreeko = () => {
    slipApi.payTicketGreeko(selectedTicketId).then((data: any) => {
      if (data) {
        getTicketGreeko()
      }
    })
  }

  useEffect(() => {
    slipApi.getTicketHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loadedTicket?.paid) {
      setStatus(t('paidOut'))
      return
    }

    if (loadedTicket?.is_winning === true) {
      setStatus(t('winTicketStatus'))
    }

    if (loadedTicket?.is_winning === false) {
      setStatus(t('lost'))
    } else {
      setStatus(t('pending'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedTicket])

  return (
    <>
      <SectionContainer translation="ticketCheck">
        <Row style={{ marginTop: '10px' }}>
          <Col span={6} className={styles.inputTitle}>
            {t('ticketID')}
          </Col>
          <Col span={18} />
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input
              name="number"
              style={{ width: '100px' }}
              type="number"
              onChange={checkTicket}
            />
          </Col>
          <Col span={4} style={{ marginLeft: '20px', width: '100px' }}>
            <Button
              disabled={!selectedTicketId}
              type="primary"
              onClick={() => {
                getTicketGreeko()
              }}
            >
              {t('checkTicket')}
            </Button>
          </Col>
          <Col span={14} />
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col span={6}>
            <Button
              disabled={!selectedTicketId}
              type="primary"
              style={{ width: '100px' }}
              onClick={() => {
                repeatTicketGreeko()
              }}
            >
              {t('repeat')}
            </Button>
          </Col>
          <Col span={4}>
            <Button
              danger
              disabled={!selectedTicketId}
              style={{ marginLeft: 20, width: '109px' }}
              onClick={() => {
                deleteTicket()
              }}
            >
              {t('cancelTicket')}
            </Button>
          </Col>
          <Col span={14} />
        </Row>
        <Row style={{ marginTop: '30px' }}>
          {loadedTicket?.numbers.map((numberValue: any) => (
            <Col className={styles.colStyle} span={2}>
              {numberValue}
            </Col>
          ))}
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col span={5} className={styles.inputTitle}>
            {status}
          </Col>
          <Col span={19} />
        </Row>
        <Row
          style={{ marginTop: '10px', marginBottom: '60px' }}
          gutter={16}
          justify="center"
        >
          <Col>
            <Button
              disabled={!loadedTicket?.is_winning}
              type="primary"
              onClick={() => {
                payTicketGreeko()
              }}
              style={{ width: '90px' }}
            >
              {t('payOut')}
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <SectionContainer translation="previousTickets">
        <div style={{ height: '645px', overflow: 'hidden' }}>
          <Row>
            <Col span={4} className={styles.inputTitle}>
              ID
            </Col>
            <Col span={8} className={styles.inputTitle}>
              {t('time')}
            </Col>
            <Col span={6} />
            <Col span={6} className={styles.inputTitle}>
              {t('amount')}
            </Col>
          </Row>
          {slipApi.greekoTicketHistory?.items.map((obj: any) => (
            <Row>
              <Col span={4} className={styles.colStyleBold}>
                {obj.id}
              </Col>
              <Col span={14} className={styles.colStyleBold}>
                {moment.utc(obj.date_of_creation).format('YYY-MM-DD HH:mm:ss')}
              </Col>
              <Col span={6} className={styles.colStyleBold}>
                {obj.amount}
              </Col>
            </Row>
          ))}
        </div>
      </SectionContainer>
    </>
  )
})
