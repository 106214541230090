import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Form, FormItem } from 'formik-antd'
import { Col, Row, AutoComplete } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { SportsIcons } from 'components/SportsIcons'
import styles from './liveMatchSearch.module.css'

export interface LiveMatchSearchType {
  competitor: any
}

export default observer(function LiveMatchSearch() {
  const { t } = useTranslation()

  const { sports } = useContext(StoreContext)
  const liveEventsArray = Array.from(sports.liveEventsMap.values())

  // Hardcoded because id's of the sports are not good on BE, we agreed on this
  const sortedSports = [
    { id: 1, name: 'Fudbal' },
    { id: 2, name: 'Kosarka' },
    { id: 5, name: 'Tenis' },
    { id: 4, name: 'Rukomet' },
    { id: 3, name: 'Odbojka' },
    { id: 7, name: 'Hokej' },
    { id: 11, name: 'Futsal' },
    { id: 8, name: 'Americki fudbal' },
    { id: 12, name: 'Stoni tenis' },
  ]

  const getLiveSports = () => {
    return sortedSports.filter((sport) =>
      liveEventsArray.find((filter) => filter.sportId === sport.id)
    )
  }

  const getEventsOptions = () => {
    return liveEventsArray.map((event) => ({
      ...event,
      label: event.name,
      value: event.name,
    }))
  }

  const initialValues: LiveMatchSearchType = {
    competitor: null,
  }

  return (
    <div style={{ minHeight: '92px' }}>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {() => (
          <Form autoComplete="off">
            <Row gutter={4}>
              <Col span={24}>
                <FormItem
                  name="competitor"
                  label={t('competitor')}
                  style={{ marginBottom: '21px' }}
                >
                  <AutoComplete
                    options={getEventsOptions()}
                    onSelect={(value, option) => {
                      sports.setSelectedLiveEvent(
                        liveEventsArray.filter(
                          (el) => el.intKey === option.intKey
                        )[0]
                      )
                    }}
                    defaultValue=""
                    filterOption={(inputValue, option) => {
                      return (
                        (option?.label as string)
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) !== -1
                      )
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Row gutter={4}>
        {getLiveSports().map((sport) => (
          <div className={styles.matchCount} key={sport.id}>
            <div className={styles.matchCountIcon}>
              {SportsIcons.get(sport.id)}
            </div>
            <div className={styles.matchCountNumber}>
              {
                liveEventsArray.filter((event) => event.sportId === sport.id)
                  .length
              }
            </div>
          </div>
        ))}
      </Row>
    </div>
  )
})
