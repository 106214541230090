import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import styles from './sectionContainer.module.css'
import { useStore } from '../../store/StoreContext'

export interface ContainerProps {
  translation: string
}

export const SectionContainerReport: React.FC<ContainerProps> = ({
  translation,
  children,
}) => {
  const { t } = useTranslation()
  const { slip } = useStore()

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.title}>
        {t(translation)}{' '}
        <Button
          size="small"
          type="primary"
          style={{ fontSize: '12px', visibility: 'hidden' }}
          onClick={() => slip.resetCounts()}
        >
          {t('reset')}
        </Button>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
