import React from 'react'
import { SectionContainer } from 'components/SectionContainer'
import SbClientFilters from 'components/SbClientFilters'
import { observer } from 'mobx-react'

function SbClientHome() {
  return (
    <div>
      <>
        <SectionContainer translation="prematchCatalogFilters">
          <SbClientFilters />
        </SectionContainer>
      </>
    </div>
  )
}

export default observer(SbClientHome)
