class ProtobufApi {
  private service: any

  constructor(service: any) {
    this.service = service
  }

  request<T>(method: any, args: any[]): Promise<T> {
    return new Promise((resolve) => {
      this.service[method](...args, (err: any, res: any) => {
        if (err) {
          // TODO: Handle server errors
          console.log(err)
        } else {
          resolve(res.toObject())
        }
      })
    })
  }

  stream(method: any, onData: any, onEnd: any, args: any[]): void {
    const streamObject = this.service[method](...args)

    streamObject.on('data', (data: any) => {
      console.log('data', method, data.toObject())
      onData(data)
    })

    streamObject.on('error', (e: any) => {
      throw e
    })
    streamObject.on('end', () => onEnd())
  }
}

export default ProtobufApi
