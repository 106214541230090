import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import StoreContext from 'store/StoreContext'
import { RouteType } from './MainRoutes'

export default observer(function Routes({ routes }: { routes: RouteType[] }) {
  const { user } = useContext(StoreContext)

  return (
    <Switch>
      {routes.map((route) => {
        if (route.auth === user.isLoggedIn) {
          return (
            <Route key={route.path} path={route.path} exact={route.exact}>
              {route.component}
            </Route>
          )
        }
        return (
          <Route key={route.path} path={route.path} exact={route.exact}>
            {user.isLoggedIn ? (
              <Redirect to="/sport-betting" />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        )
      })}
      <Route path="*">
        <div>Page not found</div>
      </Route>
    </Switch>
  )
})
