import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'antd'
import SlipLog from 'components/SlipLog'
import GetSlips from 'components/GetSlips'
import PayInReport from 'components/PayInReport'
import { SectionContainer } from 'components/SectionContainer'
import ManualApprovalInfo from 'components/ManualApprovalInfo'
import GreekoGameCheckTickets from '../../components/GreekoGameCheckTickets'
import GreekoGame from '../../components/GreekoGame'
import { SectionContainerReport } from '../../components/SectionContainerReport'

function GreekoHome() {
  return (
    <Row gutter={2} style={{ height: '1032px' }}>
      <Col span={13}>
        <GreekoGame />
      </Col>
      <Col span={5}>
        <GreekoGameCheckTickets />
      </Col>
      <Col span={6}>
        <SectionContainerReport translation="payInReport">
          <PayInReport />
        </SectionContainerReport>
        <SectionContainer translation="slipLog">
          <SlipLog />
        </SectionContainer>
        <SectionContainer translation="getSlips">
          <GetSlips />
        </SectionContainer>
        <SectionContainer translation="Information">
          <ManualApprovalInfo />
        </SectionContainer>
      </Col>
    </Row>
  )
}

export default observer(GreekoHome)
