/* eslint-disable react/destructuring-assignment */
import React, { ReactNode, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Row, Col, message } from 'antd'
import { useStore } from 'store/StoreContext'
import moment from 'moment'
import ReviewSlipModal from 'components/ReviewSlipModal'
import { useTranslation } from 'react-i18next'

import { SingleTicket, TicketStatus } from 'store/models/SlipModels'
import styles from './slipLog.module.css'
import TransactionLogModal from '../TransactionLogModal'

interface ColDef {
  title: string
  key: keyof SingleTicket
  col: number
  render?: (arg: SingleTicket) => ReactNode
}

interface SlipTableProps {
  columns: ColDef[]
  data: any
  hasRowStyle?: boolean
}

const ROW_STATUS_STYLES = ({ isRowStyle }: { isRowStyle: boolean }) => ({
  [TicketStatus.APPROVING]: '',
  [TicketStatus.MANUAL_CHANGED]: isRowStyle
    ? styles.ticketChanged
    : styles.statusChanged,
  [TicketStatus.NOT_RESOLVED]: isRowStyle
    ? styles.ticketApproved
    : styles.statusApproved,
  [TicketStatus.UNCONFIRMED]: '',
  [TicketStatus.DENIED]: isRowStyle
    ? styles.ticketCanceled
    : styles.statusCanceled,
  [TicketStatus.DOBITAN]: '',
  [TicketStatus.GUBITAN]: '',
  [TicketStatus.CASHBACK]: isRowStyle,
  [TicketStatus.PAID]: '',
  [TicketStatus.CANCELED]: isRowStyle
    ? styles.ticketCanceled
    : styles.statusCanceled,
})

const ROW_STATUS_TEXT = {
  [TicketStatus.APPROVING]: 'Approving',
  [TicketStatus.MANUAL_CHANGED]: 'Changed',
  [TicketStatus.NOT_RESOLVED]: 'Accepted',
  [TicketStatus.CANCELED]: 'Canceled',
  [TicketStatus.CASHBACK]: 'Cashback',
  [TicketStatus.UNCONFIRMED]: 'Unconfirmed',
  [TicketStatus.DENIED]: 'Rejected',
  [TicketStatus.PAID]: 'Payed out',
  [TicketStatus.DOBITAN]: 'Won',
  [TicketStatus.GUBITAN]: 'Lost',
}

const slipColumns = [
  {
    title: 'SlipID',
    col: 6,
    key: 'short_uuid',
  },
  {
    title: 'SlipTime',
    col: 6,
    key: 'created_at',
    render: ({ created_at }: SingleTicket) => (
      <div>{moment(created_at).format('DD/MM HH:mm')}</div>
    ),
  },
  {
    title: 'Amount',
    col: 4,
    key: 'amount',
    // this render possibly causes error because faulty (toFixed is undefined, question mark should fix it for now(?))
    render: ({ amount }: SingleTicket) => (
      <div>{!Number.isNaN(+amount) ? (+amount)?.toFixed(2) : amount}</div>
    ),
  },
]

const slipApprovingColumns = (onReview: any, onDecline: any) => [
  ...slipColumns,
  {
    title: 'Review/Cancel',
    col: 8,
    key: 'review',
    render: (record: SingleTicket) =>
      record.status !== TicketStatus.DENIED &&
      record.status !== TicketStatus.NOT_RESOLVED ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.status !== TicketStatus.APPROVING && (
            <Button
              type="primary"
              style={{ marginRight: '8px' }}
              onClick={() => onReview(record)}
              size="small"
            >
              review
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => onDecline(record.id)}
            size="small"
            danger
          >
            cancel
          </Button>
        </div>
      ) : null,
  },
]

// @ts-ignore
const slipSubmittedColumns = (onPreview: any) => [
  ...slipColumns,
  {
    title: 'Status',
    col: 4,
    key: 'status',
    render: ({ status }: SingleTicket) => (
      // @ts-ignore
      <div className={ROW_STATUS_STYLES({ isRowStyle: false })[status]}>
        {ROW_STATUS_TEXT[status]}
      </div>
    ),
  },
  {
    title: 'Preview',
    col: 4,
    key: 'preview',
    render: (record: SingleTicket) => (
      <Button
        type="primary"
        size="small"
        style={{ marginRight: '8px' }}
        onClick={() => onPreview(record)}
      >
        Preview
      </Button>
    ),
  },
]

export const SlipTable = ({ columns, data, hasRowStyle }: SlipTableProps) => {
  const sortedList = data.sort((a: any, b: any) => {
    const aTime = new Date(a.created_at).getTime()
    const bTime = new Date(b.created_at).getTime()
    return bTime - aTime
  })
  // @ts-ignore
  // @ts-ignore
  return (
    <div className={styles.slipTable}>
      <Row gutter={1}>
        {columns.map((colDef: ColDef) => (
          <Col key={colDef.key} span={colDef.col}>
            <div className={styles.matchHeaderColumn}>
              <span>{colDef.title}</span>
            </div>
          </Col>
        ))}
      </Row>
      <div className={styles.slipTableBody}>
        {sortedList.map((record: SingleTicket) => (
          <Row key={record.id} gutter={1}>
            {columns.map((colDef: ColDef) => (
              <Col key={`${colDef.key} ${record.id}`} span={colDef.col}>
                <div
                  // @ts-ignore
                  className={
                    hasRowStyle
                      ? ROW_STATUS_STYLES({ isRowStyle: true })[record.status]
                      : ''
                  }
                >
                  <div className={styles.matchColumn}>
                    {colDef.render ? (
                      colDef.render(record)
                    ) : (
                      <span>{record[colDef.key]}</span>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </div>
  )
}

SlipTable.defaultProps = {
  hasRowStyle: false,
}

const SlipLog = () => {
  const { slipApi, user } = useStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalData, setModalData] = useState<SingleTicket | null>(null)
  const [modalTransactionLogData, setModalTransactionLogData] =
    useState<any>(null)
  const { t } = useTranslation()

  const openModal = (record: any) => {
    setIsOpen(true)
    setModalData(record)
  }

  const closeModal = () => {
    setIsOpen(false)
    setModalData(null)
  }

  const closeTransactionModal = () => {
    setModalTransactionLogData(null)
  }

  const accept = async () => {
    await slipApi.onChangesAction(modalData?.id as string, 'acceptChanges')
    closeModal()
  }

  const decline = async () => {
    await slipApi.onChangesAction(modalData?.id as string, 'declineChanges')
    closeModal()
  }

  const cancelSlip = async (id: number) => {
    await slipApi.onChangesAction(String(id), 'declineChanges')
  }

  const getTransactionLogData = (type: string) => {
    slipApi.getTransactionLogData(type).then((data: any) => {
      if (data) {
        setModalTransactionLogData(data)
      } else {
        message.error(t('apiError'))
      }
    })
  }

  useEffect(() => {
    user.getUser()
    if (!user.isUserLoading) {
      slipApi.getSlipsPendingApproval()
      slipApi.getSlipsInLast30Minutes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isUserLoading, user.user?.id])

  useEffect(() => {
    document.addEventListener('pointerenter', slipApi.initSlipCreatedCheck)
    document.addEventListener('pointerleave', slipApi.clearSlipCheckInterval)

    return () => {
      document.removeEventListener('pointerenter', slipApi.initSlipCreatedCheck)
      document.removeEventListener(
        'pointerleave',
        slipApi.clearSlipCheckInterval
      )
    }
  }, [slipApi.initSlipCreatedCheck, slipApi.clearSlipCheckInterval])

  return (
    <div>
      <TransactionLogModal
        data={modalTransactionLogData}
        onCancel={closeTransactionModal}
        dataLoad={getTransactionLogData}
        onClose={closeTransactionModal}
        visible={!!modalTransactionLogData}
        loading={false}
        modalType="STATUS"
      />
      <div className={styles.slipTitle}>{t('slipsManualTitle')}</div>
      <SlipTable
        columns={slipApprovingColumns(openModal, cancelSlip) as ColDef[]}
        data={slipApi.approvalSlips}
        hasRowStyle
      />
      <div className={styles.slipTitle}>{t('slipsAllSubmitted')}</div>
      <SlipTable
        columns={slipSubmittedColumns(slipApi.previewSlip) as ColDef[]}
        data={slipApi.submittedSlips}
      />
      <ReviewSlipModal
        data={modalData}
        onCancel={decline}
        onConfirm={accept}
        onClose={closeModal}
        visible={isOpen}
        loading={slipApi.validateInProgress}
        modalType="REVIEW"
      />
      <Row
        gutter={8}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: '5px',
          marginTop: '5px',
        }}
      >
        <Button
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: '5px',
          }}
          onClick={() => {
            getTransactionLogData('sports-betting')
          }}
        >
          {t('transacionLog')}
        </Button>
      </Row>
    </div>
  )
}

export default observer(SlipLog)
