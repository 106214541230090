import axios from 'axios'
import { SlipPrint } from 'store/models/SlipModels'
import { http } from './request'
import { getSocketUrl } from './urlBuilder'

class SlipApi {
  private socket: WebSocket | null = null

  connectTicketFeed(handlers: any) {
    const token = localStorage.getItem('token')
    if (!token) {
      console.error('Cannot open socket because token is not provided')
      return
    }

    const url = getSocketUrl(`ws/slip/feed/${token}/`)
    this.socket = new WebSocket(url)

    this.socket.addEventListener('message', ({ data }: any) => {
      try {
        const message = JSON.parse(data)
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        handlers[message.type] && handlers[message.type](message)
      } catch (e: any) {
        console.error(e)
      }
    })
  }

  placeBet(data: any) {
    return http.post('slip/slip/sc-place-bet/', data)
  }

  getTicket = (id: string): Promise<any> => {
    return http.get(`slip/slip/${id}/get-slip/`)
  }

  getTransactionsLog(type: any) {
    return http.get(`slip/slip/transaction-log-sb/?_type=${type}`)
  }

  acceptChanges = (id: string): Promise<any> => {
    return http.put(`slip/slip/${id}/accept/`)
  }

  declineChanges = (id: string): Promise<any> => {
    return http.put(`slip/slip/${id}/denied/`)
  }

  rollBack = (id: string): Promise<any> => {
    return http.put(`slip/slip/${id}/roll-back/`)
  }

  payOut = (id: string): Promise<any> => {
    return http.put(`slip/slip/${id}/payout/`)
  }

  getSlipPaymentInfo(payload: any) {
    return http.post('slip/slip/payment-info/', payload)
  }

  getSlipBonusConfig() {
    return http.get('slip/slip/bonus-config/')
  }

  getValidationRules() {
    return http.get('/slip/validation/rules/')
  }

  getFastSlip(code: string) {
    return http.get(`/slip/slip/${code}/fast-bet/`)
  }

  getSlipByShortId(shortId: string) {
    return http.get(`/slip/slip/${shortId}/short-uuid/`)
  }

  getSlipsPendingApproval() {
    return http.get('/slip/slip/approving/')
  }

  getSlipsInLast30Minutes(bodyParams: any) {
    return http.post('/slip/slip/filter/', bodyParams)
  }

  getNextRound() {
    return http.get('/games/fww-games/lb/next_round?game=kino')
  }

  getTicketHistory() {
    return http.get('/games/fww-games/lb/tickets?game=kino')
  }

  getRoundsForDate(date: string) {
    return http.get(`/games/fww-games/lb/rounds?game=kino&date=${date}`)
  }

  getTicketGreeko(ticketId: string) {
    return http.get(
      `/games/fww-games/lb/ticket?game=kino&ticket_id=${ticketId}`
    )
  }
  payTicketGreeko(ticketId: string) {
    return http.post(`/games/fww-games/lb/ticket/pay?game=kino`, {
      ticket_id: ticketId,
    })
  }

  postTicket(data: any) {
    return http.post(`/games/fww-games/lb/ticket?game=kino`, data)
  }

  deleteTicket(data: any) {
    return http.delete(`/games/fww-games/lb/ticket?game=kino`, { data })
  }

  printSlip(payload: SlipPrint) {
    return axios({
      baseURL: 'http://localhost:60188/api/Printer/PrintSlip',
      method: 'POST',
      data: payload,
    })
  }

  printGreekoSlip(payload: any) {
    return axios({
      baseURL: 'http://localhost:60188/api/Printer/PrintGreekoSlip',
      method: 'POST',
      data: payload,
    })
  }

  printUserDepositOrWithdrawal(payload: any) {
    return axios({
      baseURL:
        'http://localhost:60188/api/Printer/PrintUserDepositOrWithdrawal',
      method: 'POST',
      data: payload,
    })
  }
}

export default SlipApi
