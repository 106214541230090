import { configure } from 'mobx'
import RootStore from './RootStore'

configure({
  enforceActions: 'never',
})

export default class BaseStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
}
