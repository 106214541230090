import React, { useState } from 'react'
import { Button, Col, Modal, Row, Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styles from './transactionLogModal.module.css'
import { formatCurrency } from '../../utils/formatCurrency'

const ReveiwSlipActions = ({ onCancel }: any) => {
  const { t } = useTranslation()

  return (
    <div
      style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}
    >
      {/* <Button onClick={onCancel} type="ghost" danger>
        {t('cancel')}
      </Button> */}
      <Button style={{ marginLeft: '5px' }} onClick={onCancel} type="primary">
        {t('ok')}
      </Button>
    </div>
  )
}

const TransactionLogModal = ({
  visible,
  onConfirm,
  onCancel,
  onClose,
  dataLoad,
  loading,
  data,
  modalType,
}: any) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState('sports-betting')

  const TransactionTableHeader = () => {
    const headerConfig = [
      {
        span: 9,
        key: 1,
        headerText: selected === 'sports-betting' ? t('slipId') : t('username'),
      },
      {
        span: 4,
        key: 2,
        headerText: t('TransactionTime'),
      },
      {
        span: 3,
        key: 3,
        headerText: t('transactionType'),
      },
      {
        span: 3,
        key: 4,
        headerText: t('amount'),
      },
      {
        span: 5,
        key: 5,
        headerText: selected === 'sports-betting' ? t('slipType') : '\u00A0',
      },
      /* {
        span: 2,
        key: 6,
        headerText: t('print'),
      }, */
    ]

    return (
      <Row gutter={1}>
        {headerConfig.map((c) => (
          <Col span={c.span} key={c.key}>
            <div className={styles.matchHeaderColumn}>
              <span>{c.headerText}</span>
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  const onChange = (e: RadioChangeEvent) => {
    dataLoad(e.target.value)
    setSelected(e.target.value)
  }

  return (
    <Modal
      closable
      visible={visible}
      onCancel={onClose}
      title={t('transacionLog')}
      footer={false}
      width={1000}
    >
      {data && (
        <div className={styles.slipInfo}>
          <Row gutter={[0, 0]}>
            <Col span={24} style={{ marginBottom: '10px' }}>
              <Radio.Group onChange={onChange} value={selected}>
                <Radio.Button value="sports-betting">
                  SportsBetting
                </Radio.Button>
                <Radio.Button value="online">Online</Radio.Button>
                <Radio.Button value="greeko">Greeko</Radio.Button>
              </Radio.Group>
            </Col>
            <Col
              span={24}
              style={{
                marginBottom: '20px',
                height: '350px',
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <div>
                <Row>
                  <div className={styles.matchHeader}>
                    <span>{t('transactionLogHeader')}</span>
                  </div>
                </Row>
                <TransactionTableHeader />
                {data ? (
                  <div>
                    {data?.data?.map((el: any) => (
                      <div key={el.key} className={styles.matchRow}>
                        <Row gutter={1}>
                          <Col span={9}>
                            <div className={styles.matchColumn}>
                              <span>
                                {selected === 'sports-betting'
                                  ? el.slip_id
                                  : el.username}
                              </span>
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className={styles.matchColumn}>
                              <span>
                                {moment(el.created_at).format(
                                  'HH:mm:ss DD/MM/YYYY'
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div className={styles.matchColumn}>
                              <span>{el.transaction_type}</span>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div className={styles.matchColumn}>
                              <span>{formatCurrency(el.amount)}</span>
                            </div>
                          </Col>
                          <Col span={4}>
                            <div className={styles.matchColumn}>
                              <span>{el.slip_type}</span>
                            </div>
                          </Col>
                          {/* <Col span={2}>
                            <div className={styles.matchColumn}>
                              <Button
                                size="small"
                                style={{ fontSize: '10px', padding: '4px' }}
                              >
                                {t('print')}
                              </Button>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.noResults}>{t('noResults')}</div>
                )}
              </div>
            </Col>
            <Col span={10}>{t('sportBettingSlipPayInCount')}</Col>
            <Col span={2}>{data.sport_betting_slip_pay_in_count}</Col>
            <Col span={8}>{t('sportBettingSlipPayInAmount')}</Col>
            <Col span={4}>
              {formatCurrency(data.sport_betting_slip_pay_in_amount)}
            </Col>
            <Col span={10}>{t('sportBettingSlipPayOutCount')}</Col>
            <Col span={2}>{data.sport_betting_slip_pay_out_count}</Col>
            <Col span={8}>{t('sportBettingSlipPayOutAmount')}</Col>
            <Col span={4}>
              {formatCurrency(data.sport_betting_slip_pay_out_amount)}
            </Col>
            <Col span={10}>{t('greekoSlipPayInCount')}</Col>
            <Col span={2}>{data.greeko_slip_pay_in_count}</Col>
            <Col span={8}>{t('greekoSlipPayInAmount')}</Col>
            <Col span={4}>{formatCurrency(data.greeko_slip_pay_in_amount)}</Col>
            <Col span={10}>{t('greekoSlipPayOutCount')}</Col>
            <Col span={2}>{data.greeko_slip_pay_out_count}</Col>
            <Col span={8}>{t('greekoSlipPayOutAmount')}</Col>
            <Col span={4}>
              {formatCurrency(data.greeko_slip_pay_out_amount)}
            </Col>
            <Col span={10}>{t('depositSlipPayInCount')}</Col>
            <Col span={2}>{data.deposit_pay_in_count}</Col>
            <Col span={8}>{t('depositSlipPayInAmount')}</Col>
            <Col span={4}>{formatCurrency(data.deposit_pay_in_amount)}</Col>
            <Col span={10}>{t('depositSlipPayOutCount')}</Col>
            <Col span={2}>{data.deposit_pay_out_count}</Col>
            <Col span={8}>{t('depositSlipPayOutAmount')}</Col>
            <Col span={4}>{formatCurrency(data.deposit_pay_out_amount)}</Col>
          </Row>
        </div>
      )}
      <ReveiwSlipActions
        onCancel={onCancel}
        loading={loading}
        onConfirm={onConfirm}
        modalType={modalType}
        status={data?.status}
      />
    </Modal>
  )
}

export default TransactionLogModal
