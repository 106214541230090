import { TicketStatus } from 'store/models/SlipModels'

export const getOutcomeBySportAndPrintCode = (
  outcomesBySportMap: { [key: string]: any },
  sportId: number,
  code: string
) => {
  return (
    outcomesBySportMap[`s-${sportId}`] &&
    outcomesBySportMap[`s-${sportId}`][`e-${code}`]
  )
}

export const getOddValue = (
  el: any,
  outcomesBySportMap: any,
  code: string,
  eventOddList: any
) => {
  if (!el.sportId) return 0
  const outcome = getOutcomeBySportAndPrintCode(
    outcomesBySportMap,
    el.sportId,
    code
  )
  if (!outcome) return null
  const odd = (eventOddList(el.intKey) as any)[`e-${outcome.id}`]
  return odd && odd.oddValue
}

export const getOddIsSuspended = (
  el: any,
  outcomesBySportMap: any,
  code: string,
  eventOddList: any,
  marketId: number
) => {
  if (!el.sportId) return 0
  const outcome = getOutcomeBySportAndPrintCode(
    outcomesBySportMap,
    el.sportId,
    code
  )
  const marketsFromEventMarketSuspendMap = el.marketsMap.find(
    (m: any) => m[0] === String(marketId)
  )
  const isMarketSuspended =
    marketsFromEventMarketSuspendMap && marketsFromEventMarketSuspendMap[1]
  if (!outcome) return null
  const odd = (eventOddList(el.intKey) as any)[`e-${outcome.id}`]
  return (odd && odd.isSuspended) || isMarketSuspended
}

export const getAcceptedStatusClass = (status: TicketStatus | undefined) => {
  switch (status) {
    case TicketStatus.APPROVING:
      return 'yellow'
    case TicketStatus.DENIED:
      return 'red'
    case TicketStatus.CANCELED:
      return 'yellow'
    default:
      return 'green'
  }
}

export const getCanceledStatusClass = (status: TicketStatus | undefined) => {
  switch (status) {
    case TicketStatus.CANCELED:
      return 'red'
    default:
      return 'yellow'
  }
}

export const getWonStatusClass = (status: TicketStatus | undefined) => {
  switch (status) {
    case TicketStatus.DOBITAN:
      return 'green'
    case TicketStatus.PAID:
      return 'green'
    case TicketStatus.GUBITAN:
      return 'red'
    default:
      return 'yellow'
  }
}

export const getCashBackClass = (status: TicketStatus | undefined) => {
  switch (status) {
    case TicketStatus.CASHBACK:
      return 'green'
    default:
      return 'yellow'
  }
}

export const getLostStatusClass = (status: TicketStatus | undefined) => {
  switch (status) {
    case TicketStatus.GUBITAN:
      return 'green'
    case TicketStatus.DOBITAN:
      return 'red'
    default:
      return 'yellow'
  }
}

export const getPaidOutStatusClass = (status: TicketStatus | undefined) => {
  switch (status) {
    case TicketStatus.PAID:
      return 'green'
    case TicketStatus.GUBITAN:
      return 'red'
    default:
      return 'yellow'
  }
}

export const getEventStatusColor = (
  eventStatus: string | null,
  modalType: 'REVIEW' | 'STATUS'
) => {
  if (modalType === 'REVIEW') return ''
  switch (eventStatus) {
    case '__PASSED__':
      return 'betWon'
    case '__FAILED__':
      return 'betLost'
    default:
      return 'betOngoing'
  }
}

export const isPayOutDisabled = (status: TicketStatus | undefined) => {
  return status !== TicketStatus.DOBITAN
}
