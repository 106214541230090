import React from 'react'

type Text = {
  text: string | undefined
}

const EllipsisText = ({ text }: Text) => {
  return (
    <span
      style={{
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        lineHeight: '15px',
        fontWeight: 'normal',
        overflowX: 'hidden',
        overflowY: 'hidden',
      }}
      title={text}
    >
      {text}
    </span>
  )
}

export default EllipsisText
