import React, { ReactNode } from 'react'
import { ReactComponent as Fudbal } from 'assets/sports/fudbal.svg'
import { ReactComponent as Kosarka } from 'assets/sports/kosarka.svg'
import { ReactComponent as Odbojka } from 'assets/sports/odbojka.svg'
import { ReactComponent as Rukomet } from 'assets/sports/rukomet.svg'
import { ReactComponent as Tenis } from 'assets/sports/tenis.svg'
import { ReactComponent as Vaterpolo } from 'assets/sports/vaterpolo.svg'
import { ReactComponent as Hokej } from 'assets/sports/hokej.svg'
import { ReactComponent as AmerickiFudbal } from 'assets/sports/americki_fudbal.svg'
import { ReactComponent as Pikado } from 'assets/sports/pikado.svg'
import { ReactComponent as Snuker } from 'assets/sports/snuker.svg'
import { ReactComponent as StoniTenis } from 'assets/sports/stoni_tenis.svg'
import { ReactComponent as Bejzbol } from 'assets/sports/bejzbol.svg'
import { ReactComponent as Badminton } from 'assets/sports/badminton.svg'
import { ReactComponent as Default } from 'assets/sports/default.svg'

export const SportsIcons = new Map<number, ReactNode>([
  [1, <Fudbal style={{ width: 'inherit', height: 'inherit' }} />],
  [2, <Kosarka style={{ width: 'inherit', height: 'inherit' }} />],
  [3, <Odbojka style={{ width: 'inherit', height: 'inherit' }} />],
  [4, <Rukomet style={{ width: 'inherit', height: 'inherit' }} />],
  [5, <Tenis style={{ width: 'inherit', height: 'inherit' }} />],
  [6, <Vaterpolo style={{ width: 'inherit', height: 'inherit' }} />],
  [7, <Hokej style={{ width: 'inherit', height: 'inherit' }} />],
  [8, <AmerickiFudbal style={{ width: 'inherit', height: 'inherit' }} />],
  [9, <Pikado style={{ width: 'inherit', height: 'inherit' }} />],
  [10, <Snuker style={{ width: 'inherit', height: 'inherit' }} />],
  [11, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [12, <StoniTenis style={{ width: 'inherit', height: 'inherit' }} />],
  [13, <Bejzbol style={{ width: 'inherit', height: 'inherit' }} />],
  [14, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [15, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [16, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [17, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [18, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [19, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [20, <Badminton style={{ width: 'inherit', height: 'inherit' }} />],
  [21, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [21, <Default style={{ width: 'inherit', height: 'inherit' }} />],
  [22, <Default style={{ width: 'inherit', height: 'inherit' }} />],
])
