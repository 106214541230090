import {
  SingleTicket,
  SlipEvent,
  SlipGroup,
  SlipOdd,
} from 'store/models/SlipModels'

const mapSlipFactory = (slip: any): SingleTicket => ({
  created_at: slip.c_a,
  amount: slip.a,
  approval_reason: slip.a_r,
  user: slip.u,
  id: slip.i,
  slip_groups: slip.s_g
    ? slip.s_g.map((s_g: any): SlipGroup => {
        return {
          system: s_g.s,
          events: s_g.e.map((e: any): SlipEvent => {
            return {
              event_id: e.e_i_k,
              odds: e.o.map((o: any): SlipOdd => {
                return {
                  odd_id: o.o_i_k,
                  odd_value: o.o_v,
                  automated_status: o.a_s,
                  manual_status: o.m_s,
                  outcome_name: o.o_n,
                  outcome_print_code: o.o_p_c,
                  market_name: o.m_n,
                  old_odd_value: o.o_o_v,
                  limit_value: o.l_v,
                  limit2_value: o.l_v2,
                  old_limit_value: o.o_l_v,
                  old_limit2_value: o.o_l_v2,
                }
              }),
              automated_status: e.a_s,
              manual_status: e.m_s,
              event_name: e.e_n,
              event_type: e.e_t,
              event_start_time: e.e_s_t,
              event_stage: e.e_s,
              event_code: e.e_c,
              event_note: e.e_nt,
              sport_id: e.s,
              sport_name: e.s_n,
              competition_name: e.c_n,
              competition_short_name: e.c_s_n,
              competition_id: e.c,
              location_id: e.loc,
            }
          }),
          num_of_combinations: s_g.n_c,
          automated_status: s_g.a_s,
          manual_status: s_g.m_s,
        }
      })
    : null,
  manual_status: null,
  automated_status: null,
  num_of_combinations: slip.n_c,
  max_odds_value: slip.max_o_v,
  min_odds_value: slip.min_o_v,
  actual_odds_value: slip.a_o_w,
  transaction_id: slip.t_i,
  number_of_combinations: slip.n_o_c,
  number_of_matches: slip.n_m,
  approved_by: slip.a_by,
  betting_place_id: slip.b_p_id,
  short_uuid: slip.s_u,
  max_winning_amount: slip.max_w_a,
  min_winning_amount: slip.min_w_a,
  max_bonus_amount: slip.max_b_a,
  min_bonus_amount: slip.min_b_a,
  actual_winning_amount: slip.act_w_a,
  is_system: slip.i_s,
  status: slip.s,
  old_amount: slip.o_a,
})

export default mapSlipFactory
