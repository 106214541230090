import React from 'react'
import { Row, Col } from 'antd'
import { LoginForm } from 'components/LoginForm'
import { ReactComponent as SoccerLogo } from 'assets/logo.svg'
import styles from './login.module.css'

export default function Login() {
  return (
    <>
      <Row className={styles.container}>
        <Col span={4}>
          <SoccerLogo className={styles.logo} />
          <LoginForm />
        </Col>
      </Row>
    </>
  )
}
