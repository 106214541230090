import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import {
  Checkbox,
  Form,
  FormItem,
  Input,
  InputNumber,
  SubmitButton,
} from 'formik-antd'
import { Button, Col, Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer, Observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import * as Yup from 'yup'
import { SectionContainer } from '../SectionContainer'
import styles from '../SlipPayIn/slipPayIn.module.css'

export default observer(function OnlineWithdraw() {
  const { t } = useTranslation()
  const { user, slipApi } = useContext(StoreContext)

  const validationSchema = Yup.object().shape({
    withdrawCode: Yup.string().required(t('mandatoryField')),
    jmbgLast6Digit: Yup.string().required(t('mandatoryField')),
  })

  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [initialState, setInitialState] = React.useState({
    withdrawCode: '',
    jmbgLast6Digit: '',
    dateOfBirth: '',
    username: '',
    name: '',
    surname: '',
    email: '',
    jmbg: '',
    reservationAmount: 0,
    withdrawAmount: 0,
    jmbgConfirm: false,
  })

  useEffect(() => {
    if (!user.selectedUserWithdraw) {
      setInitialState({
        withdrawCode: '',
        jmbgLast6Digit: '',
        dateOfBirth: '',
        username: '',
        name: '',
        surname: '',
        email: '',
        jmbg: '',
        reservationAmount: 0,
        withdrawAmount: 0,
        jmbgConfirm: false,
      })
      return
    }
    setInitialState({
      withdrawCode: user.selectedUserWithdraw?.withdrawCode || '',
      jmbgLast6Digit: user.selectedUserWithdraw?.jmbgLast6Digit || '',
      dateOfBirth: user.selectedUserWithdraw?.dateOfBirth || '',
      username: user.selectedUserWithdraw?.username || '',
      name: user.selectedUserWithdraw?.first_name || '',
      surname: user.selectedUserWithdraw?.last_name || '',
      email: user.selectedUserWithdraw?.email || '',
      jmbg: user.selectedUserWithdraw?.ssn || '',
      reservationAmount: user.selectedUserWithdraw?.reservationAmount || 0,
      withdrawAmount: user.selectedUserWithdraw?.withdrawAmount || 0,
      jmbgConfirm: false,
    })
  }, [user.selectedUserWithdraw])

  const applayFilters = (values: any) => {
    return user.getUserByWithdrawCode(
      values.withdrawCode,
      values.jmbgLast6Digit
    )
  }

  const closeErrorModal = () => {
    setErrorModal(false)
  }

  const withdrawDeposit = () => {
    setErrorModal(true)
  }

  const withdrawClear = () => {
    setInitialState({
      withdrawCode: '',
      jmbgLast6Digit: '',
      dateOfBirth: '',
      username: '',
      name: '',
      surname: '',
      email: '',
      jmbg: '',
      reservationAmount: 0,
      withdrawAmount: 0,
      jmbgConfirm: false,
    })
  }

  const sendWithdraw = (values: any, setFieldValue: any) => {
    user
      .setWithdraw(
        {
          betting_place_id: user.selectedUserWithdraw?.betting_place_id,
          amount: values.withdrawAmount,
        },
        setFieldValue
      )
      .then((data: any) => {
        if (data?.status === 200) {
          withdrawClear()
          const postData = {
            UserName: data?.data?.username,
            FirstName: data?.data?.first_name,
            LastName: data?.data?.last_name,
            Email: data?.data?.email,
            Amount: values.withdrawAmount,
            Location: data?.data?.location,
            WithdrawCode: data?.data?.withdraw_code,
            Tip: 'Kod za isplatu:',
          }
          slipApi.printSlipDepositWithdrawal(postData)
        }
      })

    setErrorModal(false)
  }

  return (
    <div>
      <SectionContainer translation="userDetails">
        <Row gutter={16}>
          <Col span={24}>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialState}
              enableReinitialize
              onSubmit={(values) => {
                return applayFilters(values)
              }}
            >
              {({ values, setFieldValue }) => (
                <Observer>
                  {() => (
                    <Form>
                      <Row gutter={16}>
                        <Col span={3}>
                          <FormItem
                            name="withdrawCode"
                            label={t('withdrawCode')}
                            labelCol={{ span: 24 }}
                            style={{ marginBottom: '20px' }}
                          >
                            <Input
                              name="withdrawCode"
                              id="withdrawCode"
                              style={{ width: '162px' }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={3}>
                          <FormItem
                            name="jmbgLast6Digit"
                            label={t('jmbgLast6Digit')}
                            labelCol={{ span: 24 }}
                            style={{ marginBottom: '20px' }}
                          >
                            <Input
                              name="jmbgLast6Digit"
                              id="jmbgLast6Digit"
                              maxLength={6}
                              style={{ width: '162px' }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={2}>
                          <SubmitButton
                            style={{ marginTop: '40px', width: '100%' }}
                          >
                            {t('searchDeposit')}
                          </SubmitButton>
                        </Col>
                        <Col span={15} />
                      </Row>
                      <Col span={6} style={{ paddingRight: '12px' }}>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="username"
                              labelAlign="left"
                              label={t('userName')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="username"
                                id="username"
                                style={{ width: '162px' }}
                                value={initialState.username}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="name"
                              labelAlign="left"
                              label={t('name')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="name"
                                id="name"
                                style={{ width: '162px' }}
                                value={initialState.name}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="surname"
                              labelAlign="left"
                              label={t('surname')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="surname"
                                id="surname"
                                style={{ width: '162px' }}
                                value={initialState.surname}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="email"
                              labelAlign="left"
                              label={t('email')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="email"
                                id="email"
                                style={{ width: '162px' }}
                                value={initialState.email}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="jmbg"
                              labelAlign="left"
                              label={t('jmbg')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="jmbg"
                                id="jmbg"
                                style={{ width: '162px' }}
                                value={initialState.jmbg}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="jmbgConfirm"
                              labelAlign="left"
                              label={t('jmbgConfirm')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Checkbox
                                name="jmbgConfirm"
                                id="jmbgConfirm"
                                style={{ width: '162px' }}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="reservationAmount"
                              labelAlign="left"
                              label={t('reservationAmount')}
                              style={{ marginBottom: '20px' }}
                            >
                              <Input
                                disabled
                                name="reservationAmount"
                                id="reservationAmount"
                                style={{ width: '162px' }}
                                value={initialState.reservationAmount}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <FormItem
                              name="withdrawAmount"
                              labelAlign="left"
                              label={t('withdrawAmountValue')}
                              style={{
                                marginBottom: '20px',
                                marginTop: '30px',
                              }}
                            >
                              <InputNumber
                                disabled
                                type="number"
                                min={1}
                                name="withdrawAmount"
                                id="withdrawAmount"
                                style={{ width: '162px' }}
                              />
                            </FormItem>
                          </Col>
                          <Col span={18} />
                        </Row>
                        <Row gutter={16} justify="end">
                          <Col>
                            <Button
                              disabled={!values.jmbgConfirm}
                              type="primary"
                              onClick={withdrawDeposit}
                              style={{ width: '75px' }}
                            >
                              {t('payOut')}
                            </Button>
                            <Button
                              danger
                              style={{ marginLeft: 12, width: '75px' }}
                              onClick={() => {
                                withdrawClear()
                              }}
                            >
                              {t('cancel')}
                            </Button>
                          </Col>
                          <Col span={18} />
                          <Modal
                            onCancel={closeErrorModal}
                            visible={errorModal}
                            footer={false}
                            closable
                            width={600}
                            title={
                              <div className={styles.errorModalHeader}>
                                {t('withdrawModalMsg', {
                                  username: initialState.username,
                                })}
                              </div>
                            }
                          >
                            <div className={styles.errorModalFooter}>
                              <Button
                                size="small"
                                type="primary"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                  sendWithdraw(values, setFieldValue)
                                }}
                              >
                                {t('yes')}
                              </Button>
                              <Button
                                size="small"
                                danger
                                onClick={closeErrorModal}
                              >
                                {t('no')}
                              </Button>
                            </div>
                          </Modal>
                        </Row>
                      </Col>
                    </Form>
                  )}
                </Observer>
              )}
            </Formik>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  )
})
