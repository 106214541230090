import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'antd'

function CashFlow() {
  return (
    <Row gutter={2} style={{ height: '1032px' }}>
      <Col span={18}>
        <div style={{ padding: '30px' }}>CashFlow container</div>
      </Col>
    </Row>
  )
}

export default observer(CashFlow)
