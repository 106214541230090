import { getSocketUrl } from './urlBuilder'
import { wsHeartBeat } from './wsHeartBeat'

class FeedApiBroadcast {
  private socket: WebSocket
  private feedStore: any
  private handlers: any

  constructor(handlers: any, store: any) {
    const url = getSocketUrl('ws/broadcast/sc/')
    this.socket = new WebSocket(url)
    this.handlers = handlers
    this.feedStore = store
  }

  refreshConnection = () => {
    const url = getSocketUrl('ws/broadcast/sc/')
    this.socket = new WebSocket(url)
    this.connect()
  }

  heartBeat = () => {
    wsHeartBeat(this.socket, this.refreshConnection, this.feedStore)
  }

  connect = () => {
    this.heartBeat()
    this.socket.addEventListener('message', ({ data }: any) => {
      try {
        const { type, message } = JSON.parse(data)
        if (this.handlers[type]) {
          this.handlers[type](message)
        }
      } catch (_) {
        // do non
      }
    })
    this.socket.addEventListener('onClose', () => console.log('disconnected'))
  }
}

export default FeedApiBroadcast
