import React, { useContext } from 'react'
import { Button, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import StoreContext from 'store/StoreContext'
import ClientRoutes from 'routes/ClientRoutes'
import { useRouteMatch } from 'react-router'
import { observer } from 'mobx-react'
import styles from './mainHeader.module.css'

export default observer(function MainHeader() {
  const { t } = useTranslation()
  const { user, router } = useContext(StoreContext)
  const match = useRouteMatch()

  return (
    <div className={styles.header}>
      <Menu
        mode="horizontal"
        selectedKeys={[match.path]}
        style={{ paddingRight: '320px', backgroundColor: '#000' }}
      >
        {ClientRoutes.map((route) => (
          <Menu.Item
            // @ts-ignore
            style={
              route.path === '/sb-client' || route.path === '/cash-flow'
                ? { float: 'right' }
                : null
            }
            key={route.path}
            disabled={
              route.path !== '/sport-betting' &&
              route.path !== '/online' &&
              route.path !== '/greeko' &&
              route.path !== '/sb-client'
            }
            onClick={() => router.router.push(route.path)}
          >
            {t(route.translationKey as string)}
          </Menu.Item>
        ))}
      </Menu>
      <Button className={styles.logout} onClick={user.logout}>
        <span>{t('logout')}</span>
        {!user.user?.first_name && !user.user?.last_name ? (
          ''
        ) : (
          <span>-{`${user.user?.first_name} ${user.user?.last_name}`}</span>
        )}
      </Button>
    </div>
  )
})
