import { Event, Location, Outcome, Sport } from './sports.types'
import {
  PlayerSportsAndMarketsSCResponse,
  SportsAndMarketsSCResponse,
} from './sportsApi'

export const findSport = (sports: Sport[], sportId: number) => {
  return sports.find((sport: Sport) => sport.id === sportId)
}

export const findLocation = (
  sports: Sport[],
  sportId: number,
  locationId: number
) => {
  const sport = findSport(sports, sportId)
  if (!sport) return null

  return sport.locationsList.find(
    (location: Location) => location.id === locationId
  )
}

// export const findCompetition = (
//   sports: Sport[],
//   sportId: number,
//   locationId: number,
//   competitionId: number
// ) => {
//   const location = findLocation(sports, sportId, locationId)
//   if (!location) return null

//   return location.competitionsList.find(
//     (competition: Competition) => competition.id === competitionId
//   )
// }

const getMarketsForSport = (marketGroups: any) => {
  return marketGroups.reduce((marketsList: any[], group: any) => {
    return [...marketsList, ...group.marketsList]
  }, [])
}

export const getMarketsList = (sports: any) => {
  return sports.reduce((markets: any[], sport: any) => {
    const { marketGroupsList } = sport
    return markets.concat(getMarketsForSport(marketGroupsList))
  }, [])
}

export const groupMarketsBySport = (
  sports: SportsAndMarketsSCResponse[],
  outcomes: Outcome[]
) => {
  sports.forEach((sport) => {
    sport.marketGroupsList.forEach((marketGroup) => {
      marketGroup.marketsList = marketGroup.marketsList.map((marketList) => {
        const outcomesMap: Map<number, Outcome> = new Map()
        marketList.outcomesList = outcomesMap
        outcomes.forEach((o) => {
          if (o.marketId === marketList.id) {
            outcomesMap.set(o.id, o)
          }
        })
        return marketList
      })
    })
  })
  return sports
}

export const combineEventsAndMarkets = (events: any, markets: any) => {
  return events.map((event: any) => ({
    ...event,
    market: markets.find((el: any) => el.id === event.marketId),
  }))
}

export const groupPlayerMarketsBySport = (
  sports: PlayerSportsAndMarketsSCResponse[],
  outcomes: Outcome[]
) => {
  sports.forEach((sport) => {
    sport.marketsList.map((marketList: any) => {
      const outcomesMap: Map<number, Outcome> = new Map()
      marketList.outcomesList = outcomesMap
      outcomes.forEach((o) => {
        if (o.marketId === marketList.id) {
          outcomesMap.set(o.id, o)
        }
      })
      return marketList
    })
  })
  return sports
}

const sportSort = {
  fudbal: 1,
  košarka: 2,
  tenis: 3,
  rukomet: 4,
  hokej: 5,
  odbojka: 6,
} as any

const sortSports = (a: Sport, b: Sport) => {
  if (!sportSort[a.name] && sportSort[b.name]) {
    return 7 - sportSort[b.name]
  }

  if (sportSort[a.name] && !sportSort[b.name]) {
    return sportSort[a.name] - 7
  }

  return sportSort[a.name] - sportSort[b.name]
}

const sortLocations = (a: Location, b: Location) => {
  const nameA = a.name.toUpperCase() // ignore upper and lowercase
  const nameB = b.name.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
}

export const sortSportsList = (sportList: Array<Sport>) =>
  sportList
    .map((sport) => ({
      ...sport,
      locationsList: sport.locationsList.sort(sortLocations),
    }))
    .sort(sortSports)

interface TopLeaguesTypes {
  fudbal: string[]
  košarka: string[]
  rukomet: string[]
  hokej: string[]
}

const topLeagues: TopLeaguesTypes = {
  fudbal: [
    'ENGLESKA 1',
    'FRANCUSKA 1',
    'ITALIJA 1',
    'NJEMAČKA 1',
    'NJEMAČKA KUP',
    'PORTUGAL 1',
    'ITALIJA KUP',
    'SRBIJA 1',
    'ŠPANIJA 1',
    'ŠPANIJA KUP',
  ],
  košarka: ['NBA', 'EUROCUP', 'EUROLEAGUE', 'ŠPANIJA 1'],
  rukomet: ['CHAMPIONS LEAGUE'],
  hokej: ['NHL'],
}

export const isTopLeague = (sportName: string, leagueName: string) => {
  const sportTopLeagues = topLeagues[sportName as keyof TopLeaguesTypes]
  if (sportTopLeagues) {
    return sportTopLeagues.includes(leagueName.toUpperCase())
  }
  return false
}

export const getAllLeaguesReducer = (accumulator: any, value: any) => {
  return [...accumulator, ...value.competitionsList]
}

export const findLiveSports = (
  sports: SportsAndMarketsSCResponse[],
  liveEvents: Event[]
) => {
  let res = []
  res = sports.filter((sport) =>
    liveEvents.find((filter) => filter.sportId === sport.id)
  )
  return res
}

// TODO this is temporary solution, live event details will be refactored on BE

export const underscoreToCamelCase = (str: string) => {
  let camelCase = ''
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '_') {
      camelCase = `${camelCase}${str[i + 1].toUpperCase()}`
    } else {
      // eslint-disable-next-line no-continue
      if (str[i - 1] === '_') continue
      camelCase = `${camelCase}${str[i]}`
    }
  }
  return camelCase
}

export const replaceBadJsonString = (badJsonString: string) =>
  `${underscoreToCamelCase(badJsonString).replace(/'/g, '"')}`
