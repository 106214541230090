import React, { useContext } from 'react'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import SportBettingRoutes from 'routes/SportBettingRoutes'
import { useLocation } from 'react-router'

export default observer(function Header() {
  const { t } = useTranslation()
  const { router } = useContext(StoreContext)
  const match = useLocation()

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[match.pathname]}
      style={{ lineHeight: '30px', backgroundColor: '#000' }}
    >
      <Menu.Item disabled>{t('matchCatalog')}</Menu.Item>
      {SportBettingRoutes.filter((f) => f.showInMenu).map((route) => (
        <Menu.Item
          key={route.path}
          onClick={() => router.router.push(route.path)}
        >
          {t(route.translationKey as string)}
        </Menu.Item>
      ))}
    </Menu>
  )
})
