import React from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'antd'
import MatchSelection from 'components/MatchBetSelection'
import SlipPayIn from 'components/SlipPayIn'
import SlipLog from 'components/SlipLog'
import GetSlips from 'components/GetSlips'
import PayInReport from 'components/PayInReport'
import { SectionContainer } from 'components/SectionContainer'
import Routes from 'routes'
import SportBettingRoutes from 'routes/SportBettingRoutes'
import SportBettingHeader from 'components/SportBettingHeader'
import SlipReview from 'components/SlipReview'
import SlipInfo from 'components/SlipInfo'
import ManualApprovalInfo from 'components/ManualApprovalInfo'
import { SectionContainerReport } from '../../components/SectionContainerReport'
// import StoreContext from 'store/StoreContext'

function SportBettingHome() {
  // const { slip } = useContext(StoreContext)

  return (
    <Row gutter={2} style={{ height: '990px' }}>
      {true && (
        <Col span={11}>
          <SportBettingHeader />
          <Routes routes={SportBettingRoutes} />
        </Col>
      )}
      <Col span={7}>
        <SectionContainer translation="matchBetSelection">
          <MatchSelection />
        </SectionContainer>
        <SectionContainer translation="slipReview">
          <SlipReview />
        </SectionContainer>
        <SectionContainer translation="slipReview">
          <SlipInfo />
        </SectionContainer>
        <SectionContainer translation="slipPayIn">
          <SlipPayIn />
        </SectionContainer>
      </Col>
      <Col span={6}>
        <SectionContainerReport translation="payInReport">
          <PayInReport />
        </SectionContainerReport>
        <SectionContainer translation="slipLog">
          <SlipLog />
        </SectionContainer>
        <SectionContainer translation="getSlips">
          <GetSlips />
        </SectionContainer>
        <SectionContainer translation="Information">
          <ManualApprovalInfo />
        </SectionContainer>
      </Col>
    </Row>
  )
}

export default observer(SportBettingHome)
