const oneDay = 1000 * 60 * 60 * 24

function getMidnight(day: any) {
  const date = new Date(day)
  date.setMilliseconds(999)
  date.setSeconds(59)
  date.setMinutes(59)
  date.setHours(23)
  return date
}

export function isToday(date: any) {
  const midnightTonight = getMidnight(new Date())
  return date < midnightTonight
}

export function isTomorrow(date: any) {
  const midnightTonight = getMidnight(new Date())
  const midnightTomorrow = new Date(midnightTonight.getTime() + oneDay)

  return date > midnightTonight && date < midnightTomorrow
}

export function isDayAfterTomorrow(date: any) {
  const midnightTomorrow = getMidnight(new Date(Date.now() + oneDay))
  const midnightDayAfterTomorrow = new Date(midnightTomorrow.getTime() + oneDay)

  return date > midnightTomorrow && date < midnightDayAfterTomorrow
}
