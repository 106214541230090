import React, { useContext, useEffect, useMemo } from 'react'
import { Formik } from 'formik'
import { Form, FormItem, Select, SubmitButton } from 'formik-antd'
import moment from 'moment'
import { Button, Col, DatePicker, Input, Row, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer, Observer } from 'mobx-react'
import StoreContext from 'store/StoreContext'
import { SortDropdown } from 'store/SportsStore'
import styles from './sectionSbClient.module.css'

const { Option } = Select

export default observer(function SbClientFilters() {
  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const { sports } = useContext(StoreContext)

  const [search, setSearch] = React.useState('')
  const [selectedSport, setSelectedSport] = React.useState(0)
  const [initialState, setInitialState] = React.useState({
    from: moment().subtract(1, 'month'),
    to: moment().add(1, 'month'),
    sportId: 0,
    catalogId: 0,
    sort: 0,
  })

  const allSportsObj = useMemo(() => {
    return {
      1: 'A1',
      2: 'B1',
      3: 'A1',
      4: 'A1',
      5: 'A1',
      6: 'A1',
      7: 'A1',
      8: 'A1',
      9: 'A1',
      10: 'A1',
      11: 'A1',
      12: 'A1',
      13: 'A1',
      6490: 'A1',
    }
  }, [])

  const getMagazin = (params: any) => {
    const objString = `?${new URLSearchParams(params).toString()}`
    return `${process.env.REACT_APP_API_MAGAZIN}pdf/magazine-add-on${objString}`
  }

  const [paramsArray, setParamsArray] = React.useState(getMagazin(allSportsObj))

  useEffect(() => {
    if (selectedSport === 0) {
      setParamsArray(getMagazin(allSportsObj))
      return
    }
    if (selectedSport) {
      // @ts-ignore
      const obj = new Map([[selectedSport, allSportsObj[selectedSport]]])
      setParamsArray(getMagazin(obj))
    }
  }, [allSportsObj, selectedSport])

  const [filteredEvents, setFilteredEvents] = React.useState<Array<any>>([])

  const allSports = sports.filterConfig

  if (!allSports.find((sport: any) => sport.name === 'Sve')) {
    allSports.push({ name: 'Sve', id: 0 })
  }

  const sortData = (sort: number, arrayToSort: Array<any>): any => {
    if (sort === 0) {
      return arrayToSort.sort((a, b) => {
        if (
          moment(a.date_time_original).isAfter(moment(b.date_time_original))
        ) {
          return 1
        }
        return -1
      })
    }
    // Sort by competition
    return arrayToSort.sort((a, b) => {
      return a.competition_name.localeCompare(b.competition_name)
    })
  }

  const showOddValue = (odd: any) => {
    return odd ? (odd / 100).toFixed(2) : ''
  }

  const applayFilters = (state: any) => {
    setSelectedSport(state.sportId)
    let filteredState = sports.allEventsSbClient.filter((item) => {
      return (
        moment(item.date_time_original).isBetween(
          moment(state.from),
          moment(state.to),
          'minutes',
          '[]'
        ) &&
        (state.sportId === 0 || item.sportId === state.sportId)
      )
    })

    filteredState = filteredState.map((item) => {
      return {
        ...item,
        g2: showOddValue(item.g2),
        gr: showOddValue(item.gr),
        h1: showOddValue(item.h1),
        h2: showOddValue(item.h2),
        k1: showOddValue(item.k1),
        k02: showOddValue(item.k02),
        k2: showOddValue(item.k2),
        k3plus: showOddValue(item.k3plus),
        k5plus: showOddValue(item.k5plus),
        k6plus: showOddValue(item.k6plus),
        kx: showOddValue(item.kx),
        over: showOddValue(item.over),
        under: showOddValue(item.under),
      }
    })
    setFilteredEvents(sortData(state.sort, filteredState))
  }

  useEffect(() => {
    if (sports.allEventsSbClient.length > 0) {
      applayFilters(initialState)
    }
  }, [sports.allEventsSbClient]) // eslint-disable-line

  const catalogOptions = [
    { name: t('allCapital'), id: 0 },
    { name: t('magazin'), id: 1 },
    { name: t('sports'), id: 2 },
  ]

  const columns = [
    {
      title: t('dateTime'),
      dataIndex: 'date_time',
      width: 130,
      key: 'date_time',
    },
    {
      title: t('matchCode'),
      dataIndex: 'event_code',
      width: 130,
      key: 'event_code',
    },
    {
      title: t('sport'),
      dataIndex: 'sport',
      width: 130,
      key: 'sport',
    },
    {
      title: t('competition'),
      dataIndex: 'competition_name',
      width: 200,
      key: 'competition_name',
    },
    {
      title: t('homeCompetitor'),
      dataIndex: 'home_competitor',
      width: 200,
      key: 'home_competitor',
    },
    {
      title: t('awayCompetitor'),
      dataIndex: 'away_competitor',
      width: 200,
      key: 'away_competitor',
    },
    {
      title: 'KI 1',
      dataIndex: 'k1',
      key: 'k1',
    },
    {
      title: 'KI X',
      dataIndex: 'kx',
      key: 'kx',
    },
    {
      title: 'KI 2',
      dataIndex: 'k2',
      key: 'k2',
    },
    {
      title: '0-2',
      dataIndex: 'k02',
      key: 'k02',
    },
    {
      title: '3+',
      dataIndex: 'k3plus',
      key: 'k3plus',
    },
    {
      title: '5+',
      dataIndex: 'k5plus',
      key: 'k5plus',
    },
    {
      title: '6+',
      dataIndex: 'k6plus',
      key: 'k6plus',
    },
    {
      title: 'GR',
      dataIndex: 'gr',
      key: 'gr',
    },
    {
      title: 'H1',
      dataIndex: 'h1',
      key: 'h1',
    },
    {
      title: 'H2',
      dataIndex: 'h2',
      key: 'h2',
    },
    {
      title: 'GR2',
      dataIndex: 'gr2',
      key: 'gr2',
    },
    {
      title: '|+|',
      dataIndex: '|+|',
      key: '|+|',
    },
    {
      title: '|-|',
      dataIndex: '|-|',
      key: '|-|',
    },
  ]

  const onSearch = (e: any) => {
    setSearch(e.target.value)
  }
  // @ts-ignore
  return (
    <div>
      <Formik
        initialValues={initialState}
        onSubmit={(values, { setSubmitting }) => {
          setInitialState(values)
          setSubmitting(false)
          applayFilters(values)
        }}
      >
        {({ values, setFieldValue }) => (
          <Observer>
            {() => (
              <Form>
                <Row gutter={16}>
                  <Col span={2}>
                    <Button
                      style={{ margin: '20px 0px 20px 0px', width: '100%' }}
                      onClick={() => {
                        setFieldValue('from', moment())
                        setFieldValue('to', moment().add(1, 'hours'))
                      }}
                    >
                      {t('oneH')}
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Button
                      style={{ margin: '20px 0px 20px 0px', width: '100%' }}
                      onClick={() => {
                        setFieldValue('from', moment())
                        setFieldValue('to', moment().add(3, 'hours'))
                      }}
                    >
                      {t('twoH')}
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Button
                      style={{ margin: '20px 0px 20px 0px', width: '100%' }}
                      onClick={() => {
                        setFieldValue('from', moment())
                        setFieldValue(
                          'to',
                          moment().add(1, 'day').set({
                            hour: 6,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                        )
                      }}
                    >
                      {t('today')}
                    </Button>
                  </Col>
                  <Col span={18} />
                </Row>
                <Row gutter={16}>
                  <Col span={5}>
                    <FormItem
                      name="date"
                      label={t('dateTimeFromTo')}
                      labelCol={{ span: 24 }}
                      style={{ marginBottom: '20px' }}
                    >
                      <RangePicker
                        style={{ width: '100%' }}
                        format="DD.MM.yyyy HH:mm"
                        value={[values.from, values.to]}
                        onCalendarChange={(date) => {
                          if (date) {
                            setFieldValue('from', date[0])
                            setFieldValue('to', date[1])
                          }
                        }}
                        showTime
                      />
                    </FormItem>
                  </Col>
                  <Col span={3}>
                    <FormItem
                      name="catalogId"
                      label={t('catalog')}
                      labelCol={{ span: 24 }}
                      style={{ marginBottom: '20px' }}
                    >
                      <Select name="catalogId" disabled>
                        {catalogOptions.map((el) => (
                          <Option key={el.id} value={el.id}>
                            {el.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={3}>
                    <FormItem
                      name="sportId"
                      label={t('sport')}
                      labelCol={{ span: 24 }}
                      style={{ marginBottom: '20px' }}
                    >
                      <Select name="sportId">
                        {allSports?.map((el: any) => (
                          <Option key={el.id} value={el.id}>
                            {el.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={3}>
                    <FormItem
                      name="sort"
                      label={t('sort')}
                      labelCol={{ span: 24 }}
                      style={{ marginBottom: '20px' }}
                    >
                      <Select name="sort">
                        {SortDropdown.map((el, index) => (
                          <Option key={el.name} value={index}>
                            {el.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={1}>
                    <SubmitButton style={{ marginTop: '40px', width: '100%' }}>
                      {t('filter')}
                    </SubmitButton>
                  </Col>
                  <Col span={1}>
                    <Button style={{ marginTop: '40px', width: '100%' }}>
                      <a
                        target="_blank"
                        href={paramsArray}
                        download
                        rel="noreferrer"
                      >
                        PDF
                      </a>
                    </Button>
                  </Col>
                  <Col span={3} />
                  <Col span={5}>
                    <Input
                      style={{ marginTop: '40px', width: '100%' }}
                      name="searchInput"
                      id="searchInput"
                      placeholder={t('search')}
                      onChange={onSearch}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Observer>
        )}
      </Formik>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            className={styles.sbClient}
            size="small"
            scroll={{ y: 700 }}
            dataSource={filteredEvents.filter((item) => {
              return (
                item.event_code.toString().includes(search) ||
                item.competition_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.home_competitor
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.away_competitor
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
            })}
            columns={columns}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200'],
            }}
          />
        </Col>
      </Row>
    </div>
  )
})
