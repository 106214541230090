import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Col, Menu, Row } from 'antd'
import SlipLog from 'components/SlipLog'
import GetSlips from 'components/GetSlips'
import PayInReport from 'components/PayInReport'
import { SectionContainer } from 'components/SectionContainer'
import Routes from 'routes'
import OnlineRoutes from 'routes/OnlineRoutes'
import ManualApprovalInfo from 'components/ManualApprovalInfo'
import StoreContext from 'store/StoreContext'
import { useTranslation } from 'react-i18next'
import { SectionContainerReport } from '../../components/SectionContainerReport'

function SportBettingHome() {
  const { t } = useTranslation()
  const { router } = useContext(StoreContext)

  return (
    <Row gutter={2}>
      <Col span={18}>
        <Menu
          mode="horizontal"
          style={{ lineHeight: '30px' }}
          defaultSelectedKeys={[router.router.location.pathname]}
        >
          <Menu.Item
            key="/online"
            onClick={() => router.router.push('/online')}
          >
            {t('deposit')}
          </Menu.Item>
          <Menu.Item
            key="/online/withdraw"
            onClick={() => router.router.push('/online/withdraw')}
          >
            {t('withdraw')}
          </Menu.Item>
        </Menu>
        <Routes routes={OnlineRoutes} />
      </Col>
      <Col span={6}>
        <SectionContainerReport translation="payInReport">
          <PayInReport />
        </SectionContainerReport>
        <SectionContainer translation="slipLog">
          <SlipLog />
        </SectionContainer>
        <SectionContainer translation="getSlips">
          <GetSlips />
        </SectionContainer>
        <SectionContainer translation="Information">
          <ManualApprovalInfo />
        </SectionContainer>
      </Col>
    </Row>
  )
}

export default observer(SportBettingHome)
