import { FilterType } from 'components/PrematchCatalogFilters'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  combineEventsAndMarkets,
  getMarketsList,
  groupMarketsBySport,
  groupPlayerMarketsBySport,
} from 'utils/sports.service'
import moment from 'moment'
import { getI18n } from 'react-i18next'
import { getOddValue } from 'utils/slip.service'
import SportsApi, {
  CompetitionsSCResponse,
  LocationsSCResponse,
  OutcomesSCResponse,
  PlayerSportsAndMarketsSCResponse,
  SportsAndMarketsSCResponse,
} from '../utils/sportsApi'
import {
  Competition,
  Event,
  Location,
  Market,
  MarketGroup,
  Outcome,
} from '../utils/sports.types'
import BaseStore from './BaseStore'
import RootStore from './RootStore'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  mapStructPeriodAndCurrentResult,
  mapCurrentResult,
} from '../utils/generalHelpers'

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortBy {
  TIME = 'start',
  LEAGUE = 'competitionName',
}

export interface SortType {
  name: string
  order: SortOrder
  by: SortBy
  type: 'number' | 'date' | 'string'
}

export const SortDropdown: SortType[] = [
  {
    name: 'Po vremenu',
    order: SortOrder.ASC,
    by: SortBy.TIME,
    type: 'date',
  },
  {
    name: 'Po igraču',
    order: SortOrder.ASC,
    by: SortBy.LEAGUE,
    type: 'string',
  },
]

const arrayToObject = (array: any[], accessor = 'intKey'): object => {
  if (!array) return {}
  return array.reduce((obj: any, item: any) => {
    obj[`e-${item[accessor]}`] = item
    return obj
  }, {})
}

const arrayToGroupedObject = (array: any[]): object => {
  const prematchArray = array.filter((el: any) => el.isPrematch === true)
  return prematchArray.reduce((obj: any, item: any) => {
    // This is not a good solution, since we have both live and prematch outcomes mixed together
    // we can override them by this key mapping ( same sportId and printCode)
    // next IF just checks if current market exists and then doesn override it ( this i think works since prematch markets come first )
    if (
      obj[`s-${item.sportId}`] &&
      obj[`s-${item.sportId}`][`e-${item.printCode}`]
    )
      return obj
    if (!obj[`s-${item.sportId}`]) obj[`s-${item.sportId}`] = {}
    obj[`s-${item.sportId}`][`e-${item.printCode}`] = item
    return obj
  }, {})
}

// create associative array sport name = > sport id
const getSportNameArray = (array: { id: number; name: string }[]) => {
  const map = new Map<string, number>()
  array.forEach((v) => map.set(v.name, v.id))
  return map
}

const groupCodesBySportId = (array: any[]): object => {
  return array.reduce((obj: any, item: any) => {
    // This is not a good solution, since we have both live and prematch outcomes mixed together
    // we can override them by this key mapping ( same sportId and printCode)
    // next IF just checks if current market exists and then doesn override it ( this i think works since prematch markets come first )
    if (obj[`s-${item.sportId}`] && obj[`s-${item.sportId}`][`id-${item.id}`])
      return obj
    if (!obj[`s-${item.sportId}`]) obj[`s-${item.sportId}`] = {}
    obj[`s-${item.sportId}`][`id-${item.id}`] = item
    return obj
  }, {})
}

const parseEventName = (home: string, away: string) => `${home} - ${away}`

export default class SportsStore extends BaseStore {
  private api: SportsApi
  public markets: SportsAndMarketsSCResponse[] = []
  public liveMarkets: SportsAndMarketsSCResponse[] = []
  public playerMarkets: PlayerSportsAndMarketsSCResponse[] = []
  public antepostEventsAndMarketsMap: Map<number, any> = new Map<number, any>()
  pageSize: number = 30
  currentPage: number = 1
  playerEventsMap: Map<number, Event> = new Map<number, Event>()
  antepostEvents: any = []
  sportNames: any = []
  upcomingEventsMap: Map<number, Event> = new Map<number, Event>()
  liveEventsMap: Map<number, Event> = new Map<number, Event>()
  filteredUpcomingEvents: Event[] = []
  filteredPlayerEvents: Event[] = []
  filteredAntepostEvents: any = []
  outcomesList: Outcome[] = []
  playOutcomesList: Outcome[] = []
  marketsList: Market[] = []
  antepostMarkets: any = []
  eventOddsInProgress = false
  $eventOdds: any
  eventsLoadedLive: boolean = false
  eventsLoadedPrematch: boolean = false
  playerEventsLoaded: boolean = false
  antepostLoaded: boolean = false
  outcomesLoaded: boolean = false
  playerOutcomesLoaded: boolean = false
  filterConfig: any = []
  selectedMatch: Event | null = null
  selectedAntepost: any | null = null
  playerSelectedMatch: Event | null = null
  selectedMarket: Market | null = null
  selectedMarketGroup: MarketGroup | null = null
  sportsList: SportsAndMarketsSCResponse[] = []
  playerSportsList: PlayerSportsAndMarketsSCResponse[] = []
  sportsListWithAll: SportsAndMarketsSCResponse[] = []
  playSportsListWithAll: PlayerSportsAndMarketsSCResponse[] = []
  locationsList: Location[] = []
  competitionsList: Competition[] = []
  sportsAndMarketsList: any
  liveSportsAndMarketsList: any
  playerSportsAndMarketsList: any
  filtersPremach: FilterType = {
    filters: {
      sportId: 1,
      locationId: 0,
      competitionId: 0,
    },
  }
  filtersPlayers: FilterType = {
    filters: {
      sportId: 1,
      locationId: 0,
      competitionId: 0,
    },
  }
  filtersOutright: FilterType = {
    filters: {
      sportId: 1,
      locationId: 0,
      competitionId: 0,
    },
  }
  sort = 0
  selectedLiveMatch: Event | null = null
  outcomesCodeMap: any = {}
  playerOutcomesCodeMap: any = {}

  constructor(rootStore: RootStore) {
    super(rootStore)
    this.api = new SportsApi()

    makeObservable(this, {
      outcomesLoaded: observable,
      playerOutcomesLoaded: observable,
      eventsLoadedLive: observable,
      eventsLoadedPrematch: observable,
      onEndLive: action,
      onEndPrematch: action,
      onPlayerEnd: action,
      onLiveEventsLoad: action,
      onPrematchEventsLoad: action,
      onPlayerEventsLoad: action,
      $eventOdds: observable,
      liveEventsMap: observable,
      playerEventsMap: observable,
      antepostEvents: observable,
      upcomingEventsMap: observable,
      outcomesList: observable,
      playOutcomesList: observable,
      markets: observable,
      liveMarkets: observable,
      playerMarkets: observable,
      antepostEventsAndMarketsMap: observable,
      antepostMarkets: observable,
      selectedMatch: observable,
      selectedAntepost: observable,
      playerSelectedMatch: observable,
      selectedMarketGroup: observable,
      sportsList: observable,
      filterConfig: observable,
      playerSportsList: observable,
      sportsListWithAll: observable,
      playSportsListWithAll: observable,
      locationsList: observable,
      competitionsList: observable,
      sportsAndMarketsList: observable,
      liveSportsAndMarketsList: observable,
      playerSportsAndMarketsList: observable,
      filtersPremach: observable,
      filtersPlayers: observable,
      filtersOutright: observable,
      sort: observable,
      filteredUpcomingEvents: observable,
      filteredPlayerEvents: observable,
      filteredAntepostEvents: observable,
      selectedLiveMatch: observable,
      setSelectedEvent: action,
      setAntepostEvent: action,
      setSelectedMarketGroup: action,
      setFilters: action,
      setSort: action,
      setSortPlayers: action,
      loadData: action,
      addToFavourites: action,
      removeFromFavourites: action,
      setSelectedLiveEvent: action,
      onLocationsLoad: action,
      onAntepostMarketsLoad: action,
      onCompetitionsLoad: action,
      onSportsAndMarketsLoad: action,
      onOutcomesLoad: action,
      handlePageChange: action,
      eventsPaginationPrematchCatalog: computed,
      loaded: computed,
      allEvents: computed,
      eventOddList: computed,
      outcomesListMap: computed,
      outcomesBySportMap: computed,
      allEventsSbClient: computed,
      outcomesCodeMap: observable,
      playerOutcomesCodeMap: observable,
      currentPage: observable,
    })
  }

  get eventsPaginationPrematchCatalog() {
    return this.filteredUpcomingEvents.slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize
    )
  }

  handlePageChange = (page: number) => {
    this.currentPage = page
  }

  get loaded() {
    return (
      this.eventsLoadedPrematch && this.eventsLoadedLive && this.outcomesLoaded
    )
  }

  get allEventsMap() {
    return arrayToObject([
      ...Array.from(this.upcomingEventsMap.values()),
      ...Array.from(this.liveEventsMap.values()),
      ...Array.from(this.playerEventsMap.values()),
      ...Array.from(this.antepostEventsAndMarketsMap.values()),
    ])
  }

  get outcomesBySportMap() {
    if (!this.outcomesList) return {}
    return arrayToGroupedObject(this.outcomesList)
  }

  get outcomesListMap() {
    if (!this.outcomesList) return {}
    return arrayToObject(this.outcomesList)
  }

  get eventOddList() {
    return (intKey: number) => {
      return (
        arrayToObject(
          (this.allEventsMap as any)[`e-${intKey}`]?.allOddsList,
          'outcomeId'
        ) || {}
      )
    }
  }

  get eventOddListIntKeyMap() {
    return (intKey: number) => {
      return (
        arrayToObject(
          (this.allEventsMap as any)[`e-${intKey}`]?.allOddsList,
          'intKey'
        ) || {}
      )
    }
  }

  parseTypingCode = (leftSide: string, rightSide: string) =>
    `${leftSide}/${rightSide}`

  get playerEventsTypingCodesMap() {
    if (!this.loaded || !this.playerEventsMap) return {}
    return Array.from(this.playerEventsMap.values()).reduce(
      (codeMap: any, event: any) => {
        return {
          ...codeMap,
          [this.parseTypingCode(event?.dualLandbaseCode, event?.eventCode)]:
            event,
        }
      },
      {}
    )
  }

  get antepostBetsTypingCodesMap() {
    if (!this.loaded || !this.antepostEvents) return {}
    return this.antepostEvents.reduce((codeMap: any, event: any) => {
      if (!event.antepostBetsList) return {}
      const eventBetsMap = event.antepostBetsList.reduce(
        (betCodeMap: any, bet: any) => {
          return {
            ...betCodeMap,
            [this.parseTypingCode(bet.antepostCode, bet.code)]: bet,
          }
        },
        {}
      )
      return {
        ...codeMap,
        ...eventBetsMap,
      }
    }, {})
  }

  get allEvents() {
    let events: Event[] = []
    if (this.rootStore.feed.isLiveOff && this.rootStore.feed.isPrematchOff) {
      events = []
    } else if (
      this.rootStore.feed.isLiveOff &&
      !this.rootStore.feed.isPrematchOff
    ) {
      events = [
        ...Array.from(this.upcomingEventsMap.values()),
        ...Array.from(this.playerEventsMap.values()),
      ]
    } else if (
      !this.rootStore.feed.isLiveOff &&
      this.rootStore.feed.isPrematchOff
    ) {
      events = Array.from(this.liveEventsMap.values())
    } else {
      events = [
        ...Array.from(this.upcomingEventsMap.values()),
        ...Array.from(this.liveEventsMap.values()),
        ...Array.from(this.playerEventsMap.values()),
      ]
    }
    return events
  }

  get allEventsSbClient() {
    if (this.outcomesLoaded) {
      const allEvents = [...Array.from(this.upcomingEventsMap.values())].filter(
        (event) => event.magazine === false
      )
      const newAllEvents = allEvents.map((event) => {
        return {
          key: event.intKey,
          date_time: moment.utc(event.start).local().format('DD/MM HH:mm'),
          date_time_original: event.start,
          competition_name: event.competitionName,
          sport: this.sportsListWithAll.find(
            (sport) => sport.id === event.sportId
          )?.name,
          sportId: event.sportId,
          event_code: event.landbaseCode,
          home_competitor: event.home,
          magazine: event.magazine,
          away_competitor: event.away,
          k1: getOddValue(
            event,
            this.outcomesBySportMap,
            'KI 1',
            this.eventOddList
          ),
          kx: getOddValue(
            event,
            this.outcomesBySportMap,
            'KI X',
            this.eventOddList
          ),
          k2: getOddValue(
            event,
            this.outcomesBySportMap,
            'KI 2',
            this.eventOddList
          ),
          k02: getOddValue(
            event,
            this.outcomesBySportMap,
            'UG 0-2',
            this.eventOddList
          ),
          k3plus: getOddValue(
            event,
            this.outcomesBySportMap,
            'UG 3+',
            this.eventOddList
          ),
          k5plus: getOddValue(
            event,
            this.outcomesBySportMap,
            'UG 5+',
            this.eventOddList
          ),
          k6plus: getOddValue(
            event,
            this.outcomesBySportMap,
            'UG 6+',
            this.eventOddList
          ),
          gr: getOddValue(
            event,
            this.outcomesBySportMap,
            'GR',
            this.eventOddList
          ),
          h1: getOddValue(
            event,
            this.outcomesBySportMap,
            'HP 1',
            this.eventOddList
          ),
          h2: getOddValue(
            event,
            this.outcomesBySportMap,
            'HP 2',
            this.eventOddList
          ),
          g2: getOddValue(
            event,
            this.outcomesBySportMap,
            'GR',
            this.eventOddList
          ),
          over: getOddValue(
            event,
            this.outcomesBySportMap,
            '|+|',
            this.eventOddList
          ),
          under: getOddValue(
            event,
            this.outcomesBySportMap,
            '|-|',
            this.eventOddList
          ),
        }
      })
      return newAllEvents
    }
    return []
  }

  resetOffer = () => {
    this.upcomingEventsMap = new Map<number, Event>()
    this.liveEventsMap = new Map<number, Event>()
    this.playerEventsMap = new Map<number, Event>()
    this.antepostEventsAndMarketsMap = new Map<number, any>()
    this.antepostEvents = []
    this.filteredUpcomingEvents = []
    this.filteredPlayerEvents = []
    this.filteredAntepostEvents = []
    this.eventsLoadedPrematch = false
    this.eventsLoadedLive = false
    this.playerEventsLoaded = false
    this.antepostLoaded = false
  }

  loadData = async () => {
    await this.getFilterConfig()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // @ts-ignore
    Promise.all([
      this.api.getPrematchEvents(this.onPrematchEventsLoad, this.onEndPrematch),
      this.api.getOutcomes().then(this.onOutcomesLoad),
      this.api.getSportsAndMarkets().then(this.onSportsAndMarketsLoad),
      this.api.getLocations().then(this.onLocationsLoad),
      this.api.getCompetitions().then(this.onCompetitionsLoad),
      this.api.getLiveSportsAndMarkets().then(this.onLiveSportsAndMarketsLoad),
      this.api.getPlayerEvents(this.onPlayerEventsLoad, this.onPlayerEnd),
      this.api.getPlayerOutcomes().then(this.onPlayerOutcomesLoad),
      this.api
        .getPlayerSportsAndMarkets()
        .then(this.onPlayerSportsAndMarketsLoad),
      this.api.getAntepost(this.onAntepostLoad, this.onAntepostEnd),
      this.api.getAntepostMarkets().then(this.onAntepostMarketsLoad),
      this.api.getLiveEvents(this.onLiveEventsLoad, this.onEndLive),
    ]).then((value) => {
      this.markets = groupMarketsBySport(
        value[2] as SportsAndMarketsSCResponse[],
        value[1] as Outcome[]
      )

      this.liveMarkets = groupMarketsBySport(
        value[5] as SportsAndMarketsSCResponse[],
        value[1] as Outcome[]
      )

      this.playerMarkets = groupPlayerMarketsBySport(
        value[8] as PlayerSportsAndMarketsSCResponse[],
        value[7] as Outcome[]
      )

      const combinedEventsWithMarkets = combineEventsAndMarkets(
        this.antepostEvents,
        this.antepostMarkets
      )
      combinedEventsWithMarkets.forEach((event: any) => {
        this.antepostEventsAndMarketsMap.set(event.intKey, event)
      })

      this.setOutrightFilters(this.filtersOutright)

      // @ts-ignore
      this.marketsList = getMarketsList([...value[2], ...value[5]])
    })
  }

  onEndPrematch = () => {
    this.eventsLoadedPrematch = true
  }

  onEndLive = () => {
    this.eventsLoadedLive = true
  }

  onPlayerEnd = () => {
    this.playerEventsLoaded = true
  }

  onAntepostEnd = () => {
    this.antepostLoaded = true
  }

  onPrematchEventsLoad = (data: any) => {
    data.getPrematchEventsList().forEach((o: any) => {
      const event = o.toObject()
      event.allOddsList = event.allOddsMap.map((odd: any) => {
        return odd[1]
      })
      event.allOddsMap = null
      event.isSuspended = event.prematchSuspended
      const eventName = parseEventName(event.home, event.away)
      const storeEvent = {
        ...event,
        event_type: 'D',
        name: eventName,
      }
      this.upcomingEventsMap.set(storeEvent.intKey, storeEvent)
    })

    this.setFilters(this.filtersPremach)
    this.setPlayerFilters(this.filtersPlayers)
    this.setSort(0)
  }

  onLiveEventsLoad = (data: any) => {
    let liveEventsArray: Event[] = []
    data.getLiveEventsList().forEach((o: any) => {
      const event = o.toObject()
      event.allOddsList = event.allOddsMap.map((odd: any) => {
        return odd[1]
      })
      event.allOddsMap = null
      event.isSuspended = event.liveSuspended

      event.periodResult = mapStructPeriodAndCurrentResult(
        event.periodResult.fieldsMap
      )
      event.detailedResult = event.periodResult

      event.currentPhase = mapStructPeriodAndCurrentResult(
        event.currentPhase.fieldsMap
      )
      event.currentResult = mapCurrentResult(event.currentResult.fieldsMap)

      event.eventStatistics = {
        CK: event.currentResult.C
          ? {
              away: event.currentResult.C[0],
              home: event.currentResult.C[1],
              periodName: '',
            }
          : undefined,
        cards:
          event.currentResult.YC && event.currentResult.RC
            ? {
                cardsAway: {
                  yellow: event.currentResult.YC[0],
                  red: event.currentResult.RC[0],
                },
                cardsHome: {
                  yellow: event.currentResult.YC[1],
                  red: event.currentResult.RC[1],
                },
              }
            : undefined,
      }
      // event.eventStatistics = mapCurrentResult(event.eventStatistics)
      const eventName = parseEventName(event.home, event.away)
      liveEventsArray.push({ ...event, event_type: 'L', name: eventName })
    })

    liveEventsArray = liveEventsArray.sort((a, b) => {
      return a.start.localeCompare(b.start)
    })

    liveEventsArray.forEach((event: Event) => {
      this.liveEventsMap.set(event.intKey, event)
    })
  }

  onPlayerEventsLoad = (data: any) => {
    data.getPrematchEventsList().forEach((o: any) => {
      const event = o.toObject()
      const eventName = parseEventName(event.dualHome, event.dualAway)
      event.allOddsList = event.allOddsMap.map((odd: any) => {
        return odd[1]
      })
      const playerEvent = { ...event, event_type: 'P', name: eventName }
      this.playerEventsMap.set(event.intKey, playerEvent)
    })
    this.setPlayerFilters(this.filtersPlayers)
  }

  onOutcomesLoad = (outcomes: OutcomesSCResponse) => {
    this.outcomesList = outcomes.outcomesList
    this.outcomesCodeMap = groupCodesBySportId(this.outcomesList)
    this.outcomesLoaded = true
    return this.outcomesList
  }

  onPlayerOutcomesLoad = (outcomes: OutcomesSCResponse) => {
    this.playOutcomesList = outcomes.outcomesList
    this.playerOutcomesCodeMap = groupCodesBySportId(this.playOutcomesList)
    this.playerOutcomesLoaded = true
    return this.playOutcomesList
  }

  onAntepostLoad = (data: any) => {
    data.getAntepostsList().forEach((o: any) => {
      const event = o.toObject()
      // const isStartDateInPast = new Date(event.start) < new Date() // TODO temp solution
      event.antepostBetsMap = event.antepostBetsMap.map((event: any) => {
        return {
          ...event[1],
          // isSuspended: isStartDateInPast,
          event_type: 'A',
        }
      })

      event.antepostBetsList = event.antepostBetsMap
      this.antepostEvents.push(event)
    })
  }

  onSportsAndMarketsLoad = (sportsAndMarketsList: {
    sportsList: SportsAndMarketsSCResponse[]
  }) => {
    this.sportNames = getSportNameArray(sportsAndMarketsList.sportsList)
    this.sportsAndMarketsList = sportsAndMarketsList
    this.sportsList = sportsAndMarketsList.sportsList
    this.sportsListWithAll = sportsAndMarketsList.sportsList
    this.sportsListWithAll.push({
      id: 0,
      name: getI18n().t('allSports'),
      marketGroupsList: [],
    })
    return sportsAndMarketsList.sportsList
  }

  onPlayerSportsAndMarketsLoad = (playerSportsAndMarketsList: {
    sportsList: PlayerSportsAndMarketsSCResponse[]
  }) => {
    this.playerSportsAndMarketsList = playerSportsAndMarketsList
    this.playerSportsList = playerSportsAndMarketsList.sportsList
    this.playSportsListWithAll = playerSportsAndMarketsList.sportsList
    this.playSportsListWithAll.push({
      id: 0,
      name: getI18n().t('allSports'),
      marketsList: [],
    })
    return playerSportsAndMarketsList.sportsList
  }

  onLiveSportsAndMarketsLoad = (liveSportsAndMarketsList: {
    sportsList: SportsAndMarketsSCResponse[]
  }) => {
    this.liveSportsAndMarketsList = liveSportsAndMarketsList
    return liveSportsAndMarketsList.sportsList
  }

  onLocationsLoad = (locations: LocationsSCResponse) => {
    this.locationsList = locations.locationsList
    return this.locationsList.push({
      id: 0,
      name: 'Sve',
    })
  }

  onAntepostMarketsLoad = (markets: any) => {
    this.antepostMarkets = markets?.antepostMarketsList
  }

  onCompetitionsLoad = (competitions: CompetitionsSCResponse) => {
    this.competitionsList = competitions.competitionsList
    return this.competitionsList.push({
      id: 0,
      name: 'Sve',
    })
  }

  setSelectedEvent = (event: any) => {
    this.selectedMatch = event
    this.selectedMarketGroup = null
  }

  setAntepostEvent = (event: any) => {
    this.selectedAntepost = event
  }

  unsetSelectedEvent = () => {
    this.selectedMatch = null
  }

  unsetAntepostSelectedEvent = () => {
    this.selectedAntepost = null
  }

  filterSingleEvent = (el: Event, filter: FilterType) => {
    if (filter?.filters?.sportId && el?.sportId !== filter.filters.sportId) {
      return false
    }
    if (
      filter.filters?.locationId === 0 &&
      filter.filters?.competitionId === 0
    ) {
      return true
    }

    if (filter?.filters?.locationId === 0) {
      if (
        filter?.filters?.competitionId &&
        el?.competitionId !== filter?.filters?.competitionId
      ) {
        return false
      }
      return true
    }

    if (filter.filters.competitionId === 0) {
      if (
        filter.filters.locationId &&
        el.locationId !== filter.filters.locationId
      ) {
        return false
      }
      return true
    }

    if (
      (filter.filters.locationId &&
        el.locationId !== filter.filters.locationId) ||
      (filter.filters.competitionId &&
        el.competitionId !== filter.filters.competitionId)
    ) {
      return false
    }
    return true
  }

  filterAnteposteEvent = (el: any, filter: FilterType) => {
    if (
      filter?.filters?.sportId &&
      el?.market?.sportId !== filter?.filters?.sportId
    ) {
      return false
    }

    if (filter?.filters?.competitionId === 0) {
      if (
        filter?.filters?.locationId &&
        el?.market?.locationId !== filter?.filters?.locationId
      ) {
        return false
      }
      return true
    }

    return true
  }

  setFilters = (value: FilterType) => {
    this.filtersPremach = value
    const newEventsMap: Map<number, Event> = new Map<number, Event>()
    this.upcomingEventsMap.forEach((el, intKey) => {
      if (this.filterSingleEvent(el, this.filtersPremach)) {
        newEventsMap.set(intKey, el)
      }
    })
    this.filteredUpcomingEvents = Array.from(newEventsMap.values())
    this.setSort(this.sort)
  }

  setPlayerFilters = (value: FilterType) => {
    this.filtersPlayers = value
    this.filteredPlayerEvents = Array.from(
      this.playerEventsMap.values()
    ).filter((el) => {
      return this.filterSingleEvent(el, this.filtersPlayers)
    })
    this.setSortPlayers(this.sort)
  }

  setOutrightFilters = (value: FilterType) => {
    this.filtersOutright = value
    this.filteredAntepostEvents = Array.from(
      this.antepostEventsAndMarketsMap.values()
    ).filter((el: any) => {
      return this.filterAnteposteEvent(el, this.filtersOutright)
    })
    for (let i = 0; i < this.filteredAntepostEvents.length; i += 1) {
      this.filteredAntepostEvents[i].antepostBetsMap =
        this.filteredAntepostEvents[i].antepostBetsMap.sort((a: any, b: any) =>
          a.code > b.code ? 1 : -1
        )

      this.filteredAntepostEvents[i].antepostBetsList =
        this.filteredAntepostEvents[i].antepostBetsMap
    }
    this.setSort(this.sort)
  }

  setSort = (value: number) => {
    this.sort = value
    this.filteredUpcomingEvents = this.filteredUpcomingEvents.sort(
      (a: Event, b: Event) => {
        if (SortDropdown[value].type === 'string') {
          return a.competitionName.localeCompare(b.competitionName)
        }
        if (SortDropdown[value].type === 'date') {
          return (
            new Date(
              SortDropdown[value].order === SortOrder.ASC ? a.start : b.start
            ).getTime() -
            new Date(
              SortDropdown[value].order === SortOrder.ASC ? b.start : a.start
            ).getTime()
          )
        }
        return 0
      }
    )
  }

  setSortPlayers = (value: number) => {
    this.sort = value
    this.filteredPlayerEvents = this.filteredPlayerEvents.sort(
      (a: Event, b: Event) => {
        if (SortDropdown[value].type === 'string') {
          return a.name.localeCompare(b.name)
        }
        if (SortDropdown[value].type === 'date') {
          return (
            new Date(
              SortDropdown[value].order === SortOrder.ASC ? a.start : b.start
            ).getTime() -
            new Date(
              SortDropdown[value].order === SortOrder.ASC ? b.start : a.start
            ).getTime()
          )
        }
        return 0
      }
    )
  }

  setSelectedMarketGroup = (marketGroup: any) => {
    this.selectedMarketGroup = marketGroup
  }

  filterEventsByName = (name: string) => {
    return this.allEvents.filter((event) =>
      event.name?.toLowerCase().includes(name.toLowerCase())
    )
  }

  getFilterConfig = async () => {
    try {
      const { data } = await this.api.getFilterConfig()
      this.filterConfig = data
    } catch (e) {
      console.log('filterConfig error', e)
      this.rootStore.user.logout()
      this.rootStore.user.isUserLoading = false
    }
    return null
  }

  filterDualEventsByCode = (code: number) => {
    const availablePrematchEvents = this.rootStore.feed.isPrematchOff
      ? []
      : this.upcomingEventsMap
    const availableLiveEvents = this.rootStore.feed.isLiveOff
      ? []
      : this.liveEventsMap
    return [
      ...Array.from(availablePrematchEvents.values()),
      ...Array.from(availableLiveEvents.values()),
    ].find((event) => event.landbaseCode === code)
  }
  // TODO: complete code search logic
  filterAntepostEventsByCode = (code: number) => {
    return Array.from(this.antepostEventsAndMarketsMap.values()).find(
      (event: any) => event.landbaseCode === code
    )
  }

  filterPlayerEventsByCode = (code: number) => {
    return Array.from(this.playerEventsMap.values()).find(
      (event: any) => event.eventCode === code
    )
  }

  filterOutcomes = (outcome: string, odds: any) => {
    const outcomes = this.outcomesList.filter((o) =>
      o.printCode.toLowerCase().includes(outcome.toLowerCase())
    )

    return outcomes.filter((o) => {
      return odds.some((odd: any) => odd.outcomeId === o.id)
    })
  }

  filterPlayerOutcomes = (outcome: string, odds: any) => {
    const outcomes = this.playOutcomesList.filter((o) =>
      o.printCode.toLowerCase().includes(outcome.toLowerCase())
    )

    return outcomes.filter((o) => {
      return odds.some((odd: any) => odd.outcomeId === o.id)
    })
  }

  filterOutcomesByCode = (code: string) => {
    return this.outcomesList.filter((o) => o.typeCode === code)
  }

  filterPlayerOutcomesByCode = (code: string) => {
    return this.playOutcomesList.filter((o) => o.typeCode === code)
  }

  addToFavourites = (event: Event) => {
    event.isFavourite = true
    // this.liveEvents = this.liveEvents.sort((a) => {
    //   return a.isFavourite ? -1 : 1
    // })
  }

  removeFromFavourites = (event: Event) => {
    event.isFavourite = false
    // this.liveEvents = this.liveEvents.sort((a, b) => {
    //   if (!!a.isFavourite === !!b.isFavourite) {
    //     return a.start.localeCompare(b.start)
    //   }
    //   return a.isFavourite ? -1 : 1
    // })
  }

  setSelectedLiveEvent = (event: Event) => {
    this.selectedLiveMatch = event
  }

  findMarketById = (id: number) => {
    return this.marketsList.find((m: any) => m.id === id)
  }
  findPlayerMarketById = (id: number, sportId: number) => {
    const sport = this.playerMarkets.find((m: any) => m.id === sportId)
    return sport?.marketsList.find((m: any) => m.id === id)
  }

  findEventById = (id: string) => {
    return this.allEvents.find((e: any) => e.id === id)
  }
}
