import React, { useContext, useRef } from 'react'
import StoreContext from 'store/StoreContext'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'
import SuspendOverlay from 'components/SuspendOverlay'
import styles from './marketsListing.module.css'

interface AntepostListingProps {
  event: any
}

export default observer(function AntepostMarketsListing({
  event,
}: AntepostListingProps) {
  const { sports, slip } = useContext(StoreContext)

  const checkIsSuspended = () => {
    if (sports.selectedAntepost && sports.selectedAntepost.isSuspended) {
      return true
    }
    return false
  }

  const refArray: any[] = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 600; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    refArray.push(useRef<HTMLSpanElement>(null))
  }

  const getOddClassName = (
    odd: any,
    isSuspended: boolean,
    inputReactPlayerVar: any
  ) => {
    inputReactPlayerVar?.current?.classList?.remove(styles.oddDownColor)
    inputReactPlayerVar?.current?.classList?.remove(styles.oddUpColor)
    inputReactPlayerVar?.current?.classList?.add(styles.oddDefaultColor)
    if (isSuspended) {
      inputReactPlayerVar?.current?.classList?.add(
        styles.oddColorMatchSuspended
      )
      return styles.oddColorMatchSuspended
    }
    if (odd.isDown) {
      inputReactPlayerVar?.current?.classList?.add(styles.oddDownColor)
      setTimeout(function () {
        inputReactPlayerVar?.current?.classList?.remove(styles.oddDownColor)
        inputReactPlayerVar?.current?.classList?.remove(styles.oddDownColor)
        inputReactPlayerVar?.current?.classList?.add(styles.oddDefaultColor)
        odd.isDown = false
        odd.isUp = false
      }, 6000)
      return styles.oddDownColor
    }
    if (odd.isUp) {
      inputReactPlayerVar?.current?.classList?.add(styles.oddUpColor)
      setTimeout(function () {
        inputReactPlayerVar?.current?.classList?.remove(styles.oddUpColor)
        inputReactPlayerVar?.current?.classList?.add(styles.oddDefaultColor)
        odd.isDown = false
        odd.isUp = false
      }, 6000)
      return styles.oddUpColor
    }
    return styles.oddDefaultColor
  }

  return (
    <div style={{ height: 'auto' }}>
      <div style={{ paddingTop: '5px', backgroundColor: '#000' }}>
        <Row gutter={8}>
          <Col
            span={12}
            key={event.intKey}
            style={{ marginBottom: '8px', position: 'relative' }}
          >
            {checkIsSuspended() && <SuspendOverlay />}
            <Row>
              <Col span={3} className={styles.matchCode}>
                {event.landbaseCode}
              </Col>
              <Col span={21} className={styles.matchHeader}>
                {event.name}
              </Col>
            </Row>
            <Row>
              {event.antepostBetsList.map((el: any, index: any) => {
                return (
                  <Col span={24} key={el.intKey}>
                    <Row>
                      {(el.isSuspended ||
                        el.oddValue?.toString() === '100' ||
                        el.oddValue?.toString() === '1') && <SuspendOverlay />}
                      <Col span={3} className={styles.matchColumn}>
                        {el.code}
                      </Col>
                      <Col span={18} className={styles.matchColumn}>
                        {el.name}
                      </Col>
                      <Col span={3}>
                        <div
                          ref={refArray[index]}
                          style={{ cursor: 'pointer' }}
                          className={`${styles.matchColumn} ${
                            slip.selectedOdd === el.intKey
                              ? styles.highlightedColumn
                              : ''
                          } ${getOddClassName(
                            el,
                            el.isSuspended,
                            refArray[index]
                          )}`}
                          onClick={() => {
                            slip.toggleAntepostEvent({
                              ...el,
                              ...event,
                              oIntKey: el.intKey,
                            })
                            if (
                              slip.checkIfItIsSameOdd({
                                ...el,
                                ...event,
                              })
                            ) {
                              if (!slip.checkIfPremachIsInSlip()) {
                                slip.setSelectedOdd(el.intKey)
                              }
                            }
                          }}
                        >
                          {el.oddValue?.toString() === '100' ||
                          el.oddValue?.toString() === '1'
                            ? ''
                            : (el?.oddValue / 100).toFixed(2)}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
})
