export const getAxiosBaseUrl = () => {
  return process.env.REACT_APP_API
}

const getApiBaseUrl = () => {
  return `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_GRPC_PATH}`
}
const getApiBaseUrlGo = () => {
  return `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_GRPC_PATH_GO}`
}

const getSocketBaseUrl = () => {
  return `${process.env.REACT_APP_SOCKET_PROTOCOL}://${process.env.REACT_APP_SOCKET_PATH}`
}

export const getApiUrl = (endpoint?: string) => {
  return `${getApiBaseUrl()}${endpoint ? `/${endpoint}` : ''}`
}

export const getApiUrlGo = (endpoint?: string) => {
  return `${getApiBaseUrlGo()}${endpoint ? `/${endpoint}` : ''}`
}

export const getSocketUrl = (endpoint?: string) => {
  return `${getSocketBaseUrl()}${endpoint ? `/${endpoint}` : ''}`
}
