import React, { useContext } from 'react'
import { SectionContainer } from 'components/SectionContainer'
import { Col, Row } from 'antd'
import StoreContext from 'store/StoreContext'
import LiveMatchSearch from 'components/LiveMatchSearch'
import LiveMatches from 'components/LiveMatches'
import LiveMatchDetails from 'components/LiveMatchDetails'
import { observer } from 'mobx-react'
import MarketsListing from 'components/MarketsListing'
import NoSelection from 'components/NoSelection'
import LiveMatchDetailsBasketball from '../LiveMatchDetailsBasketball'
import LiveMatchDetailsTableTennis from '../LiveMatchDetailsTableTennis'
import LiveMatchDetailsHockey from '../LiveMatchDetailsHockey'
import LiveMatchDetailsHandball from '../LiveMatchDetailsHandball'
import LiveMatchDetailsNFL from '../LiveMatchDetailsNFL'
import LiveMatchDetailsTennis from '../LiveMatchDetailsTennis'

export default observer(function Live() {
  const { sports, feed } = useContext(StoreContext)

  const getSportComponent = () => {
    if (sports.selectedLiveMatch) {
      if (
        sports.selectedLiveMatch.sportId === sports?.sportNames.get('Kosarka')
      ) {
        return <LiveMatchDetailsBasketball />
      }

      if (
        sports.selectedLiveMatch.sportId ===
          sports?.sportNames.get('Stoni tenis') ||
        sports.selectedLiveMatch.sportId === sports?.sportNames.get('Odbojka')
      ) {
        return <LiveMatchDetailsTableTennis />
      }

      if (
        sports.selectedLiveMatch.sportId === sports?.sportNames.get('Hokej')
      ) {
        return <LiveMatchDetailsHockey />
      }

      if (
        sports.selectedLiveMatch.sportId === sports?.sportNames.get('Tenis')
      ) {
        return <LiveMatchDetailsTennis />
      }

      if (
        sports.selectedLiveMatch.sportId === sports?.sportNames.get('Rukomet')
      ) {
        return <LiveMatchDetailsHandball />
      }

      if (
        sports.selectedLiveMatch.sportId ===
        sports?.sportNames.get('Americki fudbal')
      ) {
        return <LiveMatchDetailsNFL />
      }
    }
    return <LiveMatchDetails />
  }

  return (
    <>
      {feed.isLiveOff && <NoSelection translation="liveSuspended" />}
      {!feed.isLiveOff && (
        <Row gutter={2}>
          <Col span={9}>
            <SectionContainer translation="searchLiveMatches">
              <LiveMatchSearch />
            </SectionContainer>
            <SectionContainer translation="liveMatches">
              <LiveMatches />
            </SectionContainer>
          </Col>
          <Col span={15}>
            <SectionContainer translation="liveMatchDetails">
              {!sports.selectedLiveMatch && (
                <NoSelection translation="noSelection" />
              )}
              {sports.selectedLiveMatch && getSportComponent()}
            </SectionContainer>
            <SectionContainer translation="liveMatchBets">
              {!sports.selectedLiveMatch && (
                <NoSelection translation="noSelection" />
              )}
              {sports.selectedLiveMatch && (
                <MarketsListing event={sports.selectedLiveMatch} live />
              )}
            </SectionContainer>
          </Col>
        </Row>
      )}
    </>
  )
})
