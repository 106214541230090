import React from 'react'
import Prematch from 'components/Prematch'
import Players from 'components/Players'
import Outright from 'components/Outright'
import Live from 'components/Live'
import { RouteType } from './MainRoutes'

const SportBettingRoutes: RouteType[] = [
  {
    path: '/sport-betting',
    exact: true,
    component: <Prematch />,
    auth: true,
    translationKey: 'prematch',
    showInMenu: false,
  },
  {
    path: '/sport-betting/prematch',
    exact: true,
    component: <Prematch />,
    auth: true,
    translationKey: 'prematch',
    showInMenu: true,
  },
  {
    path: '/sport-betting/live',
    exact: true,
    component: <Live />,
    auth: true,
    translationKey: 'live',
    showInMenu: true,
  },
  {
    path: '/sport-betting/outright',
    exact: true,
    component: <Outright />,
    auth: true,
    translationKey: 'outright',
    showInMenu: true,
  },
  {
    path: '/sport-betting/players',
    exact: true,
    component: <Players />,
    auth: true,
    translationKey: 'players',
    showInMenu: true,
  },
]
export default SportBettingRoutes
