import axios, { AxiosInstance } from 'axios'

export const http: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    function (data, headers) {
      if (localStorage.getItem('token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      }

      /* if (!oc(data, 'axios.unsecured', false)) {
        
      }
      if (oc(data, 'axios', false)) {
        data.axios = {}
      } */
      return JSON.stringify(data)
    },
  ],
  transformResponse: [
    (data, headers) => {
      if (headers.authtoken) {
        /* securedStorage.setItem('token', headers.authtoken)
        RootStore.license.setToken(headers.authtoken) */
      }
      let result
      try {
        result = JSON.parse(data)
      } catch (e) {
        result = {}
      }
      return result
    },
  ],
})

http.interceptors.response.use(
  (response: any) => response,
  function (error) {
    return Promise.reject(error.response)
  }
)
